import {
  MessageTemplateButtonType,
  MessageTemplateComponentParameterType,
  MessageTemplateLanguageType,
} from "graphql/generated/types";

import { AvailableCategories } from "../data";

export enum TemplateRejectedReasons {
  "PROMOTIONAL" = "PROMOTIONAL",
  "INVALID_FORMAT" = "INVALID_FORMAT",
  "TAG_CONTENT_MISMATCH" = "TAG_CONTENT_MISMATCH",
  "ABUSIVE_CONTENT" = "ABUSIVE_CONTENT",
}

export enum TemplateStatuses {
  "APPROVED" = "APPROVED",
  "REJECTED" = "REJECTED",
  "PENDING" = "PENDING",
}
export type TTemplateStatus = TemplateStatuses.APPROVED | TemplateStatuses.PENDING | TemplateStatuses.REJECTED;

export enum TemplateCategories {
  "ACCOUNT_UPDATE" = "ACCOUNT_UPDATE",
  "ALERT_UPDATE" = "ALERT_UPDATE",
  "AUTO_REPLY" = "AUTO_REPLY",
  "APPOINTMENT_UPDATE" = "APPOINTMENT_UPDATE",
  "ISSUE_RESOLUTION" = "ISSUE_RESOLUTION",
  "PAYMENT_UPDATE" = "PAYMENT_UPDATE",
  "PERSONAL_FINANCE_UPDATE" = "PERSONAL_FINANCE_UPDATE",
  "RESERVATION_UPDATE" = "RESERVATION_UPDATE",
  "SHIPPING_UPDATE" = "SHIPPING_UPDATE",
  "TICKET_UPDATE" = "TICKET_UPDATE",
  "TRANSPORTATION_UPDATE" = "TRANSPORTATION_UPDATE",
}

export type TTemplateCategory =
  | TemplateCategories.ACCOUNT_UPDATE
  | TemplateCategories.ALERT_UPDATE
  | TemplateCategories.APPOINTMENT_UPDATE
  | TemplateCategories.AUTO_REPLY
  | TemplateCategories.ISSUE_RESOLUTION
  | TemplateCategories.PAYMENT_UPDATE
  | TemplateCategories.PERSONAL_FINANCE_UPDATE
  | TemplateCategories.RESERVATION_UPDATE
  | TemplateCategories.SHIPPING_UPDATE
  | TemplateCategories.TICKET_UPDATE
  | TemplateCategories.TRANSPORTATION_UPDATE;

export enum IComponentTypes {
  "BODY" = "BODY",
  "BUTTONS" = "BUTTONS",
  "FOOTER" = "FOOTER",
  "HEADER" = "HEADER",
}

export type TTemplateButtonPhoneNumber = {
  phoneNumber: string;
  text: string;
  type: MessageTemplateButtonType.PHONE_NUMBER;
  url: never;
};

export type TTemplateButtonURL = {
  phoneNumber: never;
  text: string;
  type: MessageTemplateButtonType.URL;
  url: string;
};

export type TTemplateButtonQuickReply = {
  phoneNumber: never;
  text: string;
  type: MessageTemplateButtonType.QUICK_REPLY;
  url: never;
};

export type TTemplateButtons = TTemplateButtonPhoneNumber | TTemplateButtonURL | TTemplateButtonQuickReply;

export type ITemplateHeaderComponent = {
  type: IComponentTypes.HEADER;
  headerText: string;
  format: MessageTemplateComponentParameterType;
};

export type ITemplateFooterComponent = {
  type: IComponentTypes.FOOTER;
  text: string;
};

export type ITemplateBodyComponent = {
  type: IComponentTypes.BODY;
  text: string;
};

export type ITemplateButtonsComponent = {
  type: IComponentTypes.BUTTONS;
  buttons: TTemplateButtons[];
};

export type TTemplateComponent =
  | ITemplateHeaderComponent
  | ITemplateFooterComponent
  | ITemplateBodyComponent
  | ITemplateButtonsComponent;

export interface ITemplateItem {
  uuid: string;
  name: string;
  language: MessageTemplateLanguageType;
  content: string;
  status: TTemplateStatus;
  category: TTemplateCategory;
  graphId: string;
  insertedAt: string;
  updatedAt: string;
  rejectedReason?: TemplateRejectedReasons;
  components: TTemplateComponent[];
  __typename: "MessageTemplateSchema";
}

export interface ITemplateItemCreateVariable {
  numberUuid: string;
  messageTemplate: {
    name: string;
    category: AvailableCategories;
    language: string;
    components: TTemplateComponent[];
  };
}

export interface ITemplatesContentData {
  number: {
    messageTemplates: ITemplateItem[];
    messageTemplatesCount: number;
    messageTemplatesLanguages: string[];
  };
}

export interface IGetTemplatesQuery {
  numberUuid?: string;
  language?: string;
  limit?: number;
  name?: string;
  offset?: number;
  orderBy?: string;
  search?: string;
  status?: string;
  withQuickReplyButtons?: boolean;
}

export interface ITemplateLanguage {
  code: string;
  name: string;
}
