import React from "react";

/*
--------------------------------------------
8 PX
--------------------------------------------
*/

export const Checkmark = ({ fill = "#929A9E", size = 8 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 8 8"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M5.70710678,8.29289322 C5.31658249,7.90236893 4.68341751,7.90236893 4.29289322,8.29289322 C3.90236893,8.68341751 3.90236893,9.31658249 4.29289322,9.70710678 L6.29289322,11.7071068 C6.73911042,12.153324 7.48200872,12.0797626 7.83205029,11.5547002 L11.8320503,5.5547002 C12.1384028,5.09517151 12.0142289,4.47430216 11.5547002,4.16794971 C11.0951715,3.86159725 10.4743022,3.98577112 10.1679497,4.4452998 L6.84457517,9.43036161 L5.70710678,8.29289322 Z"
      transform="translate(-4 -4)"
    />
  </svg>
);

export const InTransit = ({ fill = "#7A8285" }) => (
  <svg
    width="8px"
    height="8px"
    viewBox="0 0 8 8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>icon / 8 / transaction</title>
    <desc>Created with Sketch.</desc>
    <g id="icon-/-8-/-transaction" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M1,2.5 L6.4,2.5 M5.2,1 L6.7,2.5 L5.2,4 M7,5.5 L1.6,5.5 M2.8,4 L1.3,5.5 L2.8,7"
        id="Group"
        stroke={fill}
      ></path>
    </g>
  </svg>
);

/*
--------------------------------------------
12 PX
--------------------------------------------
*/

export const ImageFill = ({ fill }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M10.5,2 L8.809,2 L7.947,0.276 C7.86222872,0.106829542 7.6892216,0 7.5,0 L4.5,0 C4.3107784,0 4.13777128,0.106829542 4.053,0.276 L3.191,2 L1.5,2 C0.671572875,2 0,2.67157288 0,3.5 L0,10.5 C0,11.3284271 0.671572875,12 1.5,12 L10.5,12 C11.3284271,12 12,11.3284271 12,10.5 L12,3.5 C12,2.67157288 11.3284271,2 10.5,2 Z M6,9.5 C4.61928813,9.5 3.5,8.38071187 3.5,7 C3.5,5.61928813 4.61928813,4.5 6,4.5 C7.38071187,4.5 8.5,5.61928813 8.5,7 C8.5,8.38071187 7.38071187,9.5 6,9.5 L6,9.5 Z"
    />
  </svg>
);
export const AudioFill = ({ fill }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M6,9 C4.34314575,9 3,7.65685425 3,6 L3,3 C3,1.34314575 4.34314575,0 6,0 C7.65685425,0 9,1.34314575 9,3 L9,6 C9,7.65685425 7.65685425,9 6,9 Z M11,5.25 C11.4142136,5.25 11.75,5.58578644 11.75,6 C11.75,9.17563728 9.17563728,11.75 6,11.75 C2.82436272,11.75 0.25,9.17563728 0.25,6 C0.25,5.58578645 0.585786445,5.25 1,5.25 C1.41421355,5.25 1.75,5.58578645 1.75,6 C1.75,8.34721019 3.65278981,10.25 6,10.25 C8.34721019,10.25 10.25,8.34721019 10.25,6 C10.25,5.58578644 10.5857864,5.25 11,5.25 Z"
    />
  </svg>
);
export const VideoFill = ({ fill }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M10.5,1 L1.5,1 C0.671572875,1 0,1.67157288 0,2.5 L0,9.5 C0,10.3284271 0.671572875,11 1.5,11 L10.5,11 C11.3284271,11 12,10.3284271 12,9.5 L12,2.5 C12,1.67157288 11.3284271,1 10.5,1 Z M4,9 L4,3 L9,6 L4,9 Z"
    />
  </svg>
);

/*
--------------------------------------------
16 PX
--------------------------------------------
*/

export const LeftArrow = ({ fill = "#929a9e", ...props }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <path
      fill={fill}
      d="M3.68341017,5.8200922 L7.61588936,10.5390672 C7.7926714,10.7512057 8.10795375,10.7798677 8.3200922,10.6030857 C8.34332373,10.5837261 8.36475103,10.5622988 8.38411064,10.5390672 L12.3165898,5.8200922 C12.4933719,5.60795375 12.4647098,5.2926714 12.2525714,5.11588936 C12.1627139,5.04100811 12.0494474,5 11.9324792,5 L4.06752081,5 C3.79137843,5 3.56752081,5.22385763 3.56752081,5.5 C3.56752081,5.61696825 3.60852892,5.7302347 3.68341017,5.8200922 Z"
      transform="matrix(0 -1 -1 0 16 16)"
    />
  </svg>
);
export const RightArrow = ({ fill = "#929a9e", ...props }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <path
      fill={fill}
      d="M3.68341017,5.8200922 L7.61588936,10.5390672 C7.7926714,10.7512057 8.10795375,10.7798677 8.3200922,10.6030857 C8.34332373,10.5837261 8.36475103,10.5622988 8.38411064,10.5390672 L12.3165898,5.8200922 C12.4933719,5.60795375 12.4647098,5.2926714 12.2525714,5.11588936 C12.1627139,5.04100811 12.0494474,5 11.9324792,5 L4.06752081,5 C3.79137843,5 3.56752081,5.22385763 3.56752081,5.5 C3.56752081,5.61696825 3.60852892,5.7302347 3.68341017,5.8200922 Z"
      transform="rotate(-90 8 8)"
    />
  </svg>
);

export const Enlarge = ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M17,6 C17.5522847,6 18,6.44771525 18,7 L18,11 L16,11 L16,8 L13,8 L13,6 L17,6 Z M8,13 L8,16 L11,16 L11,18 L7,18 C6.44771525,18 6,17.5522847 6,17 L6,13 L8,13 Z M6,7 C6,6.44771525 6.44771525,6 7,6 L11,6 L11,8 L8,8 L8,11 L6,11 L6,7 Z M16,16 L16,13 L18,13 L18,17 C18,17.5522847 17.5522847,18 17,18 L13,18 L13,16 L16,16 Z"
    />
  </svg>
);

export const Archive = ({ fill = undefined }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M15,0 L1,0 C0.4,0 0,0.4 0,1 L0,15 C0,15.6 0.4,16 1,16 L15,16 C15.6,16 16,15.6 16,15 L16,1 C16,0.4 15.6,0 15,0 Z M14,2 L14,11 L11,11 C10.4,11 10,11.4 10,12 L10,13 L6,13 L6,12 C6,11.4 5.6,11 5,11 L2,11 L2,2 L14,2 Z M6.3,9.7 C6.8,10.2 7.3,10.1 7.7,9.7 L11.7,5.7 C12.1,5.3 12.1,4.7 11.7,4.3 C11.3,3.9 10.7,3.9 10.3,4.3 L7,7.6 L5.7,6.3 C5.3,5.9 4.7,5.9 4.3,6.3 C3.9,6.7 3.9,7.3 4.3,7.7 L6.3,9.7 Z"
    />
  </svg>
);
export const Assign = ({ fill = undefined }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M3,4 C1.8954305,4 1,3.1045695 1,2 C1,0.8954305 1.8954305,5.68434189e-14 3,5.68434189e-14 C4.1045695,5.68434189e-14 5,0.8954305 5,2 C5,3.1045695 4.1045695,4 3,4 Z M9,7 L5,7 L5,15 C5,15.5522847 4.55228475,16 4,16 C3.44771525,16 3,15.5522847 3,15 L3,11 L2,11 L2,15 C2,15.5522847 1.55228475,16 1,16 C0.44771525,16 0,15.5522847 0,15 L0,7 C0,5.8954305 0.8954305,5 2,5 L9,5 C9.55228475,5 10,5.44771525 10,6 C10,6.55228475 9.55228475,7 9,7 Z M15,1 C15.5522847,1 16,1.44771525 16,2 L16,11 C16,11.5522847 15.5522847,12 15,12 L12.618,12 L13.895,14.553 C14.1418713,15.0472949 13.9412949,15.6481287 13.447,15.895 C12.9527051,16.1418713 12.3518713,15.9412949 12.105,15.447 L10.382,12 L7,12 L7,10 L14,10 L14,3 L7,3 L7,1 L15,1 Z"
    />
  </svg>
);
export const More = ({ fill }) => (
  <svg
    width="4"
    height="18"
    viewBox="0 0 4 16"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M.5 6a1.5 1.5 0 1 1 3.001.001A1.5 1.5 0 0 1 .5 6zm0 4.5a1.5 1.5 0 1 1 3.001.001A1.5 1.5 0 0 1 .5 10.5zm0-9a1.5 1.5 0 1 1 3.001.001A1.5 1.5 0 0 1 .5 1.5z"
    />
  </svg>
);
export const Next = ({ fill }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M6.78132969,2.37532969 C6.43615172,1.9439953 5.80666408,1.87415172 5.37532969,2.21932969 C4.9439953,2.56450766 4.87415172,3.1939953 5.21932969,3.62532969 L8.71932969,8.00032969 L5.21932969,12.3753297 C4.87415172,12.8066641 4.9439953,13.4361517 5.37532969,13.7813297 C5.80666408,14.1265077 6.43615172,14.0566641 6.78132969,13.6253297 L10.7813297,8.62532969 C11.07383,8.25999689 11.07383,7.74066249 10.7813297,7.37532969 L6.78132969,2.37532969 Z"
    />
  </svg>
);
export const Previous = ({ fill }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M10.6253753,2.21941528 C10.4182711,2.05357939 10.153753,1.97684418 9.8900532,2.00610248 C9.62635337,2.03536078 9.38508975,2.16821403 9.21937525,2.37541528 L5.21937525,7.37541528 C4.92687492,7.74074808 4.92687492,8.26008248 5.21937525,8.62541528 L9.21937525,13.6254153 C9.56455322,14.0567497 10.1940409,14.1265932 10.6253753,13.7814153 C11.0567096,13.4362373 11.1265532,12.8067497 10.7813753,12.3754153 L7.28137525,8.00041528 L10.7813753,3.62541528 C10.9472111,3.41831117 11.0239464,3.15379307 10.9946881,2.89009323 C10.9654297,2.6263934 10.8325765,2.38512978 10.6253753,2.21941528 Z"
    />
  </svg>
);

export const Refresh = ({ fill }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M15.094,7.031 L14.555,0.82 L12.625,2.75 C11.353,1.632 9.71,1 8,1 C4.14,1 1,4.14 1,8 C1,11.86 4.14,15 8,15 C10.491,15 12.814,13.659 14.063,11.501 L12.332,10.5 C11.439,12.042 9.779,13 8,13 C5.243,13 3,10.757 3,8 C3,5.243 5.243,3 8,3 C9.179,3 10.311,3.423 11.205,4.17 L8.883,6.492 L15.094,7.031 Z"
    />
  </svg>
);

export const Remove = ({ fill, ...props }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <path
      d="M12.707,3.293 C12.3165001,2.90261806 11.6834999,2.90261806 11.293,3.293 L8,6.586 L4.707,3.293 C4.31462111,2.91402779 3.69091522,2.91944763 3.30518142,3.30518142 C2.91944763,3.69091522 2.91402779,4.31462111 3.293,4.707 L6.586,8 L3.293,11.293 C3.03304342,11.5440745 2.9287874,11.9158779 3.02030284,12.2655073 C3.11181828,12.6151368 3.38486324,12.8881817 3.73449268,12.9796972 C4.08412211,13.0712126 4.45592553,12.9669566 4.707,12.707 L8,9.414 L11.293,12.707 C11.6853789,13.0859722 12.3090848,13.0805524 12.6948186,12.6948186 C13.0805524,12.3090848 13.0859722,11.6853789 12.707,11.293 L9.414,8 L12.707,4.707 C13.0973819,4.31650015 13.0973819,3.68349985 12.707,3.293 Z"
      fill={fill}
    />
  </svg>
);
export const Settings = ({ fill = "#929A9E" }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="matrix(-1,7.65714e-16,-7.65714e-16,-1,16,16)">
      <path
        d="M11.8,5.613 L12.95,4.464 L11.536,3.05 L10.387,4.2 C9.96097007,3.92928214 9.49192343,3.73314151 9,3.62 L9,2 L7,2 L7,3.62 C6.50807657,3.73314151 6.03902993,3.92928214 5.613,4.2 L4.464,3.05 L3.05,4.464 L4.2,5.613 C3.92928214,6.03902993 3.73314151,6.50807657 3.62,7 L2,7 L2,9 L3.62,9 C3.73314151,9.49192343 3.92928214,9.96097007 4.2,10.387 L3.05,11.536 L4.464,12.95 L5.613,11.8 C6.03902993,12.0707179 6.50807657,12.2668585 7,12.38 L7,14 L9,14 L9,12.38 C9.49192343,12.2668585 9.96097007,12.0707179 10.387,11.8 L11.536,12.95 L12.95,11.536 L11.8,10.387 C12.0707179,9.96097007 12.2668585,9.49192343 12.38,9 L14,9 L14,7 L12.38,7 C12.2668585,6.50807657 12.0707179,6.03902993 11.8,5.613 Z M8,10 C6.8954305,10 6,9.1045695 6,8 C6,6.8954305 6.8954305,6 8,6 C9.1045695,6 10,6.8954305 10,8 C10,8.53043298 9.78928632,9.03914081 9.41421356,9.41421356 C9.03914081,9.78928632 8.53043298,10 8,10 Z"
        fill={fill}
      />
    </g>
  </svg>
);
export const Add = ({ fill = "#929A9E", size = 16 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M13,7 L9,7 L9,3 C9,2.44771525 8.55228475,2 8,2 C7.44771525,2 7,2.44771525 7,3 L7,7 L3,7 C2.44771525,7 2,7.44771525 2,8 C2,8.55228475 2.44771525,9 3,9 L7,9 L7,13 C7,13.5522847 7.44771525,14 8,14 C8.55228475,14 9,13.5522847 9,13 L9,9 L13,9 C13.5522847,9 14,8.55228475 14,8 C14,7.44771525 13.5522847,7 13,7 Z"
      fill={fill}
    />
  </svg>
);
export const Calendar = ({ fill }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M13,4 L12,4 L12,2 L10,2 L10,4 L6,4 L6,2 L4,2 L4,4 L3,4 C2.44771525,4 2,4.44771525 2,5 L2,13 C2,13.5522847 2.44771525,14 3,14 L13,14 C13.5522847,14 14,13.5522847 14,13 L14,5 C14,4.44771525 13.5522847,4 13,4 Z M12,12 L4,12 L4,7 L12,7 L12,12 Z"
      fill={fill}
    />
  </svg>
);
export const Selected = ({ fill, ...props }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <path
      d="M14.3,2.3 L5,11.6 L1.7,8.3 C1.3,7.9 0.7,7.9 0.3,8.3 C-0.1,8.7 -0.1,9.3 0.3,9.7 L4.3,13.7 C4.5,13.9 4.7,14 5,14 C5.3,14 5.5,13.9 5.7,13.7 L15.7,3.7 C16.1,3.3 16.1,2.7 15.7,2.3 C15.3,1.9 14.7,1.9 14.3,2.3 Z"
      fill={fill}
    />
  </svg>
);

export const Reply = ({ fill }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M6.5,0 C10.09,0 13,2.462 13,5.5 C13,8.538 10.09,11 6.5,11 C5.496,11 4.548,10.802 3.699,10.458 L1,12 L1,8.422 C0.37,7.574 0,6.575 0,5.5 C0,2.462 2.91,0 6.5,0 Z M16,9.5 C16,10.425 15.627,11.285 14.988,12 L15,12 L15,16 L11.307,13.89 C10.886,13.96 10.449,14 10,14 C8.556,14 7.231,13.617 6.195,12.979 C6.298,12.982 6.397,13 6.5,13 C10.66,13 14.124,10.348 14.853,6.861 C15.571,7.603 16,8.513 16,9.5 Z"
      fill={fill}
    />
  </svg>
);
export const LightBulb = ({ fill }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M14,6 C14,8.2 12.8,10.2 11,11.2 L11,13 L5,13 L5,11.2 C3.2,10.2 2,8.2 2,6 C2,2.7 4.7,0 8,0 C11.3,0 14,2.7 14,6 Z M7,16 C5.9,16 5,15.1 5,14 L11,14 C11,15.1 10.1,16 9,16 L7,16 Z"
      fill={fill}
    />
  </svg>
);

export const Trash = ({ fill = "#929A9E", size = "16", ...props }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3335 2.66667H12.0002C12.3668 2.66667 12.6668 2.96667 12.6668 3.33333C12.6668 3.7 12.3668 4 12.0002 4H4.00016C3.6335 4 3.3335 3.7 3.3335 3.33333C3.3335 2.96667 3.6335 2.66667 4.00016 2.66667H5.66683L6.14016 2.19333C6.26016 2.07333 6.4335 2 6.60683 2H9.3935C9.56683 2 9.74016 2.07333 9.86016 2.19333L10.3335 2.66667ZM5.3335 14C4.60016 14 4.00016 13.4 4.00016 12.6667V6C4.00016 5.26667 4.60016 4.66667 5.3335 4.66667H10.6668C11.4002 4.66667 12.0002 5.26667 12.0002 6V12.6667C12.0002 13.4 11.4002 14 10.6668 14H5.3335Z"
      fill={fill}
    />
  </svg>
);
export const Upload = ({ fill }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M12,9 L14,9 L14,13 C14,13.5522847 13.5522847,14 13,14 L3,14 C2.44771525,14 2,13.5522847 2,13 L2,9 L4,9 L4,12 L12,12 L12,9 Z M7,10 L7,6 L4.5,6 L8,2 L11.5,6 L9,6 L9,10 L7,10 Z"
    />
  </svg>
);

/*
--------------------------------------------
24 PX
--------------------------------------------
*/
export const Down = ({ fill = "#929A9E", size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <polyline
      fill="none"
      stroke={fill}
      strokeWidth="2"
      points="7.086 3.174 17.086 13.174 7.086 23.174"
      transform="scale(1 -1) rotate(-89 -1.32 0)"
    ></polyline>
  </svg>
);

export const Sound = ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M14.447,1.105 C14.786,1.275 15,1.621 15,2 L15,22 C15,22.379 14.786,22.725 14.447,22.895 C14.306,22.965 14.152,23 14,23 C13.788,23 13.576,22.933 13.4,22.8 L5.667,17 L1,17 C0.447,17 0,16.553 0,16 L0,8 C0,7.447 0.447,7 1,7 L5.667,7 L13.4,1.2 C13.703,0.973 14.109,0.938 14.447,1.105 Z M18.243,7.758 C20.582,10.097 20.582,13.903 18.243,16.242 L17.536,16.949 L16.122,15.535 L16.829,14.828 C18.389,13.269 18.389,10.732 16.829,9.172 L16.122,8.465 L17.536,7.051 L18.243,7.758 Z M20.364,4.222 L21.071,4.928 C24.97,8.828 24.97,15.172 21.071,19.071 L20.364,19.778 L18.95,18.364 L19.657,17.657 C22.776,14.538 22.776,9.462 19.657,6.343 L18.95,5.636 L20.364,4.222 Z"
    />
  </svg>
);
export const SoundMute = ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M19,3.586 L22.293,0.293 C22.684,-0.098 23.316,-0.098 23.707,0.293 C24.098,0.684 24.098,1.316 23.707,1.707 L1.707,23.707 C1.512,23.902 1.256,24 1,24 C0.744,24 0.488,23.902 0.293,23.707 C-0.098,23.316 -0.098,22.684 0.293,22.293 L5.586,17 L2,17 C1.447,17 1,16.553 1,16 L1,8 C1,7.447 1.447,7 2,7 L9.667,7 L17.4,1.2 C17.703,0.973 18.109,0.938 18.447,1.105 C18.786,1.275 19,1.621 19,2 L19,3.586 Z M10.567,17.675 L19,9.242 L19,22 C19,22.379 18.786,22.725 18.447,22.895 C18.306,22.965 18.152,23 18,23 C17.788,23 17.576,22.933 17.4,22.8 L10.567,17.675 Z"
    />
  </svg>
);

/*
--------------------------------------------
40+ PX
--------------------------------------------
*/

export const VideoAttachmentSmall = ({ fill }) => (
  <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 36c-8.837 0-16-7.163-16-16S11.163 4 20 4s16 7.163 16 16-7.163 16-16 16zm4.6-16.8l-6-4a1 1 0 0 0-1.6.8v8c0 .8.9 1.3 1.6.8l6-4c.5-.4.5-1.2 0-1.6z"
      fill={fill}
      fillRule="evenodd"
    />
  </svg>
);

export const StickerAttachment = ({ width = "36px", height = "36px" }) => (
  <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18" cy="18" r="18" fill="#00C2CC" />
    <rect x="12" y="8" width="16" height="16" rx="2" fill="#00979E" />
    <path fillRule="evenodd" clipRule="evenodd" d="M25.375 11.5H10V26.875H20.9146L25.375 22.4146V11.5Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.625 12.25C10.625 11.9048 10.9048 11.625 11.25 11.625H23.7499C24.0951 11.625 24.3749 11.9048 24.3749 12.25V19.3851H22.3186C19.8386 19.3851 18.744 21.4828 18.7421 22.9588L18.7421 25.3749H11.25C10.9048 25.3749 10.625 25.0951 10.625 24.7499V12.25ZM20.8872 27.2141L26.2499 21.8514V12.25C26.2499 10.8693 25.1306 9.75 23.7499 9.75H11.25C9.86929 9.75 8.75 10.8693 8.75 12.25V24.7499C8.75 26.1306 9.86929 27.2499 11.25 27.2499H18.7421V27.2503H20.8872V27.2141ZM20.8872 24.5624V22.961C20.8877 22.662 21.005 22.2832 21.2462 21.995C21.4634 21.7354 21.7929 21.5302 22.3186 21.5302H23.9194L20.8872 24.5624Z"
      fill="#08415C"
    />
  </svg>
);

export const LocationAttachment = ({ width = "48px", height = "48px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M24,0 C37.2456,0 48,10.75392 48,24 C48,37.24608 37.2456,48 24,48 C10.75392,48 0,37.24608 0,24 C0,10.75392 10.75392,0 24,0 Z"
        id="path-1"
      ></path>
    </defs>
    <g id="icon-/-48-/-location-pin" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <mask id="mask-2" fill="white">
        <use xlinkHref="#path-1"></use>
      </mask>
      <use id="Mask" fill="#00C2CC" xlinkHref="#path-1"></use>
      <rect id="Rectangle" fill="#00C2CC" mask="url(#mask-2)" x="7.2" y="43.2" width="31.2" height="7.2"></rect>
      <path
        d="M24,34 C24,34 16,28 16,22 C16,17 20,14 24,14 C28,14 32,17 32,22 C32,28 24,34 24,34 Z M24,25 C25.657,25 27,23.657 27,22 C27,20.343 25.657,19 24,19 C22.343,19 21,20.343 21,22 C21,23.657 22.343,25 24,25 L24,25 Z"
        id="Path"
        stroke="#08415C"
        strokeWidth="2"
        fill="#FFFFFF"
        mask="url(#mask-2)"
      ></path>
      <rect id="Rectangle" fill="#00979E" mask="url(#mask-2)" x="19" y="36" width="10" height="2" rx="1"></rect>
    </g>
  </svg>
);

export const Action = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#00C2CC"
        d="M24,0 C37.246,0 48,10.754 48,24 C48,37.246 37.246,48 24,48 C10.754,48 0,37.246 0,24 C0,10.754 10.754,0 24,0 Z"
      />
      <path
        fill="#08415C"
        d="M0.481,28.8 L31.4058,28.8 L29.8544,27.2485 C29.3857,26.7799 29.3857,26.0201 29.8544,25.5515 C30.323,25.0828 31.0828,25.0828 31.5514,25.5515 L36,30 L31.5514,34.4485 C31.0828,34.9172 30.323,34.9172 29.8544,34.4485 C29.3857,33.9799 29.3857,33.2201 29.8544,32.7515 L31.4058,31.2 L1.1005,31.2 C0.8541,30.4165 0.6468,29.6158 0.481,28.8 Z"
      />
      <path
        fill="#FFF"
        d="M16.0942,19.2 L17.6456,20.7515 C18.1143,21.2201 18.1143,21.9799 17.6456,22.4485 C17.177,22.9172 16.4172,22.9172 15.9486,22.4485 L11.5,18 L15.9486,13.5515 C16.4172,13.0828 17.177,13.0828 17.6456,13.5515 C18.1143,14.0201 18.1143,14.7799 17.6456,15.2485 L16.0942,16.8 L46.8995,16.8 C47.1459,17.5835 47.3532,18.3842 47.519,19.2 L16.0942,19.2 Z"
      />
    </g>
  </svg>
);

export const VideoAttachment = ({ fill = "#929A9E", fill2 = "#E7EAEB", size = 48 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill={fill}
        d="M23.5,0 C36.4700417,0 47,10.5299583 47,23.5 C47,36.4700417 36.4700417,47 23.5,47 C10.5299583,47 0,36.4700417 0,23.5 C0,10.5299583 10.5299583,0 23.5,0 Z"
      />
      <polygon
        fill={fill2}
        points="20.109 15.053 20.211 15.061 20.312 15.075 20.413 15.094 20.512 15.118 20.61 15.148 20.706 15.182 20.801 15.222 20.893 15.266 20.983 15.316 32.983 22.316 33.071 22.371 33.157 22.43 33.239 22.494 33.318 22.563 33.393 22.635 33.464 22.711 33.53 22.791 33.593 22.875 33.651 22.962 33.704 23.052 33.752 23.144 33.795 23.239 33.833 23.336 33.866 23.435 33.894 23.535 33.916 23.637 33.933 23.74 33.944 23.844 33.949 23.948 33.949 24.052 33.944 24.156 33.933 24.26 33.916 24.363 33.894 24.465 33.866 24.565 33.833 24.664 33.795 24.761 33.752 24.856 33.704 24.948 33.651 25.038 33.593 25.125 33.53 25.209 33.464 25.289 33.393 25.365 33.318 25.437 33.239 25.506 33.157 25.57 33.071 25.63 32.983 25.684 20.983 32.684 20.893 32.734 20.801 32.778 20.706 32.818 20.61 32.852 20.512 32.882 20.413 32.906 20.312 32.925 20.211 32.939 20.109 32.947 20.007 32.95 19.904 32.948 19.802 32.94 19.701 32.927 19.6 32.909 19.501 32.885 19.402 32.856 19.306 32.822 19.211 32.783 19.119 32.739 19.029 32.691 18.941 32.638 18.857 32.58 18.776 32.518 18.698 32.452 18.623 32.381 18.553 32.307 18.486 32.229 18.424 32.148 18.366 32.064 18.312 31.977 18.263 31.887 18.219 31.795 18.18 31.7 18.146 31.604 18.117 31.506 18.093 31.406 18.074 31.306 18.061 31.204 18.053 31.102 18.05 31 18.05 17 18.053 16.898 18.061 16.796 18.074 16.694 18.093 16.594 18.117 16.494 18.146 16.396 18.18 16.3 18.219 16.205 18.263 16.113 18.312 16.023 18.366 15.936 18.424 15.852 18.486 15.771 18.553 15.693 18.623 15.619 18.698 15.549 18.776 15.482 18.857 15.42 18.941 15.362 19.029 15.309 19.119 15.261 19.211 15.217 19.306 15.178 19.402 15.144 19.501 15.115 19.6 15.091 19.701 15.073 19.802 15.06 19.904 15.052 20.007 15.05"
      />
    </g>
  </svg>
);

export const QuestionCircleIcon = ({ fill = "#6BA043", textFill = "#fff", ...props }) => (
  <svg width={14} height={14} viewBox="0 0 14 14" fill="none" {...props}>
    <circle cx={7} cy={7} r={7} fill={fill} />
    <path
      d="M5.512 4.585C5.88 4.193 6.401 4 7 4c1.266 0 2 .97 2 1.765 0 .507-.178.892-.466 1.18-.246.246-.556.4-.772.508l-.028.014a.582.582 0 00-.13.081.44.44 0 00-.01.069 3.6 3.6 0 00-.006.266v.47H6.412v-.512c0-.197-.002-.48.1-.736.133-.33.389-.536.696-.69.254-.127.397-.205.494-.301.065-.065.122-.15.122-.35 0-.145-.208-.588-.824-.588-.342 0-.527.104-.63.214-.109.116-.194.311-.194.61H5c0-.518.15-1.029.512-1.415zM6.412 9.765v-.941h1.176v.941H6.412z"
      fill={textFill}
    />
  </svg>
);

export const StatusWarningIconOutline = ({ stroke = "#E96B41", props }) => (
  <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
    <title>icon / 16 / status-warning-outline</title>
    <path
      d="M8 2l6.667 12H1.333L8 2zm0 4v4m0 .667V12"
      stroke={stroke}
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinejoin="round"
    />
  </svg>
);

export const CircleQuestion = (props) => (
  <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
    <title>98FA72EF-4655-4E28-ACB3-A8E9B5A93905</title>
    <path
      d="M8 14.667A6.667 6.667 0 108 1.333a6.667 6.667 0 000 13.334zM8 10v-.667c0-.666 0-1 .667-1.333C9.333 7.667 10 7.333 10 6.333c0-.666-.667-1.666-2-1.666s-2 .842-2 2m2 4V12"
      stroke="#929A9E"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);
