import React, { useRef } from "react";

import { FieldWrapper } from "../FieldWrapper";
import { TIcon } from "../FieldWrapper/Icon";
import { getPrompt } from "../FieldWrapper/utils";
import { StyledOption, StyledSelect } from "./styles";
import { TSelectOption } from "./types";

export * from "./types";

type TTextInputProps = Omit<JSX.IntrinsicElements["select"], "value" | "onChange" | "ref">;
export interface ISelectProps extends TTextInputProps {
  name: string;
  label?: string;
  error?: string;
  warning?: string;
  success?: string;
  options: TSelectOption[];
  value?: TSelectOption;
  onChange: (value: TSelectOption) => unknown;
  icon?: TIcon;
}

export const Select = ({
  label,
  name,
  id,
  error,
  warning,
  success,
  options,
  value,
  onChange,
  placeholder,
  icon,
  ...props
}: ISelectProps) => {
  const selectRef = useRef<HTMLSelectElement>(null);
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const option = options.find((option) => option.value === e.target.value) || options[0];
    onChange(option);
    selectRef.current?.blur();
  };
  return (
    <FieldWrapper
      label={label}
      prompt={getPrompt({ error, warning, success })}
      labelProps={{ htmlFor: id || name }}
      icon={icon}
    >
      <StyledSelect
        ref={selectRef}
        onChange={handleChange}
        value={value?.value || placeholder}
        data-test-gl="select"
        id={id || name}
        {...props}
      >
        {placeholder && (
          <StyledOption disabled hidden className="ph-no-capture">
            {placeholder}
          </StyledOption>
        )}
        {options.map((option, index) => (
          <StyledOption key={index} value={option.value} className="ph-no-capture">
            {option.name}
          </StyledOption>
        ))}
      </StyledSelect>
    </FieldWrapper>
  );
};
