import { FontSize10, FontSize50, FontSize100, FontSize400, FontSize700, FontSize900 } from "ds/Core";
import _kebabCase from "lodash/kebabCase.js";

import { AvatarProps } from "./types";

const getStyles = (props: any) =>
  Object.entries(props).reduce(
    (styles, [key, value]) =>
      key === "hover" ? `${styles}\n&:hover {${getStyles(value)}}` : `${styles}\n${_kebabCase(key)}: ${value};`,
    ""
  );

export const getAvatarStyles = ({ size }: AvatarProps) => `
    ${size && getStyles(theme.size[size])}
  `;

export const theme = {
  size: {
    xxxsmall: {
      width: "16px",
      minWidth: "16px",
      height: "16px",
      fontSize: FontSize10,
    },
    xxsmall: {
      width: "20px",
      minWidth: "20px",
      height: "20px",
      fontSize: FontSize10,
    },
    xsmall: {
      width: "24px",
      minWidth: "24px",
      height: "24px",
      fontSize: FontSize50,
    },
    small: {
      width: "32px",
      minWidth: "32px",
      height: "32px",
      fontSize: FontSize100,
    },
    medium: {
      width: "40px",
      minWidth: "40px",
      height: "40px",
      fontSize: FontSize400,
    },
    large: {
      width: "96px",
      minWidth: "96px",
      height: "96px",
      fontSize: FontSize700,
    },
    xlarge: {
      width: "104px",
      minWidth: "104px",
      height: "104px",
      fontSize: FontSize900,
    },
  },
};
