import * as React from "react";

import { theme } from "../styles/theme";
import { TIconProps, getIconSize } from "./types";

export const QuestionRoundIcon = ({ fill = theme.colors.black, size = "m", ...props }: TIconProps) => {
  const sizes = getIconSize(size);
  return (
    <svg width={sizes} height={sizes} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 1.463c4.712 0 8.537 3.825 8.537 8.537 0 4.712-3.825 8.537-8.537 8.537-4.712 0-8.537-3.825-8.537-8.537 0-4.712 3.825-8.537 8.537-8.537zm-.537 12.625a.146.146 0 00-.146.146v1.473c0 .081.066.146.146.146h1.074a.146.146 0 00.146-.146v-1.473a.146.146 0 00-.146-.146h-1.074zm1.243-1.256a.098.098 0 01-.098.097l-1.267-.002a.097.097 0 01-.098-.098l.003-1.306a2.48 2.48 0 01.905-1.91l.85-.699.433-.356a1.36 1.36 0 00.496-1.048v-.001a1.53 1.53 0 00-1.407-1.529 15.803 15.803 0 00-.212-.017 3.177 3.177 0 00-2.313.742l-.589.498a.097.097 0 01-.137-.012l-.818-.967a.098.098 0 01.01-.137l.59-.499a4.64 4.64 0 013.376-1.083l.213.017a2.994 2.994 0 012.749 2.988v.001a2.822 2.822 0 01-1.03 2.177l-.57.468-.713.587c-.235.192-.37.48-.371.783l-.001.734-.001.572z"
        fill={fill}
      />
    </svg>
  );
};
