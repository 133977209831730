import linkifyHtml from "linkify-html";
import _isUndefined from "lodash/isUndefined";

import { replaceAll } from "utils/string";

import { safelyParseHtml } from "./html";

interface WhatsAppRemarkPluginProps {
  hideReplacer?: boolean;
  useLinkMarkdown?: boolean;
}

export const WhatsAppRemark = (
  value?: string | null,
  { hideReplacer, useLinkMarkdown }: WhatsAppRemarkPluginProps = {}
) => {
  if (!value) return value;

  value = replaceAll(value, "<", "&lt;");
  value = replaceAll(value, ">", "&gt;");
  value = MarkUp(replaceAll(value?.toString(), "```", "`"), "`", "<tt>", "</tt>");
  value = MarkUp(value, "_", "<em>", "</em>");
  value = MarkUp(value, "*", "<strong>", "</strong>");
  value = MarkUp(value, "~", "<del>", "</del>");
  if (!hideReplacer) {
    value = value?.replace(/\n/gi, "<br>");
    value = replaceAll(value, "↵", "<br>");
  } else {
    value = replaceAll(value, "↵", " ");
  }
  if (useLinkMarkdown) {
    // eslint-disable-next-line no-useless-escape
    value = value?.replace(/\[([^\]]+)\]\(([^\)]+)\)/gi, '<a href="$2" target="_blank">$1</a>');
  }

  return safelyParseHtml(
    linkifyHtml(value!, {
      validate: {
        url: (value) => /^https?:\/\//.test(value),
      },
    })
  );
};

const isAlphaNumeric = (c) => {
  const x = c.charCodeAt();
  return (x >= 65 && x <= 90) || (x >= 97 && x <= 122) || (x >= 48 && x <= 57);
};

const MarkUp = (format, wildcard, opTag, clTag) => {
  const indices: number[] = [];
  if (format) {
    for (let i = 0; i < format.length; i++) {
      if (format[i] === wildcard && format[i + 1] !== wildcard) {
        if (indices.length % 2) {
          if (format[i - 1] && format[i - 1].length === 1) {
            if (_isUndefined(format[i + 1])) {
              indices.push(i);
            } else if (!isAlphaNumeric(format[i + 1])) {
              indices.push(i);
            }
          }
        } else {
          if (!_isUndefined(format[i + 1])) {
            if (format[i + 1].length === 1) {
              if (_isUndefined(format[i - 1])) {
                indices.push(i);
              } else if (!isAlphaNumeric(format[i - 1])) {
                indices.push(i);
              }
            }
          }
        }
      } else {
        if (format[i].charCodeAt() === 10 && indices.length % 2) {
          indices.pop();
        }
      }
    }
  }
  if (indices.length % 2) {
    indices.pop();
  }
  let e = 0;
  indices.forEach((v, i) => {
    const t = i % 2 ? clTag : opTag;
    v += e;
    format = format.substr(0, v) + t + format.substr(v + 1);
    e += t.length - 1;
  });
  return format;
};
