import React from "react";

const colors = [
  { font: "#E87F47", background: "#FFF3EC" },
  { font: "#00979E", background: "#E8FDFF" },
  { font: "#6BA043", background: "#E9FFF6" },
  { font: "#8155A1", background: "#FAF4FF" },
  { font: "#E25F84", background: "#FFF5F8" },
  { font: "#E8A004", background: "#FFF8EA" },
  { font: "#99AD35", background: "#F6FAE1" },
  { font: "#1B82CC", background: "#DEFAFD" },
];

export const SVGAvatar = ({ name = "UN" }) => {
  const names = name.split(/[\s\-_—]+/);
  const label = names.length > 1 ? `${[...names[0]][0]}${[...names[1]][0]}` : [...name].slice(0, 2).join("");
  const getColor = () => {
    const sum = name.split("").reduce((sum, char) => sum + char.charCodeAt(0), 0);
    const colorNumber = Math.round((7 * +sum.toString().slice(-1)) / 10);
    return colors[colorNumber];
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40">
      <circle fill={getColor().background} cx="20" cy="20" r="20"></circle>
      <text fill={getColor().font} fontSize="16" fontFamily="sans-serif" textAnchor="middle" x="20" y="26">
        {label.toUpperCase()}
      </text>
    </svg>
  );
};
