import gql from "graphql-tag";

import { boundary, required } from "api/boundary";

import { MESSAGE_TEMPLATE_DETAILS_FRAGMENT } from "./fragments";

export const GET_MESSAGE_TEMPLATES = {
  query: gql`
    query getNumberMessageTemplates(
      $numberUuid: ID!
      $language: String
      $limit: Int
      $name: String
      $offset: Int
      $orderBy: String
      $search: String
      $status: MessageTemplateStatus
      $withQuickReplyButtons: Boolean
    ) {
      number(numberUuid: $numberUuid) {
        uuid
        messageTemplatesLanguages
        messageTemplatesCount(
          language: $language
          name: $name
          search: $search
          status: $status
          withQuickReplyButtons: $withQuickReplyButtons
        )
        messageTemplates(
          language: $language
          limit: $limit
          name: $name
          offset: $offset
          orderBy: $orderBy
          search: $search
          status: $status
          withQuickReplyButtons: $withQuickReplyButtons
        ) {
          ...MessageTemplateDetails
        }
      }
    }

    ${MESSAGE_TEMPLATE_DETAILS_FRAGMENT}
  `,
  parse: boundary({
    number: {
      messageTemplatesLanguages: required(),
      messageTemplatesCount: required(),
      messageTemplates: {
        uuid: required(),
      },
    },
  }),
};

export const GET_MESSAGE_TEMPLATE = gql`
  query getMessageTemplate($numberUuid: ID!, $language: String, $name: String) {
    number(numberUuid: $numberUuid) {
      uuid
      messageTemplates(language: $language, name: $name) {
        uuid
        name
        language
        content
        components {
          type
          ... on MessageTemplateSchemaComponentBody {
            text
          }
          ... on MessageTemplateSchemaComponentHeader {
            format
            # context(justinvdm, 23 August 2021): Alias as a workaround:
            # codegen fails since "text" is non-null in some implementations
            # and null in others
            headerText: text
          }
          ... on MessageTemplateSchemaComponentFooter {
            text
          }
          ... on MessageTemplateSchemaComponentButtons {
            buttons {
              type
              text
              ... on MessageTemplateSchemaButtonPhoneNumber {
                phoneNumber
              }
              ... on MessageTemplateSchemaButtonUrl {
                url
              }
            }
          }
        }
      }
    }
  }
`;
