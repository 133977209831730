import styled from "styled-components";

import { colors } from "lib/impact/styles/colors";

export const StyledCountryItem = styled.li`
  padding: ${({ theme: { spaces } }) => `${spaces.s + spaces.xs}px ${spaces.s}px`};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;

  &:hover {
    background-color: ${colors["light-2"]};
  }
`;
