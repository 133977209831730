import React, { createContext, useMemo, useReducer } from "react";

import { TURN_HOST, TURN_LOGIN_STATE, TURN_WS_HOST } from "_constants";
import { RoleType } from "graphql/generated/types";
import { TOrganisationDetails } from "graphql/organisation";
import { TurnHostInfo } from "types";
import { saveLastOrganisation } from "utils/localStorage";

import {
  setChatUuid,
  setCurrentOrganisation,
  setIsAdmin,
  setMyAccountUuid,
  setNumberUuid,
  setOrganisationUuid,
  setRole,
  setVisibleMenu,
  set_UNSAFE_NumberUuid,
  set_UNSAFE_OrganisationUuid,
} from "./actions";
import { IGlobalState, INITIAL_GLOBAL_STATE, reducer } from "./state";

export interface IGlobalContext extends IGlobalState {
  logOut: () => void;
  turnHostInfo: TurnHostInfo;
  setChatUuid: (payload?: string) => void;
  setNumberUuid: (payload?: string) => void;
  setOrganisationUuid: (payload?: string) => void;
  setVisibleMenu: (payload: boolean) => void;
  setIsAdmin: (payload: boolean) => void;
  setRole: (payload?: RoleType) => void;
  setMyAccountUuid: (uuid: string) => void;
  setCurrentOrganisation: (organisation?: TOrganisationDetails) => void;
}

export const GlobalContext = createContext<IGlobalContext>({
  ...INITIAL_GLOBAL_STATE,
  logOut: () => {},
  turnHostInfo: {
    turn_host: TURN_HOST,
    turn_ws_host: TURN_WS_HOST,
    login_state: TURN_LOGIN_STATE,
  },
  setChatUuid: () => {},
  setNumberUuid: () => {},
  setOrganisationUuid: () => {},
  setVisibleMenu: () => {},
  setIsAdmin: () => {},
  setRole: () => {},
  setMyAccountUuid: () => {},
  setCurrentOrganisation: () => {},
});
interface IGlobalProviderProps {
  children: React.ReactNode;
  logOut: () => void;
  turnHostInfo: TurnHostInfo;
  menuVisible?: boolean;
}

export const GlobalProvider = ({ children, logOut, turnHostInfo }: IGlobalProviderProps) => {
  const [
    {
      chatUuid,
      numberUuid,
      UNSAFE_numberUuid,
      UNSAFE_organisationUuid,
      organisationUuid,
      menuVisible,
      isAdmin,
      role,
      myAccountUuid,
      currentOrganisation,
    },
    dispatch,
  ] = useReducer(reducer, INITIAL_GLOBAL_STATE);

  const stateSetters = useMemo(
    () => ({
      setChatUuid: (uuid?: string) => dispatch(setChatUuid(uuid)),
      setNumberUuid: (uuid?: string) => {
        dispatch(set_UNSAFE_NumberUuid(uuid));
        dispatch(setNumberUuid(uuid ?? ""));
      },
      setOrganisationUuid: (uuid?: string) => {
        saveLastOrganisation(uuid);
        dispatch(set_UNSAFE_OrganisationUuid(uuid));
        dispatch(setOrganisationUuid(uuid ?? ""));
      },
      setVisibleMenu: (visible: boolean) => dispatch(setVisibleMenu(visible)),
      setIsAdmin: (isAdmin: boolean) => dispatch(setIsAdmin(isAdmin)),
      setRole: (role?: RoleType) => dispatch(setRole(role || undefined)),
      setMyAccountUuid: (uuid: string) => dispatch(setMyAccountUuid(uuid)),
      setCurrentOrganisation: (organisation?: TOrganisationDetails) => dispatch(setCurrentOrganisation(organisation)),
    }),
    []
  );

  return (
    <GlobalContext.Provider
      value={{
        chatUuid,
        logOut,
        numberUuid,
        UNSAFE_numberUuid,
        organisationUuid,
        UNSAFE_organisationUuid,
        turnHostInfo,
        menuVisible,
        isAdmin,
        role,
        myAccountUuid,
        currentOrganisation,
        ...stateSetters,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
