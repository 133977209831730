/* tslint:disable */

/* eslint-disable */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as Types from "../../generated/types";
import { SubscriptionDetailsFragment } from "./fragments.generated";
import { SubscriptionDetailsFragmentDoc } from "./fragments.generated";

const defaultOptions = {} as const;
export type UpdateSubscriptionNumberEmailMutationVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  number: Types.NumberIo;
}>;

export type UpdateSubscriptionNumberEmailMutation = {
  __typename: "RootMutationType";
  updateNumber: ({ __typename: "Number" } & SubscriptionDetailsFragment) | null;
};

export type SwitchCollectionModeToAutomaticMutationVariables = Types.Exact<{
  subscriptionId: Types.Scalars["String"];
  targetPrice?: Types.InputMaybe<Types.SwitchTargetPrice>;
  chargePastDueOnAutomatic?: Types.InputMaybe<Types.Scalars["Boolean"]>;
}>;

export type SwitchCollectionModeToAutomaticMutation = {
  __typename: "RootMutationType";
  switchCollectionModeToAutomatic: {
    __typename: "SwitchRequest";
    checkoutUrl: string | null;
    status: Types.SwitchRequestStatus | null;
  };
};

export type GetOrCreatePaddleCustomerMutationVariables = Types.Exact<{
  customerEmail: Types.Scalars["String"];
  customerName: Types.Scalars["String"];
  numberUuid: Types.Scalars["ID"];
}>;

export type GetOrCreatePaddleCustomerMutation = { __typename: "RootMutationType"; getOrCreatePaddleCustomer: string };

export type PausePaddleSubscriptionMutationVariables = Types.Exact<{
  subscriptionId: Types.Scalars["String"];
}>;

export type PausePaddleSubscriptionMutation = { __typename: "RootMutationType"; pausePaddleSubscription: boolean };

export type ResumePausedPaddleSubscriptionMutationVariables = Types.Exact<{
  subscriptionId: Types.Scalars["String"];
}>;

export type ResumePausedPaddleSubscriptionMutation = {
  __typename: "RootMutationType";
  resumePausedPaddleSubscription: boolean;
};

export type CancelPaddleScheduledPauseMutationVariables = Types.Exact<{
  subscriptionId: Types.Scalars["String"];
}>;

export type CancelPaddleScheduledPauseMutation = {
  __typename: "RootMutationType";
  cancelPaddleScheduledPause: boolean;
};

export const UpdateSubscriptionNumberEmailDocument = gql`
  mutation updateSubscriptionNumberEmail($numberUuid: ID!, $number: NumberIo!) {
    updateNumber(numberUuid: $numberUuid, number: $number) {
      ...SubscriptionDetails
    }
  }
  ${SubscriptionDetailsFragmentDoc}
`;
export type UpdateSubscriptionNumberEmailMutationFn = Apollo.MutationFunction<
  UpdateSubscriptionNumberEmailMutation,
  UpdateSubscriptionNumberEmailMutationVariables
>;

/**
 * __useUpdateSubscriptionNumberEmail__
 *
 * To run a mutation, you first call `useUpdateSubscriptionNumberEmail` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionNumberEmail` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionNumberEmail, { data, loading, error }] = useUpdateSubscriptionNumberEmail({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      number: // value for 'number'
 *   },
 * });
 */
export function useUpdateSubscriptionNumberEmail(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSubscriptionNumberEmailMutation,
    UpdateSubscriptionNumberEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSubscriptionNumberEmailMutation, UpdateSubscriptionNumberEmailMutationVariables>(
    UpdateSubscriptionNumberEmailDocument,
    options
  );
}
export type UpdateSubscriptionNumberEmailHookResult = ReturnType<typeof useUpdateSubscriptionNumberEmail>;
export type UpdateSubscriptionNumberEmailMutationResult = Apollo.MutationResult<UpdateSubscriptionNumberEmailMutation>;
export type UpdateSubscriptionNumberEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubscriptionNumberEmailMutation,
  UpdateSubscriptionNumberEmailMutationVariables
>;
export const SwitchCollectionModeToAutomaticDocument = gql`
  mutation switchCollectionModeToAutomatic(
    $subscriptionId: String!
    $targetPrice: SwitchTargetPrice
    $chargePastDueOnAutomatic: Boolean
  ) {
    switchCollectionModeToAutomatic(
      subscriptionId: $subscriptionId
      targetPrice: $targetPrice
      chargePastDueOnAutomatic: $chargePastDueOnAutomatic
    ) {
      checkoutUrl
      status
    }
  }
`;
export type SwitchCollectionModeToAutomaticMutationFn = Apollo.MutationFunction<
  SwitchCollectionModeToAutomaticMutation,
  SwitchCollectionModeToAutomaticMutationVariables
>;

/**
 * __useSwitchCollectionModeToAutomaticMutation__
 *
 * To run a mutation, you first call `useSwitchCollectionModeToAutomaticMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchCollectionModeToAutomaticMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchCollectionModeToAutomaticMutation, { data, loading, error }] = useSwitchCollectionModeToAutomaticMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      targetPrice: // value for 'targetPrice'
 *      chargePastDueOnAutomatic: // value for 'chargePastDueOnAutomatic'
 *   },
 * });
 */
export function useSwitchCollectionModeToAutomaticMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SwitchCollectionModeToAutomaticMutation,
    SwitchCollectionModeToAutomaticMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SwitchCollectionModeToAutomaticMutation, SwitchCollectionModeToAutomaticMutationVariables>(
    SwitchCollectionModeToAutomaticDocument,
    options
  );
}
export type SwitchCollectionModeToAutomaticMutationHookResult = ReturnType<
  typeof useSwitchCollectionModeToAutomaticMutation
>;
export type SwitchCollectionModeToAutomaticMutationResult =
  Apollo.MutationResult<SwitchCollectionModeToAutomaticMutation>;
export type SwitchCollectionModeToAutomaticMutationOptions = Apollo.BaseMutationOptions<
  SwitchCollectionModeToAutomaticMutation,
  SwitchCollectionModeToAutomaticMutationVariables
>;
export const GetOrCreatePaddleCustomerDocument = gql`
  mutation getOrCreatePaddleCustomer($customerEmail: String!, $customerName: String!, $numberUuid: ID!) {
    getOrCreatePaddleCustomer(customerEmail: $customerEmail, customerName: $customerName, numberUuid: $numberUuid)
  }
`;
export type GetOrCreatePaddleCustomerMutationFn = Apollo.MutationFunction<
  GetOrCreatePaddleCustomerMutation,
  GetOrCreatePaddleCustomerMutationVariables
>;

/**
 * __useGetOrCreatePaddleCustomerMutation__
 *
 * To run a mutation, you first call `useGetOrCreatePaddleCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetOrCreatePaddleCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getOrCreatePaddleCustomerMutation, { data, loading, error }] = useGetOrCreatePaddleCustomerMutation({
 *   variables: {
 *      customerEmail: // value for 'customerEmail'
 *      customerName: // value for 'customerName'
 *      numberUuid: // value for 'numberUuid'
 *   },
 * });
 */
export function useGetOrCreatePaddleCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetOrCreatePaddleCustomerMutation,
    GetOrCreatePaddleCustomerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GetOrCreatePaddleCustomerMutation, GetOrCreatePaddleCustomerMutationVariables>(
    GetOrCreatePaddleCustomerDocument,
    options
  );
}
export type GetOrCreatePaddleCustomerMutationHookResult = ReturnType<typeof useGetOrCreatePaddleCustomerMutation>;
export type GetOrCreatePaddleCustomerMutationResult = Apollo.MutationResult<GetOrCreatePaddleCustomerMutation>;
export type GetOrCreatePaddleCustomerMutationOptions = Apollo.BaseMutationOptions<
  GetOrCreatePaddleCustomerMutation,
  GetOrCreatePaddleCustomerMutationVariables
>;
export const PausePaddleSubscriptionDocument = gql`
  mutation pausePaddleSubscription($subscriptionId: String!) {
    pausePaddleSubscription(subscriptionId: $subscriptionId)
  }
`;
export type PausePaddleSubscriptionMutationFn = Apollo.MutationFunction<
  PausePaddleSubscriptionMutation,
  PausePaddleSubscriptionMutationVariables
>;

/**
 * __usePausePaddleSubscription__
 *
 * To run a mutation, you first call `usePausePaddleSubscription` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePausePaddleSubscription` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pausePaddleSubscription, { data, loading, error }] = usePausePaddleSubscription({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function usePausePaddleSubscription(
  baseOptions?: Apollo.MutationHookOptions<PausePaddleSubscriptionMutation, PausePaddleSubscriptionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PausePaddleSubscriptionMutation, PausePaddleSubscriptionMutationVariables>(
    PausePaddleSubscriptionDocument,
    options
  );
}
export type PausePaddleSubscriptionHookResult = ReturnType<typeof usePausePaddleSubscription>;
export type PausePaddleSubscriptionMutationResult = Apollo.MutationResult<PausePaddleSubscriptionMutation>;
export type PausePaddleSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  PausePaddleSubscriptionMutation,
  PausePaddleSubscriptionMutationVariables
>;
export const ResumePausedPaddleSubscriptionDocument = gql`
  mutation resumePausedPaddleSubscription($subscriptionId: String!) {
    resumePausedPaddleSubscription(subscriptionId: $subscriptionId)
  }
`;
export type ResumePausedPaddleSubscriptionMutationFn = Apollo.MutationFunction<
  ResumePausedPaddleSubscriptionMutation,
  ResumePausedPaddleSubscriptionMutationVariables
>;

/**
 * __useResumePausedPaddleSubscription__
 *
 * To run a mutation, you first call `useResumePausedPaddleSubscription` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumePausedPaddleSubscription` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumePausedPaddleSubscription, { data, loading, error }] = useResumePausedPaddleSubscription({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useResumePausedPaddleSubscription(
  baseOptions?: Apollo.MutationHookOptions<
    ResumePausedPaddleSubscriptionMutation,
    ResumePausedPaddleSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResumePausedPaddleSubscriptionMutation, ResumePausedPaddleSubscriptionMutationVariables>(
    ResumePausedPaddleSubscriptionDocument,
    options
  );
}
export type ResumePausedPaddleSubscriptionHookResult = ReturnType<typeof useResumePausedPaddleSubscription>;
export type ResumePausedPaddleSubscriptionMutationResult =
  Apollo.MutationResult<ResumePausedPaddleSubscriptionMutation>;
export type ResumePausedPaddleSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  ResumePausedPaddleSubscriptionMutation,
  ResumePausedPaddleSubscriptionMutationVariables
>;
export const CancelPaddleScheduledPauseDocument = gql`
  mutation cancelPaddleScheduledPause($subscriptionId: String!) {
    cancelPaddleScheduledPause(subscriptionId: $subscriptionId)
  }
`;
export type CancelPaddleScheduledPauseMutationFn = Apollo.MutationFunction<
  CancelPaddleScheduledPauseMutation,
  CancelPaddleScheduledPauseMutationVariables
>;

/**
 * __useCancelPaddleScheduledPauseMutation__
 *
 * To run a mutation, you first call `useCancelPaddleScheduledPauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPaddleScheduledPauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPaddleScheduledPauseMutation, { data, loading, error }] = useCancelPaddleScheduledPauseMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useCancelPaddleScheduledPauseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelPaddleScheduledPauseMutation,
    CancelPaddleScheduledPauseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelPaddleScheduledPauseMutation, CancelPaddleScheduledPauseMutationVariables>(
    CancelPaddleScheduledPauseDocument,
    options
  );
}
export type CancelPaddleScheduledPauseMutationHookResult = ReturnType<typeof useCancelPaddleScheduledPauseMutation>;
export type CancelPaddleScheduledPauseMutationResult = Apollo.MutationResult<CancelPaddleScheduledPauseMutation>;
export type CancelPaddleScheduledPauseMutationOptions = Apollo.BaseMutationOptions<
  CancelPaddleScheduledPauseMutation,
  CancelPaddleScheduledPauseMutationVariables
>;
