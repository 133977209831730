import { Box } from "ds/Box";
import { Tooltip } from "ds/Tooltip";
import { Text } from "ds/Typography";
import { Icon } from "ds/icons";
import React from "react";

import { ICON_NAV_SIZE, StyledCountBox, StyledNav, StyledTextContainer, StyledVersion } from "./styles";
import { NavItemProps, NavType } from "./types";

export const Nav = ({ type = NavType.MAIN, iconName, text, version, anchor, counter, ...props }: NavItemProps) => {
  return (
    <StyledNav version={version} {...props} type={type}>
      <a {...anchor}>
        {counter && (
          <StyledCountBox type={type} unread={counter.unread} className="counter-box">
            <Text preset="small200">{counter.value}</Text>
          </StyledCountBox>
        )}
        <Tooltip
          placement="right"
          disabled={type !== NavType.ICON}
          showPointer={false}
          trigger={
            (iconName || type === NavType.ICON) && (
              <Box width={ICON_NAV_SIZE} height={ICON_NAV_SIZE} alignItems="center" justifyContent="center">
                <Icon
                  size="20px"
                  name={iconName ?? "PlaceholderIcon"}
                  color={type === NavType.ICON ? "textColorSubtle" : "textColorNonessential"}
                />
              </Box>
            )
          }
        >
          {text}
        </Tooltip>
        {type !== NavType.ICON && (
          <StyledTextContainer type={type}>
            <Text color="textColorDefault" preset="paragraph1-100">
              {text}
            </Text>
            {version && (
              <StyledVersion>
                <Text color="textColorOnDark" preset="caption100">
                  {version}
                </Text>
              </StyledVersion>
            )}
          </StyledTextContainer>
        )}
      </a>
    </StyledNav>
  );
};
