export function wait<T>(timeout: number) {
  return (value: T): Promise<T> =>
    new Promise((resolve) => {
      setTimeout(() => resolve(value), timeout);
    });
}

export function log<T>(value: T) {
  console.log(value);

  return value;
}
