/* tslint:disable */

/* eslint-disable */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as Types from "../../generated/types";
import { ListOrganisationTokensDetailsFragment } from "./fragments.generated";
import { OrganisationDetailsFragment } from "./fragments.generated";
import { ListOrganisationTokensDetailsFragmentDoc } from "./fragments.generated";
import { OrganisationDetailsFragmentDoc } from "./fragments.generated";

const defaultOptions = {} as const;
export type ListOrganisationTokensQueryVariables = Types.Exact<{
  organisationUuid: Types.Scalars["ID"];
}>;

export type ListOrganisationTokensQuery = {
  __typename: "RootQueryType";
  organisation: ({ __typename: "Organisation" } & ListOrganisationTokensDetailsFragment) | null;
};

export type GetOrganisationQueryVariables = Types.Exact<{
  organisationUuid: Types.Scalars["ID"];
}>;

export type GetOrganisationQuery = {
  __typename: "RootQueryType";
  organisation: ({ __typename: "Organisation" } & OrganisationDetailsFragment) | null;
};

export type GetNumberLogosQueryVariables = Types.Exact<{
  organisationUuid: Types.Scalars["ID"];
}>;

export type GetNumberLogosQuery = {
  __typename: "RootQueryType";
  organisation: {
    __typename: "Organisation";
    uuid: string | null;
    numbers: Array<{ __typename: "Number"; uuid: string | null; profilePicture: string | null } | null> | null;
  } | null;
};

export type GetOrganisationsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetOrganisationsQuery = {
  __typename: "RootQueryType";
  organisations: Array<({ __typename: "Organisation" } & OrganisationDetailsFragment) | null> | null;
};

export type GetEnabledFeaturesQueryVariables = Types.Exact<{
  organisationUuid: Types.Scalars["ID"];
  numberUuid: Types.Scalars["ID"];
}>;

export type GetEnabledFeaturesQuery = { __typename: "RootQueryType"; getEnabledFeatures: Array<string | null> };

export const ListOrganisationTokensDocument = gql`
  query listOrganisationTokens($organisationUuid: ID!) {
    organisation(organisationUuid: $organisationUuid) {
      ...ListOrganisationTokensDetails
    }
  }
  ${ListOrganisationTokensDetailsFragmentDoc}
`;

/**
 * __useListOrganisationTokensQuery__
 *
 * To run a query within a React component, call `useListOrganisationTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrganisationTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrganisationTokensQuery({
 *   variables: {
 *      organisationUuid: // value for 'organisationUuid'
 *   },
 * });
 */
export function useListOrganisationTokensQuery(
  baseOptions: Apollo.QueryHookOptions<ListOrganisationTokensQuery, ListOrganisationTokensQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListOrganisationTokensQuery, ListOrganisationTokensQueryVariables>(
    ListOrganisationTokensDocument,
    options
  );
}
export function useListOrganisationTokensLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListOrganisationTokensQuery, ListOrganisationTokensQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListOrganisationTokensQuery, ListOrganisationTokensQueryVariables>(
    ListOrganisationTokensDocument,
    options
  );
}
export type ListOrganisationTokensQueryHookResult = ReturnType<typeof useListOrganisationTokensQuery>;
export type ListOrganisationTokensLazyQueryHookResult = ReturnType<typeof useListOrganisationTokensLazyQuery>;
export type ListOrganisationTokensQueryResult = Apollo.QueryResult<
  ListOrganisationTokensQuery,
  ListOrganisationTokensQueryVariables
>;
export const GetOrganisationDocument = gql`
  query getOrganisation($organisationUuid: ID!) {
    organisation(organisationUuid: $organisationUuid) {
      ...OrganisationDetails
    }
  }
  ${OrganisationDetailsFragmentDoc}
`;

/**
 * __useGetOrganisationQuery__
 *
 * To run a query within a React component, call `useGetOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationQuery({
 *   variables: {
 *      organisationUuid: // value for 'organisationUuid'
 *   },
 * });
 */
export function useGetOrganisationQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrganisationQuery, GetOrganisationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrganisationQuery, GetOrganisationQueryVariables>(GetOrganisationDocument, options);
}
export function useGetOrganisationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrganisationQuery, GetOrganisationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrganisationQuery, GetOrganisationQueryVariables>(GetOrganisationDocument, options);
}
export type GetOrganisationQueryHookResult = ReturnType<typeof useGetOrganisationQuery>;
export type GetOrganisationLazyQueryHookResult = ReturnType<typeof useGetOrganisationLazyQuery>;
export type GetOrganisationQueryResult = Apollo.QueryResult<GetOrganisationQuery, GetOrganisationQueryVariables>;
export const GetNumberLogosDocument = gql`
  query getNumberLogos($organisationUuid: ID!) {
    organisation(organisationUuid: $organisationUuid) {
      uuid
      numbers {
        uuid
        profilePicture
      }
    }
  }
`;

/**
 * __useGetNumberLogosQuery__
 *
 * To run a query within a React component, call `useGetNumberLogosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNumberLogosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNumberLogosQuery({
 *   variables: {
 *      organisationUuid: // value for 'organisationUuid'
 *   },
 * });
 */
export function useGetNumberLogosQuery(
  baseOptions: Apollo.QueryHookOptions<GetNumberLogosQuery, GetNumberLogosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNumberLogosQuery, GetNumberLogosQueryVariables>(GetNumberLogosDocument, options);
}
export function useGetNumberLogosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNumberLogosQuery, GetNumberLogosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNumberLogosQuery, GetNumberLogosQueryVariables>(GetNumberLogosDocument, options);
}
export type GetNumberLogosQueryHookResult = ReturnType<typeof useGetNumberLogosQuery>;
export type GetNumberLogosLazyQueryHookResult = ReturnType<typeof useGetNumberLogosLazyQuery>;
export type GetNumberLogosQueryResult = Apollo.QueryResult<GetNumberLogosQuery, GetNumberLogosQueryVariables>;
export const GetOrganisationsDocument = gql`
  query getOrganisations {
    organisations {
      ...OrganisationDetails
    }
  }
  ${OrganisationDetailsFragmentDoc}
`;

/**
 * __useGetOrganisationsQuery__
 *
 * To run a query within a React component, call `useGetOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganisationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOrganisationsQuery, GetOrganisationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrganisationsQuery, GetOrganisationsQueryVariables>(GetOrganisationsDocument, options);
}
export function useGetOrganisationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrganisationsQuery, GetOrganisationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrganisationsQuery, GetOrganisationsQueryVariables>(GetOrganisationsDocument, options);
}
export type GetOrganisationsQueryHookResult = ReturnType<typeof useGetOrganisationsQuery>;
export type GetOrganisationsLazyQueryHookResult = ReturnType<typeof useGetOrganisationsLazyQuery>;
export type GetOrganisationsQueryResult = Apollo.QueryResult<GetOrganisationsQuery, GetOrganisationsQueryVariables>;
export const GetEnabledFeaturesDocument = gql`
  query getEnabledFeatures($organisationUuid: ID!, $numberUuid: ID!) {
    getEnabledFeatures(organisationUuid: $organisationUuid, numberUuid: $numberUuid)
  }
`;

/**
 * __useGetEnabledFeaturesQuery__
 *
 * To run a query within a React component, call `useGetEnabledFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnabledFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnabledFeaturesQuery({
 *   variables: {
 *      organisationUuid: // value for 'organisationUuid'
 *      numberUuid: // value for 'numberUuid'
 *   },
 * });
 */
export function useGetEnabledFeaturesQuery(
  baseOptions: Apollo.QueryHookOptions<GetEnabledFeaturesQuery, GetEnabledFeaturesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEnabledFeaturesQuery, GetEnabledFeaturesQueryVariables>(
    GetEnabledFeaturesDocument,
    options
  );
}
export function useGetEnabledFeaturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEnabledFeaturesQuery, GetEnabledFeaturesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEnabledFeaturesQuery, GetEnabledFeaturesQueryVariables>(
    GetEnabledFeaturesDocument,
    options
  );
}
export type GetEnabledFeaturesQueryHookResult = ReturnType<typeof useGetEnabledFeaturesQuery>;
export type GetEnabledFeaturesLazyQueryHookResult = ReturnType<typeof useGetEnabledFeaturesLazyQuery>;
export type GetEnabledFeaturesQueryResult = Apollo.QueryResult<
  GetEnabledFeaturesQuery,
  GetEnabledFeaturesQueryVariables
>;
