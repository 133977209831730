import { ColorBrandAccent } from "ds/Core";
import { Anchor } from "grommet";
import _delay from "lodash/delay";
import React from "react";
import { Trans } from "react-i18next";
import { toast } from "react-toastify";

import { ToastContent } from "lib/notification/Toast";
import i18n from "utils/i18n";
import { toastOptions } from "utils/options";

const oneMb = 1000000;

export const fileReader = async ({ target: { files } }) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (files.length > 1) {
      reader.readAsText(files);
    } else if (files[0]) {
      reader.readAsText(files[0]);
    }

    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject(reader.error);
  });

export const uploadingFileReader = async ({ target: { files } }, ImageWidth, ImageHeight, FileSize) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    const file = files[0];
    //Read the contents of Image File.

    if (file) {
      reader?.readAsDataURL(file);
    }

    reader.onerror = () => reject(reader.error);
    reader.onload = function (e) {
      const resolveReader = () =>
        resolve({
          file,
          blob: reader.result,
        });

      if (file.type.includes("image")) {
        //Initiate the JavaScript Image object.
        const image = new Image();
        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;
        //Validate the File Height and Width.
        image.onload = function () {
          const maxSize = FileSize || oneMb * 5;
          if (
            file.size >= maxSize ||
            (ImageWidth && this.width > ImageWidth) ||
            (ImageHeight && this.height > ImageHeight)
          ) {
            toast.error(
              <ToastContent
                title={i18n.t("toasts.error.image-upload.title")}
                description={
                  <Trans i18nKey="toasts.error.image-upload.description">
                    <Anchor
                      href="https://learn.turn.io/article/rcr0qh58gu-media-in-messages"
                      target="_blank"
                      color={ColorBrandAccent}
                    />
                  </Trans>
                }
                type="error"
              />,
              toastOptions
            );
            reject(new Error("Image exceeds the allowed dimensions"));
          } else {
            resolveReader();
          }
        };
      } else {
        const maxSize =
          (FileSize ?? (file.type.includes("video") || file.type.includes("audio"))) ? oneMb * 16 : oneMb * 100;
        if (file.size >= maxSize) {
          toast.error(
            <ToastContent
              title={i18n.t("toasts.error.file-upload.title")}
              description={
                <Trans i18nKey="toasts.error.file-upload.description">
                  <Anchor
                    href="https://learn.turn.io/article/rcr0qh58gu-media-in-messages"
                    target="_blank"
                    color={ColorBrandAccent}
                  />
                </Trans>
              }
              type="error"
            />,
            toastOptions
          );
          reject(new Error("File exceeds the allowed size"));
        } else {
          resolveReader();
        }
      }
    };
  });

export const stickerImageReader = async ({ target: { files } }, d = 1500) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    const file = files[0];

    if (file) {
      reader.readAsDataURL(file);
    }

    reader.onload = function (e) {
      const image = new Image();
      image.src = e.target.result;
      image.onload = function () {
        resolve({
          file,
          height: image.height,
          width: image.width,
          opacity: getOpacityRatio(image),
        });
      };
    };

    reader.onerror = () =>
      _delay(() => {
        reject(reader.error);
      }, d);
  });

// Returns an opacity score between 0 and 1, where 1 is fully opaque and 0 is fully transparent
const getOpacityRatio = (image) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = image.width;
  canvas.height = image.height;
  context.drawImage(image, 0, 0);
  const data = context.getImageData(0, 0, canvas.width, canvas.height).data;
  let opacity = 0;
  for (let i = 0; i < data.length; i += 4) {
    opacity += data[i + 3];
  }
  return opacity / 255 / (data.length / 4);
};

export const checkIsValidJson = (json) => {
  try {
    JSON.parse(json);
  } catch (e) {
    return false;
  }
  return true;
};
