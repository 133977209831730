import _get from "lodash/get";
import _isString from "lodash/isString";

import { Path } from "types/utils";

export const colors = {
  white: "#FFF",
  black: "#000",
  "dark-1": "#0D1F26",
  "dark-2": "#535C5F",
  "dark-3": "#7A8285",
  "dark-4": "#929A9E",
  "light-2": "#F6F8F8",
  "light-4": "#DADCDD",
  "light-5": "#BABEC0",
  "light-8": "#CACDCE",
  "status-critical": "#DB4B50",
  "status-warning": "#FFBF1F",
  "status-ok": "#00C78B",
  "ocean-blue": "#3F95FF",
  unknown: {
    "1": "#FFD815", // Login, Number, Sandbox and Done screens https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=3969%3A11807
    "2": "#D4B6FF", // Signup, Terms, Readiness, Invite screens https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=3969%3A11807
    "3": "#FF6B42", // Choose Plan screens https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=3969%3A11807
    "4": "#A9FF71", // Embeddedd Signup screens https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=3969%3A11807
    "5": "#64DBFF", // Business Verification screen https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=3969%3A11807
    "6": "#FFE0E1", // Error message https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=3969%3A12102
    "7": "", // unused token
    "8": "", // unused token
    "9": "", // unused token
    "10": "", // unused token
    "11": "", // unused token
    "12": "#00B899", // success status https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=2119%3A3407
    "13": "#EEBA00", // warning status https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=2119%3A3407
    "14": "#FF452D", // error status https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=2119%3A3407
    "15": "#C4C4C4", // neutral status https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=2119%3A3407
    "16": "#CCFFF2", // verification code background https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=3425%3A8812
    "17": "#FFFCBF", // number background https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=3425%3A8812
    "18": "#FBF9EF", // Plans table background https://www.figma.com/file/4xr1OiR25SuVgJLQaHaeBE/Onboarding-Improvements?node-id=3969%3A11807
  },
};

export type TColors = Path<typeof colors>;

export const getColor = (path: TColors): string => {
  const color = _get(colors, path);

  return _isString(color) ? color : path;
};
