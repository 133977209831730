import React from "react";

import { getOneSizeSmaller, paragraph, useBreakpointHook } from "../styles";
import { Typography } from "./Typography";
import { ITypographyProps } from "./types";

export type TParagraphProps = ITypographyProps & JSX.IntrinsicElements["p"];

export const Paragraph = ({ children, size = "medium", ...props }: TParagraphProps) => {
  const { isSmallerThan } = useBreakpointHook();
  const currentSize = isSmallerThan("tablet") ? getOneSizeSmaller(size) : size;
  return (
    <Typography theme={paragraph} size={currentSize} as="p" {...props}>
      {children}
    </Typography>
  );
};
