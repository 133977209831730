import gql from "graphql-tag";

import { boundary, required } from "api/boundary";
import { LEASE_MIN_FRAGMENT } from "graphql/leases";

export const CHAT_MIN_FRAGMENT = {
  query: gql`
    fragment ChatMin on Chat {
      uuid
      title
      owner
      state
      inboundTimestamp
    }
  `,
  parse: {
    uuid: required(),
    owner: required(),
  },
};

export const CHAT_MEDIUM_FRAGMENT = {
  query: gql`
    fragment ChatMedium on Chat {
      ...ChatMin
      assignedTo {
        firstName
        lastName
        profilePicture
        uuid
      }
    }

    ${CHAT_MIN_FRAGMENT.query}
  `,
  parse: {
    ...CHAT_MIN_FRAGMENT.parse,
    assignedTo: boundary({
      uuid: required(),
    }),
  },
};

export const MESSAGE_FOR_CHAT_LIST_FRAGMENT = {
  query: gql`
    fragment MessageForChatList on Message {
      uuid
      insertedAt
      content
      messageType
      isHandled
      templateButtonText
      lastStatus
      messageType
      hsm {
        elementName
        language {
          code
        }
        localizableParams {
          default
        }
      }
      template {
        name
        language {
          code
        }
        components {
          type
          subType
          index
          parameters {
            type
            ... on MessageTemplateComponentParameterText {
              text
            }
            ... on MessageTemplateComponentParameterVideo {
              video {
                id
                link
              }
            }
            ... on MessageTemplateComponentParameterImage {
              image {
                id
                link
              }
            }
            ... on MessageTemplateComponentParameterDocument {
              document {
                id
                link
                filename
              }
            }
          }
        }
      }
      interactive {
        body {
          text
        }
        buttonReply {
          id
          title
        }
        listReply {
          id
          title
          description
        }
        type
      }
    }
  `,
  parse: {
    uuid: required(),
  },
};

export const CHAT_DETAILS_FRAGMENT = {
  query: gql`
    fragment ChatDetails on Chat {
      ...ChatMedium
      type
      unreadCount
      icon
      timestamp
      outboundTimestamp
      updatedAt
      lastReaction {
        reaction
        timestamp
        message {
          ...MessageForChatList
        }
      }
      lastMessage {
        ...MessageForChatList
      }
      lease {
        childLease {
          ...LeaseMin
        }
        ...LeaseMin
      }
      number {
        uuid
      }
    }

    ${CHAT_MEDIUM_FRAGMENT.query}
    ${LEASE_MIN_FRAGMENT.query}
    ${MESSAGE_FOR_CHAT_LIST_FRAGMENT.query}
  `,
  parse: {
    ...CHAT_MEDIUM_FRAGMENT.parse,
    lastMessage: boundary(MESSAGE_FOR_CHAT_LIST_FRAGMENT.parse),
    number: {
      uuid: required(),
    },
  },
};

export const CHAT_SEARCH_RESULTS_FRAGMENT = {
  query: gql`
    fragment ChatSearchResultsFragment on ChatSearchResults {
      count
      results {
        ...ChatDetails
      }
    }

    ${CHAT_DETAILS_FRAGMENT.query}
  `,
  parse: {
    count: required(),
    results: CHAT_DETAILS_FRAGMENT.parse,
  },
};
export const parseChatSearchResultsFragment = boundary(CHAT_SEARCH_RESULTS_FRAGMENT.parse);
