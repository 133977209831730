import gql from "graphql-tag";

export const DELETE_ORGANISATION_TOKEN = gql`
  mutation deleteOrganisationToken($organisationUuid: ID!, $organisationTokenUuid: ID!) {
    deleteOrganisationToken(organisationUuid: $organisationUuid, organisationTokenUuid: $organisationTokenUuid) {
      uuid
      name
    }
  }
`;

export const SET_PRIVACY_ENABLED_FIELD = gql`
  mutation setPrivacyEnabledField($organisationUuid: ID!, $privacyEnabled: Boolean!) {
    setPrivacyEnabledField(organisationUuid: $organisationUuid, privacyEnabled: $privacyEnabled) {
      uuid
      privacyEnabled
    }
  }
`;
