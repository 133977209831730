import gql from "graphql-tag";

import { boundary } from "api/boundary";

import { CHAT_DETAILS_FRAGMENT } from "./fragments";

export const ON_CHAT_CHANGED = {
  query: gql`
    subscription onChatChanged($chatUuid: ID!) {
      onChatChanged(chatUuid: $chatUuid) {
        ...ChatDetails
      }
    }

    ${CHAT_DETAILS_FRAGMENT.query}
  `,
  parse: boundary({
    onChatChanged: CHAT_DETAILS_FRAGMENT.parse,
  }),
};
