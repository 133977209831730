import { Space200 } from "ds/Core";
import styled from "styled-components";
import { layout, space } from "styled-system";

import { IStyledSpinner } from "./types";

export const StyledSpinner = styled.div<IStyledSpinner>`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  animation: ${({ speed }) => speed && `spin ${1 / speed}s linear infinite`};
  ${({ size }) => `
      width: ${size};
      height: ${size};
  `}

  ${space}
`;

export const StyledSpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${Space200};

  ${layout}
  ${space}
`;
