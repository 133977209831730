/* tslint:disable */

/* eslint-disable */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as Types from "../../generated/types";
import { OperatorAvailabilityFragment } from "./fragments.generated";
import { OperatorAvailabilityFragmentDoc } from "./fragments.generated";

const defaultOptions = {} as const;
export type SetOperatorAsAvailableMutationVariables = Types.Exact<{
  accountUuid: Types.Scalars["ID"];
  numberUuid: Types.Scalars["ID"];
}>;

export type SetOperatorAsAvailableMutation = {
  __typename: "RootMutationType";
  setOperatorAsAvailable: {
    __typename: "AvailabilityAndChatsAssignedCount";
    count: number | null;
    operatorAvailability: ({ __typename: "OperatorAvailability" } & OperatorAvailabilityFragment) | null;
  } | null;
};

export type SetOperatorAsUnavailableMutationVariables = Types.Exact<{
  accountUuid: Types.Scalars["ID"];
  numberUuid: Types.Scalars["ID"];
  releaseChats: Types.Scalars["Boolean"];
}>;

export type SetOperatorAsUnavailableMutation = {
  __typename: "RootMutationType";
  setOperatorAsUnavailable: ({ __typename: "OperatorAvailability" } & OperatorAvailabilityFragment) | null;
};

export const SetOperatorAsAvailableDocument = gql`
  mutation setOperatorAsAvailable($accountUuid: ID!, $numberUuid: ID!) {
    setOperatorAsAvailable(numberUuid: $numberUuid, accountUuid: $accountUuid) {
      count
      operatorAvailability {
        ...OperatorAvailabilityFragment
      }
    }
  }
  ${OperatorAvailabilityFragmentDoc}
`;
export type SetOperatorAsAvailableMutationFn = Apollo.MutationFunction<
  SetOperatorAsAvailableMutation,
  SetOperatorAsAvailableMutationVariables
>;

/**
 * __useSetOperatorAsAvailableMutation__
 *
 * To run a mutation, you first call `useSetOperatorAsAvailableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOperatorAsAvailableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOperatorAsAvailableMutation, { data, loading, error }] = useSetOperatorAsAvailableMutation({
 *   variables: {
 *      accountUuid: // value for 'accountUuid'
 *      numberUuid: // value for 'numberUuid'
 *   },
 * });
 */
export function useSetOperatorAsAvailableMutation(
  baseOptions?: Apollo.MutationHookOptions<SetOperatorAsAvailableMutation, SetOperatorAsAvailableMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetOperatorAsAvailableMutation, SetOperatorAsAvailableMutationVariables>(
    SetOperatorAsAvailableDocument,
    options
  );
}
export type SetOperatorAsAvailableMutationHookResult = ReturnType<typeof useSetOperatorAsAvailableMutation>;
export type SetOperatorAsAvailableMutationResult = Apollo.MutationResult<SetOperatorAsAvailableMutation>;
export type SetOperatorAsAvailableMutationOptions = Apollo.BaseMutationOptions<
  SetOperatorAsAvailableMutation,
  SetOperatorAsAvailableMutationVariables
>;
export const SetOperatorAsUnavailableDocument = gql`
  mutation setOperatorAsUnavailable($accountUuid: ID!, $numberUuid: ID!, $releaseChats: Boolean!) {
    setOperatorAsUnavailable(numberUuid: $numberUuid, accountUuid: $accountUuid, releaseChats: $releaseChats) {
      ...OperatorAvailabilityFragment
    }
  }
  ${OperatorAvailabilityFragmentDoc}
`;
export type SetOperatorAsUnavailableMutationFn = Apollo.MutationFunction<
  SetOperatorAsUnavailableMutation,
  SetOperatorAsUnavailableMutationVariables
>;

/**
 * __useSetOperatorAsUnavailableMutation__
 *
 * To run a mutation, you first call `useSetOperatorAsUnavailableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOperatorAsUnavailableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOperatorAsUnavailableMutation, { data, loading, error }] = useSetOperatorAsUnavailableMutation({
 *   variables: {
 *      accountUuid: // value for 'accountUuid'
 *      numberUuid: // value for 'numberUuid'
 *      releaseChats: // value for 'releaseChats'
 *   },
 * });
 */
export function useSetOperatorAsUnavailableMutation(
  baseOptions?: Apollo.MutationHookOptions<SetOperatorAsUnavailableMutation, SetOperatorAsUnavailableMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetOperatorAsUnavailableMutation, SetOperatorAsUnavailableMutationVariables>(
    SetOperatorAsUnavailableDocument,
    options
  );
}
export type SetOperatorAsUnavailableMutationHookResult = ReturnType<typeof useSetOperatorAsUnavailableMutation>;
export type SetOperatorAsUnavailableMutationResult = Apollo.MutationResult<SetOperatorAsUnavailableMutation>;
export type SetOperatorAsUnavailableMutationOptions = Apollo.BaseMutationOptions<
  SetOperatorAsUnavailableMutation,
  SetOperatorAsUnavailableMutationVariables
>;
