import { Box } from "ds/Box";
import { Modal } from "ds/Modal";
import { Text } from "ds/Typography";
import React, { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";

import { useOperatorAvailability } from "hooks/useOperatorAvailability";
import { dayjs } from "utils/date";

// context(alexandrchebotar, 2023-12-08): 30m inactivity & 10m timer in modal
const INACTIVITY_TIMEOUT = 30 * 60 * 1000;
const MODAL_TIMER = 10 * 60 * 1000;
const LOCAL_STORAGE_KEY = "userActivityTimestamp";

export const UserActivityTracker = () => {
  const { t } = useTranslation();

  const { isAvailable, setUnavailable } = useOperatorAvailability();

  const [isVisibleInactivityModal, setVisibleInactivityModal] = useState(false);
  const [timer, setTimer] = useState("");
  const timerId = useRef(0);
  const timerTargetTimestamp = useRef(0);

  const clearTimer = () => {
    if (timerId.current) {
      clearInterval(timerId.current);
    }
    timerId.current = 0;
  };

  useEffect(() => clearTimer, []);

  const onSetUnavailable = (releaseChats: boolean) => {
    setUnavailable(releaseChats);
    setVisibleInactivityModal(false);
    clearTimer();
  };

  const openModal = () => {
    timerTargetTimestamp.current = Date.now() + MODAL_TIMER;
    setVisibleInactivityModal(true);
    timerId.current = window.setInterval(() => {
      const leftTime = timerTargetTimestamp.current - Date.now();
      if (leftTime > 0) {
        setTimer(dayjs.duration(leftTime).format("mm:ss"));
      } else {
        onSetUnavailable(true);
      }
    }, 1000);
  };

  const setDebouncedTimeout = useDebouncedCallback(() => {
    // context(alexandrchebotar, 2023-12-08): record in localStorage to handle multiple tabs activity
    const lastActivityTimestamp = parseInt(localStorage.getItem(LOCAL_STORAGE_KEY) ?? "");

    // context(alexandrchebotar, 2023-12-08): added 5s for processing (actually miliseconds are needed, but as timer value is 15m, it's ok to use 5s)
    if (Date.now() - lastActivityTimestamp > INACTIVITY_TIMEOUT - 5000) {
      openModal();
    } else {
      // context(alexandrchebotar, 2023-12-08): re-run timeout function if some activity was made in other tab
      setDebouncedTimeout();
    }
  }, INACTIVITY_TIMEOUT);

  useEffect(() => {
    if (isAvailable) {
      // context(alexandrchebotar, 2023-12-08): set initial timer after page loaded
      localStorage.setItem(LOCAL_STORAGE_KEY, Date.now().toString());
      setDebouncedTimeout();

      const onUserActivity = () => {
        // context(alexandrchebotar, 2023-12-08): we should not re-run timer if the user switched to another tab
        if (!document.hidden) {
          localStorage.setItem(LOCAL_STORAGE_KEY, Date.now().toString());
          setDebouncedTimeout();
        }
      };

      document.addEventListener("visibilitychange", onUserActivity);
      document.addEventListener("mousemove", onUserActivity);
      document.addEventListener("keypress", onUserActivity);

      return () => {
        setDebouncedTimeout.cancel();
        document.removeEventListener("visibilitychange", onUserActivity);
        document.removeEventListener("mousemove", onUserActivity);
        document.removeEventListener("keypress", onUserActivity);
      };
    }
    return;
  }, [isAvailable, setDebouncedTimeout]);

  return (
    <Modal
      type="STEPS"
      visible={isVisibleInactivityModal}
      onHide={() => setVisibleInactivityModal(false)}
      title={t("routing.modals.inactive.title")}
      primaryAction={{
        onClick: () => onSetUnavailable(true),
        text: t("routing.modals.assign-to-other"),
      }}
      secondaryAction={{
        onClick: () => onSetUnavailable(false),
        text: t("routing.modals.keep-assigned-to-me"),
      }}
    >
      <Box width="578px" gap="300">
        <Text preset="medium100">{t("routing.modals.inactive.description-1")}</Text>
        <Box flexDirection="row">
          <Text preset="medium100">
            <Trans
              i18nKey="routing.modals.inactive.description-2"
              components={{
                strong: (
                  <Text preset="medium200" color="interactiveColorAction">
                    ""
                  </Text>
                ),
              }}
              values={{ timer }}
            />
          </Text>
        </Box>
      </Box>
    </Modal>
  );
};
