import { useLayoutEffect, useState } from "react";
import ReactDOM from "react-dom";

function createWrapperAndAppendToBody(wrapperId: string) {
  const wrapperElement = document.createElement("div");
  wrapperElement.setAttribute("id", wrapperId);
  wrapperElement.setAttribute("class", "nodrag");
  document.body.appendChild(wrapperElement);
  return wrapperElement;
}

interface ReactPortalProps {
  children: React.ReactNode;
  wrapperId?: string;
}

export const ReactPortal = ({ children, wrapperId = "modal-root" }: ReactPortalProps) => {
  const [wrapperElement, setWrapperElement] = useState<Element | null>(null);

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    let systemCreated = false;
    // if element is not found with wrapperId or wrapperId is not provided,
    // create and append to body
    if (!element) {
      systemCreated = true;
      element = createWrapperAndAppendToBody(wrapperId);
    }
    setWrapperElement(element);

    return () => {
      // delete the programatically created element
      if (systemCreated && element.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [wrapperId]);

  // wrapperElement state will be null on the very first render.
  return wrapperElement && ReactDOM.createPortal(children, wrapperElement);
};
