import React from "react";

export enum AvatarSizes {
  "XXXSMALL" = "xxxsmall",
  "XXSMALL" = "xxsmall",
  "XSMALL" = "xsmall",
  "SMALL" = "small",
  "MEDIUM" = "medium",
  "LARGE" = "large",
  "XLARGE" = "xlarge",
}

export interface AvatarProps extends React.ComponentPropsWithoutRef<"div"> {
  label?: string;
  url?: string;
  size?: `${AvatarSizes}`;
  color?: string;
  showLabel?: boolean;
}
