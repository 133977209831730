import gql from "graphql-tag";

import { boundary, required } from "api/boundary";

import { NUMBER_TAG_DETAILS_FRAGMENT } from "./fragments";

export const GET_NUMBER_TAGS = {
  query: gql`
    query getNumberTags($numberUuid: ID!) {
      number(numberUuid: $numberUuid) {
        uuid
        numberTags {
          ...NumberTagDetails
        }
      }
    }
    ${NUMBER_TAG_DETAILS_FRAGMENT.query}
  `,
  parse: boundary({
    number: {
      uuid: required(),
      numberTags: NUMBER_TAG_DETAILS_FRAGMENT.parse,
    },
  }),
};
