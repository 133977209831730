import { Box, Heading, Text } from "grommet";
import React from "react";
import { useTranslation } from "react-i18next";

import { LockIconOutline } from "lib/icons/LockIconOutline";

export const Forbidden = () => {
  const { t } = useTranslation();

  return (
    <Box pad="large" align="center" justify="center" direction="column" height="100vh">
      <LockIconOutline />
      <Heading level="2" margin="32px 0 16px">
        {t("forbidden.title")}
      </Heading>
      <Box width={{ max: "560px" }}>
        <Text size="large" color="dark-3" textAlign="center">
          {t("forbidden.description")}
        </Text>
      </Box>
    </Box>
  );
};
