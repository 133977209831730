import gql from "graphql-tag";

import { boundary } from "api/boundary";

import { CHAT_DETAILS_FRAGMENT } from "./fragments";

export const ASSIGN_CHAT = {
  query: gql`
    mutation assignChat($organisationUuid: ID!, $chatUuid: ID!, $accountUuid: ID) {
      assignChat(organisationUuid: $organisationUuid, chatUuid: $chatUuid, accountUuid: $accountUuid) {
        ...ChatDetails
      }
    }

    ${CHAT_DETAILS_FRAGMENT.query}
  `,
  parse: boundary({
    assignChat: CHAT_DETAILS_FRAGMENT.parse,
  }),
};

export const UPDATE_CHAT_STATE = {
  query: gql`
    mutation updateChatState($chatUuid: ID!, $state: ChatState!, $assignToAccountUuid: ID) {
      updateChatState(chatUuid: $chatUuid, state: $state, assignToAccountUuid: $assignToAccountUuid) {
        ...ChatDetails
      }
    }

    ${CHAT_DETAILS_FRAGMENT.query}
  `,
  parse: boundary({
    updateChatState: CHAT_DETAILS_FRAGMENT.parse,
  }),
};

export const RESET_UNREAD_COUNTER = gql`
  mutation resetUnreadCounter($chatUuid: ID!) {
    resetUnreadCounter(chatUuid: $chatUuid) {
      ...ChatDetails
    }
  }

  ${CHAT_DETAILS_FRAGMENT.query}
`;
