import React from "react";

import { getOneSizeSmaller, heading, useBreakpointHook } from "../styles";
import { Typography } from "./Typography";
import { ITypographyProps, THeadingSizes, TPartialHeadingSizes } from "./types";

type THeadingProps = ITypographyProps & JSX.IntrinsicElements["p"];

export interface IHeadingProps extends Omit<THeadingProps, "size"> {
  size?: THeadingSizes;
}

export const Heading = ({ children, size = 1, ...props }: IHeadingProps) => {
  const { isSmallerThan } = useBreakpointHook();
  const as: TPartialHeadingSizes = `h${size}`;
  const currentSize = isSmallerThan("tablet") ? (getOneSizeSmaller(as) as TPartialHeadingSizes) : as;
  return (
    <Typography weight={500} size={currentSize} theme={heading} as={as} {...props}>
      {children}
    </Typography>
  );
};
