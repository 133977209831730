import { Space100, Space150, Space200 } from "ds/Core";
import _kebabCase from "lodash/kebabCase.js";

import { CheckboxProps } from "./types";

const getStyles = (props: any) =>
  Object.entries(props).reduce((styles, [key, value]) => `${styles}\n${_kebabCase(key)}: ${value};`, "");

export const getLabelCheckboxStyles = ({ size }: CheckboxProps) => `
    ${size && getStyles(theme.checkbox[size])}
  `;

export const theme = {
  checkbox: {
    small: {
      width: "20px",
      marginRight: Space100,
      height: "20px",
    },
    medium: {
      width: "24px",
      marginRight: Space150,
      height: "24px",
    },
    large: {
      width: "26px",
      marginRight: Space200,
      height: "26px",
    },
  },
};
