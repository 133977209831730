import _debounce from "lodash/debounce";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

const getDistanceFromBottom = ({ scrollHeight, offsetHeight, scrollTop }) => scrollHeight - scrollTop - offsetHeight;

/**
 * Magic number that waits for the message list to finish rendering
 * before scroll starts. Tested on a slow connection.
 *
 * TODO: Determine if this is still necessary. I've left it in here
 * in case some component still relies on this feature.
 */
const FIRST_SCROLL_TIMEOUT = 1000;

/**
 * How many pixels a user needs to scroll up before we disable
 * auto-scrolling. We want this to be non-zero in case the user
 * accidentally scrolls a tiny amount.
 */
const AUTO_SCROLL_THRESHOLD = 100;

/**
 * A custom effect that scrolls a DOM element into view.
 */
export const useScrollIntoView = ({
  ignoreWhenScrolled = false,
  delayed = true,
  delay = FIRST_SCROLL_TIMEOUT,
  onScrollToEnd,
  triggers = [],
  skip = false,
  autoScrollTreshhold,
}) => {
  const [target, setTarget] = useState(null);
  const [root, setRootRef] = useState(null);
  const [didInitialScroll, setDidInitialScroll] = useState();
  const lastScrollPosition = useRef(0);

  const doScroll = useCallback(
    (initial = false) => {
      target.scrollIntoView({
        behavior: initial ? "auto" : "smooth",
        block: "end",
        inline: "end",
      });
    },
    [target]
  );

  const timeoutId = useRef();
  useEffect(() => () => clearTimeout(timeoutId.current));

  useLayoutEffect(() => {
    if (root && target && !skip) {
      if (didInitialScroll) {
        if (!ignoreWhenScrolled || lastScrollPosition.current <= (autoScrollTreshhold || AUTO_SCROLL_THRESHOLD)) {
          doScroll();
        }
      } else {
        if (delayed) {
          timeoutId.current = setTimeout(() => {
            setDidInitialScroll(true);
            doScroll(true);
          }, delay);
        } else {
          setDidInitialScroll(true);
          doScroll(true);
        }
      }
    }
  }, [
    skip,
    target,
    root,
    didInitialScroll,
    ignoreWhenScrolled,
    delayed,
    delay,
    lastScrollPosition,
    doScroll,
    autoScrollTreshhold,
    // additional triggers, like message changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...triggers,
  ]);

  useEffect(() => {
    if (root) {
      lastScrollPosition.current = getDistanceFromBottom(root);
      let cleanUp;
      const userScrollHandler = () => {
        const distanceFromBottom = getDistanceFromBottom(root);

        if (onScrollToEnd && distanceFromBottom <= 10) {
          cleanUp = onScrollToEnd();
          setIsScrolledToBottom(true);
        } else {
          setIsScrolledToBottom(false);
        }
        lastScrollPosition.current = distanceFromBottom;
      };
      const debouncedHandler = _debounce(userScrollHandler, 300);
      root.addEventListener("scroll", debouncedHandler);

      return () => {
        root.removeEventListener("scroll", debouncedHandler);
        debouncedHandler.cancel();
        if (cleanUp) {
          cleanUp();
        }
      };
    }
  }, [root, onScrollToEnd]);

  const resetInitialScroll = useCallback(() => {
    setDidInitialScroll(false);
  }, []);

  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const scrollToBottom = isScrolledToBottom ? undefined : () => doScroll();

  return [setTarget, setRootRef, resetInitialScroll, scrollToBottom];
};
