export function insertIf<T>(condition?: boolean, ...elements: T[]) {
  return condition ? elements : [];
}

export const insertIfDefined = <T>(element?: T) => (element ? [element] : []);

export const equalBy =
  <T>(key: keyof T, value: T[keyof T]) =>
  (item: T) =>
    item[key] === value;

export const hasDuplicates = <T>(arr?: T[], key?: keyof T) => {
  const set = new Set();
  return arr?.some((el) => {
    if (set.has(key ? el[key] : el)) return true;
    set.add(key ? el[key] : el);
    return false;
  });
};

export const getDuplicates = <T>(arr?: T[], key?: keyof T) =>
  arr?.filter((item, index) =>
    arr.some((elem, idx) => (key ? elem[key] === item[key] : elem === item) && idx !== index)
  ) ?? [];
