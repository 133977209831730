import gql from "graphql-tag";

import { boundary, required } from "api/boundary";

import { SETTINGS_NUMBER_FRAGMENT } from "./fragments";

export const GET_SETTINGS_NUMBER = {
  query: gql`
    query getNumberSettings($numberUuid: ID!) {
      number(numberUuid: $numberUuid) {
        ...SettingsNumber
      }
    }

    ${SETTINGS_NUMBER_FRAGMENT.query}
  `,
  parse: boundary({
    number: SETTINGS_NUMBER_FRAGMENT.parse,
  }),
};

export const parseAssistant = {
  uuid: required(),
  name: required(),
  aiVendor: required(),
  aiModel: required(),
};

export const LIST_ASSISTANTS = {
  query: gql`
    query listAssistants($numberUuid: ID!) {
      listAssistants(numberUuid: $numberUuid) {
        uuid
        name
        aiVendor
        aiModel
        emoji
      }
    }
  `,
  parse: boundary({
    listAssistants: parseAssistant,
  }),
};
