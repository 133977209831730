import useBreakpoint from "use-breakpoint";

import { TPartialHeadingSizes } from "../Typography/types";

interface ISizesBreakpoints {
  small;
  medium;
  lmedium;
  large;
}

export type TSizes = keyof ISizesBreakpoints;

export const unit = "px";
type TBreakpoints<T> = {
  mobile: T;
  tablet: T;
};

export const BREAKPOINTS: TBreakpoints<number> = {
  mobile: 425,
  tablet: 1024,
};
export type TBreakpoint = keyof TBreakpoints<any>;

export const devices: TBreakpoints<string> = {
  mobile: `(max-width: ${BREAKPOINTS.mobile - 0.1}px)`,
  tablet: `(max-width: ${BREAKPOINTS.tablet - 0.1}px)`,
};

export const useBreakpointHook = (key: TBreakpoint = "tablet") => {
  const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS, key, false);
  return {
    breakpoint,
    maxWidth,
    minWidth,
    isSmallerThan: (breakpoint: TBreakpoint) => minWidth < BREAKPOINTS[breakpoint],
    isLargerThan: (breakpoint: TBreakpoint) => minWidth > BREAKPOINTS[breakpoint],
  };
};

export const getOneSizeSmaller = (size?: TSizes | TPartialHeadingSizes) => {
  switch (size) {
    case "large":
      return "lmedium";
    case "lmedium":
      return "medium";
    case "h1":
      return "h2";
    case "h2":
      return "h3";
    case "h3":
      return "h4";
    case "h4":
      return "h5";
    case "h5":
      return "h6";
    default:
      return "small";
  }
};
