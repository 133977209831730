import gql from "graphql-tag";

import { boundary, required } from "api/boundary";

import {
  PADDLE_BILLING_PRICES_CONFIGURATION_FRAGMENT,
  PADDLE_CONFIGURATION_FRAGMENT,
  PAYMENT_DETAILS_FRAGMENT,
  SUBSCRIPTION_FRAGMENT,
} from "./fragments";

export const GET_SUBSCRIPTIONS_CONFIGURATION = {
  query: gql`
    query getSubscriptionsConfiguration($numberUuid: ID!, $forBootcamp: Boolean) {
      billingSubscriptionsConfiguration(numberUuid: $numberUuid, forBootcamp: $forBootcamp) {
        paddleConfiguration {
          ...paddleConfiguration
        }
        paddleSubscriptionPlans {
          id
          billingType
          recurringPrice
        }
      }
    }

    ${PADDLE_CONFIGURATION_FRAGMENT}
  `,
  parse: boundary({
    billingSubscriptionsConfiguration: {
      paddleConfiguration: {
        paddleConfiguration: required(),
      },
      paddleSubscriptionPlans: required(),
    },
  }),
};

export const GET_PADDLE_CONFIGURATION = {
  query: gql`
    query getPaddleConfiguration($numberUuid: ID!) {
      billingSubscriptionsConfiguration(numberUuid: $numberUuid) {
        paddleConfiguration {
          ...paddleConfiguration
        }
      }
    }

    ${PADDLE_CONFIGURATION_FRAGMENT}
  `,
  parse: boundary({
    billingSubscriptionsConfiguration: {
      paddleConfiguration: required(),
    },
  }),
};

export const GET_BILLING_PRICES_CONFIGURATION = {
  query: gql`
    query billingPricesConfiguration($numberUuid: ID!) {
      billingPricesConfiguration(numberUuid: $numberUuid) {
        ...PricesConfigurationDetails
      }
    }

    ${PADDLE_BILLING_PRICES_CONFIGURATION_FRAGMENT}
  `,
  parse: boundary({
    billingPricesConfiguration: {
      paddleConfiguration: required(),
      paddleBillingPrices: boundary({
        id: required(),
        description: required(),
      }),
    },
  }),
};

export const GET_PADDLE_VERSION_CONFIGURATION = {
  query: gql`
    query paddleVersionConfiguration {
      paddleVersionConfiguration
    }
  `,
  parse: boundary({
    paddleVersionConfiguration: required(),
  }),
};

export const GET_NUMBER_BILLING_SUBSCRIPTION_QUERY = gql`
  query getNumberBillingSubscriptionQuery($numberUuid: ID!) {
    number(numberUuid: $numberUuid) {
      ...SubscriptionDetails
    }
  }

  ${SUBSCRIPTION_FRAGMENT}
`;

export const GET_PAYMENT_DETAILS_QUERY = gql`
  query getPaymentDetailsQuery($numberUuid: ID!) {
    number(numberUuid: $numberUuid) {
      uuid
      subscription {
        ...PaymentNumberSubscriptionDetails
      }
    }
  }

  ${PAYMENT_DETAILS_FRAGMENT}
`;

export const SWITCH_REQUEST_ALREADY_SUBMITTED = gql`
  query switchRequestAlreadySubmitted($subscriptionId: String!) {
    switchRequestAlreadySubmitted(subscriptionId: $subscriptionId)
  }

  ${PAYMENT_DETAILS_FRAGMENT}
`;
