import styled from "styled-components";

export const StyledTextInput = styled.input`
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
`;

type TIcon = { left?: boolean; right?: boolean };

export const StyledIcon = styled.span<TIcon>`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  line-height: 0;
  font-size: 0;
  ${({ left, right, theme: { spaces } }) => {
    if (left) return `left: ${spaces.ml}px;`;
    if (right) return `right: ${spaces.m}px;`;
    return "";
  }}
`;

export const StyledIconContainer = styled.div<TIcon>`
  position: relative;
  > div input {
    width: 100%;
    ${({ left, right, theme: { spaces } }) => {
      const spaceLeft = `${spaces.ml + spaces.ml + spaces.m}px`;
      const spaceRight = `${spaces.m + spaces.ml + spaces.ml}px`;
      if (left && right) return `padding: 0px ${spaceRight} 0px ${spaceLeft};`;
      if (left) return `padding-left: ${spaceLeft};`;
      if (right) return `padding-right: ${spaceRight};`;
      return "";
    }}
  }
`;
