import { Box } from "ds/Box";
import { NavItemProps, Navs } from "ds/Navs";
import { NavType } from "ds/Navs";
import { ButtonProps } from "ds/main";
import React from "react";
import { down } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";

import { NavigationFooter } from "./NavigationFooter";
import { NavigationHeader } from "./NavigationHeader";
import { MenuProps } from "./types";

export interface MainNavigationProps {
  collapsed?: boolean;
  items: NavItemProps[];
  numberMenu: MenuProps;
  help?: ButtonProps;
  menu?: MenuProps;
  qrAction?: ButtonProps;
  feedback?: NavItemProps;
}

export const MainNavigation = ({
  numberMenu,
  collapsed,
  help,
  menu,
  qrAction,
  items,
  feedback,
}: MainNavigationProps) => {
  const isMobile = useBreakpoint(down("lg"));

  return (
    <Box
      borderRightColor="borderColorDefault"
      borderRightWidth="1px"
      borderRightStyle="solid"
      width={collapsed ? "64px" : isMobile ? "100%" : "248px"}
      height="100%"
    >
      <NavigationHeader numberMenu={numberMenu} collapsed={collapsed} />
      <Box alignItems={collapsed ? "center" : "stretch"} py="100" px={collapsed ? "150" : "100"} flex={1}>
        <Navs
          items={
            collapsed
              ? items.map((item) => ({
                  ...item,
                  type: NavType.ICON,
                }))
              : items
          }
        />
      </Box>
      <NavigationFooter qrAction={qrAction} help={help} menu={menu} collapsed={collapsed} feedback={feedback} />
    </Box>
  );
};
