import styled from "styled-components";

import { getColor } from "../styles/colors";
import { getSpacing } from "../styles/spacing";
import { TStyledAnchor } from "./types";

export const StyledAnchor = styled.a<TStyledAnchor>`
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  cursor: pointer;
  ${({ fontSize, weight, margin, lineHeight, color }) => `
    font-size: ${fontSize};
    font-weight: ${weight};
    line-height: ${lineHeight};
    color: ${color};
    margin: ${getSpacing(margin)};
  `}
  color: ${({ color }) => color};
  text-decoration: ${({ decoration }) => decoration};
  &:hover {
    color: ${getColor("dark-2")};
    text-decoration: underline;
  }
`;
