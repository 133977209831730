/* tslint:disable */

/* eslint-disable */
import { gql } from "@apollo/client";

import * as Types from "../../generated/types";

export type PoolNumberDetailsFragment = {
  __typename: "PoolNumber";
  fbGraphId: string;
  phoneNumber: string;
  uuid: string;
};

export const PoolNumberDetailsFragmentDoc = gql`
  fragment PoolNumberDetails on PoolNumber {
    fbGraphId
    phoneNumber
    uuid
  }
`;
