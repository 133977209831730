import gql from "graphql-tag";

import {
  BILLING_CHART_DETAILS_FRAGMENT,
  BILLING_COUNTRY_DETAILS_FRAGMENT,
  BILLING_DETAILS_FRAGMENT,
  BILLING_NUMBER_DETAILS_FRAGMENT,
  CATEGORY_BILLING_STATS_FIELDS_FRAGMENT,
} from "./fragments";

export const GET_CREDIT_BALANCE = gql`
  query creditBalance($organisationUuid: ID!) {
    creditBalance(organisationUuid: $organisationUuid)
  }
`;

export const GET_BILLING = gql`
  query billingOverview($organisationUuid: ID!) {
    billingOverview(organisationUuid: $organisationUuid) {
      ...BillingDetails
    }
  }

  ${BILLING_DETAILS_FRAGMENT}
`;

export const GET_BILLING_CHART = gql`
  query billingChartOverview($organisationUuid: ID!) {
    billingOverview(organisationUuid: $organisationUuid) {
      ...BillingChartDetails
    }
  }

  ${BILLING_CHART_DETAILS_FRAGMENT}
`;

export const GET_COUNTRY_SPENDS = gql`
  query billingCountrySpends($datetime: DateTime, $numbers: [ID], $organisationUuid: ID!) {
    billingCountrySpends(numbers: $numbers, organisationUuid: $organisationUuid, datetime: $datetime) {
      ...BillingCountryDetails
    }
  }
  ${BILLING_COUNTRY_DETAILS_FRAGMENT}
`;

export const GET_NUMBER_SPENDS = gql`
  query billingNumberSpends($datetime: DateTime, $organisationUuid: ID!, $numberUuid: ID) {
    billingNumberSpends(organisationUuid: $organisationUuid, datetime: $datetime, numberUuid: $numberUuid) {
      ...BillingNumberDetails
    }
  }
  ${BILLING_NUMBER_DETAILS_FRAGMENT}
`;

export const GET_BILLING_DAILY_SPENDS = gql`
  query billingDailySpends($datetime: DateTime, $organisationUuid: ID!, $numberUuid: ID) {
    billingDailySpends(datetime: $datetime, organisationUuid: $organisationUuid, numberUuid: $numberUuid) {
      spendings {
        title
        total {
          ...CategoryBillingStatsFields
        }
        referralConversion {
          ...CategoryBillingStatsFields
        }
        authentication {
          ...CategoryBillingStatsFields
        }
        marketing {
          ...CategoryBillingStatsFields
        }
        utility {
          ...CategoryBillingStatsFields
        }
        service {
          ...CategoryBillingStatsFields
        }
      }
    }
  }
  ${CATEGORY_BILLING_STATS_FIELDS_FRAGMENT}
`;

export const ADD_CREDIT = gql`
  mutation addCredit($amount: Int!, $email: String!, $orgUuid: ID!) {
    addCredit(amount: $amount, email: $email, orgUuid: $orgUuid) {
      error
      url
    }
  }
`;
