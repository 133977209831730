import styled from "styled-components";

import { getSpacing } from "../styles/spacing";
import { TStyledTypography } from "./types";

export const StyledTypography = styled.div<TStyledTypography>`
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  ${({ fontSize, weight, margin, padding, lineHeight, color }) => `
    font-size: ${fontSize};
    font-weight: ${weight};
    line-height: ${lineHeight};
    color: ${color};
    margin: ${getSpacing(margin)};
    padding: ${getSpacing(padding)};
  `}
  ${({ wordBreak }) => wordBreak && `word-break: ${wordBreak}`}
`;
