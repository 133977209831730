// polyfill crypto.randomUUID()
if (typeof window.crypto === "undefined") window.crypto = {};

if (!("randomUUID" in crypto)) {
  // https://github.com/ungap/random-uuid/blob/main/package.json
  // https://stackoverflow.com/a/2117523/2800218
  crypto.randomUUID = function randomUUID() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    );
  };
}
