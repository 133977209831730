import { Box, Button, Text } from "grommet";
import styled from "styled-components";

import { turnTheme } from "theme";

export const StyledButton = styled(Button)`
  border: none;
  min-height: 48px;
  font-family: Inter;
  font-size: 15px;
  line-height: 18px;
  &:hover {
    box-shadow: none;
    opacity: 0.7;
  }
`;

export const StyledDeleteButton = styled(StyledButton)<{ btnType?: "primary" }>`
  background: ${({ btnType }) =>
    btnType === "primary" ? turnTheme.global.colors.brand : turnTheme.global.colors.error};
  box-shadow: 0px 0px 14px rgba(6, 51, 67, 0.11869);
  border-radius: 28px;
  color: ${turnTheme.global.colors.white};
  margin-right: 16px;
  font-weight: 600;
`;

export const StyledCancelButton = styled(StyledButton)`
  background: transparent;
  color: ${turnTheme.global.colors.brandTone};
`;

export const StyledConfirmTitle = styled(Text)`
  font-family: Inter;
  font-weight: ${({ weight }) => weight || "normal"};
  font-size: 18px;
  line-height: 26px;
  color: ${turnTheme.global.colors["dark-1"]};

  p {
    margin: 0 0 1em;
    &:last-of-type {
      margin: 0;
    }
  }
`;

export const StyledConfirmContainer = styled(Box)`
  background: ${turnTheme.global.colors.white};
  border: 1px solid ${turnTheme.global.colors["light-3"]};
  box-shadow: 0px 4px 10px rgba(13, 31, 38, 0.08);
  border-radius: 5px;
  position: relative;
`;

export const StyledCloseButton = styled(StyledButton)`
  position: absolute;
  right: 16px;
  top: 16px;
  padding: 4px;
  min-height: auto;
  height: 32px;
  width: 32px;
  border-radius: 32px;

  &:hover {
    background: ${turnTheme.global.colors["light-2"]};
  }
  &:focus {
    box-shadow: none;
  }
`;
