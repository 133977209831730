import gql from "graphql-tag";

import { boundary } from "api/boundary";

import { CHAT_NOTE_FRAGMENT, MESSAGE_DETAILS_FRAGMENT, MESSAGE_DETAILS_WITH_CHAT_MIN_FRAGMENT } from "./fragments";

export const DELETE_MESSAGE = gql`
  mutation deleteMessage($numberUuid: ID!, $messageUuid: ID!) {
    deleteMessage(messageUuid: $messageUuid, numberUuid: $numberUuid) {
      ...MessageDetails
    }
  }

  ${MESSAGE_DETAILS_FRAGMENT.query}
`;

export const MARK_MESSAGES_AS_HANDLED = gql`
  mutation markMessagesAsHandled($handled: Boolean!, $messagesUuids: [ID]!, $numberUuid: ID!) {
    markMessagesAsHandled(handled: $handled, messagesUuids: $messagesUuids, numberUuid: $numberUuid) {
      uuid
      isHandled
    }
  }
`;

export const ASSIGN_MESSAGE_NUMBER_TAG = gql`
  mutation assignMessageNumberTag($messageUuid: ID!, $numberTagUuid: ID!) {
    assignMessageNumberTag(messageUuid: $messageUuid, numberTagUuid: $numberTagUuid) {
      ...MessageDetails
    }
  }
  ${MESSAGE_DETAILS_FRAGMENT.query}
`;

export const REMOVE_MESSAGE_NUMBER_TAG = gql`
  mutation removeMessageNumberTag($messageUuid: ID!, $numberTagUuid: ID!) {
    removeMessageNumberTag(messageUuid: $messageUuid, numberTagUuid: $numberTagUuid) {
      ...MessageDetails
    }
  }
  ${MESSAGE_DETAILS_FRAGMENT.query}
`;

export const SUBMIT_TEXT_MESSAGE = {
  query: gql`
    mutation submitTextMessage(
      $chatUuid: ID!
      $content: String!
      $faqUuid: ID
      $optInUuid: ID
      $attachmentId: ID
      $isTranslationEnabled: Boolean
      $translatedContent: String
      $originalLanguageCode: String
      $translatedLanguageCode: String
    ) {
      submitTextMessage(
        chatUuid: $chatUuid
        content: $content
        faqUuid: $faqUuid
        optInUuid: $optInUuid
        attachmentId: $attachmentId
        isTranslationEnabled: $isTranslationEnabled
        translatedContent: $translatedContent
        originalLanguageCode: $originalLanguageCode
        translatedLanguageCode: $translatedLanguageCode
      ) {
        ...MessageDetails
      }
    }

    ${MESSAGE_DETAILS_FRAGMENT.query}
  `,
  parse: boundary({
    submitTextMessage: MESSAGE_DETAILS_FRAGMENT.parse,
  }),
};

export const CREATE_TEMPLATE_MESSAGE = {
  query: gql`
    mutation createTemplatedMessage($chatUuid: ID!, $template: TemplatedMessageTemplateIo!, $attachmentId: ID) {
      createTemplatedMessage(chatUuid: $chatUuid, template: $template, attachmentId: $attachmentId) {
        ...MessageDetails
      }
    }

    ${MESSAGE_DETAILS_FRAGMENT.query}
  `,
  parse: boundary({
    createTemplatedMessage: MESSAGE_DETAILS_FRAGMENT.parse,
  }),
};

export const SCHEDULE_REMINDER = gql`
  mutation scheduleReminder(
    $accountUuid: ID!
    $numberUuid: ID!
    $segmentUuid: ID
    $query: String!
    $attachmentId: ID
    $scheduledAt: DateTime
    $name: String
    $templateParams: TemplatedMessageTemplateIo!
  ) {
    scheduleReminder(
      accountUuid: $accountUuid
      numberUuid: $numberUuid
      segmentUuid: $segmentUuid
      query: $query
      attachmentId: $attachmentId
      scheduledAt: $scheduledAt
      name: $name
      templateParams: $templateParams
    ) {
      uuid
    }
  }
`;

export const SEARCH_MESSAGES = gql`
  mutation searchMessages(
    $numberUuid: ID!
    $query: SearchQueryIo!
    $page: Int
    $pageSize: Int
    $sort: SortType = DESC
  ) {
    searchMessages(numberUuid: $numberUuid, query: $query, page: $page, pageSize: $pageSize, sort: $sort) {
      count
      results {
        ...MessageDetailsWithChatMin
      }
    }
  }

  ${MESSAGE_DETAILS_WITH_CHAT_MIN_FRAGMENT.query}
`;

export const CREATE_CHAT_NOTE = {
  query: gql`
    mutation createChatNote($chatUuid: ID!, $content: String!, $mentions: [ChatNoteMentionIo!]!) {
      createChatNote(chatUuid: $chatUuid, content: $content, mentions: $mentions) {
        ...MessageDetails
      }
    }

    ${MESSAGE_DETAILS_FRAGMENT.query}
  `,
  parse: boundary({
    createChatNote: MESSAGE_DETAILS_FRAGMENT.parse,
  }),
};

export const DELETE_CHAT_NOTE = gql`
  mutation deleteChatNote($chatNoteUuid: ID!) {
    deleteChatNote(chatNoteUuid: $chatNoteUuid) {
      uuid
    }
  }
`;

export const TOGGLE_MENTION_IS_CLEARED = gql`
  mutation toggleMentionIsCleared($isCleared: Boolean!, $mentionUuid: ID!) {
    toggleMentionIsCleared(isCleared: $isCleared, mentionUuid: $mentionUuid) {
      ...ChatNoteMentionFragment
    }
  }

  ${CHAT_NOTE_FRAGMENT}
`;

export const SEND_REACTION_TO_MESSAGE = gql`
  mutation sendReactionToMessage($emoji: String!, $messageUuid: ID!, $numberUuid: ID!) {
    sendReactionToMessage(emoji: $emoji, messageUuid: $messageUuid, numberUuid: $numberUuid) {
      ...MessageDetails
    }
  }

  ${MESSAGE_DETAILS_FRAGMENT.query}
`;

export const PREVIEW_TRANSLATION = gql`
  mutation previewTranslation(
    $chatUuid: ID!
    $content: String!
    $originalLanguageCode: String!
    $translatedLanguageCode: String!
  ) {
    previewTranslation(
      chatUuid: $chatUuid
      content: $content
      originalLanguageCode: $originalLanguageCode
      translatedLanguageCode: $translatedLanguageCode
    ) {
      translatedContent
    }
  }
`;

export const TRANSLATE_MESSAGES = gql`
  mutation translateMessages(
    $chatUuid: ID!
    $messageUuids: [ID]!
    $accountLanguageCode: String!
    $contactLanguageCode: String!
  ) {
    translateMessages(
      chatUuid: $chatUuid
      messageUuids: $messageUuids
      accountLanguageCode: $accountLanguageCode
      contactLanguageCode: $contactLanguageCode
    ) {
      failedMessagesUuids
    }
  }
`;

export const SET_TRANSLATION_OPTION = gql`
  mutation setTranslationOption($operatorUuid: ID!, $option: String!, $languageCode: String!) {
    setTranslationOption(operatorUuid: $operatorUuid, option: $option, languageCode: $languageCode) {
      uuid
      languageCode
    }
  }
`;
