import gql from "graphql-tag";

import { MESSAGE_DETAILS_FRAGMENT } from "graphql/messages";

import { NOTEBOOK_DETAILS_FRAGMENT, SIMULATOR_FRAGMENT, STACK_CONTAINER_DETAILS_FRAGMENT } from "./fragments";

export const CREATE_NOTEBOOK = gql`
  mutation createNotebook($numberUuid: ID!, $name: String!, $notebook: NotebookIo!) {
    createNotebook(numberUuid: $numberUuid, name: $name, notebook: $notebook) {
      ...StackContainerDetails
    }
  }

  ${STACK_CONTAINER_DETAILS_FRAGMENT.query}
`;

export const UPDATE_NOTEBOOK = gql`
  mutation updateNotebook(
    $numberUuid: ID!
    $stackContainerUuid: ID!
    $name: String!
    $notebook: NotebookIo!
    $publish: Boolean
  ) {
    updateNotebook(
      numberUuid: $numberUuid
      stackContainerUuid: $stackContainerUuid
      name: $name
      notebook: $notebook
      publish: $publish
    ) {
      ...StackContainerDetails
    }
  }

  ${STACK_CONTAINER_DETAILS_FRAGMENT.query}
`;

export const TOGGLE_NOTEBOOK = gql`
  mutation toggleNotebook($numberUuid: ID!, $stackContainerUuid: ID!, $enabled: Boolean!) {
    toggleNotebook(numberUuid: $numberUuid, stackContainerUuid: $stackContainerUuid, enabled: $enabled) {
      ...StackContainerDetails
    }
  }

  ${STACK_CONTAINER_DETAILS_FRAGMENT.query}
`;

export const DELETE_NOTEBOOK = gql`
  mutation deleteNotebook($numberUuid: ID!, $stackContainerUuid: ID!) {
    deleteNotebook(numberUuid: $numberUuid, stackContainerUuid: $stackContainerUuid) {
      uuid
      name
    }
  }
`;

export const CONVERT_ALL_THREADS_TO_JOURNEYS = gql`
  mutation convertAllThreadsToJourneys($numberUuid: ID!) {
    convertAllThreadsToJourneys(numberUuid: $numberUuid)
  }
`;

export const CONVERT_THREAD_TO_JOURNEY = gql`
  mutation convertThreadToJourney($numberUuid: ID!, $uuid: ID!) {
    convertThreadToJourney(numberUuid: $numberUuid, uuid: $uuid) {
      uuid
      title
    }
  }
`;

export const CREATE_SIMULATOR = gql`
  mutation createSimulator($numberUuid: ID!, $notebook: NotebookIo!, $stackContainerUuid: ID) {
    createSimulator(numberUuid: $numberUuid, notebook: $notebook, stackContainerUuid: $stackContainerUuid) {
      ...SimulatorFragment
    }
  }

  ${SIMULATOR_FRAGMENT}
`;

export const DUPLICATE_JOURNEY = gql`
  mutation duplicateJourney($numberUuid: ID!, $stackContainerUuid: ID!) {
    duplicateJourney(numberUuid: $numberUuid, stackContainerUuid: $stackContainerUuid) {
      uuid
      isCanvas
    }
  }
`;

export const SUBMIT_SIMULATOR_INPUT = gql`
  mutation submitSimulatorInput(
    $numberUuid: ID!
    $uuid: ID!
    $vars: Json!
    $notebook: NotebookIo!
    $userInput: String!
  ) {
    submitSimulatorInput(
      numberUuid: $numberUuid
      uuid: $uuid
      vars: $vars
      notebook: $notebook
      userInput: $userInput
    ) {
      simulator {
        ...SimulatorFragment
      }
      input
      state
      outputs {
        ...SimulatorOutputStateFragment
      }
    }
  }

  ${SIMULATOR_FRAGMENT}
`;

export const CREATE_JOURNEY_GLOBAL_DATA = gql`
  mutation createJourneyGlobalData($numberUuid: ID!, $data: JourneyDataIo!) {
    createJourneyGlobalData(numberUuid: $numberUuid, data: $data) {
      data {
        id
        type
      }
      name
      uuid
    }
  }
`;

export const UPDATE_JOURNEY_GLOBAL_DATA = gql`
  mutation updateJourneyGlobalData($data: JourneyDataIo!, $globalDataUuid: ID!, $numberUuid: ID!) {
    updateJourneyGlobalData(data: $data, globalDataUuid: $globalDataUuid, numberUuid: $numberUuid) {
      data {
        id
        type
      }
      name
      uuid
    }
  }
`;

export const DELETE_JOURNEY_GLOBAL_DATA = gql`
  mutation deleteJourneyGlobalData($numberUuid: ID!, $globalDataUuid: ID!) {
    deleteJourneyGlobalData(numberUuid: $numberUuid, globalDataUuid: $globalDataUuid) {
      data {
        id
        type
      }
      name
      uuid
    }
  }
`;

export const CREATE_JOURNEY_LOCAL_DATA = gql`
  mutation createJourneyLocalData($numberUuid: ID!, $stackContainerUuid: ID!, $data: JourneyDataIo!) {
    createJourneyLocalData(numberUuid: $numberUuid, stackContainerUuid: $stackContainerUuid, data: $data) {
      data {
        id
        type
      }
      name
      uuid
    }
  }
`;

export const UPDATE_JOURNEY_LOCAL_DATA = gql`
  mutation updateJourneyLocalData($data: JourneyDataIo!, $localDataUuid: ID!, $numberUuid: ID!) {
    updateJourneyLocalData(data: $data, localDataUuid: $localDataUuid, numberUuid: $numberUuid) {
      data {
        id
        type
      }
      name
      uuid
    }
  }
`;

export const DELETE_JOURNEY_LOCAL_DATA = gql`
  mutation deleteJourneyLocalData($numberUuid: ID!, $localDataUuid: ID!) {
    deleteJourneyLocalData(numberUuid: $numberUuid, localDataUuid: $localDataUuid) {
      data {
        id
        type
      }
      name
      uuid
    }
  }
`;

export const START_JOURNEY = gql`
  mutation startJourney($chatUuid: ID!, $numberUuid: ID!, $stackContainerUuid: ID!) {
    startJourney(chatUuid: $chatUuid, numberUuid: $numberUuid, stackContainerUuid: $stackContainerUuid) {
      ...MessageDetails
    }
  }

  ${MESSAGE_DETAILS_FRAGMENT.query}
`;

export const NAME_JOURNEY_VERSION = gql`
  mutation nameJourneyVersion($name: String!, $notebookRevisionUuid: ID!, $numberUuid: ID!) {
    nameNotebookRevision(name: $name, notebookRevisionUuid: $notebookRevisionUuid, numberUuid: $numberUuid) {
      uuid
      versionName
      accountUuid
      insertedAt
    }
  }
`;

export const RESTORE_JOURNEY_VERSION = gql`
  mutation restoreJourneyVersion($journeyUuid: ID!, $notebookRevisionUuid: ID!, $numberUuid: ID!) {
    restoreNotebookRevision(
      journeyUuid: $journeyUuid
      notebookRevisionUuid: $notebookRevisionUuid
      numberUuid: $numberUuid
    ) {
      uuid
      notebook {
        ...NotebookDetails
      }
      notebookRevisions(limit: 1, offset: 0) {
        hasNextPage
        revisions {
          accountUuid
          insertedAt
          uuid
          versionName
        }
      }
    }
  }

  ${NOTEBOOK_DETAILS_FRAGMENT.query}
`;

export const PUBLISH_LATEST_NOTEBOOK_REVISION = gql`
  mutation publishLatestNotebookRevision($numberUuid: ID!, $stackContainerUuid: ID!) {
    publishLatestNotebookRevision(numberUuid: $numberUuid, stackContainerUuid: $stackContainerUuid) {
      uuid
      productionNotebookRevisionUuid
    }
  }
`;
