import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import duration from "dayjs/plugin/duration";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import localizedFormat from "dayjs/plugin/localizedFormat";
import minMax from "dayjs/plugin/minMax";
import moment from "moment";

// === MOMENT ===

export const DATE_UPDATE_FORMAT = "Do of MMMM YYYY, hh:mmA";
export const TABLE_DATE_FORMAT = "YYYY-MM-DD hh:mm:ss";
export const TABLE_DATE = "YYYY-MM-DD";

export const FULL_MONTH_FORMAT = "DD MMM YYYY";
export const FULL_MONTH_WITH_COMA_FORMAT = "DD MMMM, YYYY";

export const DAY_OF_THE_MONTH = "Do [of] MMMM";

export const START_MONTH_DAY = moment().startOf("month").format("MMMM Do");
export const END_MONTH_DAY = moment().endOf("month").format("MMMM Do");

export const getHumanizedDuration = (isoDuration) => {
  const duration = moment.duration(isoDuration);
  const hours = duration.hours();
  const minutes = duration.minutes();

  return hours
    ? `${moment.duration(hours, "h").humanize({ h: 24 })} ${
        minutes ? `and ${moment.duration(minutes, "m").humanize({ m: 60 })}` : ""
      }`
    : minutes
      ? moment.duration(minutes, "m").humanize({ m: 60 })
      : "immediately";
};

export const getTimeFormatHHMMSS = (time) => {
  return new Date(time).toISOString().substring(11, 19);
};

export const getDateFormatBasedByUserLocale = (
  data: { y?: string; m?: string; d?: string } = { y: "yyyy", m: "mm", d: "dd" }
) => {
  const year = data.y ?? "yyyy";
  const month = data.m ?? "mm";
  const day = data.d ?? "dd";
  const date = new Date("2023-11-16").toLocaleDateString(navigator.language);
  const format = date
    .replace("2023", year)
    .replace("11", month)
    .replace("16", day)
    // context(Aratramba, 2024-04-13): Also replacing day 15 is the simplest fix for the issue of
    // people in Brazil getting a day before because of the timezone offset
    // e.g new Date('2024-01-01') returns Dec. 31 in Brazil
    .replace("15", day);
  return format;
};

// === DAYJS ===

dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(calendar);
dayjs.extend(duration);
dayjs.extend(minMax);
dayjs.extend(localizedFormat);

export const formatChatlistTimestamp = (value: string) => {
  const timestamp = dayjs(value);

  return timestamp.isToday()
    ? timestamp.toDate().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
    : timestamp.isYesterday()
      ? timestamp.calendar().split(" ")[0]
      : dayjs().diff(timestamp, "day", true) < 7
        ? timestamp.format("dddd")
        : timestamp.toDate().toLocaleDateString();
};

export { dayjs };
