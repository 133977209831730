import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery as apolloUseQuery,
} from "@apollo/client";

// usage of useQuery
// useQuery(query, { showStaleData: false, ...variables })

interface IQueryHookOptions<TData, TVariables extends OperationVariables> extends QueryHookOptions<TData, TVariables> {
  showStaleData?: boolean;
}

export const useQuery = <TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: IQueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> => {
  const { data, previousData, ...rest } = apolloUseQuery<TData, TVariables>(query, options);
  const { showStaleData = true } = options ?? {};

  return {
    ...rest,
    previousData,
    data: showStaleData ? (data ?? previousData) : data,
  };
};

// TODO(vasylp): add possibility to use useParsedQuery

// export const useParsedQuery = <TData = any, TVariables = OperationVariables, Fn extends Function = any>(
//   query: {
//     query: DocumentNode | TypedDocumentNode<TData, TVariables>;
//     parse: Fn;
//   },
//   options?: IQueryHookOptions<Call<Fn, TData>, TVariables>
// ): QueryResult<Call<Fn, TData>, TVariables> => {
//   const {
//     data,
//     previousData,
//     subscribeToMore: baseSubcribeToMore,
//     updateQuery: mainUpdateQuery,
//     refetch: baseRefetch,
//     ...rest
//   } = apolloUseQuery<TData, TVariables>(query.query, options as TVariables);
//   const showStaleData = Boolean(options?.showStaleData) ? options?.showStaleData : true;
//   const parseData = (data?: TData) => {
//     if (showStaleData) {
//       return query.parse(data) ?? query.parse(previousData);
//     }
//     return query.parse(data);
//   };

//   const subscribeToMore = (subscribeToMoreOptions) => {
//     const { updateQuery: baseUpdateQuery } = subscribeToMoreOptions;

//     const updateQuery = (previousResult: TData, updateQueryOptions: { variables: TVariables }) =>
//       baseUpdateQuery(parseData(previousResult), updateQueryOptions);

//     baseSubcribeToMore({
//       ...subscribeToMoreOptions,
//       updateQuery,
//     });
//   };
//   // (previousQueryResult: TData, options: Pick<WatchQueryOptions<TVars, TData>, "variables">)
//   const updateQuery = () => {
//     const updateMapFn = (previousQueryResult: TData) => parseData(previousQueryResult);

//     mainUpdateQuery(updateMapFn);
//   };

//   const refetch = async (variables?: Partial<TVariables>): Promise<ApolloQueryResult<Call<Fn, TData>>> => {
//     const data = await baseRefetch(variables);
//     return parseData(data.data);
//   };

//   return {
//     ...rest,
//     previousData: parseData(previousData),
//     data: parseData(data),
//     subscribeToMore: (subscribeToMore as unknown) as any,
//     updateQuery: updateQuery as any,
//     refetch: refetch as any,
//   };
// };
