import data from "@emoji-mart/data";
import { init } from "emoji-mart";
import { ComponentType, LazyExoticComponent, lazy } from "react";
import { matchPath } from "react-router";

import { wait } from "utils/promise";

type TImportStatement<T extends {}> = () => Promise<{ default: ComponentType<T> }>;

type TLazyComponent<T extends {}> = LazyExoticComponent<ComponentType<T>>;

type TLazyComponentWithPreload<T extends {}> = TLazyComponent<T> & {
  preload: TImportStatement<T>;
};

const numberImportStatements = {
  Answers: () => import("containers/Content/Answers"),
  Automation: () => import("pages/Automation"),
  Build: () => import("pages/Build"),
  Journeys: () => import("pages/Journeys"),
  Inbox: () => import("pages/NumberDetail/pages/Inbox"),
  Insights: () => import("containers/Insights/Insights"),
  OnboardingProgress: () => import("containers/Onboarding/OnboardingProgress"),
  Playbooks: () => import("pages/NumberDetail/pages/Playbooks"),
  Reminders: () => import("containers/Reminders/Reminders"),
  Settings: () => import("containers/Settings/Settings"),
};

const appImportStatements = {
  Accounts: () => import("pages/Accounts"),
  BillingContainer: () => import("containers/Billing/BillingContainer"),
  ButtonsContainer: () => import("containers/Buttons/ButtonsContainer"),
  NumberDetail: () => import("pages/NumberDetail"),
  Onboarding: () => import("containers/Onboarding"),
  PlaybookDetail: () => import("pages/PlaybookDetail"),
  PlaybookForm: () => import("pages/PlaybookForm"),
  PlaybooksPublic: () => import("pages/PlaybooksPublic"),
  QrView: () => import("pages/Qr"),
  ReminderDetail: () => import("containers/Reminders/ReminderDetail"),
  Sandbox: () => import("pages/Sandbox"),
  StackDetail: () => import("pages/StackDetail"),
  StackDetailCanvas: () => import("pages/StackDetail/StackDetailCanvas"),
  TemplateContainer: () => import("containers/Template/TemplateContainer"),
  Thread: () => import("containers/Thread/Thread"),
  Payment: () => import("pages/Payment/Payment"),
};

export const lazyWithPreload = <T extends {}>(importStatement: TImportStatement<T>): TLazyComponentWithPreload<T> => {
  const Component = lazy(importStatement) as TLazyComponentWithPreload<T>;
  Component.preload = importStatement;
  return Component;
};

const preloadNumberChunks = () => {
  Object.values(numberImportStatements).forEach((importStatement) => importStatement());
};
const preloadAppChunks = () => {
  Object.entries(appImportStatements).forEach(([component, importStatement]) =>
    importStatement().then(() => {
      if (component === "NumberDetail") {
        preloadNumberChunks();
      }
    })
  );

  // context(alexandrchebotar, 2023-04-10): preload not route-related chunks
  init({ data });
};

const preloadNumberRouteChunks = () => {
  const { pathname } = window.location;
  const isPath = (path: string, exact = true) =>
    Boolean(
      matchPath(pathname, {
        path: `/number/:numberUuid${path}`,
        exact,
      })
    );

  if (isPath("")) {
    numberImportStatements.Inbox();
    numberImportStatements.OnboardingProgress();
  } else if (isPath("/content")) {
    numberImportStatements.Answers();
  } else if (isPath("/journeys/automation")) {
    numberImportStatements.Automation();
  } else if (isPath("/journeys")) {
    numberImportStatements.Build();
  } else if (isPath("/insights")) {
    numberImportStatements.Insights();
  } else if (isPath("/playbooks")) {
    numberImportStatements.Playbooks();
  } else if (isPath("/reminders")) {
    numberImportStatements.Reminders();
  } else if (isPath("/settings")) {
    numberImportStatements.Settings();
  }
};

export const preloadAppRouteChunks = () => {
  const { pathname } = window.location;
  const isPath = (path: string, exact = true) =>
    Boolean(
      matchPath(pathname, {
        path,
        exact,
      })
    );

  if (isPath("/")) {
    appImportStatements.NumberDetail().then(preloadNumberRouteChunks);
  } else if (isPath("/org/:organisationUuid/members")) {
    appImportStatements.Accounts();
  } else if (isPath("/billing") || isPath("/billing/:numberUuid", false)) {
    appImportStatements.BillingContainer();
  } else if (isPath("/buttons/:numberUuid/:cardUuid?")) {
    appImportStatements.ButtonsContainer();
  } else if (isPath("/number/:numberUuid", false)) {
    appImportStatements.NumberDetail().then(preloadNumberRouteChunks);
  } else if (isPath("/onboarding/:numberUuid")) {
    appImportStatements.Onboarding().then(numberImportStatements.OnboardingProgress);
  } else if (isPath("/playbooks/:playbookUuid") || isPath("/playbooks/public/:playbookUuid")) {
    appImportStatements.PlaybookDetail();
  } else if (
    isPath("/playbooks/:numberUuid/new/:threadUuid?") ||
    isPath("/playbooks/:numberUuid/edit/:editPlaybookUuid")
  ) {
    appImportStatements.PlaybookForm();
  } else if (isPath("/playbooks/public")) {
    appImportStatements.PlaybooksPublic();
  } else if (isPath("/qr/:numberUuid", false)) {
    appImportStatements.QrView();
  } else if (isPath("/reminder/:numberUuid/")) {
    appImportStatements.ReminderDetail();
  } else if (isPath("/sandbox/new") || isPath("/org/:organisationUuid/sandbox/new", false)) {
    appImportStatements.Sandbox();
  } else if (isPath("/stacks/:numberUuid/:stackContainerUuid?")) {
    appImportStatements.StackDetail();
  } else if (isPath("/stacks/:numberUuid/:stackContainerUuid/canvas")) {
    appImportStatements.StackDetailCanvas();
  } else if (isPath("/template/:numberUuid/:templateUuid?")) {
    appImportStatements.TemplateContainer();
  } else if (isPath("/threads/:numberUuid/new") || isPath("/threads/:numberUuid/edit/:threadUuid")) {
    appImportStatements.Thread();
  } else if (isPath("/payment")) {
    appImportStatements.Payment();
  }
};

let postPreloadStarted = false;
const withPostPreload =
  <T extends {}>(statement: TImportStatement<T>, name: string): TImportStatement<T> =>
  () => {
    const _import = statement();

    if (name !== "NumberDetail") {
      // start preload all other chunks with delay to let user fetch queries
      _import.then(wait(5000)).then(() => {
        if (!postPreloadStarted) {
          postPreloadStarted = true;
          preloadAppChunks();
        }
      });
    }

    return _import;
  };

// context(alexandrchebotar, 2023-04-25): lazy components for NumberDetail routes
export const numberComponents = {
  Answers: lazy(withPostPreload(numberImportStatements.Answers, "Answers")),
  Automation: lazy(withPostPreload(numberImportStatements.Automation, "Automation")),
  Build: lazy(withPostPreload(numberImportStatements.Build, "Build")),
  Inbox: lazy(withPostPreload(numberImportStatements.Inbox, "Inbox")),
  Insights: lazy(withPostPreload(numberImportStatements.Insights, "Insights")),
  Journeys: lazy(withPostPreload(numberImportStatements.Journeys, "Journeys")),
  OnboardingProgress: lazy(withPostPreload(numberImportStatements.OnboardingProgress, "OnboardingProgress")),
  Playbooks: lazy(withPostPreload(numberImportStatements.Playbooks, "Playbooks")),
  Reminders: lazy(withPostPreload(numberImportStatements.Reminders, "Reminders")),
  Settings: lazy(withPostPreload(numberImportStatements.Settings, "Settings")),
};

// context(alexandrchebotar, 2023-04-25): lazy components for BaseRouter routes
export const appComponents = {
  Accounts: lazy(withPostPreload(appImportStatements.Accounts, "Accounts")),
  BillingContainer: lazy(withPostPreload(appImportStatements.BillingContainer, "BillingContainer")),
  ButtonsContainer: lazy(withPostPreload(appImportStatements.ButtonsContainer, "ButtonsContainer")),
  StackDetail: lazy(withPostPreload(appImportStatements.StackDetail, "StackDetail")),
  StackDetailCanvas: lazy(withPostPreload(appImportStatements.StackDetailCanvas, "StackDetailCanvas")),
  NumberDetail: lazy(withPostPreload(appImportStatements.NumberDetail, "NumberDetail")),
  Onboarding: lazy(withPostPreload(appImportStatements.Onboarding, "Onboarding")),
  PlaybookDetail: lazy(withPostPreload(appImportStatements.PlaybookDetail, "PlaybookDetail")),
  PlaybookForm: lazy(withPostPreload(appImportStatements.PlaybookForm, "PlaybookForm")),
  PlaybooksPublic: lazy(withPostPreload(appImportStatements.PlaybooksPublic, "PlaybooksPublic")),
  QrView: lazy(withPostPreload(appImportStatements.QrView, "QrView")),
  Thread: lazy(withPostPreload(appImportStatements.Thread, "Thread")),
  Sandbox: lazy(withPostPreload(appImportStatements.Sandbox, "Sandbox")),
  TemplateContainer: lazy(withPostPreload(appImportStatements.TemplateContainer, "TemplateContainer")),
  ReminderDetail: lazy(withPostPreload(appImportStatements.ReminderDetail, "ReminderDetail")),
  Payment: lazy(withPostPreload(appImportStatements.Payment, "Payment")),
};

// context(alexandrchebotar, 2023-04-25): lazy components not related to any route
export const LazyEmojiPicker = lazyWithPreload(
  () => import("pages/NumberDetail/pages/Inbox/components/ReplyBox/components/EmojiPicker")
);
