import { useEffect, useRef, useState } from "react";

export const useTimer = () => {
  const intervalId = useRef(0);
  const startedAt = useRef(0);
  const storedDuration = useRef(0);
  const [timer, setTimer] = useState(0);

  const getDuration = () => storedDuration.current + Date.now() - startedAt.current;
  const updateTimer = () => setTimer(getDuration());
  const clearUpdateAndStoreDuration = (duration: number) => {
    clearInterval(intervalId.current);
    setTimer(duration);
    storedDuration.current = duration;
  };

  const startTimer = () => {
    startedAt.current = Date.now();
    intervalId.current = window.setInterval(updateTimer, 1000);
  };
  const pauseTimer = () => clearUpdateAndStoreDuration(getDuration());
  const resetTimer = () => clearUpdateAndStoreDuration(0);

  useEffect(() => () => clearInterval(intervalId.current), []);

  return {
    timer,
    startTimer,
    pauseTimer,
    resetTimer,
  };
};
