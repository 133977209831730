import gql from "graphql-tag";

import { boundary, required } from "api/boundary";

import { MESSAGE_DETAILS_FRAGMENT, MESSAGE_DETAILS_WITH_CHAT_MEDIUM_FRAGMENT } from "./fragments";

export const GET_MESSAGES = {
  query: gql`
    query getMessages($chatUuid: ID!, $limit: Int, $orderBy: String) {
      messages(chatUuid: $chatUuid, limit: $limit, orderBy: $orderBy) {
        canLoadMore
        results {
          ...MessageDetails
        }
      }
    }

    ${MESSAGE_DETAILS_FRAGMENT.query}
  `,
  parse: boundary({
    messages: {
      results: MESSAGE_DETAILS_FRAGMENT.parse,
    },
  }),
};

export const GET_MESSAGE_SEARCH_RESULTS = {
  query: gql`
    query getMessageSearchResults(
      $numberUuid: ID!
      $query: SearchQueryIo!
      $page: Int
      $pageSize: Int
      $sort: SortType = DESC
    ) {
      messageSearchResults(numberUuid: $numberUuid, query: $query, page: $page, pageSize: $pageSize, sort: $sort) {
        count
        results {
          ...MessageDetailsWithChatMedium
        }
      }
    }

    ${MESSAGE_DETAILS_WITH_CHAT_MEDIUM_FRAGMENT.query}
  `,
  parse: boundary({
    messageSearchResults: {
      count: required(),
      results: MESSAGE_DETAILS_WITH_CHAT_MEDIUM_FRAGMENT.parse,
    },
  }),
};

export const CHAT_NOTES_WITH_MENTIONS_FOR_TAGGED_ACCOUNT = {
  query: gql`
    query chatNotesWithMentionsForTaggedAccount(
      $isCleared: Boolean
      $limit: Int
      $numberUuid: ID!
      $offset: Int
      $orderBy: String
    ) {
      chatNotesWithMentionsForTaggedAccount(
        isCleared: $isCleared
        limit: $limit
        numberUuid: $numberUuid
        offset: $offset
        orderBy: $orderBy
      ) {
        count
        results {
          ...MessageDetailsWithChatMedium
        }
      }
    }

    ${MESSAGE_DETAILS_WITH_CHAT_MEDIUM_FRAGMENT.query}
  `,
  parse: boundary({
    chatNotesWithMentionsForTaggedAccount: {
      count: required(),
      results: MESSAGE_DETAILS_WITH_CHAT_MEDIUM_FRAGMENT.parse,
    },
  }),
};
