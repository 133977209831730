/* eslint-disable no-useless-escape */

const isExisty = function (value) {
  return value !== null && value !== undefined;
};

const isEmpty = function (value) {
  return value === "";
};

const validations = {
  isDefaultRequiredValue: function (values, value) {
    return value === undefined || value === "";
  },
  isExisty: function (values, value) {
    return isExisty(value);
  },
  matchRegexp: function (values, value, regexp) {
    return !isExisty(value) || isEmpty(value) || regexp.test(value);
  },
  isUndefined: function (values, value) {
    return value === undefined;
  },
  isEmptyString: function (values, value) {
    return isEmpty(value);
  },
  notEmptyString: function (values, value) {
    return !isEmpty(value);
  },
  isUrl: function (values, value) {
    return validations.matchRegexp(
      values,
      value,
      /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:{|}|@)|\/|\?)*)?$/i
    );
  },
  isHttpsUrl: function (value) {
    return validations.matchRegexp(
      "",
      value,
      /^https:(\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~{}:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
    );
  },
  isPhoneNumber: function (value) {
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value);
  },
  isTrue: function (values, value) {
    return value === true;
  },
  isFalse: function (values, value) {
    return value === false;
  },
  isNumeric: function (values, value) {
    if (typeof value === "number") {
      return true;
    }
    return validations.matchRegexp(values, value, /^[-+]?(?:\d*[.])?\d+$/);
  },
  isAlpha: function (values, value) {
    return validations.matchRegexp(values, value, /^[A-Z]+$/i);
  },
  isAlphanumeric: function (values, value) {
    return validations.matchRegexp(values, value, /^[0-9A-Z]+$/i);
  },
  isInt: function (values, value) {
    return validations.matchRegexp(values, value, /^(?:[-+]?(?:0|[1-9]\d*))$/);
  },
  isFloat: function (values, value) {
    return validations.matchRegexp(values, value, /^(?:[-+]?(?:\d+))?(?:\.\d*)?(?:[eE][\+\-]?(?:\d+))?$/);
  },
  isWords: function (values, value) {
    return validations.matchRegexp(values, value, /^[A-Z\s]+$/i);
  },
  isSpecialWords: function (values, value) {
    return validations.matchRegexp(values, value, /^[A-Z\s\u00C0-\u017F]+$/i);
  },
  isLength: function (length) {
    return function (values, value) {
      return !isExisty(value) || isEmpty(value) || value.length === length;
    };
  },
  equals: function (eql) {
    return function (values, value) {
      return !isExisty(value) || isEmpty(value) || value === eql;
    };
  },
  equalsField: function (field) {
    return function (values, value) {
      return value === values[field];
    };
  },
  maxLength: function (length) {
    return function (values, value) {
      return !isExisty(value) || value.length <= length;
    };
  },
  minLength: function (length) {
    return function (values, value) {
      return !isExisty(value) || isEmpty(value) || value.length >= length;
    };
  },
  isBigger: function (number) {
    return function (values, value) {
      return value > number;
    };
  },
  isSmaller: function (number) {
    return function (values, value) {
      return value < number;
    };
  },
  isBiggerOrEqual: function (number) {
    return function (values, value) {
      return value >= number;
    };
  },
  isSmallerOrEqual: function (number) {
    return function (values, value) {
      return value <= number;
    };
  },
  isEmail: function (string) {
    return /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/.test(string);
  },
};

module.exports = validations;
