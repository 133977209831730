import { Box } from "ds/Box";
import { Spinner } from "ds/Spinner";
import { Text } from "ds/Typography";
import { Icon } from "ds/icons";
import React from "react";

import { TSavingStatus } from "../types";

// context(alexandrchebotar, 2023-03-02): added local copy of enum as import from global `types/status.ts` cause backlight studio error
const enum StatusType {
  ERROR = "error",
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
}

export const SavingStatus = ({ type, label }: TSavingStatus) => (
  <Box flexDirection="row" gap="100" px="300" alignItems="center">
    {type === StatusType.SUCCESS ? (
      <Icon name="AutosaveSuccessIcon" color="feedbackColorBackgroundSuccess" />
    ) : type === StatusType.WARNING ? (
      <Icon name="AutosaveWaitingIcon" color="feedbackColorBackgroundWarning" />
    ) : type === StatusType.ERROR ? (
      <Icon name="AutosaveFailIcon" color="feedbackColorCritical" />
    ) : (
      <Spinner size="small" />
    )}
    <Text preset="medium100">{label}</Text>
  </Box>
);
