import React, { Ref, forwardRef } from "react";
import styled from "styled-components";

import { theme } from "../styles/theme";
import { TIconProps, getIconSize } from "./types";

// TODO(alexandrchebotar, 2021-11-08): move that out or add another wrapper component for icons
const SVGIcon = styled.svg`
  ${({ onClick }) =>
    onClick &&
    `&:hover {
      cursor: pointer;
    }`};
`;

export const DropDownCarretIcon = forwardRef(
  ({ fill = theme.colors.black, size = "m", ...props }: TIconProps, ref: Ref<SVGSVGElement>) => {
    const sizes = getIconSize(size);
    return (
      <SVGIcon
        ref={ref}
        width={sizes}
        height={sizes}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path d="M15.9 10.3a.5.5 0 00-.4-.8h-7a.5.5 0 00-.4.8l3.5 4.667a.5.5 0 00.8 0l3.5-4.667z" fill={fill} />
      </SVGIcon>
    );
  }
);

export const DropUpCarretIcon = ({ fill = theme.colors.black, size = "m", ...props }: TIconProps) => {
  const sizes = getIconSize(size);
  return (
    <svg width={sizes} height={sizes} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15.9 10.3a.5.5 0 00-.4-.8h-7a.5.5 0 00-.4.8l3.5 4.667a.5.5 0 00.8 0l3.5-4.667z" fill={fill} />
    </svg>
  );
};
