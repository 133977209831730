import gql from "graphql-tag";

import { boundary, required } from "api/boundary";

import { LIST_ORGANISATION_TOKENS_FRAGMENT, ORGANISATION_DETAILS_FRAGMENT } from "./fragments";

export const LIST_ORGANISATION_TOKENS = {
  query: gql`
    query listOrganisationTokens($organisationUuid: ID!) {
      organisation(organisationUuid: $organisationUuid) {
        ...ListOrganisationTokensDetails
      }
    }
    ${LIST_ORGANISATION_TOKENS_FRAGMENT}
  `,
  parse: boundary({
    uuid: required(),
    organisationTokens: {
      uuid: required(),
      type: required(),
      name: required(),
      createdAt: required(),
      scope: required(),
    },
  }),
};

export const GET_ORGANISATION = {
  query: gql`
    query getOrganisation($organisationUuid: ID!) {
      organisation(organisationUuid: $organisationUuid) {
        ...OrganisationDetails
      }
    }
    ${ORGANISATION_DETAILS_FRAGMENT.query}
  `,
  parse: boundary({
    organisation: ORGANISATION_DETAILS_FRAGMENT.parse,
  }),
};

export const GET_NUMBER_LOGOS = gql`
  query getNumberLogos($organisationUuid: ID!) {
    organisation(organisationUuid: $organisationUuid) {
      uuid
      numbers {
        uuid
        profilePicture
      }
    }
  }
`;

export const GET_ORGANISATIONS = {
  query: gql`
    query getOrganisations {
      organisations {
        ...OrganisationDetails
      }
    }

    ${ORGANISATION_DETAILS_FRAGMENT.query}
  `,
  parse: boundary({
    organisations: ORGANISATION_DETAILS_FRAGMENT.parse,
  }),
};

export const GET_ENABLED_FEATURES = {
  query: gql`
    query getEnabledFeatures($organisationUuid: ID!, $numberUuid: ID!) {
      getEnabledFeatures(organisationUuid: $organisationUuid, numberUuid: $numberUuid)
    }
  `,
  parse: boundary({
    organisationUuid: required(),
    numberUuid: required(),
  }),
};
