import { TextExtendedProps } from "grommet";
import React from "react";
import { ToastOptions, toast } from "react-toastify";

import { ToastContent } from "./Toast";

export const defaultToastOptions = {
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  containerId: "defaultToast",
};

interface INotifyOptions extends ToastOptions {
  title?: React.ReactNode;
  description?: React.ReactNode;
  action?: React.ReactNode;
  titleOptions?: TextExtendedProps;
}

export const notify = ({ type = "success", title, description, action, titleOptions, ...options }: INotifyOptions) => {
  toast(
    <ToastContent action={action} title={title} titleOptions={titleOptions} description={description} type={type} />,
    {
      type,
      ...defaultToastOptions,
      ...options,
    }
  );
};

export const notifyError = (e: Error) =>
  notify({
    type: "error",
    description: e.message,
  });

export const notifyErrorNoAutoClose = (e: Error) =>
  notify({
    type: "error",
    description: e.message,
    autoClose: false,
  });
