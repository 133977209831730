import gql from "graphql-tag";

import { boundary, required } from "api/boundary";

import { CONTACT_FIELD_MIN_FRAGMENT } from "./fragments";

export const GET_NUMBER_CONTACT_SCHEMA_MIN = {
  query: gql`
    query getNumberContactMinSchema($numberUuid: ID!) {
      number(numberUuid: $numberUuid) {
        uuid
        contactSchema {
          latest
          uuid
          contactFields {
            ...ContactFieldMinDetails
          }
        }
      }
    }

    ${CONTACT_FIELD_MIN_FRAGMENT}
  `,
  parse: boundary({
    number: {
      contactSchema: {
        uuid: required(),
        contactFields: { fieldName: required(), fieldDisplay: required() },
      },
    },
  }),
};

export const GET_NUMBER_SENTRY_URL = gql`
  query getSentryUrl($numberUuid: ID!) {
    number(numberUuid: $numberUuid) {
      uuid
      sentryUrl
    }
  }
`;

export const GET_NUMBER_LANGUAGES = gql`
  query getNumberLanguages($numberUuid: ID!) {
    number(numberUuid: $numberUuid) {
      uuid
      languages {
        code
        label
      }
    }
  }
`;
