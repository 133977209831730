import gql from "graphql-tag";

import { required } from "api/boundary";

export const NUMBER_TAG_DETAILS_FRAGMENT = {
  query: gql`
    fragment NumberTagDetails on NumberTag {
      uuid
      color
      value
    }
  `,
  parse: {
    uuid: required(),
    value: required(),
    color: required(),
  },
};
