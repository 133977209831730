import { Box } from "ds/Box";
import { ColorNeutralBlack, SpaceNone } from "ds/Core";
import { NavigationHeader } from "ds/NavigationHeader";
import { Text } from "ds/Typography";
import { down } from "ds/theme";
import styled from "styled-components";

export const StyledNavigationHeader = styled(NavigationHeader)<{ width?: string }>`
  position: fixed;
  height: 64px;
  z-index: 9;
  ${({ width }) => `width: ${width};`}
`;

export const StyledText = styled(Text)`
  a {
    color: ${ColorNeutralBlack};
  }
`;

export const StyledLabel = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 125px;
`;

interface IStyledSidebarContainer {
  menuVisible?: boolean | null;
  offset?: `${number}px`;
}
export const StyledSidebarContainer = styled(Box)<IStyledSidebarContainer>`
  transition: 0.3s;
  position: fixed;
  z-index: 2;
  min-height: ${({ offset = SpaceNone }) => `calc(100vh - ${offset})`};
  min-height: ${({ offset = SpaceNone }) => `calc(var(--vh, 1vh) * 100 - ${offset})`};

  ${down("lg")} {
    z-index: 10;
    ${({ menuVisible }) => (menuVisible ? "top: 0;" : "top: -150vh;")}
  }
`;
