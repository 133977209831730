import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";

import { FullPageLoader } from "lib/loaders/FullPageLoader";
import { PaddleCheckoutFrame } from "pages/Payment/components/Checkout/PaddleCheckoutFrame";

export const Checkout = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [query] = useQueryParams({
    sellerId: NumberParam,
    _ptxn: StringParam,
  });

  useEffect(() => {
    if (!query._ptxn || !query.sellerId) {
      window.location.replace("/");
    }
  }, [query]);

  return (
    <PaddleCheckoutFrame
      width="100%"
      minHeight="100vh"
      justifyContent="center"
      alignItems="center"
      sellerId={query.sellerId!}
      _ptxn={query._ptxn!}
      onLoading={setLoading}
    >
      {loading ? <FullPageLoader cover title={t("checkout.loading")} /> : null}
    </PaddleCheckoutFrame>
  );
};
