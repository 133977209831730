import React from "react";

import { getOneSizeSmaller, text, useBreakpointHook } from "../styles";
import { Typography } from "./Typography";
import { ITypographyProps } from "./types";

export type TTextProps = ITypographyProps & JSX.IntrinsicElements["span"];

export const Text = ({ children, size = "medium", ...props }: TTextProps) => {
  const { isSmallerThan } = useBreakpointHook();
  const currentSize = isSmallerThan("tablet") ? getOneSizeSmaller(size) : size;
  return (
    <Typography theme={text} size={currentSize} {...props}>
      {children}
    </Typography>
  );
};
