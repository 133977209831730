import * as React from "react";

export const CloseIconOutline = ({ fill = "#929A9E", ...props }) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7 5.3c-.4-.4-1-.4-1.4 0L12 10.6 6.7 5.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l5.3 5.3-5.3 5.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.3 0 .5-.1.7-.3l5.3-5.3 5.3 5.3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4L13.4 12l5.3-5.3c.4-.4.4-1 0-1.4z"
      fill={fill}
    />
    <mask id="prefix__a" maskUnits="userSpaceOnUse" x={5} y={5} width={14} height={14}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7 5.3c-.4-.4-1-.4-1.4 0L12 10.6 6.7 5.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l5.3 5.3-5.3 5.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.3 0 .5-.1.7-.3l5.3-5.3 5.3 5.3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4L13.4 12l5.3-5.3c.4-.4.4-1 0-1.4z"
        fill="#fff"
      />
    </mask>
  </svg>
);

export const CloseIcon = ({ fill = "#00979E", ...props }) => (
  <svg width={17} height={16} viewBox="0 0 17 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.207 3.293a1 1 0 00-1.414 0L8.5 6.586 5.207 3.293a1 1 0 00-1.414 1.414L7.086 8l-3.293 3.293a1 1 0 101.414 1.414L8.5 9.414l3.293 3.293a1 1 0 001.414-1.414L9.914 8l3.293-3.293a1 1 0 000-1.414z"
      fill={fill}
    />
  </svg>
);
