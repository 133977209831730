import { Plugin } from "@uppy/core";
import type { Uppy as IBaseUppy } from "@uppy/core/types";
import type { XHRUploadOptions } from "@uppy/xhr-upload/types";

interface IUploaderOpts {
  headers: Record<string, string>;
}
interface IUploader {
  opts: IUploaderOpts;
}
interface IPlugins {
  uploader: IUploader[];
}
interface IUppy extends IBaseUppy {
  plugins: IPlugins;
}

/* Custom plugin to fetch CSRF token from endpoint 
  and include it as 'x-csrf-token' header when uploading. */

// todo(patricia-montoya, 2022-07-13) Keep looking to work on Types in this class
export class UppyCSRF extends Plugin {
  opts: XHRUploadOptions;
  uppy: IUppy;

  constructor(uppy: IUppy, options: XHRUploadOptions) {
    super(uppy, options);
    this.uppy = uppy;
    this.opts = options;
    this.id = options.id || "UppyCSRF";
    this.type = "modifier";
    this.setCSRFHeader = this.setCSRFHeader.bind(this);
  }

  setCSRFHeader() {
    return fetch(this.opts.endpoint, { credentials: "include" })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        this.uppy.plugins.uploader[0].opts.headers = {
          "x-csrf-token": json.csrf_token,
        };
      });
  }

  install() {
    this.uppy.addPreProcessor(this.setCSRFHeader);
  }

  uninstall() {
    this.uppy.removePreProcessor(this.setCSRFHeader);
  }
}
