import * as React from "react";

import { TDefaultIconProps } from "./types";

export const NextArrow = ({ fill = "#FFFFFF", size = 16, ...props }: TDefaultIconProps) => (
  <svg width={size} height={size} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill={fill}
      d="M11.59 9H1a1 1 0 110-2h10.59l-2.3-2.3a1 1 0 111.42-1.4l4 4a1 1 0 010 1.4l-4 4a1 1 0 11-1.42-1.4L11.6 9z"
    />
  </svg>
);
