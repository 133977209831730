import _isObject from "lodash/isObject";
import useBreakpoint from "use-breakpoint";

// Grommet breakpoints

export interface IGrommetBreakpoints<T> {
  small: T;
  medium: T;
  lmedium: T;
  large: T;
  xlarge: T;
  xxlarge: T;
}
export const isGrommetBreakpoints = (arg): arg is IGrommetBreakpoints<any> =>
  _isObject(arg) &&
  "small" in arg &&
  "medium" in arg &&
  "lmedium" in arg &&
  "large" in arg &&
  "xlarge" in arg &&
  "xxlarge" in arg;

// Custom breakpoints and styles

type IBreakpoints<T> = {
  mobileS: T;
  mobileM: T;
  mobileL: T;
  tablet: T;
  laptop: T;
  laptopM: T;
  laptopL: T;
  desktop: T;
};
type TBreakpoint = keyof IBreakpoints<any>;

export const BREAKPOINTS: IBreakpoints<number> = {
  mobileS: 0,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopM: 1281,
  laptopL: 1440,
  desktop: 1728,
};

const modal: IBreakpoints<number> = {
  mobileS: 16,
  mobileM: 24,
  mobileL: 24,
  tablet: 32,
  laptop: 32,
  laptopM: 32,
  laptopL: 32,
  desktop: 32,
};

export const devices: IBreakpoints<string> = {
  mobileS: `(max-width: ${BREAKPOINTS.mobileM - 0.1}px)`,
  mobileM: `(max-width: ${BREAKPOINTS.mobileL - 0.1}px)`,
  mobileL: `(max-width: ${BREAKPOINTS.tablet - 0.1}px)`,
  tablet: `(max-width: ${BREAKPOINTS.laptop - 0.1}px)`,
  laptop: `(max-width: ${BREAKPOINTS.laptopL - 0.1}px)`,
  laptopM: `(max-width: ${BREAKPOINTS.laptopL - 0.1}px)`,
  laptopL: `(max-width: ${BREAKPOINTS.desktop - 0.1}px)`,
  desktop: `(min-width: ${BREAKPOINTS.desktop - 0.1}px)`,
};

export const useBreakpointHook = () => {
  const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS, "laptop", false);
  return {
    breakpoint,
    maxWidth,
    minWidth,
    isSmallerThan: (breakpoint: TBreakpoint) => minWidth < BREAKPOINTS[breakpoint],
    isLargerThan: (breakpoint: TBreakpoint) => minWidth > BREAKPOINTS[breakpoint],
    pad: {
      modal: `${modal[breakpoint]}px`,
    },
  };
};
