import React from "react";

export const EditIcon = ({ fill = "#000", ...props }) => (
  <svg width={8} height={8} viewBox="0 0 8 8" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.903 1.877c.13.13.13.34 0 .47l-.61.61-1.25-1.25.61-.61a.332.332 0 01.47 0l.78.78zM1 6.835V5.821c0-.047.016-.087.05-.12l3.636-3.637 1.25 1.25-3.64 3.637A.156.156 0 012.18 7H1.166A.165.165 0 011 6.834z"
      fill={fill}
      fillOpacity={0.54}
    />
  </svg>
);

export const EditIconSmall = ({ fill = "#7A8285", size = 12, ...props }) => (
  <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.121.293l1.586 1.586a1 1 0 010 1.414l-1.353 1.353-3-3L8.707.293a1 1 0 011.414 0zM1 8l5.646-5.646 3 3L4 11l-2.868.717a.7.7 0 01-.849-.849L1 8z"
      fill={fill}
    />
  </svg>
);
