import * as React from "react";

import { theme } from "../styles/theme";
import { TIconProps, getIconSize } from "./types";

export const CheckRoundIcon = ({ fill = theme.colors.black, size = "m", ...props }: TIconProps) => {
  const sizes = getIconSize(size);
  return (
    <svg width={sizes} height={sizes} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z" fill={fill} />
      <path d="M6 12l4 4 8-8" stroke="#fff" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" />
    </svg>
  );
};
