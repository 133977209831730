import styled from "styled-components";

import { colors } from "lib/impact/styles/colors";

export const StyledContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledActionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const StyledDrop = styled.div<{ visible?: boolean; top?: string; width?: string; minHeight?: string }>`
  position: absolute;
  display: ${({ visible }) => (visible ? "block" : "none")};
  top: ${({ top, theme: { spaces } }) => top || `${spaces.xl + spaces.xs}px`};
  left: 0;
  background: ${colors.white};
  margin: 0;
  padding: 0;
  list-style-type: none;
  border: 1px solid ${colors["light-4"]};
  box-shadow: 0px 4px 10px 0px rgba(13, 31, 38, 0.08);
  border-radius: 5px;
  width: ${({ width }) => width ?? "100%"};
  box-sizing: border-box;
  min-height: ${({ minHeight }) => minHeight ?? "62px"};
  max-height: 342px;
  overflow: auto;
  z-index: 99;
`;
