import { useEffect } from "react";

import { useGlobalContext } from "./useGlobalContext";

export const useNumberInitiation = (numberUuid?: string) => {
  const { setNumberUuid } = useGlobalContext();

  useEffect(() => {
    if (numberUuid) {
      setNumberUuid(numberUuid);
    }
  }, [numberUuid, setNumberUuid]);
};
