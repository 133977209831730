import { Country } from "countries-phone-masks";
import _isArray from "lodash/isArray";

import { ICountryState } from "../CountryInput/types";

export const replaceCountry = (country?: Country): ICountryState | undefined => {
  if (!country) {
    return;
  }
  const mask = _isArray(country?.mask) ? country.mask[country.mask.length - 1] : country?.mask;
  const replacedMask = mask?.replace(/\d+/gi, "#").replace(/#/g, "0").replace("(", "").replace(")", "-");
  return {
    ...country,
    mask: replacedMask,
  };
};

export const maskPhoneNumber = (value: string, country?: ICountryState): string => {
  const maskRegExp = country?.mask
    .split("-")
    .map((item) => `(\\d{0,${item.length}})`)
    .join("");

  const match = value.replace(/\D/g, "").match(new RegExp(maskRegExp || "")) || [];
  return !match[2]
    ? match[1]
    : match[1] +
        "-" +
        match[2] +
        (match[3] ? "-" + match[3] : "") +
        (match[4] ? "-" + match[4] : "") +
        (match[5] ? "-" + match[5] : "");
};
