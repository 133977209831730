import { Anchor, Box, Text } from "grommet";
import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";

import { spacingPx } from "lib/tokens";

import { Warning } from "../icons/Warning";

const StyledDisconnectNotification = styled(Box)`
  position: absolute;
  z-index: 100;
`;

interface IDisconnectBanner {
  reconnectionFailed?: boolean;
  logOut: () => void;
}

export const DisconnectBanner = ({ reconnectionFailed, logOut }: IDisconnectBanner) => (
  <StyledDisconnectNotification fill="horizontal" align="center" background="label-6-tint">
    <Box direction="row" gap="xsmall" pad="small" align="center">
      <Box pad={spacingPx.xs}>
        <Warning />
      </Box>
      <Text color="accent-2">
        <Trans
          i18nKey={
            reconnectionFailed
              ? "notifications.disconnect-banner.reconnection-failed"
              : "notifications.disconnect-banner.connection-lost"
          }
          components={{ a: <Anchor onClick={logOut} /> }}
        />
      </Text>
    </Box>
  </StyledDisconnectNotification>
);
