import gql from "graphql-tag";

import { boundary, required } from "api/boundary";

import {
  CONTACT_FIELD_FRAGMENT,
  INITIAL_NUMBER_FRAGMENT,
  NUMBER_FOR_COLLECTION_FRAGMENT,
  NUMBER_STATUS_FRAGMENT,
} from "./fragments";

export const GET_NUMBER_FOR_COLLECTION = gql`
  query getNumberForCollection($numberUuid: ID!) {
    number(numberUuid: $numberUuid) {
      ...NumberForCollection
    }
  }

  ${NUMBER_FOR_COLLECTION_FRAGMENT}
`;

export const GET_INITIAL_NUMBER = {
  query: gql`
    query getInitialNumber($numberUuid: ID!) {
      number(numberUuid: $numberUuid) {
        ...InitialNumber
      }
    }

    ${INITIAL_NUMBER_FRAGMENT.query}
  `,
  parse: boundary({
    number: INITIAL_NUMBER_FRAGMENT.parse,
  }),
};

export const parseContactFields = {
  fieldName: required(),
  fieldType: required(),
  fieldDisplay: required(),
};

export const parseContactSchema = {
  uuid: required(),
  contactFields: parseContactFields,
};

export const GET_NUMBER_CONTACT_SCHEMA = {
  query: gql`
    query getNumberContactSchema($numberUuid: ID!) {
      number(numberUuid: $numberUuid) {
        uuid
        contactSchema {
          latest
          uuid
          contactFields {
            ...ContactFieldDetails
          }
        }
      }
    }

    ${CONTACT_FIELD_FRAGMENT}
  `,
  parse: boundary({
    number: {
      contactSchema: parseContactSchema,
    },
  }),
};

export const GET_NUMBER_STATUS_DETAILS = {
  query: gql`
    query getNumberStatusDetails($numberUuid: ID!) {
      number(numberUuid: $numberUuid) {
        ...NumberStatusFragment
      }
    }

    ${NUMBER_STATUS_FRAGMENT.query}
  `,
  parse: boundary({
    number: {
      ...NUMBER_STATUS_FRAGMENT.parse,
    },
  }),
};
