import { FormItem } from "ds/FormItem";
import { StatusType } from "ds/types/status";
import React from "react";

import { TextInput } from "./TextInput";
import { TextInputFormItemProps } from "./types";

const TextInputFormItem = React.forwardRef<HTMLInputElement, TextInputFormItemProps>(
  ({ containerProps, hint, label, labelHint, optional, status, tooltip, labelExtras, ...rest }, ref) => (
    <FormItem
      hint={hint}
      label={label}
      labelHint={labelHint}
      optional={optional}
      status={status}
      tooltip={tooltip}
      labelExtras={labelExtras}
      {...containerProps}
    >
      <TextInput error={status?.type === StatusType.ERROR} ref={ref} {...rest} />
    </FormItem>
  )
);
TextInputFormItem.displayName = "TextInputFormItem";

export { TextInputFormItem };

/* todo(alexandrchebotar, 2022-10-30): 
        1. Types
        2. Add docs
*/
