import styled from "styled-components";

import { colors } from "lib/impact/styles/colors";

const iconDown = (fill: "black" | "grey" = "black") =>
  `'data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.9 3.8C10.1472 3.47038 9.91202 3 9.5 3H2.5C2.08798 3 1.85279 3.47038 2.1 3.8L5.6 8.46667C5.8 8.73333 6.2 8.73333 6.4 8.46667L9.9 3.8Z" fill="${fill}"/></svg>'`;

const iconUp = (fill: "black" | "grey" = "black") =>
  `'data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.9 8.2C10.1472 8.52962 9.91202 9 9.5 9H2.5C2.08798 9 1.85279 8.52962 2.1 8.2L5.6 3.53333C5.8 3.26667 6.2 3.26667 6.4 3.53333L9.9 8.2Z" fill="${fill}"/></svg>'`;

export const StyledSelect = styled.select<{ active?: boolean }>`
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: ${colors.white};
  background-image: url(${iconDown()});
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: ${({ theme: { spaces } }) => `calc(100% - ${spaces.m}px - 5px)`};
  &:focus {
    background-color: ${colors.white};
    background-image: url(${iconUp()});
  }
  &:disabled {
    background-image: url(${iconDown("grey")});
  }
`;

export const StyledOption = styled.option`
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
`;
