/* tslint:disable */

/* eslint-disable */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as Types from "../../generated/types";
import { SubscriptionDetailsFragment } from "./fragments.generated";
import { SubscriptionDetailsFragmentDoc } from "./fragments.generated";

const defaultOptions = {} as const;
export type OnNumberChangedSubscriptionVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
}>;

export type OnNumberChangedSubscription = {
  __typename: "RootSubscriptionType";
  onNumberChanged: ({ __typename: "Number" } & SubscriptionDetailsFragment) | null;
};

export const OnNumberChangedDocument = gql`
  subscription onNumberChanged($numberUuid: ID!) {
    onNumberChanged(numberUuid: $numberUuid) {
      ...SubscriptionDetails
    }
  }
  ${SubscriptionDetailsFragmentDoc}
`;

/**
 * __useOnNumberChangedSubscription__
 *
 * To run a query within a React component, call `useOnNumberChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnNumberChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnNumberChangedSubscription({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *   },
 * });
 */
export function useOnNumberChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<OnNumberChangedSubscription, OnNumberChangedSubscriptionVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnNumberChangedSubscription, OnNumberChangedSubscriptionVariables>(
    OnNumberChangedDocument,
    options
  );
}
export type OnNumberChangedSubscriptionHookResult = ReturnType<typeof useOnNumberChangedSubscription>;
export type OnNumberChangedSubscriptionResult = Apollo.SubscriptionResult<OnNumberChangedSubscription>;
