/* eslint-disable no-useless-escape */
/* eslint-disable no-extend-native */

// context(justinvdm, 26 October 2021): The methods here were extending Array.prototype,
// which started to cause issues with ua-parser-js 0.7.30. More context here:
// https://turnio.slack.com/archives/C027370LDHB/p1635236889013400

// Must be unique key (uuid field)
export const order = (values, otherValues) => {
  const map = addPosition(otherValues).map((item) => item.position);
  return map.map((item) => addPosition(values).find((i) => i.position === item)).filter((item) => !!item);
};

export const move = (values, from, to) => {
  values = values.slice();
  values.splice(to, 0, values.splice(from, 1)[0]);
  return values;
};

const addPosition = (arr) => arr.map((item) => ({ ...item, position: item.uuid }));
