import React, { createContext, useState } from "react";

interface ModalState {
  content?: boolean;
  threadImport?: boolean;
  stack?: boolean;
  automation?: boolean;
  mfa?: boolean;
  disableMfa?: boolean;
  trigger?: boolean | string | number;
  createTemplate?: boolean | "update";
  chooseTemplate?: boolean | "update";
  updateTemplateVariables?: boolean;
  profileModal?: boolean;
  switchToJourneys?: boolean;
  pastDueInvoice?: boolean;
  duplicateJourneys?: boolean;
  deleteJourneys?: boolean;
  cancelSubscription?: boolean;
  nameJourneyVersion?: boolean;
  restoreJourneyVersion?: boolean;
}

export type ModalTypes = keyof ModalState;

export interface ModalContext extends ModalState {
  updateModal: (type: ModalTypes, value?: boolean | string | number) => any;
  replaceModal: (prev: ModalTypes, current: ModalTypes, value?: boolean | string | number) => any;
}

export const ModalContext = createContext<ModalContext>({
  updateModal: () => {},
  replaceModal: () => {},
});

interface ModalProviderProps {
  children: React.ReactNode;
}

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [state, setState] = useState<ModalState>({
    content: false,
    threadImport: false,
    stack: false,
    automation: false,
    mfa: false,
    disableMfa: false,
    trigger: false,
    createTemplate: false,
    chooseTemplate: false,
    updateTemplateVariables: false,
    profileModal: false,
    switchToJourneys: false,
    cancelSubscription: false,
    nameJourneyVersion: false,
    restoreJourneyVersion: false,
  });

  const updateModal = (type: ModalTypes, value?: boolean | string | number) => {
    const isBooleanState = typeof state[type] === "boolean";

    setState({
      ...state,
      [type]: value ?? (isBooleanState ? !state[type] : false),
    });
  };

  const replaceModal = (prev: ModalTypes, current: ModalTypes, value?: boolean | string | number) => {
    const isBooleanState = typeof state[current] === "boolean";

    setState({
      ...state,
      [prev]: false,
      [current]: value ?? (isBooleanState ? !state[current] : false),
    });
  };

  return (
    <ModalContext.Provider
      value={{
        updateModal,
        replaceModal,
        ...state,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
