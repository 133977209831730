import _isArray from "lodash/isArray";

import { isDefinedAndNotNull } from "utils/checks";

interface IData {
  number: any;
}

export const getNumberData = (data?: null | IData | (IData | undefined | null)[]) => {
  if (_isArray(data)) {
    if (data.every(isDefinedAndNotNull)) {
      return data.reduce(
        (number, data) => ({
          ...number,
          ...data.number,
        }),
        {}
      );
    }
  } else if (data) {
    return data.number;
  }
};
