import gql from "graphql-tag";

export const LEASE_MIN_FRAGMENT = {
  query: gql`
    fragment LeaseMin on Lease {
      uuid
      claimedAt
      claimedUntil
      scope
      scopeResourceAsString
    }
  `,
};
