import { unit } from "./breakpoints";
import { Spaces, calcSpacing } from "./spacing";

export const text = {
  small: {
    fontSize: `12${unit}`,
    lineHeight: `14${unit}`,
  },
  medium: {
    fontSize: `13${unit}`,
    lineHeight: `16${unit}`,
  },
  lmedium: {
    fontSize: `15${unit}`,
    lineHeight: `19${unit}`,
  },
  large: {
    fontSize: `18${unit}`,
    lineHeight: `22${unit}`,
  },
};

export const paragraph = {
  small: {
    fontSize: `14${unit}`,
    lineHeight: `17${unit}`,
  },
  medium: {
    fontSize: `16${unit}`,
    lineHeight: `20${unit}`,
  },
  lmedium: {
    fontSize: `18${unit}`,
    lineHeight: `22${unit}`,
  },
  large: {
    fontSize: `24${unit}`,
    lineHeight: `30${unit}`,
  },
};

export const heading = {
  h1: {
    fontSize: `48${unit}`,
    lineHeight: `54${unit}`,
    margin: {
      bottom: Spaces.L,
    },
  },
  h2: {
    fontSize: `36${unit}`,
    lineHeight: `49${unit}`,
    margin: {
      bottom: Spaces.S,
    },
  },
  h3: {
    fontSize: `32${unit}`,
    lineHeight: `40${unit}`,
    margin: {
      bottom: "12",
    },
  },
  h4: {
    fontSize: `22${unit}`,
    lineHeight: `34${unit}`,
    margin: {
      bottom: Spaces.S,
    },
  },
  h5: {
    fontSize: `20${unit}`,
    lineHeight: `31${unit}`,
    margin: {
      bottom: Spaces.S,
    },
  },
  h6: {
    fontSize: `18${unit}`,
    lineHeight: "1.2",
    margin: {
      bottom: Spaces.S,
    },
  },
};

export const label = {
  small: {
    fontSize: `12${unit}`,
    lineHeight: `14${unit}`,
  },
  medium: {
    fontSize: `16${unit}`,
    lineHeight: `22${unit}`,
  },
  lmedium: {
    fontSize: `16${unit}`,
    lineHeight: `22${unit}`,
  },
  large: {
    fontSize: `18${unit}`,
    lineHeight: `22${unit}`,
  },
};

export const anchor = {
  small: {
    fontSize: `12${unit}`,
    lineHeight: `14${unit}`,
  },
  medium: {
    fontSize: `15${unit}`,
    lineHeight: `19${unit}`,
  },
  lmedium: {
    fontSize: `16${unit}`,
    lineHeight: `20${unit}`,
  },
  large: {
    fontSize: `18${unit}`,
    lineHeight: `22${unit}`,
  },
};

export const button = {
  text: {
    small: {
      fontSize: `16${unit}`,
      lineHeight: `20${unit}`,
    },
    medium: {
      fontSize: `17${unit}`,
      lineHeight: `21${unit}`,
    },
    lmedium: {
      fontSize: `20${unit}`,
      lineHeight: `24${unit}`,
    },
    large: {
      fontSize: `20${unit}`,
      lineHeight: `25${unit}`,
    },
  },
  container: {
    small: {
      padding: {
        horizontal: Spaces.M,
        vertical: Spaces.S,
      },
    },
    medium: {
      padding: {
        horizontal: Spaces.M,
        vertical: Spaces.M,
      },
    },
    lmedium: {
      padding: {
        horizontal: calcSpacing([Spaces.ML, Spaces.ML]),
        vertical: Spaces.M,
      },
    },
    large: {
      padding: {
        horizontal: Spaces.M,
        vertical: Spaces.M,
      },
    },
  },
};
