import { Box } from "grommet";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import { ISignIn, SignIn } from "./components/SignIn";

export const Login = ({ turnHostInfo }: ISignIn) => (
  <Box width="100vw" height={{ min: "100vh" }}>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        {/* context(alexandrchebotar, 2022-03-30): keeping redirections for deprecated routes: "/signup", "/signup-india-bootcamp-2021" */}
        <Redirect from="/signup" to="/" />
        <Redirect from="/signup-india-bootcamp-2021" to="/" />
        <Route>
          <SignIn turnHostInfo={turnHostInfo} />
        </Route>
      </Switch>
    </BrowserRouter>
  </Box>
);
