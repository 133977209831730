/* tslint:disable */

/* eslint-disable */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as Types from "../../generated/types";
import { ChatDetailsFragment } from "./fragments.generated";
import { ChatDetailsFragmentDoc } from "./fragments.generated";

const defaultOptions = {} as const;
export type AssignChatMutationVariables = Types.Exact<{
  organisationUuid: Types.Scalars["ID"];
  chatUuid: Types.Scalars["ID"];
  accountUuid?: Types.InputMaybe<Types.Scalars["ID"]>;
}>;

export type AssignChatMutation = {
  __typename: "RootMutationType";
  assignChat: ({ __typename: "Chat" } & ChatDetailsFragment) | null;
};

export type UpdateChatStateMutationVariables = Types.Exact<{
  chatUuid: Types.Scalars["ID"];
  state: Types.ChatState;
  assignToAccountUuid?: Types.InputMaybe<Types.Scalars["ID"]>;
}>;

export type UpdateChatStateMutation = {
  __typename: "RootMutationType";
  updateChatState: ({ __typename: "Chat" } & ChatDetailsFragment) | null;
};

export type ResetUnreadCounterMutationVariables = Types.Exact<{
  chatUuid: Types.Scalars["ID"];
}>;

export type ResetUnreadCounterMutation = {
  __typename: "RootMutationType";
  resetUnreadCounter: ({ __typename: "Chat" } & ChatDetailsFragment) | null;
};

export const AssignChatDocument = gql`
  mutation assignChat($organisationUuid: ID!, $chatUuid: ID!, $accountUuid: ID) {
    assignChat(organisationUuid: $organisationUuid, chatUuid: $chatUuid, accountUuid: $accountUuid) {
      ...ChatDetails
    }
  }
  ${ChatDetailsFragmentDoc}
`;
export type AssignChatMutationFn = Apollo.MutationFunction<AssignChatMutation, AssignChatMutationVariables>;

/**
 * __useAssignChatMutation__
 *
 * To run a mutation, you first call `useAssignChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignChatMutation, { data, loading, error }] = useAssignChatMutation({
 *   variables: {
 *      organisationUuid: // value for 'organisationUuid'
 *      chatUuid: // value for 'chatUuid'
 *      accountUuid: // value for 'accountUuid'
 *   },
 * });
 */
export function useAssignChatMutation(
  baseOptions?: Apollo.MutationHookOptions<AssignChatMutation, AssignChatMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignChatMutation, AssignChatMutationVariables>(AssignChatDocument, options);
}
export type AssignChatMutationHookResult = ReturnType<typeof useAssignChatMutation>;
export type AssignChatMutationResult = Apollo.MutationResult<AssignChatMutation>;
export type AssignChatMutationOptions = Apollo.BaseMutationOptions<AssignChatMutation, AssignChatMutationVariables>;
export const UpdateChatStateDocument = gql`
  mutation updateChatState($chatUuid: ID!, $state: ChatState!, $assignToAccountUuid: ID) {
    updateChatState(chatUuid: $chatUuid, state: $state, assignToAccountUuid: $assignToAccountUuid) {
      ...ChatDetails
    }
  }
  ${ChatDetailsFragmentDoc}
`;
export type UpdateChatStateMutationFn = Apollo.MutationFunction<
  UpdateChatStateMutation,
  UpdateChatStateMutationVariables
>;

/**
 * __useUpdateChatStateMutation__
 *
 * To run a mutation, you first call `useUpdateChatStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChatStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChatStateMutation, { data, loading, error }] = useUpdateChatStateMutation({
 *   variables: {
 *      chatUuid: // value for 'chatUuid'
 *      state: // value for 'state'
 *      assignToAccountUuid: // value for 'assignToAccountUuid'
 *   },
 * });
 */
export function useUpdateChatStateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateChatStateMutation, UpdateChatStateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateChatStateMutation, UpdateChatStateMutationVariables>(
    UpdateChatStateDocument,
    options
  );
}
export type UpdateChatStateMutationHookResult = ReturnType<typeof useUpdateChatStateMutation>;
export type UpdateChatStateMutationResult = Apollo.MutationResult<UpdateChatStateMutation>;
export type UpdateChatStateMutationOptions = Apollo.BaseMutationOptions<
  UpdateChatStateMutation,
  UpdateChatStateMutationVariables
>;
export const ResetUnreadCounterDocument = gql`
  mutation resetUnreadCounter($chatUuid: ID!) {
    resetUnreadCounter(chatUuid: $chatUuid) {
      ...ChatDetails
    }
  }
  ${ChatDetailsFragmentDoc}
`;
export type ResetUnreadCounterMutationFn = Apollo.MutationFunction<
  ResetUnreadCounterMutation,
  ResetUnreadCounterMutationVariables
>;

/**
 * __useResetUnreadCounterMutation__
 *
 * To run a mutation, you first call `useResetUnreadCounterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUnreadCounterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUnreadCounterMutation, { data, loading, error }] = useResetUnreadCounterMutation({
 *   variables: {
 *      chatUuid: // value for 'chatUuid'
 *   },
 * });
 */
export function useResetUnreadCounterMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetUnreadCounterMutation, ResetUnreadCounterMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetUnreadCounterMutation, ResetUnreadCounterMutationVariables>(
    ResetUnreadCounterDocument,
    options
  );
}
export type ResetUnreadCounterMutationHookResult = ReturnType<typeof useResetUnreadCounterMutation>;
export type ResetUnreadCounterMutationResult = Apollo.MutationResult<ResetUnreadCounterMutation>;
export type ResetUnreadCounterMutationOptions = Apollo.BaseMutationOptions<
  ResetUnreadCounterMutation,
  ResetUnreadCounterMutationVariables
>;
