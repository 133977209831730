import { Box } from "ds/Box";
import { ColorsType } from "ds/types/colors";
import React from "react";

import { BannerProps, BannerStatus } from "./types";

const colours: {
  [key: string]: ColorsType;
} = {
  [BannerStatus.DEFAULT]: "feedbackColorBackgroundInformative",
  [BannerStatus.SUCCESS]: "feedbackColorBackgroundSuccess",
  [BannerStatus.WARNING]: "feedbackColorBackgroundWarning",
  [BannerStatus.ERROR]: "feedbackColorBackgroundCritical",
};

const Banner = React.forwardRef<HTMLDivElement, BannerProps>(
  ({ status = BannerStatus.DEFAULT, children, ...props }, ref) => {
    return (
      <Box
        ref={ref}
        justifyContent="center"
        alignItems="center"
        p="100"
        width="100%"
        minHeight="38px"
        bg={colours[status]}
        {...props}
      >
        {children}
      </Box>
    );
  }
);

Banner.displayName = "Banner";

export { Banner };
