export enum Prompts {
  "ERROR" = "error",
  "SUCCESS" = "success",
  "WARNING" = "warning",
  "DEFAULT" = "default",
}

export type TPromptTypes = `${Prompts}`;

export type TStyleStates = "prompt" | "focus";

export type TFieldWrapperTheme = {
  padding: string;
  height: {
    min: string;
  };
  text: {
    fontSize: string;
    lineHeight: string;
  };
  label: {
    fontSize: string;
    lineHeight: string;
  };
};
