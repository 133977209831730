import gql from "graphql-tag";

import { SUBSCRIPTION_FRAGMENT } from "./fragments";

export const SET_SUBSCRIPTION_EMAIL = gql`
  mutation updateSubscriptionNumberEmail($numberUuid: ID!, $number: NumberIo!) {
    updateNumber(numberUuid: $numberUuid, number: $number) {
      ...SubscriptionDetails
    }
  }

  ${SUBSCRIPTION_FRAGMENT}
`;

export const SWITCH_COLLECTION_MODE_TO_AUTOMATIC = gql`
  mutation switchCollectionModeToAutomatic(
    $subscriptionId: String!
    $targetPrice: SwitchTargetPrice
    $chargePastDueOnAutomatic: Boolean
  ) {
    switchCollectionModeToAutomatic(
      subscriptionId: $subscriptionId
      targetPrice: $targetPrice
      chargePastDueOnAutomatic: $chargePastDueOnAutomatic
    ) {
      checkoutUrl
      status
    }
  }
`;

export const GET_OR_CREATE_PADDLE_CUSTOMER_MUTATION = gql`
  mutation getOrCreatePaddleCustomer($customerEmail: String!, $customerName: String!, $numberUuid: ID!) {
    getOrCreatePaddleCustomer(customerEmail: $customerEmail, customerName: $customerName, numberUuid: $numberUuid)
  }
`;

export const PAUSE_PADDLE_SUBSCRIPTION = gql`
  mutation pausePaddleSubscription($subscriptionId: String!) {
    pausePaddleSubscription(subscriptionId: $subscriptionId)
  }
`;

export const RESUME_PADDLE_SUBSCRIPTION = gql`
  mutation resumePausedPaddleSubscription($subscriptionId: String!) {
    resumePausedPaddleSubscription(subscriptionId: $subscriptionId)
  }
`;

export const CANCEL_PADDLE_SCHEDULED_PAUSE = gql`
  mutation cancelPaddleScheduledPause($subscriptionId: String!) {
    cancelPaddleScheduledPause(subscriptionId: $subscriptionId)
  }
`;
