import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { Trash } from "../icons/Icons";
import { DropMenuBtn } from "../menu/Menu";
import { ConfirmButton } from "./ConfirmButton";
import { StyledConfirmTitle } from "./Styles";

export const ConfirmDeleteMessageButton = ({ onConfirmDeleteMesage, beforeConfirm, disabled, ...props }) => {
  const { t } = useTranslation();

  return (
    <ConfirmButton
      onConfirm={onConfirmDeleteMesage}
      beforeConfirm={beforeConfirm}
      btnConfirmTitle={t("btn.delete-message")}
      alertMessage={
        <StyledConfirmTitle>
          <Trans i18nKey="modals.delete-message">
            Are you sure you want to delete <b>this message</b>? You will not be able to undo this action.
          </Trans>
        </StyledConfirmTitle>
      }
      trigger={
        <DropMenuBtn plain disabled={disabled} label={t("btn.delete-message")} reverse icon={<Trash />} {...props} />
      }
    />
  );
};
