import {
  InteractiveHeaderType,
  MessageTemplateButtonType,
  MessageTemplateComponentParameterType,
} from "graphql/generated/types";
import {
  IComponentTypes,
  ITemplateBodyComponent,
  ITemplateButtonsComponent,
  ITemplateContent,
  ITemplateFooterComponent,
  ITemplateHeaderComponent,
  ITemplateItem,
  IUploadMedia,
  IVariableState,
} from "types";

export const isMediaHeader = (type?: MessageTemplateComponentParameterType | InteractiveHeaderType | null) =>
  type ? ["IMAGE", "VIDEO", "DOCUMENT"].includes(type) : false;

export const variableBlock = /\{{[0-9]+\b}}/gi;
export const findAllVariableBlocks = (content?: string) => content?.match(variableBlock) || [];
export const variableChartBlock = /\{{(?:\d+[a-z]|[a-z]+\d)[a-z\d]*}}|{{[a-z]+\b}}/gi;

export const splitTemplateComponents = (template?: ITemplateItem) => ({
  headerComponent: template?.components?.find((c): c is ITemplateHeaderComponent => c.type === IComponentTypes.HEADER),
  footerComponent: template?.components?.find((c): c is ITemplateFooterComponent => c.type === IComponentTypes.FOOTER),
  bodyComponent: template?.components?.find((c): c is ITemplateBodyComponent => c.type === IComponentTypes.BODY),
  buttonsComponent: template?.components?.find(
    (c): c is ITemplateButtonsComponent => c.type === IComponentTypes.BUTTONS
  ),
});

export const replaceVariables = (text?: string, variables?: string[]) => {
  const splitedText = text?.split(/{{\d+}}+/) || [];
  return splitedText
    .map((item, index) =>
      variables && variables?.[index]
        ? `${item}${variables?.[index]}`
        : splitedText.length === index + 1
          ? item
          : `${item}{{${index + 1}}}`
    )
    .join("");
};

export const generateContentWithVariables = (text?: string, variables?: IVariableState[] | (string | undefined)[]) => {
  if (!text) return;
  let newText = text;

  variables?.forEach((variable, index) => {
    if (typeof variable === "string" && variable) {
      newText = newText.replace(`{{${index + 1}}}`, variable);
    }
    if (variable?.value) {
      newText = newText.replace(variable.key, variable.value);
    }
  });
  return newText;
};

export const generateTemplateVariablesContent = (
  template?: ITemplateItem,
  content?: ITemplateContent,
  attachment?: IUploadMedia
) => {
  if (!template) return {};

  const { headerComponent, footerComponent, bodyComponent, buttonsComponent } = splitTemplateComponents(template);

  const cleanVariables = (variables?: string) =>
    findAllVariableBlocks(variables).map((variable) => ({ key: variable }));

  const headerVariables =
    cleanVariables(headerComponent?.headerText).map((item, index) => ({
      ...item,
      value: content?.header?.[index],
    })) || [];

  const contentData = template.content || bodyComponent;
  const contentVariables = contentData
    ? cleanVariables(template.content || bodyComponent?.text).map((item, index) => ({
        ...item,
        value: content?.body?.[index],
      }))
    : [];

  const quickReplyButtons = buttonsComponent?.buttons.filter(
    (button) => button.type === MessageTemplateButtonType.QUICK_REPLY
  );
  const urlButtons = buttonsComponent?.buttons.some((button) => button.type === MessageTemplateButtonType.URL);
  const urlButton = buttonsComponent?.buttons.find((button) => button.type === MessageTemplateButtonType.URL);
  const getUrlVariables = (urlButton) => cleanVariables(urlButton?.url);
  const urlVariables = urlButtons
    ? getUrlVariables(urlButton).map((item, index) => ({ ...item, value: content?.url?.[index] }))
    : [];

  const variableValuesExist = [...contentVariables, ...headerVariables, ...urlVariables].every(
    (variable) => variable.value
  );

  const body = replaceVariables(bodyComponent?.text, content?.body as string[]);
  const header = replaceVariables(headerComponent?.headerText, content?.header as string[]);
  const url = replaceVariables(buttonsComponent?.buttons.find((button) => button.url)?.url, content?.url as string[]);

  const isMediaHeaderIsValid = isMediaHeader(headerComponent?.format) ? !!attachment : true;

  return {
    isValid: variableValuesExist && isMediaHeaderIsValid,
    headerVariables,
    footerComponent,
    contentVariables,
    urlVariables,
    body,
    header,
    url,
    quickReplyButtons,
  };
};
