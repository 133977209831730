import gql from "graphql-tag";

import { SUBSCRIPTION_FRAGMENT } from "./fragments";

export const SUBSCRIBE_TO_NUMBER_BILLING_SUBSCRIPTION = gql`
  subscription onNumberChanged($numberUuid: ID!) {
    onNumberChanged(numberUuid: $numberUuid) {
      ...SubscriptionDetails
    }
  }
  ${SUBSCRIPTION_FRAGMENT}
`;
