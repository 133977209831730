import * as React from "react";

import { theme } from "../styles/theme";
import { TIconProps, getIconSize } from "./types";

export const ArrowRightIcon = ({ fill = theme.colors.white, size = "m", ...props }: TIconProps) => {
  const sizes = getIconSize(size);
  return (
    <svg
      width={(14 * sizes) / 24}
      height={sizes}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14 12L.5 19.794V4.207L14 12z" fill={fill} />
    </svg>
  );
};
