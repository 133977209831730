import * as React from "react";

import { TDefaultIconProps } from "./types";

export const CheckMarkIcon = ({ size = 12, fill = "#54AF4F", ...props }: TDefaultIconProps) => (
  <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M1.5 6L4.5 9L10 2.5" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const FormCheckmarkIcon = ({ size = 12, fill = "#fff", ...props }: TDefaultIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={13} height={size} viewBox="0 0 12 12" fill="none" {...props}>
    <path
      d="M10.28 1.78L3.989 8.075 1.695 5.78A1 1 0 00.28 7.195l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.78z"
      fill={fill}
    />
  </svg>
);
