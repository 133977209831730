import React from "react";

import { TColors } from "../styles/colors";
import { Spaces } from "../styles/spacing";

interface IIconProps {
  children: React.ReactElement;
  icon?: JSX.Element;
  reverse?: boolean;
  color?: TColors;
}

const Icon = ({ icon, reverse, color, children }: IIconProps) => (
  <>
    {icon && !reverse ? React.cloneElement(icon, { fill: color }) : null}
    {icon ? React.cloneElement(children, { margin: !reverse ? { left: Spaces.M } : { right: Spaces.M } }) : children}
    {icon && reverse ? React.cloneElement(icon, { fill: color }) : null}
  </>
);

export default Icon;
