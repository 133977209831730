import { useEffect, useRef, useState } from "react";

interface ICountdownTimerProps {
  startDate: string;
  endDate: string;
}

export const useCountdownTimer = ({ startDate, endDate }: ICountdownTimerProps) => {
  const countDownStartDate = new Date(startDate).getTime();
  const countDownEndDate = new Date(endDate).getTime();
  const intervalRef = useRef<number>();

  const [countDown, setCountDown] = useState(countDownEndDate - countDownStartDate);

  const getReturnValues = (countDown: number) => {
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [formatReturnValues(minutes), formatReturnValues(seconds)];
  };

  const formatReturnValues = (value: number) => (value < 10 ? `0${value}` : value);

  useEffect(() => {
    intervalRef.current = window.setInterval(() => {
      setCountDown(countDownEndDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(intervalRef.current);
  }, [countDownStartDate, countDownEndDate]);

  useEffect(() => {
    if (countDown < 0) {
      clearInterval(intervalRef.current);
      setCountDown(0);
    }
  }, [countDown]);

  return getReturnValues(countDown);
};
