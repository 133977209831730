import React, { createContext } from "react";

import { ParagraphProps, TextProps, TypographyStyleProps } from "./types";

export type TypographyContext = TypographyStyleProps;

export const TypographyContext = createContext<TypographyContext>({});

export const TypographyProvider = ({
  children,
  fontSize,
  fontWeight,
  color,
  lineHeight,
  textAlign,
}: ParagraphProps | TextProps) => (
  <TypographyContext.Provider value={{ fontSize, fontWeight, color, lineHeight, textAlign }}>
    {children}
  </TypographyContext.Provider>
);
