import { getLabelSize } from "ds/Form";
import { Text } from "ds/Typography";
import React from "react";
import { v4 as uuid } from "uuid";

import { StyledCheckbox, StyledCheckboxContainer, StyledLabel } from "./Styles";
import { CheckboxProps, CheckboxSizes } from "./types";

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ size = CheckboxSizes.MEDIUM, text, textProps, id = uuid(), icon = "CheckIcon", ...props }, ref) => (
    <StyledCheckboxContainer>
      <StyledCheckbox id={id} ref={ref} inputSize={size} icon={icon} {...props} type="checkbox" />
      <StyledLabel isPresentText={!!text} size={size} htmlFor={id} {...textProps}>
        {text && <Text preset={getLabelSize(size)}>{text}</Text>}
      </StyledLabel>
    </StyledCheckboxContainer>
  )
);

Checkbox.displayName = "Checkbox";

export { Checkbox };
