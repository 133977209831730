import { useQuery } from "api/useQuery";
import { GET_MESSAGE_TEMPLATE } from "graphqlQueries";
import { getMessageTemplate, getMessageTemplateVariables } from "graphqlQueries/generated/getMessageTemplate";
import { Maybe } from "types";

import { useGlobalContext } from "./useGlobalContext";

interface ITemplateMessageFragment {
  hsm: Maybe<{
    elementName: string | null;
    language: Maybe<{ code: string | null }>;
  }>;
  template: Maybe<{
    name: string | null;
    language: Maybe<{ code: string | null }>;
  }>;
}
interface ITemplateMessage extends ITemplateMessageFragment {
  inReplyTo?: Maybe<ITemplateMessageFragment>;
}

const getTemplateData = (message: Maybe<ITemplateMessageFragment>) => {
  if (message?.template) {
    return {
      name: message.template.name,
      language: message.template.language?.code,
    };
  }
  if (message?.hsm) {
    return {
      name: message.hsm.elementName,
      language: message.hsm.language?.code,
    };
  }
  return {};
};

export const useMessageTemplates = (message: Maybe<ITemplateMessage>) => {
  const { numberUuid } = useGlobalContext();
  const messageTemplate = getTemplateData(message);
  const replyTemplate = getTemplateData(message?.inReplyTo);

  const { data, loading } = useQuery<getMessageTemplate, getMessageTemplateVariables>(GET_MESSAGE_TEMPLATE, {
    variables: {
      numberUuid,
      name: messageTemplate.name,
      language: messageTemplate.language,
    },
    skip: !messageTemplate.name || !messageTemplate.language,
  });

  const { data: inReplyToData, loading: inReplyToLoading } = useQuery<getMessageTemplate, getMessageTemplateVariables>(
    GET_MESSAGE_TEMPLATE,
    {
      variables: {
        numberUuid,
        name: replyTemplate.name,
        language: replyTemplate.language,
      },
      skip: !replyTemplate.name || !replyTemplate.language,
    }
  );

  return {
    loadingMessageTemplates: loading || inReplyToLoading,
    messageTemplates: [
      ...(data?.number?.messageTemplates ?? []), //
      ...(inReplyToData?.number?.messageTemplates ?? []),
    ],
  };
};
