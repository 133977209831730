import { FontSize25, LineHeight50, Space100, SpaceNone } from "ds/Core";

import {
  FormBorderRadiusDefault,
  FormBorderWidth,
  FormColorBackgroundInput,
  FormColorBorderDefault,
  FormColorBorderDisabled,
  FormColorBorderError,
  FormColorBorderFocus,
  FormSizeLarge,
  FormSizeMedium,
  FormSizeSmall,
  FormSpaceLargeLeft,
  FormSpaceLargeRight,
  FormSpaceSmallLeft,
  FormSpaceSmallRight,
  FormTextInputLarge,
  FormTextInputSmall,
} from "./tokens";

export const inputDefaultTheme = {
  size: {
    xsmall: {
      minHeight: "32px",
      height: "32px",
      fontSize: FontSize25,
      lineHeight: LineHeight50,
      padding: `${SpaceNone} ${Space100} ${SpaceNone} ${Space100}`,
    },
    small: {
      minHeight: FormSizeSmall,
      height: FormSizeSmall,
      fontSize: FormTextInputSmall.fontSize,
      lineHeight: FormTextInputSmall.lineHeight,
      padding: `${SpaceNone} ${FormSpaceSmallRight} ${SpaceNone} ${FormSpaceSmallLeft}`,
    },
    medium: {
      minHeight: FormSizeMedium,
      height: FormSizeMedium,
      fontSize: FormTextInputSmall.fontSize,
      lineHeight: FormTextInputSmall.lineHeight,
      padding: `${SpaceNone} ${FormSpaceSmallRight} ${SpaceNone} ${FormSpaceSmallLeft}`,
    },
    large: {
      minHeight: FormSizeLarge,
      height: FormSizeLarge,
      fontSize: FormTextInputLarge.fontSize,
      lineHeight: FormTextInputLarge.lineHeight,
      padding: `${SpaceNone} ${FormSpaceLargeRight} ${SpaceNone} ${FormSpaceLargeLeft}`,
    },
  },
  main: {
    borderColor: FormColorBorderDefault,
    borderWidth: FormBorderWidth,
    borderRadius: FormBorderRadiusDefault,
    borderStyle: "solid",
    background: FormColorBackgroundInput,
    focus: {
      outline: "none",
      borderWidth: FormBorderWidth,
      borderColor: FormColorBorderFocus,
    },
    error: {
      borderColor: FormColorBorderError,
    },
    disabled: {
      border: "none",
      background: FormColorBorderDisabled,
    },
  },
} as const;
