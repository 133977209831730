import { DIRECTION_INBOUND } from "_constants";

export const getFirstNewMessageUuid = ({ lastMessageUuid, sortedMessages, unreadCount }) => {
  let inboundMessagesCount = 0;
  for (let i = sortedMessages.length - 1; i > 0; i--) {
    if (sortedMessages[i].direction === DIRECTION_INBOUND) {
      inboundMessagesCount++;
      if (inboundMessagesCount === 1 && sortedMessages[i].uuid !== lastMessageUuid) {
        return;
      }
      if (inboundMessagesCount === unreadCount) {
        return sortedMessages[i].uuid;
      }
    }
  }
};
