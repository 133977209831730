import { ButtonType, Text } from "grommet";
import React, { ReactElement, ReactNode, SyntheticEvent, useLayoutEffect } from "react";
import { confirmAlert } from "react-confirm-alert";

import { ButtonTextIcon } from "../buttons";
import { Confirm } from "./Confirm";
import { StyledConfirmTitle } from "./Styles";

import "./react-confirm-overlay-styles.css";

type TOverlayStyle = "dark" | "light";

interface IConfirmCard {
  alertMessage: ReactNode;
  btnCancelTitle?: string;
  btnConfirmTitle: string;
  btnType?: "primary";
  hideCloseBtn?: boolean;
  onCancel?: () => void;
  onClose: () => void;
  onConfirm?: () => void;
  overlayStyle?: TOverlayStyle;
  title?: string;
}

export const ConfirmCard = ({
  alertMessage,
  btnCancelTitle,
  btnConfirmTitle,
  btnType,
  hideCloseBtn,
  onCancel,
  onClose,
  onConfirm,
  overlayStyle = "dark",
  title,
}: IConfirmCard) => {
  const onClick = () => {
    if (onConfirm) {
      onConfirm();
    }
    onClose();
  };
  const handleClose = () => {
    if (onCancel) {
      onCancel();
    }
    onClose();
  };
  useLayoutEffect(() => {
    if (overlayStyle === "dark") {
      const overlayEl = document.querySelector(".react-confirm-alert-overlay");
      overlayEl?.classList.add("react-confirm-alert-overlay-dark");
    }
  }, [overlayStyle]);

  return (
    <Confirm
      onClick={onClick}
      title={
        <Text size="24px" margin={{ bottom: "12px" }} weight={600}>
          {title}
        </Text>
      }
      text={<StyledConfirmTitle>{alertMessage}</StyledConfirmTitle>}
      onClose={handleClose}
      btnConfirmTitle={btnConfirmTitle}
      btnCancelTitle={btnCancelTitle}
      btnType={btnType}
      hideCloseBtn={hideCloseBtn}
    />
  );
};

interface IConfirmButton extends ButtonType {
  alertMessage: ReactNode;
  beforeConfirm?: () => void;
  btnConfirmTitle: string;
  onConfirm?: () => void;
  overlayStyle?: TOverlayStyle;
  stopPropagation?: boolean;
  trigger?: ReactElement;
  btnType?: "primary";
}

export const ConfirmButton = ({
  alertMessage,
  beforeConfirm,
  btnConfirmTitle,
  btnType,
  icon,
  label,
  onConfirm,
  overlayStyle,
  stopPropagation,
  trigger,
  disabled,
  ...props
}: IConfirmButton) => {
  const handleConfirm = (e: SyntheticEvent) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
    if (beforeConfirm) {
      beforeConfirm();
    }
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmCard
          alertMessage={alertMessage}
          btnConfirmTitle={btnConfirmTitle}
          btnType={btnType}
          onClose={onClose}
          onConfirm={onConfirm}
          overlayStyle={overlayStyle}
        />
      ),
    });
  };

  return trigger ? (
    React.cloneElement(trigger, {
      onClick: !disabled && handleConfirm,
    })
  ) : (
    <ButtonTextIcon icon={icon} label={label} disabled={disabled} {...props} onClick={handleConfirm} />
  );
};
