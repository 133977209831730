import styled from "styled-components";

import { colors } from "lib/impact/styles/colors";

import { devices } from "../styles/breakpoints";
import { getSpacing } from "../styles/spacing";
import { TStyledButton } from "./types";

export const StyledButton = styled.button<TStyledButton>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width = "max-content" }) => width};
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
  min-height: ${({ minHeight = "64px" }) => minHeight};
  border-radius: ${({ round = "90px" }) => round};
  cursor: pointer;
  box-sizing: border-box;
  ${({ margin, padding }) => `
    padding: ${getSpacing(padding)};
    margin: ${getSpacing(margin)};
  `}
  ${({ state, theme: { spaces } }) => {
    switch (state) {
      case "light":
        return `
          background: ${colors.white};
          border: 1px solid ${colors["light-4"]};
          &:disabled {
            background: ${colors["light-2"]};
            border: 1px solid transparent;
          }
          &:hover:not(:disabled) {
            background: ${colors["light-2"]};
          }
        `;
      case "light-contrast":
        return `
            background: ${colors.white};
            border: 2px solid ${colors.black};
            &:disabled {
              background: ${colors["light-2"]};
              border-color: ${colors["light-2"]};
            }
            &:hover:not(:disabled) {
              background: ${colors["light-2"]};
            }
            & > span {
              font-weight: 500;
            }
          `;
      case "dark":
        return `
          padding: 0 ${spaces.s};
          background: ${colors.black};
          border: 1px solid ${colors.black};
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
          &:hover {
            background: ${colors["dark-2"]};
            border: 1px solid ${colors["dark-2"]};
          }
        `;
      case "primary":
        return `
          border: none;
          background: #F6F6F7;
          border-radius: 8px;
          &:hover {
            > span {
              color: ${colors["dark-2"]};
            }
            > svg {
              path {
                fill: ${colors["dark-2"]};
              }
            }
          }
        `;
      default:
        return `
          min-height: 32px;
          border: none;
          background: transparent;
          border-radius: 0px;
          &:hover {
            > span {
              color: ${colors["dark-2"]};
            }
            > svg {
              path {
                fill: ${colors["dark-2"]};
              }
            }
          }
      `;
    }
  }}
  &:disabled {
    cursor: not-allowed;
  }
  @media ${devices.mobile} {
    ${({ state }) =>
      state !== "default"
        ? `
            width: 100%;
          `
        : ""}
  }
`;
