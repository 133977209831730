import { Box } from "ds/Box";
import { Button } from "ds/Button";
import { ColorGray50 } from "ds/Core";
import styled, { css, keyframes } from "styled-components";

const bell = keyframes`
  0% { transform: rotate(0deg) scale(1); }
  4% { transform: rotate(0deg) scale(1.3); }
  8% { transform: rotate(6deg) scale(1.3); }
  12% { transform: rotate(6deg) scale(1.3); }
  16% { transform: rotate(0deg) scale(1); }
  100% { transform: rotate(0deg) scale(1); }
`;

const move = keyframes`
  0% { transform: translate(0, 0); }
  5% { transform: translate(4px, 0); }
  10% { transform: translate(2px, 0); }
  15% { transform: translate(4px, 0); }
  20% { transform: translate(0, 0); }
  100% { transform: translate(0, 0); }
`;

const shake = keyframes`
  0% { transform: rotate(0deg) scale(1); }
  4% { transform: rotate(6deg) scale(1.3); }
  8% { transform: rotate(-6deg) scale(1.3); }
  12% { transform: rotate(6deg) scale(1.3); }
  16% { transform: rotate(-6deg) scale(1.3); }
  20% { transform: rotate(6deg) scale(1.3); }
  24% { transform: rotate(0deg) scale(1.3); }
  28% { transform: rotate(0deg) scale(1); }
  100% { transform: rotate(0deg) scale(1); }
`;

export const StyledIconContainer = styled(Box)`
 > span {
  animation-duration: 5s;
  animation-delay: 1000ms;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
 }
  .bell {
    ${css`
      animation-name: ${bell};
    `}}
  }
  .move {
    ${css`
      animation-name: ${move};
    `}}
  }
  .shake {
    ${css`
      animation-name: ${shake};
    `}}
  }
`;

export const StyledActionButton = styled(Button)`
  &:hover {
    color: ${ColorGray50};
    opacity: 0.8;
  }
`;
