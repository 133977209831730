import React, { MouseEvent, ReactNode, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Divider } from "../../Divider";
import { SearchIcon } from "../../Icons/SearchIcon";
import { Paragraph } from "../../Typography";
import { spaces, text } from "../../styles";
import { useKeyboard } from "../../utils";
import {
  ItemContainer,
  SearchContainer,
  SearchContainerOuter,
  SearchIconContainer,
  SearchInput,
  SelectItemsList,
} from "./styles";

type TSelectDropProps<T> = {
  items?: T[];
  favorites?: T[];
  hideCode?: boolean;
  hideFlag?: boolean;
  showSearch?: boolean;
  searchPlaceholder?: string;
  filterValue?: string;
  onFilter?: (value: string) => (item: T) => boolean;
  onSelect: (item: T) => void;
  renderItem?: (item: T) => ReactNode;
  visible?: boolean;
  onHide?: () => void;
  noItemsPlaceholder?: string;
};
export const SelectDropContent = <T,>({
  items = [],
  favorites = [],
  onSelect,
  showSearch,
  searchPlaceholder = "",
  onFilter = (value) => (item) => (item as unknown as string).includes(value),
  filterValue: outerFiltervalue,
  renderItem = (item) => item,
  visible,
  onHide,
  noItemsPlaceholder,
}: TSelectDropProps<T>) => {
  const { t } = useTranslation();
  const [innerFilterValue, setInnerFilterValue] = useState("");
  const filterValue = outerFiltervalue ?? innerFilterValue;

  const filtered = useMemo(() => {
    return filterValue
      ? {
          favorites: favorites.filter(onFilter(filterValue)),
          items: items.filter(onFilter(filterValue)),
        }
      : {
          favorites,
          items,
        };
  }, [favorites, filterValue, items, onFilter]);

  const [forcedFocus, setForcedFocus] = useState(true);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const focusedItemEl = useRef<HTMLDivElement>(null);
  const searchEl = useRef<HTMLInputElement>(null);

  const onEnter = () => {
    if (visible) {
      const item =
        focusedIndex < filtered.favorites.length
          ? filtered.favorites[focusedIndex]
          : filtered.items[focusedIndex - filtered.favorites.length];

      onSelect(item);
    }
  };
  const onUp = (e: KeyboardEvent) => {
    if (visible) {
      e.preventDefault();
      if (forcedFocus && focusedIndex > 0) {
        setFocusedIndex(focusedIndex - 1);
      }
    }
  };
  const onDown = (e: KeyboardEvent) => {
    if (visible) {
      e.preventDefault();
      if (forcedFocus && filtered.favorites.length + filtered.items.length > focusedIndex + 1) {
        setFocusedIndex(focusedIndex + 1);
      }
    }
  };
  const pageSize = showSearch ? 6 : 7;
  const onPageDown = (e: KeyboardEvent) => {
    if (visible && forcedFocus && filtered.favorites.length + filtered.items.length > focusedIndex + 1) {
      e.preventDefault();

      const targetIndex = focusedIndex + pageSize;
      const itemsCount = filtered.favorites.length + filtered.items.length;
      const indexToFocus = itemsCount > targetIndex ? targetIndex : itemsCount - 1;

      setFocusedIndex(indexToFocus);
    }
  };
  const onPageUp = (e: KeyboardEvent) => {
    if (visible && forcedFocus && focusedIndex > 0) {
      e.preventDefault();

      const targetIndex = focusedIndex - pageSize;
      const indexToFocus = targetIndex < 0 ? 0 : targetIndex;

      setFocusedIndex(indexToFocus);
    }
  };
  const onEsc = () => {
    if (visible && onHide) {
      onHide();
    }
  };

  useKeyboard({
    onEnter,
    onTab: onEnter,
    onUp,
    onDown,
    onPageUp,
    onPageDown,
    onEsc,
  });

  const onHover = (index: number) => (e: MouseEvent<HTMLDivElement>) => {
    if (showSearch) {
      const searchBottom = searchEl.current?.getBoundingClientRect()?.bottom;
      const itemTop = e.currentTarget.getBoundingClientRect().top;

      if (Number(searchBottom) > itemTop) {
        return;
      }
    }
    setFocusedIndex(index);
  };

  useEffect(() => {
    setFocusedIndex(0);
  }, [filterValue]);

  useEffect(() => {
    if (!visible) {
      setFocusedIndex(0);
    }
  }, [visible, focusedIndex]);

  useEffect(() => {
    if (showSearch) {
      if (visible) {
        searchEl.current?.focus();
      } else {
        setInnerFilterValue("");
      }
    }
  }, [visible, showSearch, innerFilterValue]);

  // context(alexandrchebotar, 2021-11-12): updating dropdown scroll position if the focused item is out of view
  useLayoutEffect(() => {
    if (visible) {
      const itemEl = focusedItemEl.current;
      const containerEl = focusedItemEl.current?.parentElement?.parentElement;

      if (forcedFocus && itemEl && containerEl) {
        const itemRect = itemEl.getBoundingClientRect();
        const containerRect = containerEl.getBoundingClientRect();

        if (itemRect.bottom > containerRect.bottom) {
          itemEl.scrollIntoView({ block: "end" });
          containerEl.scrollBy({ top: spaces.s });
        }
        if (itemRect.top - itemRect.height < containerRect.top + spaces.s) {
          const scrollHeight = showSearch ? parseInt(text.large.lineHeight) + 2 * spaces.m + spaces.s : spaces.s;

          itemEl.scrollIntoView({ block: "start" });
          containerEl.scrollBy({ top: -scrollHeight });
        }
      }
    }
  }, [focusedIndex, forcedFocus, showSearch, visible]);

  const renderDropItems = (items: T[], favorites?: boolean) =>
    items.map((item, index) => {
      const listIndex = favorites ? index : filtered.favorites.length + index;
      const focused = forcedFocus && focusedIndex === listIndex;
      const ref = focused ? focusedItemEl : null;

      return (
        <ItemContainer
          key={index}
          focused={focused}
          ref={ref}
          onClick={() => onSelect(item)}
          onMouseEnter={onHover(listIndex)}
        >
          {renderItem(item)}
        </ItemContainer>
      );
    });
  const renderFavorites = () => renderDropItems(filtered.favorites, true);
  const renderItems = () => renderDropItems(filtered.items);

  const showDivider = !!filtered.favorites.length;
  const noItems = !filtered.items.length && !filtered.favorites.length;

  return (
    <>
      {showSearch && (
        <SearchContainerOuter>
          <SearchContainer>
            <SearchIconContainer>
              <SearchIcon size={21} />
            </SearchIconContainer>
            <SearchInput
              type="text"
              autoComplete="off"
              ref={searchEl}
              value={innerFilterValue}
              onChange={(e) => setInnerFilterValue(e.target.value)}
              placeholder={searchPlaceholder}
            />
          </SearchContainer>
          <Divider />
        </SearchContainerOuter>
      )}
      <SelectItemsList
        onMouseEnter={() => {
          setForcedFocus(false);
        }}
        onMouseLeave={() => {
          setForcedFocus(true);
        }}
      >
        {renderFavorites()}
        {showDivider && <Divider />}
        {renderItems()}
      </SelectItemsList>
      {noItems && (
        <Paragraph size="lmedium" padding="m" margin="none">
          {noItemsPlaceholder || t("form.placeholders.no-country-found")}
        </Paragraph>
      )}
    </>
  );
};
