import { TypePolicy } from "@apollo/client";
import _isUndefined from "lodash/isUndefined";
import _mapValues from "lodash/mapValues";
import _merge from "lodash/merge";
import _omitBy from "lodash/omitBy";

import schemaMetadataJson from "./schemaMetadata.json";
import { typePolicies as explicitTypePolicies } from "./typePolicies";

interface SchemaMetadataEntry {
  keyFields?: string[] | null;
  possibleTypes?: string[];
}

const schemaMetadata: { [s: string]: SchemaMetadataEntry } = schemaMetadataJson;

export const typePolicies = _merge(
  _mapValues(
    schemaMetadata,
    ({ keyFields }): TypePolicy => ({
      keyFields: keyFields ?? undefined,
      merge: true,
    })
  ),
  explicitTypePolicies
);

export const possibleTypes = _omitBy(
  _mapValues(schemaMetadata, (d) => d?.possibleTypes),
  _isUndefined
) as { [s: string]: string[] };
