import React, { useCallback, useEffect } from "react";

enum KeyCodes {
  Enter = "onEnter",
  Escape = "onEsc",
  PageUp = "onPageUp",
  PageDown = "onPageDown",
  Home = "onHome",
  End = "onEnd",
  ArrowLeft = "onLeft",
  ArrowUp = "onUp",
  ArrowRight = "onRight",
  ArrowDown = "onDown",
  Tab = "onTab",
}

type KeyboardHandler = (e: KeyboardEvent) => void;

export interface IUseKeyboard extends Partial<Record<KeyCodes, KeyboardHandler>> {
  targetRef?: React.MutableRefObject<HTMLElement | null>;
  onKeyDown?: KeyboardHandler;
  stopPropagation?: boolean;
  preventDefault?: boolean;
  capture?: boolean;
  options?: AddEventListenerOptions;
}

export const useKeyboard = ({
  targetRef,
  stopPropagation,
  preventDefault,
  capture,
  options,
  onKeyDown,
  ...callbacks
}: IUseKeyboard) => {
  const { onEnter, onEsc, onPageUp, onPageDown, onHome, onEnd, onLeft, onUp, onRight, onDown, onTab } = callbacks;
  const onKeyDownHandler = useCallback(
    (e) => {
      if (stopPropagation) {
        e.stopPropagation();
      }
      if (preventDefault) {
        e.preventDefault();
      }

      const callbackName = KeyCodes[e.code];
      const callback = callbackName && callbacks[callbackName];

      if (callback) {
        callback(e);
      }
      if (onKeyDown) {
        onKeyDown(e);
      }
    },
    // context(alexandrchebotar, 2021-11-15): ignored callbacks object dependency as it's a rest of props
    // but added dependency for every single callback
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      stopPropagation,
      preventDefault,
      onKeyDown,
      onEnter,
      onEsc,
      onPageUp,
      onPageDown,
      onHome,
      onEnd,
      onLeft,
      onUp,
      onRight,
      onDown,
      onTab,
    ]
  );

  useEffect(() => {
    if (targetRef) {
      const targetElement = targetRef.current;
      targetElement?.addEventListener("keydown", onKeyDownHandler, options ?? capture);

      return () => {
        targetElement?.removeEventListener("keydown", onKeyDownHandler, options ?? capture);
      };
    } else {
      document.addEventListener("keydown", onKeyDownHandler, options ?? capture);

      return () => {
        document.removeEventListener("keydown", onKeyDownHandler, options ?? capture);
      };
    }
  }, [capture, options, onKeyDownHandler, targetRef]);
};
