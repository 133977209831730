import { AbsintheSocket, Notifier, cancel as cancelNotifier } from "@absinthe/socket";
import { Anchor } from "grommet";
import { Socket as PhoenixSocket } from "phoenix";
import React, { useEffect } from "react";
import { Trans } from "react-i18next";

import { notify } from "lib/notification";
import { TurnSupport } from "utils/links";

interface IUsePhxErrorHandler {
  socket?: PhoenixSocket;
  absintheSocket?: AbsintheSocket;
  skip?: boolean;
}
export const usePhxErrorHandler = ({ socket, absintheSocket, skip }: IUsePhxErrorHandler) => {
  useEffect(() => {
    if (!skip && socket && absintheSocket) {
      socket.onMessage((message) => {
        if (message["event"] === "phx_error" && message["topic"] === "__absinthe__:control") {
          absintheSocket.notifiers = absintheSocket.notifiers.reduce<Notifier[]>((notifiers, notifier) => {
            if (notifier.operationType === "subscription") {
              notifiers.push(notifier);
            } else {
              cancelNotifier(absintheSocket, notifier);
            }
            return notifiers;
          }, []);

          notify({
            type: "error",
            toastId: "phx-error",
            description: (
              <Trans
                i18nKey="notifications.errors.phx-error"
                components={{ a: <Anchor href={TurnSupport} target="_blank" /> }}
              />
            ),
          });
        }
      });
    }
  }, [socket, absintheSocket, skip]);
};
