import React from "react";

import { turnTheme } from "theme";

import { TDefaultIconProps } from "./types";

export const Tag = ({ fill = turnTheme.global.colors["dark-4"], size = 14, ...props }: TDefaultIconProps) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.054 0h5.104a2.007 2.007 0 0 1 1.431.596l5.813 5.824a2.016 2.016 0 0 1 0 2.87l-5.097 5.107a2.003 2.003 0 0 1-2.863 0L.63 8.572A2.017 2.017 0 0 1 0 7.138V2.023A2.033 2.033 0 0 1 1.272.146c.249-.1.515-.15.782-.146Zm2.49 6.536A2.009 2.009 0 0 0 6.51 4.907a2.02 2.02 0 0 0-1.209-2.251 2.007 2.007 0 0 0-2.44.748 2.019 2.019 0 0 0 .256 2.544c.38.378.892.589 1.427.588Z"
      fill={fill}
    />
  </svg>
);
