export const TemplateStatuses = {
  REJECTED: "REJECTED",
  APPROVED: "APPROVED",
  PENDING: "PENDING",
  IN_APPEAL: "IN_APPEAL",
  PENDING_DELETION: "PENDING_DELETION",
  DISABLED: "DISABLED",
  DELETED: "DELETED",
};

export const LOW_CREDIT = (credit) => credit < 5;

export const LOW_CONVERSATIONS = (conversations) => conversations < 200;
