import styled from "styled-components";

import { getLogoStyles } from "./theme";
import { LogoProps } from "./types";

export const StyledLogo = styled.div<LogoProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  padding: ${({ size = 0 }) => size / 5}px;
  box-sizing: border-box;

  ${({ type }) => getLogoStyles({ type })};
`;

export const StyledWordMark = styled.div<LogoProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size}px;
  height: auto;
`;
