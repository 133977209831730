import { ColorBrandDefault } from "ds/Core";
import React from "react";

export const TurnLogo = ({ fill = ColorBrandDefault }: { fill?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 136 334" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M135.465 273.846C128.483 276.938 120.292 278.483 110.893 278.483C92.915 278.483 80.854 271.961 74.7031 258.91C71.2334 251.522 69.4992 240.516 69.4992 225.895V129.269H124.213V71.0245H69.4992V70.9579H69.1195V0.333344C69.1195 0.333344 33.2287 20.9296 0.134015 40.3641V70.9579H0.083313V225.895C0.083313 255.295 3.79412 277.54 11.2229 292.633C24.4984 319.989 50.2581 333.667 88.5019 333.667C101.777 333.667 114.31 332.885 124.191 329.428C127.889 328.134 131.638 326.42 135.437 324.287C135.516 304.411 135.516 296.447 135.465 273.846Z"
      fill={fill}
    />
  </svg>
);

export const TurnWordMark = ({ fill = ColorBrandDefault }: { fill?: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 377 151" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M60.844 123.899C57.7031 125.298 54.0237 125.998 49.8059 125.998C41.7293 125.998 36.309 123.038 33.527 117.138C31.9655 113.784 31.1938 108.797 31.1938 102.179V58.4163H55.7827V32.033H31.1938V31.9972H31.0322V0C31.0322 0 14.8969 9.32654 0.0179437 18.1329V31.9972H0V102.179C0 115.505 1.66918 125.567 5.00752 132.419C10.9842 144.812 22.5607 151 39.755 151C45.7317 151 51.3495 150.641 55.8006 149.081C57.4698 148.489 59.1569 147.718 60.8619 146.749V146.744C60.8619 137.744 60.8619 134.138 60.844 123.899Z"
      fill={fill}
    />
    <path
      d="M346.543 44.5523C347.691 46.3459 348.391 47.4041 348.678 47.7628L377.001 31.3158V147.754H346.112V84.4232C346.112 76.944 345.125 70.9177 343.133 66.362C339.292 57.9502 332.202 53.7533 321.846 53.7533C313.751 53.7533 308.331 56.6947 305.567 62.6135C304.006 65.9674 303.234 70.9535 303.234 77.5718V147.754H272.004V77.5718C272.004 64.2456 273.673 54.1658 277.012 47.3323C282.988 34.9388 294.565 28.751 311.777 28.751C317.736 28.751 322.941 29.5402 327.392 31.1006C331.825 32.661 337.73 35.5845 342.486 39.8532C344.048 41.1983 345.412 42.7767 346.543 44.5523Z"
      fill={fill}
    />
    <path
      d="M147.408 102.161V31.9614H178.62V102.143C178.62 115.47 176.951 125.549 173.613 132.383C167.636 144.776 156.059 150.964 138.847 150.964C132.888 150.964 127.683 150.175 123.232 148.615C118.799 147.054 112.894 144.131 108.138 139.862C106.576 138.517 105.212 136.939 104.082 135.163C102.933 133.369 102.233 132.311 101.946 131.952L73.6237 148.399V31.9614H104.53V95.31C104.53 102.789 105.517 108.816 107.51 113.371C111.351 121.783 118.44 125.98 128.796 125.98C136.891 125.98 142.311 123.038 145.075 117.12C146.637 113.766 147.408 108.78 147.408 102.161Z"
      fill={fill}
    />
    <path
      d="M257.519 28.7687C258.075 28.7687 258.56 28.7867 258.955 28.8225C259.258 28.8514 259.886 28.8918 260.839 28.9532C261.071 28.9681 261.322 28.9843 261.593 29.0019V60.1919C259.673 59.9767 257.95 59.8332 256.46 59.7615C254.952 59.6897 253.75 59.6539 252.816 59.6539C240.558 59.6539 232.32 63.6356 228.12 71.6169C225.769 76.1008 224.584 83.006 224.584 92.3326V147.969H193.893V31.5308H222.987V51.8339C227.689 44.0857 231.781 38.7768 235.281 35.925C240.971 31.1542 248.383 28.7687 257.519 28.7687Z"
      fill={fill}
    />
  </svg>
);
