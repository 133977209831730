import { Mp3MediaRecorder } from "mp3-mediarecorder";
import { useEffect, useRef, useState } from "react";
import mp3RecorderWorker, { WorkerWithReady } from "workerize-loader?ready!./worker";

import { useTimer } from "hooks/useTimer";

export const enum RecorderStatus {
  INACTIVE = "inactive",
  RECORDING = "recording",
  PAUSED = "paused",
}

export const useRecorder = () => {
  const [loading, setLoading] = useState(true);

  const [recorderState, setRecorderState] = useState<RecorderStatus>(RecorderStatus.INACTIVE);
  const [blob, setBlob] = useState<Blob>();
  const [blobUrl, setBlobUrl] = useState<string>();
  const { timer, startTimer, pauseTimer, resetTimer } = useTimer();

  const workerRef = useRef<WorkerWithReady>();
  const recorderRef = useRef<Mp3MediaRecorder>();

  useEffect(() => {
    workerRef.current = mp3RecorderWorker();
    workerRef.current.ready.then(() => {
      setLoading(false);
    });

    return () => workerRef.current?.terminate();
  }, []);

  const onRecord = () => {
    window.navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const recorder = new Mp3MediaRecorder(stream, { worker: workerRef.current! });
      recorderRef.current = recorder;

      recorder.ondataavailable = (event) => {
        setBlob(event.data);
        setBlobUrl(URL.createObjectURL(event.data));
      };
      recorder.onstart = () => {
        startTimer();
        setRecorderState(RecorderStatus.RECORDING);
      };
      recorder.onstop = () => {
        resetTimer();
        setRecorderState(RecorderStatus.INACTIVE);
        stream.getTracks().forEach((track) => track.stop());
      };
      recorder.onpause = () => {
        pauseTimer();
        setRecorderState(RecorderStatus.PAUSED);
      };
      recorder.onresume = () => {
        startTimer();
        setRecorderState(RecorderStatus.RECORDING);
      };

      recorder.start();
    });
  };
  const onStop = () => recorderRef.current?.stop();
  const onPause = () => recorderRef.current?.pause();
  const onResume = () => recorderRef.current?.resume();
  const clearBlob = () => {
    if (
      recorderRef.current?.state === RecorderStatus.RECORDING ||
      recorderRef.current?.state === RecorderStatus.PAUSED
    ) {
      recorderRef.current?.stop();
    }
    setBlob(undefined);
    setBlobUrl(undefined);
  };

  return {
    blob,
    blobUrl,
    clearBlob,
    loading,
    onPause,
    onRecord,
    onResume,
    onStop,
    recorderState,
    timer,
  };
};
