import { useRef } from "react";

export const useCounter = () => {
  const counter = useRef(0);
  const nextNumber = useRef(() => ++counter.current).current;
  const nextString = useRef(() => nextNumber().toString()).current;

  return {
    value: counter.current,
    next: nextNumber,
    nextNumber,
    nextString,
  };
};
