import gql from "graphql-tag";

export const OPERATOR_AVAILABILITY_FRAGMENT = gql`
  fragment OperatorAvailabilityFragment on OperatorAvailability {
    accountUuid
    isAvailable
    numberUuid
    uuid
  }
`;
