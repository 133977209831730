import * as React from "react";

export const SuccessIcon = ({ fill = "#00C78B", ...props }) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10C0 4.48 4.48 0 10 0s10 4.48 10 10-4.48 10-10 10S0 15.52 0 10zm3.7.7l3.59 3.59c.39.39 1.03.39 1.41 0l7.59-7.59a.996.996 0 10-1.41-1.41L8 12.17 5.11 9.29A.996.996 0 103.7 10.7z"
      fill={fill}
    />
  </svg>
);
