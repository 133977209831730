import { IconsListType } from "@backlight-dev/turnio.design-icons/Icons/types";
import React from "react";

export enum CheckboxSizes {
  "SMALL" = "small",
  "MEDIUM" = "medium",
  "LARGE" = "large",
}

export type CheckboxSizesType = `${CheckboxSizes}`;

export interface CheckboxProps extends Omit<React.ComponentPropsWithoutRef<"input">, "size"> {
  size?: CheckboxSizesType;
  text?: string;
  textProps?: React.ComponentPropsWithoutRef<"label">;
  icon?: IconsListType;
}
