import gql from "graphql-tag";

import { required } from "api/boundary";
import { CHAT_MEDIUM_FRAGMENT, CHAT_MIN_FRAGMENT } from "graphql/chats";

export const CHAT_NOTE_FRAGMENT = gql`
  fragment ChatNoteMentionFragment on ChatNoteMention {
    authorAccountUuid
    isCleared
    taggedAccountUuid
    uuid
  }
`;

export const MESSAGE_TRANSLATION_FRAGMENT = gql`
  fragment MessageTranslationFragment on MessageTranslation {
    originalLanguageCode
    originalMessage
    translatedLanguageCode
    translatedMessage
    uuid
  }
`;

export const MESSAGE_MIN_FRAGMENT = {
  query: gql`
    fragment MessageMin on Message {
      uuid
      content
      messageType
      isHandled
    }
  `,
  parse: {
    uuid: required(),
  },
};

export const MESSAGE_INTERACTIVE_DETAILS_FRAGMENT = gql`
  fragment MessageInteractiveDetails on Message {
    uuid
    interactive {
      type
      header {
        type
        text
        video
        image
        document
      }
      body {
        text
      }
      footer {
        text
      }
      action {
        button
        buttons {
          type
          reply {
            title
            id
          }
        }
        sections {
          title
          rows {
            id
            title
            description
          }
        }
      }
      buttonReply {
        id
        title
      }
      listReply {
        id
        title
        description
      }
    }
  }
`;

export const MESSAGE_DETAILS_FRAGMENT = {
  query: gql`
    fragment MessageDetails on Message {
      ...MessageMin
      ...MessageInteractiveDetails
      fromAddr
      direction
      lastStatus
      lastStatusTimestamp
      insertedAt
      chat {
        ...ChatMedium
      }
      chatNoteMentions {
        ...ChatNoteMentionFragment
      }
      sourceType
      inReplyTo {
        ...MessageInteractiveDetails
        content
        messageType
        direction
        updatedAt
        insertedAt
        template {
          name
          language {
            code
          }
          components {
            type
            parameters {
              type
              ... on MessageTemplateComponentParameterText {
                text
              }
              ... on MessageTemplateComponentParameterVideo {
                video {
                  id
                  link
                }
              }
              ... on MessageTemplateComponentParameterImage {
                image {
                  id
                  link
                }
              }
              ... on MessageTemplateComponentParameterDocument {
                document {
                  id
                  link
                  filename
                }
              }
            }
          }
        }
        hsm {
          elementName
          language {
            code
          }
          localizableParams {
            default
          }
        }
        author {
          id
          type
          name
        }
        messageAttachments {
          insertedAt
          attachment {
            id
            mediaType
            mimeType
            mediaObject
            uri
            autoCaption
            autoCaptionStatus
            transcriptions {
              text
              startTimestampSeconds
              endTimestampSeconds
            }
            transcriptionsStatus
          }
          caption
        }
      }
      messageTags {
        confidence
        insertedAt
        numberTag {
          uuid
          value
          color
        }
      }
      hsm {
        elementName
        language {
          code
        }
        localizableParams {
          default
        }
      }
      templateButtonText
      templateButtonIndex
      templateButtonTemplateName
      templateButtonTemplateLanguage
      template {
        name
        language {
          code
        }
        components {
          type
          subType
          index
          parameters {
            type
            ... on MessageTemplateComponentParameterText {
              text
            }
            ... on MessageTemplateComponentParameterVideo {
              video {
                id
                link
              }
            }
            ... on MessageTemplateComponentParameterImage {
              image {
                id
                link
              }
            }
            ... on MessageTemplateComponentParameterDocument {
              document {
                id
                link
                filename
              }
            }
          }
        }
      }
      author {
        id
        type
        name
      }
      messageAttachments {
        insertedAt
        attachment {
          id
          mediaType
          mimeType
          mediaObject
          uri
          autoCaption
          autoCaptionStatus
          transcriptions {
            text
            startTimestampSeconds
            endTimestampSeconds
          }
          transcriptionsStatus
        }
        caption
      }
      externalTimestamp
      updatedAt
      insertedAt
      isDeleted
      deletedReason
      deletedByActor {
        uuid
        type
        account {
          profilePicture
          uuid
          firstName
          lastName
          email
        }
      }
      messageReactions {
        direction
        reaction
      }
      messageTranslations {
        ...MessageTranslationFragment
      }
    }

    ${CHAT_MEDIUM_FRAGMENT.query}
    ${CHAT_NOTE_FRAGMENT}
    ${MESSAGE_MIN_FRAGMENT.query}
    ${MESSAGE_INTERACTIVE_DETAILS_FRAGMENT}
    ${MESSAGE_TRANSLATION_FRAGMENT}
  `,
  parse: {
    ...MESSAGE_MIN_FRAGMENT.parse,
    insertedAt: required(),
    chat: CHAT_MEDIUM_FRAGMENT.parse,
  },
};

export const MESSAGE_DETAILS_WITH_CHAT_MIN_FRAGMENT = {
  query: gql`
    fragment MessageDetailsWithChatMin on Message {
      ...MessageDetails
      chat {
        ...ChatMin
      }
    }

    ${CHAT_MIN_FRAGMENT.query}
    ${MESSAGE_DETAILS_FRAGMENT.query}
  `,
  parse: {
    ...MESSAGE_DETAILS_FRAGMENT.parse,
    chat: CHAT_MIN_FRAGMENT.parse,
  },
};

export const MESSAGE_DETAILS_WITH_CHAT_MEDIUM_FRAGMENT = {
  query: gql`
    fragment MessageDetailsWithChatMedium on Message {
      ...MessageDetails
      chat {
        ...ChatMedium
      }
    }

    ${CHAT_MEDIUM_FRAGMENT.query}
    ${MESSAGE_DETAILS_FRAGMENT.query}
  `,
  parse: {
    ...MESSAGE_DETAILS_FRAGMENT.parse,
    chat: CHAT_MEDIUM_FRAGMENT.parse,
  },
};
