import { useCallback, useState } from "react";

interface IUseListSelection {
  key?: string | false;
  active?: boolean;
}

export const useListSelection = <TItem>(options?: IUseListSelection) => {
  const { key = "uuid", active = false } = options ?? {};

  const [listSelectingActive, setListSelectingActive] = useState(active);
  const [selectedItems, setSelectedItems] = useState<TItem[]>([]);

  const toggleListSelecting = useCallback(() => {
    if (listSelectingActive) {
      setSelectedItems([]);
    }
    setListSelectingActive(!listSelectingActive);
  }, [listSelectingActive]);

  const toggleSelectItem = useCallback(
    (item?: TItem) => {
      if (item) {
        setSelectedItems((selectedItems) => {
          const isItemSelected = key ? selectedItems.find((i) => i[key] === item[key]) : selectedItems.includes(item);

          return isItemSelected
            ? selectedItems.filter((i) => (key ? i[key] !== item[key] : i !== item))
            : [...selectedItems, item];
        });
      }
    },
    [key]
  );

  return {
    listSelectingActive,
    toggleListSelecting,
    selectedItems,
    toggleSelectItem,
    setSelectedItems,
  };
};
