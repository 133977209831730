import { ICard, ICardNumberTag, ICardTag, Maybe } from "types";

import { AUTOMATED_MESSAGES } from "./constants";

export const getItemTags = (
  item:
    | ICard
    | {
        __typename?: never;
        cardNumberTags?: ICardNumberTag[];
        tags?: ICardTag[];
      },
  numberTags: boolean
) => {
  switch (item.__typename) {
    case "Card":
      return numberTags ? item.cardNumberTags?.filter((tag) => tag.numberTag) : [];
    default:
      return (
        (numberTags ? item.cardNumberTags?.filter((tag) => tag.numberTag) : item.tags?.filter((tag) => tag.tag)) || []
      );
  }
};

export const getItemTitle = (item: { name?: string; title?: string; question?: string }) =>
  item.question || item.title || item.name || "";

export const isAutomatedMessage = (
  card: Maybe<{
    cardNumberTags?: {
      value?: string;
      numberTag?: {
        value?: string;
      };
    }[];
  }>
) =>
  Boolean(
    card?.cardNumberTags?.some(
      (tag) => tag?.numberTag?.value === AUTOMATED_MESSAGES || tag?.value === AUTOMATED_MESSAGES
    )
  );
