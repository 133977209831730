import { BorderRadiusPill, ColorBrandDefault, ColorTransparency00, FontFamilyInter } from "ds/Core";
import styled from "styled-components";

import { getAvatarStyles } from "./theme";
import { AvatarProps } from "./types";

export const StyledAvatar = styled.div<AvatarProps>`
  background-color: ${({ color, url }) => (url ? ColorTransparency00 : color)};
  border-radius: ${BorderRadiusPill};
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    color: ${ColorBrandDefault};
    line-height: 0px;
    font-family: ${FontFamilyInter};
    pointer-events: none;
  }

  ${({ size }) => getAvatarStyles({ size })};

  ${({ url }) =>
    url &&
    `
      background-image: url("${url}");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
  `}
`;
