import { BreadcrumbsItemProps } from "ds/Breadcrumbs/src/types";
import { ButtonProps } from "ds/Button/src/types";
import { DropdownProps } from "ds/Dropdown/src/types";
import { NavItemProps } from "ds/Navs/src/types";
import { SelectProps } from "ds/Select/src/types";
import { TextInputProps } from "ds/TextInput/src/types";
import { StatusTypes } from "ds/types";
import React, { ReactNode } from "react";

export enum NavigationHeaderVariants {
  "PRIMARY" = "primary",
  "SECONDARY" = "secondary",
  "BACK" = "back",
  "LOGO" = "logo",
}

export interface TSavingStatus {
  type?: StatusTypes;
  label: string;
  error?: string;
}

export enum ActionsType {
  BUTTON = "BUTTON",
  DROPDOWN = "DROPDOWN",
}

export type ButtonActionType = Omit<ButtonProps, "size" | "variation"> & {
  actionType?: ActionsType.BUTTON;
};

export type DropdownActionType = Omit<DropdownProps, "size" | "variation" | "position"> & {
  actionType?: ActionsType.DROPDOWN;
};

export type ActionType = ButtonActionType | DropdownActionType;

export type Tab = {
  selected: boolean;
  text: ReactNode;
  onClick: () => void;
  actions?: ActionType[];
  search?: Omit<TextInputProps, "size" | "variation">;
  sort?: SelectProps<string, { label: string; value: string }>;
  filter?: SelectProps<string, { label: string; value: string }>;
};

export interface NavigationHeaderProps extends React.ComponentPropsWithoutRef<"div"> {
  variant?: `${NavigationHeaderVariants}`;
  text?: string;
  logo?: boolean;
  actions?: ActionType[];
  search?: Omit<TextInputProps, "size" | "variation">;
  sort?: SelectProps<string, { label: string; value: string }>;
  filter?: SelectProps<string, { label: string; value: string }>;
  help?: Omit<ButtonProps, "size" | "variation">;
  feedback?: Omit<ButtonProps, "size" | "variation">;
  menu?: Omit<DropdownProps, "size" | "variation" | "position">;
  tabs?: Tab[];
  navs?: Omit<ButtonProps, "size" | "variation">;
  breadcrumb?: BreadcrumbsItemProps[];
  centerActions?: NavItemProps[];
  back?: Omit<ButtonProps, "size" | "variation">;
  savingStatus?: TSavingStatus;
}
