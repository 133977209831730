import { SyntheticEvent } from "react";

export const stopPropagation = (e: SyntheticEvent) => {
  e.stopPropagation();
};

export const isTargetPathContainsClass = (e: React.MouseEvent, classNames: string[]) => {
  let el = e.target as HTMLElement;

  while (el !== e.currentTarget) {
    if (classNames.some((className) => el.classList.contains(className))) {
      return true;
    } else if (el.parentElement) {
      el = el.parentElement;
    } else {
      break;
    }
  }

  return false;
};
