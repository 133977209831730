import { Number, SubscriptionState } from "graphql/generated/types";
import { TNumberMin, getInitialNumber_number, onInitialNumberChanged_onNumberChanged } from "graphqlQueries";
import { NumberType } from "graphqlQueries/generated/globalTypes";

export const numberHasActiveSubscription = (
  number?: Number | getInitialNumber_number | onInitialNumberChanged_onNumberChanged
) => {
  if (numberIsExemptedFromBilling(number)) return true;

  return (
    (number?.subscription &&
      number.subscription.state &&
      number.subscription.state !== SubscriptionState.CANCELED &&
      number.subscription.state !== SubscriptionState.PAUSED) ??
    false
  );
};

// Checks override fields for specific numbers that are exempt from billing. Used mostly for local/QA.
export const numberIsExemptedFromBilling = (number?: Number | getInitialNumber_number) =>
  number?.allowMessagingWithInvalidSubscription || number?.billingEnabled === false;

export const isSandbox = (number?: TNumberMin) => {
  if (!number?.numberType) {
    return true;
  }
  return number?.numberType === NumberType.VIRTUAL_NUMBER || number?.numberType === NumberType.VIRTUAL_NUMBER_PROVIDER;
};
