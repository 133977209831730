import { BackgroundColorWhite, InteractiveColorHoverAlt, TextColorDefault, TextColorNonessential } from "ds/Alias";
import { Space150, SpaceNone } from "ds/Core";
import _kebabCase from "lodash/kebabCase.js";

import { DropdownSizes, DropdownVariations } from "./types";

const getStyles = (props: any, disabled?: boolean) =>
  Object.entries(props).reduce(
    (styles, [key, value]) =>
      key === "disabled"
        ? disabled
          ? `${styles}\n${getStyles(value)}`
          : styles
        : key === "hover"
          ? `${styles}\n&:hover {${getStyles(value)}}`
          : `${styles}\n${_kebabCase(key)}: ${value};`,
    ""
  );

export const getDropdownStyles = ({
  size,
  variation,
  disabled,
}: {
  size?: DropdownSizes;
  variation?: DropdownVariations;
  disabled?: boolean;
}) => `
    ${size && getStyles(theme.size[size], disabled)}
    ${variation && getStyles(theme.variation[variation], disabled)}
  `;

export const theme = {
  size: {
    small: {
      minHeight: "40px",
      padding: `${SpaceNone} ${Space150}`,
    },
    large: {
      minHeight: "48px",
      padding: `${SpaceNone} ${Space150}`,
    },
  },
  variation: {
    primary: {
      backgroundColor: BackgroundColorWhite,
      color: TextColorDefault,
      hover: {
        backgroundColor: InteractiveColorHoverAlt,
      },
      disabled: {
        backgroundColor: BackgroundColorWhite,
        color: TextColorNonessential,
        hover: {
          cursor: "not-allowed",
          backgroundColor: BackgroundColorWhite,
        },
      },
    },
  },
};
