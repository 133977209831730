export enum OnboardingStep {
  NONE,
  SIGNUP,
  TERMS,
  READINESS,
  INVITE,
  NUMBER,
  EMBEDDED_SIGNUP,
  META_VERIFICATION,
  CHOOSE_PLAN,
  DONE,
  PROTOTYPE,
}

export enum StepStatuses {
  NONE = "none",
  NEXT = "next",
  PENDING = "pending",
  DONE = "done",
}

export enum EmbeddedSignupSteps {
  NONE = "none",
  STARTED = "started",
  NOT_REGISTERED = "not-registered",
  PENDING = "pending",
  CONNECTED = "connected",
  NOT_CONNECTED = "not-connected",
  COMPLETED = "completed",
}

export enum Plans {
  SANDBOX = "sandbox",
  PRO = "pro",
  ENTERPRISE = "enterprise",
}

export const enum BillingPeriods {
  MONTH = "month",
  YEAR = "year",
}
