import { Box, BoxExtendedProps } from "grommet";
import React, { ReactNode } from "react";
import styled from "styled-components";

import { Bullet } from "lib/icons/Bullet";
import { Text, calcSpacing, getSpacing } from "lib/impact";
import { colors } from "lib/impact/styles/colors";

enum Markers {
  CHECK = "check",
  DOT = "dot",
}
type MarkerType = `${Markers}`;

const MarkedListItemText = styled(Text)`
  font-size: 18px;
  line-height: 1.55;
`;

interface IMarkedListItem {
  text: ReactNode;
  marker?: MarkerType;
}
export const MarkedListItem = ({ text, marker = Markers.CHECK }: IMarkedListItem) => (
  <Box direction="row" gap={calcSpacing(["xxs", "s"])} pad={{ vertical: getSpacing("xs") }} align="center">
    {marker === Markers.CHECK && <Bullet style={{ flexShrink: "0" }} />}
    {marker === Markers.DOT && (
      <Box
        height="8px"
        width="8px"
        margin={getSpacing("s")}
        round="4px"
        flex={{ shrink: 0 }}
        background={colors.black}
      />
    )}
    <MarkedListItemText>{text}</MarkedListItemText>
  </Box>
);

interface IMarkedList extends BoxExtendedProps {
  items?: ReactNode[];
  marker?: MarkerType;
}
export const MarkedList: React.FC<IMarkedList> = ({ children, items, marker = Markers.CHECK, ...props }) => (
  <Box {...props}>
    {React.Children.map(children || items, (item, index) => (
      <MarkedListItem key={index} text={item} marker={marker} />
    ))}
  </Box>
);
