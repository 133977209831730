import { ValueOf } from "types";

export const LayoutState = {
  DEFAULT: "DEFAULT",
  CHAT_LIST: "CHAT_LIST",
  MESSAGE_LIST: "MESSAGE_LIST",
  PROFILE: "PROFILE",
} as const;

export type TLayoutState = ValueOf<typeof LayoutState>;

export const ChatState = {
  OPEN: "OPEN",
  CLOSED: "CLOSED",
  ALL: "ALL",
} as const;

export type TChatState = ValueOf<typeof ChatState>;

export const OrderBy = {
  chats: {
    NEWEST: "-inbound_timestamp",
    OLDEST: "inbound_timestamp",
  },
  collections: {
    NEWEST: "-timestamp",
    OLDEST: "timestamp",
  },
  messages: {
    NEWEST: "-insertedAt",
    OLDEST: "insertedAt",
  },
} as const;

export const ChatGroup = {
  ALL_CHATS: "allChats",
  BLOCKED: "blocked",
  UNASSIGNED: "unassigned",
  WITH_BOT: "withBot",
} as const;
export type TChatGroup = ValueOf<typeof ChatGroup>;

export const InboxGroup = {
  ...ChatGroup,
  MENTIONS: "mentions",
} as const;
export type TInboxGroup = ValueOf<typeof InboxGroup>;
