import * as React from "react";

import { TDefaultIconProps } from "./types";

export const Send = ({ fill = "#7A8285", size, ...props }: TDefaultIconProps) => (
  <svg
    width={size || 17}
    height={size || 24}
    viewBox="0 0 17 16"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <path
      fill={fill}
      d="M15.7068123,0.292636506 C15.4338123,0.0206365055 15.0268123,-0.0723634945 14.6638123,0.0586365055 L0.663812313,5.05863651 C0.286812313,5.19263651 0.0258123126,5.53963651 0.00181231257,5.93863651 C-0.0221876874,6.33863651 0.193812313,6.71363651 0.552812313,6.89363651 L5.13881231,9.18563651 L10.9998123,4.99963651 L6.81281231,10.8616365 L9.10481231,15.4476365 C9.27581231,15.7866365 9.62281231,15.9996365 9.99981231,15.9996365 C10.0208123,15.9996365 10.0408123,15.9986365 10.0608123,15.9976365 C10.4608123,15.9736365 10.8078123,15.7136365 10.9428123,15.3356365 L15.9428123,1.33563651 C16.0718123,0.972636506 15.9798123,0.565636506 15.7068123,0.292636506 Z"
    />
  </svg>
);

export const SendIcon = ({ size = 20, ...props }: TDefaultIconProps) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_2002_12541)">
      <path
        d="M8.74952 11.25L17.4995 2.50002M8.85584 11.5234L11.0459 17.1551C11.2389 17.6512 11.3353 17.8993 11.4743 17.9717C11.5948 18.0345 11.7384 18.0345 11.859 17.9719C11.998 17.8997 12.0948 17.6517 12.2883 17.1558L17.7803 3.08269C17.955 2.63504 18.0423 2.41121 17.9945 2.26819C17.953 2.14398 17.8556 2.04651 17.7314 2.00501C17.5883 1.95723 17.3645 2.04458 16.9169 2.21927L2.84373 7.71122C2.34784 7.90474 2.09989 8.0015 2.02763 8.14059C1.96499 8.26116 1.96508 8.4047 2.02786 8.5252C2.10028 8.66421 2.34834 8.76067 2.84446 8.95361L8.47613 11.1437C8.57684 11.1829 8.62719 11.2024 8.66959 11.2327C8.70717 11.2595 8.74004 11.2924 8.76685 11.3299C8.79709 11.3723 8.81667 11.4227 8.85584 11.5234Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2002_12541">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
