import gql from "graphql-tag";

import { boundary } from "api/boundary";

import { INITIAL_NUMBER_FRAGMENT } from "./fragments";

export const ON_INITIAL_NUMBER_CHANGED = {
  query: gql`
    subscription onInitialNumberChanged($numberUuid: ID!) {
      onNumberChanged(numberUuid: $numberUuid) {
        ...InitialNumber
      }
    }

    ${INITIAL_NUMBER_FRAGMENT.query}
  `,
  parse: boundary({
    onNumberChanged: INITIAL_NUMBER_FRAGMENT.parse,
  }),
};
