import React from "react";

import { TDefaultIconProps } from "./types";

export const DownTail = ({ fill = "#BABEC0", size = 24, ...props }: TDefaultIconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11 5.209v11.17l-4.88-4.88c-.39-.39-1.03-.39-1.42 0a.996.996 0 000 1.41l6.59 6.59c.39.39 1.02.39 1.41 0l6.6-6.58a.996.996 0 000-1.41.996.996 0 00-1.41 0L13 16.379V5.209c0-.55-.45-1-1-1s-1 .45-1 1z"
      fill={fill}
    />
  </svg>
);
