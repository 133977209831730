import gql from "graphql-tag";

import { boundary, required } from "api/boundary";

import { CHAT_DETAILS_FRAGMENT, CHAT_SEARCH_RESULTS_FRAGMENT } from "./fragments";

export const GET_CHAT = {
  query: gql`
    query getChat($chatUuid: ID!) {
      chat(chatUuid: $chatUuid) {
        ...ChatDetails
      }
    }

    ${CHAT_DETAILS_FRAGMENT.query}
  `,
  parse: boundary({
    chat: CHAT_DETAILS_FRAGMENT.parse,
  }),
};

export const GET_CHATS = gql`
  query getChats($numberUuid: ID!) {
    chats(numberUuid: $numberUuid) {
      contact {
        failureCount
        isFallbackActive
        type
        urn
        uuid
      }
    }
  }
`;

export const GET_COLLECTION_CHATS = {
  query: gql`
    query getCollectionChats($collectionUuid: ID!, $orderBy: String, $state: String, $beforeUuid: ID, $afterUuid: ID) {
      collection(collectionUuid: $collectionUuid) {
        uuid
        chatsTotal(state: $state)
        chats(orderBy: $orderBy, state: $state, beforeUuid: $beforeUuid, afterUuid: $afterUuid) {
          ...ChatDetails
        }
      }
    }

    ${CHAT_DETAILS_FRAGMENT.query}
  `,
  parse: boundary({
    collection: {
      chatsTotal: required(),
      // todo(alexandrchebotar, 2023-09-20): investigate why do we need to fetch ChatDetail
      chats: CHAT_DETAILS_FRAGMENT.parse,
    },
  }),
};

export const CHATS_ASSIGNED_TO_ACCOUNT = {
  query: gql`
    query chatsAssignedToAccount(
      $numberUuid: ID!
      $assignedToUuid: ID!
      $orderBy: String!
      $state: String
      $limit: Int
      $offset: Int
    ) {
      chatsAssignedToAccount(
        numberUuid: $numberUuid
        assignedToUuid: $assignedToUuid
        orderBy: $orderBy
        state: $state
        limit: $limit
        offset: $offset
      ) {
        ...ChatSearchResultsFragment
      }
    }

    ${CHAT_SEARCH_RESULTS_FRAGMENT.query}
  `,
  parse: boundary({
    chatsAssignedToAccount: CHAT_SEARCH_RESULTS_FRAGMENT.parse,
  }),
};

export const CHATS_ASSIGNED_TO_ACCOUNT_COUNT = {
  query: gql`
    query chatsAssignedToAccountCount(
      $numberUuid: ID!
      $assignedToUuid: ID!
      $orderBy: String!
      $state: String
      $limit: Int
      $offset: Int
    ) {
      chatsAssignedToAccount(
        numberUuid: $numberUuid
        assignedToUuid: $assignedToUuid
        orderBy: $orderBy
        state: $state
        limit: $limit
        offset: $offset
      ) {
        count
      }
    }
  `,
  parse: boundary({
    chatsAssignedToAccount: {
      count: required(),
    },
  }),
};

export const ALL_CHATS = {
  query: gql`
    query allChats($numberUuid: ID!, $limit: Int, $offset: Int, $orderBy: String!, $state: String) {
      allChats(numberUuid: $numberUuid, orderBy: $orderBy, state: $state, limit: $limit, offset: $offset) {
        ...ChatSearchResultsFragment
      }
    }

    ${CHAT_SEARCH_RESULTS_FRAGMENT.query}
  `,
  parse: boundary({
    allChats: CHAT_SEARCH_RESULTS_FRAGMENT.parse,
  }),
};

export const BLOCKED_CHATS = {
  query: gql`
    query blockedChats($numberUuid: ID!, $limit: Int, $offset: Int, $orderBy: String!, $state: String) {
      blockedChats(numberUuid: $numberUuid, orderBy: $orderBy, state: $state, limit: $limit, offset: $offset) {
        ...ChatSearchResultsFragment
      }
    }

    ${CHAT_SEARCH_RESULTS_FRAGMENT.query}
  `,
  parse: boundary({
    blockedChats: CHAT_SEARCH_RESULTS_FRAGMENT.parse,
  }),
};

export const UNASSIGNED_CHATS = {
  query: gql`
    query unassignedChats($numberUuid: ID!, $limit: Int, $offset: Int, $orderBy: String!, $state: String) {
      unassignedChats(numberUuid: $numberUuid, orderBy: $orderBy, state: $state, limit: $limit, offset: $offset) {
        ...ChatSearchResultsFragment
      }
    }

    ${CHAT_SEARCH_RESULTS_FRAGMENT.query}
  `,
  parse: boundary({
    unassignedChats: CHAT_SEARCH_RESULTS_FRAGMENT.parse,
  }),
};

export const WITH_BOT_CHATS = {
  query: gql`
    query withBotChats($numberUuid: ID!, $limit: Int, $offset: Int, $orderBy: String!, $state: String) {
      withBot(numberUuid: $numberUuid, orderBy: $orderBy, state: $state, limit: $limit, offset: $offset) {
        ...ChatSearchResultsFragment
      }
    }

    ${CHAT_SEARCH_RESULTS_FRAGMENT.query}
  `,
  parse: boundary({
    withBot: CHAT_SEARCH_RESULTS_FRAGMENT.parse,
  }),
};
