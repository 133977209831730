import { PropsWithRef } from "react";

import { Override } from "types/utils";

export const iconSizes = {
  s: 16,
  m: 24,
};

export type TIconSize = keyof typeof iconSizes;

export type TIconProps = Override<
  PropsWithRef<React.SVGProps<SVGSVGElement>>,
  {
    fill?: string;
    fill2?: string;
    fill3?: string;
    size?: TIconSize | number;
  }
>;

export const getIconSize = (size: TIconSize | number) => {
  if (typeof size === "number") {
    return size;
  }
  return iconSizes[size];
};
