import { Anchor, Box, Heading, Paragraph, Text } from "grommet";
import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { useTranslation } from "react-i18next";

import logoQr from "assets/logo-qr.png";
import { turnTheme } from "theme";
import { numberDisplay, numberWaMeUrl } from "utils";
import { MakeYourServiceDocLink, WhatsAppQRCodeLink } from "utils/links";

export const QrContent = ({ number, title, onQrCodeClick = undefined }) => {
  const { t } = useTranslation();
  return (
    <Box align="center">
      <Box margin={{ top: "40px" }} id="qr_canvas_wrapper" onClick={onQrCodeClick} flex={{ shrink: 0 }}>
        <QRCodeSVG
          value={numberWaMeUrl(number)}
          level="H"
          size={200}
          fgColor={turnTheme.global.colors["dark-1"]}
          imageSettings={{
            src: logoQr,
            excavate: false,
            height: 40,
            width: 40,
          }}
        />
      </Box>
      {title ? (
        <>
          <Heading level="2" textAlign="center" margin={{ top: "medium", bottom: "0px" }}>
            {title}
          </Heading>
          <Heading level="2" textAlign="center" margin="0px" style={{ maxWidth: "none" }}>
            {`${t("modals.qr.scan-code")} ${t("modals.qr.start-chat")} `}
            <Anchor
              target="_blank"
              href={numberWaMeUrl(number)}
              label={numberDisplay(number, { weight: 600, size: "24px" })}
            />
          </Heading>
        </>
      ) : (
        <>
          <Heading level="2" margin={{ top: "medium", bottom: "8px" }}>
            {t("modals.qr.scan-code")}
          </Heading>
          <Text weight={600} size="xlarge" textAlign="center">
            {`${t("modals.qr.start-chat")} `}
            <Anchor
              target="_blank"
              href={numberWaMeUrl(number)}
              label={numberDisplay(number, { weight: 600, size: "xlarge" })}
            />
          </Text>
        </>
      )}
      <Paragraph
        size="medium"
        textAlign="center"
        margin={{ vertical: "medium", horizontal: "32px" }}
        style={{ width: "100%", maxWidth: "560px" }}
      >
        {`${t("modals.qr.use-the")} `}
        <Anchor target="_blank" href={WhatsAppQRCodeLink} label={t("modals.qr.code-scanner")} />
        {t("modals.qr.your-mobile")}
      </Paragraph>
      <Paragraph
        size="medium"
        textAlign="center"
        margin={{ top: "0px", bottom: "small", horizontal: "32px" }}
        style={{ width: "100%", maxWidth: "560px" }}
      >
        {`${t("modals.qr.use-this")} `}
        <Anchor
          alignSelf="center"
          target="_blank"
          href={MakeYourServiceDocLink}
          label={t("modals.qr.click-to-whatsapp")}
        />
        {` ${t("modals.qr.link")} `}
        <Anchor target="_blank" href={numberWaMeUrl(number)} label={numberWaMeUrl(number)} />
        {` ${t("modals.qr.connect-web")}`}
      </Paragraph>
    </Box>
  );
};
