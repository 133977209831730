import { Spinner } from "grommet";
import styled from "styled-components";

import { turnTheme } from "theme";

export const StyledGrommetSpinner = styled(Spinner)<{
  animated: boolean;
}>`
  ${({ animated }) =>
    !animated &&
    `
      animation: none;
      transform: rotate(45deg);
    `}
`;

export const SpinnerText = styled.span<{
  reverse?: boolean;
  size?: string;
  weight?: number;
}>`
  margin: ${({ reverse }) => (reverse ? "0 16px 0 0" : "0 0 0 16px")};
  font-size: ${({ size = "14px" }) => size};
  font-weight: ${({ weight = 400 }) => weight};
  line-height: 1.6;
  color: ${({ color = turnTheme.global.colors["dark-1"] }) => color};
`;
