import { Box } from "grommet";
import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";

import { Anchor, Text, getColor, getSpacing } from "lib/impact";
import { QuestionRoundIcon } from "lib/impact/Icons/QuestionRoundIcon";
import { TooltipBox } from "lib/tooltip";

import { StatusColors } from "../styles";
import { StepStatusText } from "./StepStatusCard";

const StatusIcon = styled.div<{ color: StatusColors }>`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: ${({ color }) => getColor(color)};

  @media screen and (max-height: 830px) {
    width: 12px;
    height: 12px;
  }
`;

const StatusTagContainer = styled(Box)`
  height: 28px;
  border-radius: 14px;
  padding: ${`${getSpacing("xs")} ${getSpacing("s")}`};
  background-color: ${getColor("white")};
  align-items: center;

  @media screen and (max-height: 830px) {
    height: 24px;
  }
`;

interface IStatusTag {
  label: string;
  color: StatusColors;
  tooltip?: {
    id: string;
    i18nKey: string;
    href?: string;
  };
}

export const StatusTag = ({ label, color, tooltip }: IStatusTag) => (
  <Box direction="row" align="center" gap={getSpacing("s")}>
    <StatusTagContainer direction="row" gap={getSpacing("s")}>
      <StatusIcon color={color} />
      <StepStatusText size="medium" padding={{ right: "xxs" }} className="ph-no-capture">
        {label}
      </StepStatusText>
    </StatusTagContainer>
    {tooltip && (
      <TooltipBox
        id={tooltip.id}
        tooltip={
          <Text size="lmedium">
            <Trans
              i18nKey={tooltip.i18nKey}
              components={{
                a: <Anchor size="medium" href={tooltip.href} decoration="underline" target="_blank" />,
              }}
            />
          </Text>
        }
        tooltipProps={{
          place: "top",
          width: "216px",
          offset: { bottom: 5 },
          delayHide: 300,
          opacity: "1",
          padding: "12px",
          round: "8px",
        }}
      >
        <QuestionRoundIcon />
      </TooltipBox>
    )}
  </Box>
);
