import gql from "graphql-tag";

import { MESSAGE_DETAILS_FRAGMENT } from "./fragments";

export const ON_MESSAGE_CHANGED = gql`
  subscription onMessageChanged($chatUuid: ID!) {
    onMessageChanged(chatUuid: $chatUuid) {
      ...MessageDetails
    }
  }

  ${MESSAGE_DETAILS_FRAGMENT.query}
`;
