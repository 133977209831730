// Explicit (non-generated/custom) type policies go here

export const typePolicies = {
  // This is needed to skip normalising of CardAssociations in the cache.
  // We need to skip normalisation of CardAssociations because they share uuids with Cards, which was causing CardAssociations to be incorrectly shared between threads.
  // If this causes problems at any stage, a better fix may be to update CardAssociations in the backend to have their own UUIDs instead of sharing them with Cards.
  CardAssocation: {
    keyFields: null,
  },
  // context(alexandrchebotar, 2023-11-17): chat groups based on collection have unique `name`, while others contain assigned chats and have unique `accountUuid`
  InboxGroupingSummary: {
    keyFields: ["numberUuid", "accountUuid", "name"],
  },
};
