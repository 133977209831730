import { InteractiveColorDefault, InteractiveColorDisabled, InteractiveColorHover } from "ds/Alias";
import { SpaceNone } from "ds/Core";
import { ButtonSizeType } from "ds/theme/button-tokens";
import { Override } from "ds/types";
import _kebabCase from "lodash/kebabCase.js";

import {
  ButtonColorBackgroundFlatDefault,
  ButtonColorBackgroundFlatHover,
  ButtonColorBackgroundPrimaryDefault,
  ButtonColorBackgroundPrimaryDestructive,
  ButtonColorBackgroundPrimaryDestructiveHover,
  ButtonColorBackgroundPrimaryDisabled,
  ButtonColorBackgroundPrimaryHover,
  ButtonColorBackgroundSecondaryDefault,
  ButtonColorBackgroundSecondaryHover,
  ButtonColorTexticonDisabled,
  ButtonColorTexticonPrimary,
  ButtonColorTexticonSecondary,
  ButtonSizeLarge,
  ButtonSizeMedium,
  ButtonSizeMicro,
  ButtonSizeSmall,
  ButtonSizeXsmall,
  ButtonSpaceLarge,
  ButtonSpaceMedium,
  ButtonSpaceMicro,
  ButtonSpaceSmall,
  ButtonSpaceXsmall,
  ButtonTextLarge,
  ButtonTextMedium,
  ButtonTextMicro,
  ButtonTextSmall,
  ButtonTextXsmall,
} from "./tokens";
import { ButtonStyleProps } from "./types";

const getDisabledStyles = (props: any) => {
  const styles = getStyles(props);

  return `
    cursor: not-allowed;
    ${styles}
    &:hover {
      ${styles}
    }
`;
};

const getStyles = (props: any, destructive?: boolean, disabled?: boolean, active?: boolean, iconType?: boolean) =>
  Object.entries(props).reduce(
    (styles, [key, value]) =>
      key === "destructive"
        ? destructive
          ? `${styles}\n${getStyles(value)}`
          : styles
        : key === "iconType"
          ? iconType
            ? `${styles}\n${getStyles(value)}`
            : styles
          : key === "hover"
            ? `${styles}\n&:hover {${getStyles(value)}}`
            : key === "disabled"
              ? disabled
                ? `${styles}\n${getDisabledStyles(value)}`
                : styles
              : key === "active"
                ? active
                  ? `${styles}\n${getStyles(value)}`
                  : styles
                : `${styles}\n${_kebabCase(key)}: ${value};`,
    ""
  );

export const getButtonStyles = ({
  size,
  variation,
  destructive,
  disabled,
  iconType,
  active,
}: Override<ButtonStyleProps, { loading?: number }>) => `
    ${size && getStyles(theme.size[size], destructive, disabled, active, iconType)}
    ${variation && getStyles(theme.variation[variation], destructive, disabled, active)}
  `;

// todo(alexandrchebotar, 2023-09-08): ? refactor this to use button-tokens?
export const theme = {
  size: {
    micro: {
      height: ButtonSizeMicro,
      fontSize: ButtonTextMicro.fontSize,
      lineHeight: ButtonTextMicro.lineHeight,
      padding: `${SpaceNone} ${ButtonSpaceMicro}`,
      iconType: {
        padding: SpaceNone,
        width: ButtonSizeMicro,
      },
    },
    xsmall: {
      height: ButtonSizeXsmall,
      fontSize: ButtonTextXsmall.fontSize,
      lineHeight: ButtonTextXsmall.lineHeight,
      padding: `${SpaceNone} ${ButtonSpaceXsmall}`,
      iconType: {
        padding: SpaceNone,
        width: ButtonSizeXsmall,
      },
    },
    small: {
      height: ButtonSizeSmall,
      fontSize: ButtonTextSmall.fontSize,
      lineHeight: ButtonTextSmall.lineHeight,
      padding: `${SpaceNone} ${ButtonSpaceSmall}`,
      iconType: {
        padding: SpaceNone,
        width: ButtonSizeSmall,
      },
    },
    medium: {
      height: ButtonSizeMedium,
      fontSize: ButtonTextMedium.fontSize,
      lineHeight: ButtonTextMedium.lineHeight,
      padding: `${SpaceNone} ${ButtonSpaceMedium}`,
      iconType: {
        padding: SpaceNone,
        width: ButtonSizeMedium,
      },
    },
    large: {
      height: ButtonSizeLarge,
      fontSize: ButtonTextLarge.fontSize,
      lineHeight: ButtonTextLarge.lineHeight,
      padding: `${SpaceNone} ${ButtonSpaceLarge}`,
      iconType: {
        padding: SpaceNone,
        width: ButtonSizeLarge,
      },
    },
  },
  variation: {
    primary: {
      backgroundColor: ButtonColorBackgroundPrimaryDefault,
      color: ButtonColorTexticonPrimary,
      active: {
        backgroundColor: ButtonColorBackgroundPrimaryHover,
      },
      hover: {
        backgroundColor: ButtonColorBackgroundPrimaryHover,
      },
      destructive: {
        backgroundColor: ButtonColorBackgroundPrimaryDestructive,
        hover: {
          backgroundColor: ButtonColorBackgroundPrimaryDestructiveHover,
        },
      },
      disabled: {
        backgroundColor: ButtonColorBackgroundPrimaryDisabled,
        hover: {
          backgroundColor: ButtonColorBackgroundPrimaryDisabled,
        },
      },
    },
    secondary: {
      backgroundColor: ButtonColorBackgroundSecondaryDefault,
      color: ButtonColorTexticonSecondary,
      active: {
        backgroundColor: ButtonColorBackgroundSecondaryHover,
      },
      hover: {
        backgroundColor: ButtonColorBackgroundSecondaryHover,
      },
      destructive: {
        backgroundColor: ButtonColorBackgroundPrimaryDestructive,
        hover: {
          backgroundColor: ButtonColorBackgroundPrimaryDestructive,
        },
      },
      disabled: {
        color: ButtonColorTexticonDisabled,
        backgroundColor: ButtonColorBackgroundSecondaryDefault,
      },
    },
    flat: {
      color: ButtonColorTexticonSecondary,
      backgroundColor: ButtonColorBackgroundFlatDefault,
      active: {
        backgroundColor: ButtonColorBackgroundFlatHover,
      },
      hover: {
        backgroundColor: ButtonColorBackgroundFlatHover,
      },
      destructive: {
        backgroundColor: ButtonColorBackgroundPrimaryDestructive,
        hover: {
          backgroundColor: ButtonColorBackgroundPrimaryDestructive,
        },
      },
      disabled: {
        color: ButtonColorTexticonDisabled,
        backgroundColor: ButtonColorBackgroundFlatDefault,
      },
    },
    text: {
      color: InteractiveColorDefault,
      backgroundColor: "transparent",
      display: "inline-block",
      height: "min-content",
      textDecoration: "underline",
      padding: SpaceNone,
      borderRadius: "0px",
      active: {
        color: InteractiveColorHover,
      },
      hover: {
        color: InteractiveColorHover,
      },
      disabled: {
        color: InteractiveColorDisabled,
      },
    },
  },
};

export const getIconSize = (size: ButtonSizeType) =>
  size === "micro" || size === "xsmall" ? 16 : size === "small" ? 20 : 24;

export const getSpinnerSize = (size: ButtonSizeType) =>
  size === "micro" ? "18px" : size === "small" || size === "xsmall" ? "small" : "big";

export const getIconColor = ({ variation, disabled }: Pick<ButtonStyleProps, "variation" | "disabled">) => {
  const variationColor = theme.variation[variation].color;

  if (disabled) {
    const disabledStyles = theme.variation[variation].disabled;

    return disabledStyles && "color" in disabledStyles ? disabledStyles.color : variationColor;
  }

  return variationColor;
};
