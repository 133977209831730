import _capitalize from "lodash/capitalize";
import _filter from "lodash/filter";
import _isNumber from "lodash/isNumber";
import _isObject from "lodash/isObject";
import _isString from "lodash/isString";
import moment from "moment";
import numeral from "numeral";
import PropTypes from "prop-types";

import { CardType } from "graphqlQueries/generated/globalTypes";

// load a locale
numeral.register("locale", "us", {
  delimiters: {
    thousands: ",",
    decimal: ".",
  },
  abbreviations: {
    thousand: "k",
    million: "mil",
    billion: "bil",
    trillion: "T",
  },
  currency: {
    symbol: "$",
  },
});

numeral.locale("us");

const convertNumberContent = (data, { hideCards, cardForThreadsOnly }) => {
  if (data) {
    const cards = hideCards ? [] : filterCardTypes(data.number.cards, cardForThreadsOnly);
    return [...cards];
  }
  return [];
};

const filterCardTypes = (cards, cardForThreadsOnly = false) => {
  if (cardForThreadsOnly) {
    const threadCardTypes = [CardType.MESSAGE, CardType.MULTI, CardType.TEXT, CardType.BUTTONS];

    return cards.filter((card) => threadCardTypes.includes(card.type));
  }

  return cards;
};

const getStickerCards = (data) => {
  if (data) {
    const cards = data.number.cards;
    return _filter(cards, { type: "STICKER" });
  }
  return [];
};

const getNewData = (data, options = {}) => {
  const { hideCards, cardForThreadsOnly, onlyStickers } = options;
  if (onlyStickers) {
    return getStickerCards(data);
  }
  return convertNumberContent(data, {
    hideCards,
    cardForThreadsOnly,
  });
};

export const getSortedContent = (data, options = {}) => {
  const { returnData } = options;
  if (data) {
    const newData = getNewData(data, options);
    return returnData
      ? newData
      : newData.sort((a, b) => {
          const dateA = moment(a.updatedAt).valueOf();
          const dateB = moment(b.updatedAt).valueOf();
          if (dateA < dateB) return 1;
          if (dateA > dateB) return -1;
          return 0;
        });
  }
  return [];
};

export const capsToStarCase = (string, divider = "_") =>
  (_isString(string) ? string : "")
    .split(divider)
    .map((word) => _capitalize(word))
    .join(" ");

export const getCurrencyCode = (currency) => {
  switch (currency) {
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    default:
      return currency;
  }
};

export const convertContactValue = ({ value, fieldType }) => {
  return fieldType === "BOOLEAN"
    ? value.toString()
    : fieldType === "ENUM"
      ? { value }
      : fieldType === "STRING" && value === null
        ? ""
        : value;
};

export const countToPercent = (count, total, fixed, removeFixedIfSaveInteger) => {
  if (!total || total === 0 || !_isNumber(count) || !_isNumber(total)) {
    return 0;
  }
  const percent = (count * 100) / total;
  if (Number.isSafeInteger(percent) && !fixed) {
    return percent;
  }
  if (fixed) {
    return removeFixedIfSaveInteger && Number.isSafeInteger(percent) ? percent : percent.toFixed(fixed);
  }
  return percent;
};

countToPercent.propTypes = {
  count: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  fixed: PropTypes.number,
  removeFixedIfSaveInteger: PropTypes.bool,
};

export const abbrNum = (num, fullNumbers, format) => {
  if (num < 1e4) {
    return numeral(num).format(format || "0,0[.]00");
  }
  if (fullNumbers) {
    return numeral(num).format(format || "0,0[.]00");
  }
  return numeral(num).format(format || "0.00a");
};

export const getContactFieldDisplayValue = (contactField, value) => {
  if (contactField && contactField.fieldType === "BOOLEAN") {
    return value ? "Yes" : "No";
  }
  if (contactField && contactField.fieldType === "ENUM") {
    const enumField = contactField.fieldSpec.validatorParams.enum.find(
      ({ value: enumValue }) => value && value.value === enumValue
    );
    return enumField ? enumField.display : _isObject(value) ? value.value : value;
  }
  return value;
};

export const convertIntegrationAction = (integrationAction) => {
  const { code, description, payload, url } = integrationAction;

  return {
    code,
    description,
    payload: JSON.stringify(payload),
    url,
  };
};

export const removeSpacesAndLineBreaks = /\r?\n|\r| /gi;
