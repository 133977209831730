import _isNumber from "lodash/isNumber";
import _isString from "lodash/isString";

import { unit } from "./breakpoints";

export enum Spaces {
  NONE = "none",
  XXS = "xxs",
  XS = "xs",
  S = "s",
  SM = "sm",
  M = "m",
  ML = "ml",
  L = "l",
  XL = "xl",
  XXL = "xxl",
}

export enum Operators {
  "PLUS" = "+",
  "MINUS" = "-",
  "SUBTRACT" = "/",
  "ADD" = "*",
}

type TSpaces = {
  [Spaces.NONE]: number;
  [Spaces.XXS]: number;
  [Spaces.XS]: number;
  [Spaces.S]: number;
  [Spaces.M]: number;
  [Spaces.ML]: number;
  [Spaces.L]: number;
  [Spaces.XL]: number;
  [Spaces.XXL]: number;
};

export const spaces: TSpaces = {
  none: 0,
  xxs: 2,
  xs: 4,
  s: 8,
  m: 16,
  ml: 24,
  l: 32,
  xl: 64,
  xxl: 96,
};

type TSpacesKeys = `${Spaces}` | `calc(${string})`;

export type TSpacing =
  | TSpacesKeys
  | {
      calc?: TSpacesKeys[];
      top?: TSpacesKeys;
      right?: TSpacesKeys;
      bottom?: TSpacesKeys;
      left?: TSpacesKeys;
      vertical?: TSpacesKeys;
      horizontal?: TSpacesKeys;
    };

export const getSpacing = (spacing?: TSpacing): string | undefined => {
  if (!spacing) return "";
  if (_isString(spacing)) {
    const checkedUnit = _isString(spacing) ? (spacing.includes("calc") ? "" : unit) : unit;
    return `${_isNumber(spaces[spacing]) ? spaces[spacing] : spacing}${checkedUnit}`;
  }

  const zero = `0${unit}`;
  return `${getSpacing(spacing.vertical || spacing.top) || `${zero}`} ${
    getSpacing(spacing.horizontal || spacing.right) || `${zero}`
  } ${getSpacing(spacing.vertical || spacing.bottom) || `${zero}`} ${
    getSpacing(spacing.horizontal || spacing.left) || `${zero}`
  }`;
};

export type TCalcSpacing = TSpacesKeys[];

export const calcSpacing = (calc: TCalcSpacing, operator: `${Operators}` = Operators.PLUS): TSpacesKeys =>
  `calc(${calc.map(getSpacing).join(` ${operator} `)})`;
