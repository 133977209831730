/* tslint:disable */

/* eslint-disable */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as Types from "../../generated/types";
import { OperatorAvailabilityFragment } from "./fragments.generated";
import { OperatorAvailabilityFragmentDoc } from "./fragments.generated";

const defaultOptions = {} as const;
export type GetOperatorAvailabilityQueryVariables = Types.Exact<{
  accountUuid: Types.Scalars["ID"];
  numberUuid: Types.Scalars["ID"];
}>;

export type GetOperatorAvailabilityQuery = {
  __typename: "RootQueryType";
  getOperatorAvailability: ({ __typename: "OperatorAvailability" } & OperatorAvailabilityFragment) | null;
};

export const GetOperatorAvailabilityDocument = gql`
  query getOperatorAvailability($accountUuid: ID!, $numberUuid: ID!) {
    getOperatorAvailability(numberUuid: $numberUuid, accountUuid: $accountUuid) {
      ...OperatorAvailabilityFragment
    }
  }
  ${OperatorAvailabilityFragmentDoc}
`;

/**
 * __useGetOperatorAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetOperatorAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperatorAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperatorAvailabilityQuery({
 *   variables: {
 *      accountUuid: // value for 'accountUuid'
 *      numberUuid: // value for 'numberUuid'
 *   },
 * });
 */
export function useGetOperatorAvailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<GetOperatorAvailabilityQuery, GetOperatorAvailabilityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOperatorAvailabilityQuery, GetOperatorAvailabilityQueryVariables>(
    GetOperatorAvailabilityDocument,
    options
  );
}
export function useGetOperatorAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOperatorAvailabilityQuery, GetOperatorAvailabilityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOperatorAvailabilityQuery, GetOperatorAvailabilityQueryVariables>(
    GetOperatorAvailabilityDocument,
    options
  );
}
export type GetOperatorAvailabilityQueryHookResult = ReturnType<typeof useGetOperatorAvailabilityQuery>;
export type GetOperatorAvailabilityLazyQueryHookResult = ReturnType<typeof useGetOperatorAvailabilityLazyQuery>;
export type GetOperatorAvailabilityQueryResult = Apollo.QueryResult<
  GetOperatorAvailabilityQuery,
  GetOperatorAvailabilityQueryVariables
>;
