/* tslint:disable */

/* eslint-disable */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as Types from "../../generated/types";
import { LeaseMinFragment } from "./fragments.generated";
import { LeaseMinFragmentDoc } from "./fragments.generated";

const defaultOptions = {} as const;
export type RequestLeaseMutationVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  chatUuid: Types.Scalars["ID"];
  messageUuid: Types.Scalars["ID"];
  eventMessage: Types.Scalars["String"];
  ttl?: Types.InputMaybe<Types.Scalars["Int"]>;
}>;

export type RequestLeaseMutation = {
  __typename: "RootMutationType";
  requestLease:
    | ({ __typename: "Lease"; childLease: ({ __typename: "Lease" } & LeaseMinFragment) | null } & LeaseMinFragment)
    | null;
};

export type OverrideLeaseMutationVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  chatUuid: Types.Scalars["ID"];
  messageUuid: Types.Scalars["ID"];
  eventMessage: Types.Scalars["String"];
}>;

export type OverrideLeaseMutation = {
  __typename: "RootMutationType";
  overrideLease:
    | ({ __typename: "Lease"; childLease: ({ __typename: "Lease" } & LeaseMinFragment) | null } & LeaseMinFragment)
    | null;
};

export type ReleaseLeaseMutationVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  chatUuid: Types.Scalars["ID"];
}>;

export type ReleaseLeaseMutation = {
  __typename: "RootMutationType";
  releaseLease: ({ __typename: "Lease" } & LeaseMinFragment) | null;
};

export const RequestLeaseDocument = gql`
  mutation requestLease($numberUuid: ID!, $chatUuid: ID!, $messageUuid: ID!, $eventMessage: String!, $ttl: Int) {
    requestLease(
      numberUuid: $numberUuid
      chatUuid: $chatUuid
      messageUuid: $messageUuid
      eventMessage: $eventMessage
      ttl: $ttl
    ) {
      ...LeaseMin
      childLease {
        ...LeaseMin
      }
    }
  }
  ${LeaseMinFragmentDoc}
`;
export type RequestLeaseMutationFn = Apollo.MutationFunction<RequestLeaseMutation, RequestLeaseMutationVariables>;

/**
 * __useRequestLeaseMutation__
 *
 * To run a mutation, you first call `useRequestLeaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestLeaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestLeaseMutation, { data, loading, error }] = useRequestLeaseMutation({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      chatUuid: // value for 'chatUuid'
 *      messageUuid: // value for 'messageUuid'
 *      eventMessage: // value for 'eventMessage'
 *      ttl: // value for 'ttl'
 *   },
 * });
 */
export function useRequestLeaseMutation(
  baseOptions?: Apollo.MutationHookOptions<RequestLeaseMutation, RequestLeaseMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestLeaseMutation, RequestLeaseMutationVariables>(RequestLeaseDocument, options);
}
export type RequestLeaseMutationHookResult = ReturnType<typeof useRequestLeaseMutation>;
export type RequestLeaseMutationResult = Apollo.MutationResult<RequestLeaseMutation>;
export type RequestLeaseMutationOptions = Apollo.BaseMutationOptions<
  RequestLeaseMutation,
  RequestLeaseMutationVariables
>;
export const OverrideLeaseDocument = gql`
  mutation overrideLease($numberUuid: ID!, $chatUuid: ID!, $messageUuid: ID!, $eventMessage: String!) {
    overrideLease(
      numberUuid: $numberUuid
      chatUuid: $chatUuid
      messageUuid: $messageUuid
      eventMessage: $eventMessage
    ) {
      ...LeaseMin
      childLease {
        ...LeaseMin
      }
    }
  }
  ${LeaseMinFragmentDoc}
`;
export type OverrideLeaseMutationFn = Apollo.MutationFunction<OverrideLeaseMutation, OverrideLeaseMutationVariables>;

/**
 * __useOverrideLeaseMutation__
 *
 * To run a mutation, you first call `useOverrideLeaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOverrideLeaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overrideLeaseMutation, { data, loading, error }] = useOverrideLeaseMutation({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      chatUuid: // value for 'chatUuid'
 *      messageUuid: // value for 'messageUuid'
 *      eventMessage: // value for 'eventMessage'
 *   },
 * });
 */
export function useOverrideLeaseMutation(
  baseOptions?: Apollo.MutationHookOptions<OverrideLeaseMutation, OverrideLeaseMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OverrideLeaseMutation, OverrideLeaseMutationVariables>(OverrideLeaseDocument, options);
}
export type OverrideLeaseMutationHookResult = ReturnType<typeof useOverrideLeaseMutation>;
export type OverrideLeaseMutationResult = Apollo.MutationResult<OverrideLeaseMutation>;
export type OverrideLeaseMutationOptions = Apollo.BaseMutationOptions<
  OverrideLeaseMutation,
  OverrideLeaseMutationVariables
>;
export const ReleaseLeaseDocument = gql`
  mutation releaseLease($numberUuid: ID!, $chatUuid: ID!) {
    releaseLease(numberUuid: $numberUuid, chatUuid: $chatUuid) {
      ...LeaseMin
    }
  }
  ${LeaseMinFragmentDoc}
`;
export type ReleaseLeaseMutationFn = Apollo.MutationFunction<ReleaseLeaseMutation, ReleaseLeaseMutationVariables>;

/**
 * __useReleaseLeaseMutation__
 *
 * To run a mutation, you first call `useReleaseLeaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseLeaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseLeaseMutation, { data, loading, error }] = useReleaseLeaseMutation({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      chatUuid: // value for 'chatUuid'
 *   },
 * });
 */
export function useReleaseLeaseMutation(
  baseOptions?: Apollo.MutationHookOptions<ReleaseLeaseMutation, ReleaseLeaseMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReleaseLeaseMutation, ReleaseLeaseMutationVariables>(ReleaseLeaseDocument, options);
}
export type ReleaseLeaseMutationHookResult = ReturnType<typeof useReleaseLeaseMutation>;
export type ReleaseLeaseMutationResult = Apollo.MutationResult<ReleaseLeaseMutation>;
export type ReleaseLeaseMutationOptions = Apollo.BaseMutationOptions<
  ReleaseLeaseMutation,
  ReleaseLeaseMutationVariables
>;
