import gql from "graphql-tag";

import { boundary, required } from "api/boundary";

import { POOL_NUMBER_DETAILS } from "./fragments";

export const PULL_NUMBERS = gql`
  query availablePoolNumbers {
    availablePoolNumbers {
      ...PoolNumberDetails
    }
  }
  ${POOL_NUMBER_DETAILS}
`;

export const AVAILABLE_COUNTRIES_FOR_SUBSCRIPTION_ADDRESS = gql`
  query availableCountriesForSubscriptionAddress {
    availableCountriesForSubscriptionAddress
  }
`;

export const ONBOARDING_CONFIGURATION = {
  query: gql`
    query onboardingConfiguration {
      onboardingConfiguration {
        bspFacebookAppId
        bspBusinessLoginConfigurationId
      }
    }
  `,
  parse: boundary({
    bspFacebookAppId: required(),
    bspBusinessLoginConfigurationId: required(),
  }),
};

export const CHECK_WABA = gql`
  query checkWaba($onboardingApplicationUuid: ID!, $organisationUuid: ID!, $embeddedSignupUserCode: String!) {
    wabaIsAvailableForEmbeddedSignup(
      onboardingApplicationUuid: $onboardingApplicationUuid
      organisationUuid: $organisationUuid
      embeddedSignupUserCode: $embeddedSignupUserCode
    ) {
      isAvailable
      wabaGraphId
    }
  }
`;
