import gql from "graphql-tag";

import { OPERATOR_AVAILABILITY_FRAGMENT } from "./fragments";

export const SET_OPERATOR_AS_AVAILABLE = gql`
  mutation setOperatorAsAvailable($accountUuid: ID!, $numberUuid: ID!) {
    setOperatorAsAvailable(numberUuid: $numberUuid, accountUuid: $accountUuid) {
      count
      operatorAvailability {
        ...OperatorAvailabilityFragment
      }
    }
  }
  ${OPERATOR_AVAILABILITY_FRAGMENT}
`;

export const SET_OPERATOR_AS_UNAVAILABLE = gql`
  mutation setOperatorAsUnavailable($accountUuid: ID!, $numberUuid: ID!, $releaseChats: Boolean!) {
    setOperatorAsUnavailable(numberUuid: $numberUuid, accountUuid: $accountUuid, releaseChats: $releaseChats) {
      ...OperatorAvailabilityFragment
    }
  }

  ${OPERATOR_AVAILABILITY_FRAGMENT}
`;
