import gql from "graphql-tag";

import { AUTOMATOR_DETAILS_FRAGMENT } from "./fragments";

export const CREATE_AUTOMATOR = gql`
  mutation createAutomator($numberUuid: ID!, $name: String!, $config: AutomatorConfigIo!) {
    createAutomator(numberUuid: $numberUuid, name: $name, config: $config) {
      ...AutomatorDetails
    }
  }

  ${AUTOMATOR_DETAILS_FRAGMENT}
`;

export const UPDATE_AUTOMATOR = gql`
  mutation updateAutomator(
    $numberUuid: ID!
    $automatorUuid: ID!
    $name: String!
    $enabled: Boolean!
    $config: AutomatorConfigIo!
  ) {
    updateAutomator(
      numberUuid: $numberUuid
      automatorUuid: $automatorUuid
      name: $name
      enabled: $enabled
      config: $config
    ) {
      ...AutomatorDetails
    }
  }

  ${AUTOMATOR_DETAILS_FRAGMENT}
`;

export const DELETE_AUTOMATOR = gql`
  mutation deleteAutomator($numberUuid: ID!, $automatorUuid: ID!) {
    deleteAutomator(numberUuid: $numberUuid, automatorUuid: $automatorUuid) {
      uuid
    }
  }
`;
