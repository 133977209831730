import { pxify } from "./utils";

export const rounding = {
  xxs: 2,
  xs: 4,
  s: 8,
  sm: 12,
  m: 16,
  ml: 24,
  l: 32,
  xl: 64,
  xxl: 96,
};

export const roundingPx = pxify(rounding);
