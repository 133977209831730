import React, { createContext, useState } from "react";

export type ContentAction = {
  name: string;
  uuid: string;
};

export interface SelectionContext {
  selectedItems?: string[];
  setSelectedItems: (value: string[] | undefined) => void;
  selectedContent?: ContentAction;
  setSelectedContent: (value?: ContentAction) => void;
}

export const SelectionContext = createContext<SelectionContext>({
  setSelectedItems: () => {},
  setSelectedContent: () => {},
});

interface SelectionProviderProps {
  children: React.ReactNode;
}

export const SelectionProvider = ({ children }: SelectionProviderProps) => {
  const [selectedItems, setSelectedItems] = useState<string[]>();
  const [selectedContent, setSelectedContent] = useState<ContentAction>();

  return (
    <SelectionContext.Provider
      value={{
        selectedItems,
        setSelectedItems,
        selectedContent,
        setSelectedContent,
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};
