/* tslint:disable */

/* eslint-disable */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as Types from "../../generated/types";
import { ChatDetailsFragment } from "./fragments.generated";
import { ChatDetailsFragmentDoc } from "./fragments.generated";

const defaultOptions = {} as const;
export type OnChatChangedSubscriptionVariables = Types.Exact<{
  chatUuid: Types.Scalars["ID"];
}>;

export type OnChatChangedSubscription = {
  __typename: "RootSubscriptionType";
  onChatChanged: ({ __typename: "Chat" } & ChatDetailsFragment) | null;
};

export const OnChatChangedDocument = gql`
  subscription onChatChanged($chatUuid: ID!) {
    onChatChanged(chatUuid: $chatUuid) {
      ...ChatDetails
    }
  }
  ${ChatDetailsFragmentDoc}
`;

/**
 * __useOnChatChangedSubscription__
 *
 * To run a query within a React component, call `useOnChatChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnChatChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnChatChangedSubscription({
 *   variables: {
 *      chatUuid: // value for 'chatUuid'
 *   },
 * });
 */
export function useOnChatChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<OnChatChangedSubscription, OnChatChangedSubscriptionVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnChatChangedSubscription, OnChatChangedSubscriptionVariables>(
    OnChatChangedDocument,
    options
  );
}
export type OnChatChangedSubscriptionHookResult = ReturnType<typeof useOnChatChangedSubscription>;
export type OnChatChangedSubscriptionResult = Apollo.SubscriptionResult<OnChatChangedSubscription>;
