import { BackendType, OrganisationSignupType, OrganisationTokenType } from "graphql/generated/types";

import { INumberType } from ".";

export interface IOrganizationNumber {
  fromAddr: string;
  profilePicture?: null | string;
  uuid: string;
  vname: string;
  backendType: BackendType;
  numberType: INumberType;
}

export interface INumberOrganization {
  uuid: string;
  numbers: IOrganizationNumber[];
}
export interface IAccount {
  email: string;
  firstName: string;
  lastName: string;
  profilePicture: string;
  uuid: string;
}

export interface IOrganizationAccount {
  isAdmin: boolean;
  account: IAccount[];
}

export interface IOrganization extends INumberOrganization {
  myAccount: IAccount;
  signupType: OrganisationSignupType;
  name: string;
  organisationAccounts: IOrganizationAccount[];
  type: "GROUP" | "INDIVIDUAL";
  uuid: string;
}

export const OrganisationTokenNameType: Record<OrganisationTokenType, string> = {
  FACEBOOK: "Facebook",
  META_AI: "Meta AI",
  OPENAI: "OpenAI",
};
