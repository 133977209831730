import React from "react";

import { TDefaultIconProps } from "./types";

export const ArrowDropUpIcon = ({ fill = "#000", ...props }: TDefaultIconProps) => (
  <svg width={8} height={6} viewBox="0 0 8 6" fill="none" {...props}>
    <path
      d="M.706 3.587l2.59-2.59a.996.996 0 011.41 0l2.59 2.59c.63.63.18 1.71-.71 1.71h-5.18c-.89 0-1.33-1.08-.7-1.71z"
      fill={fill}
      fillOpacity={0.54}
    />
  </svg>
);

export const ArrowDropDownIcon = ({ fill = "#000", ...props }: TDefaultIconProps) => (
  <svg width={8} height={6} viewBox="0 0 8 6" fill="none" {...props}>
    <path
      d="M.706 2.414l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71h-5.18c-.89 0-1.33 1.08-.7 1.71z"
      fill={fill}
      fillOpacity={0.54}
    />
  </svg>
);

export const ArrowLeftIcon = ({ fill = "#535C5F", ...props }: TDefaultIconProps) => (
  <svg width={7} height={12} viewBox="0 0 7 12" fill="none" {...props}>
    <path
      d="M2.859 6.359l3.82-3.821a1.061 1.061 0 00-1.5-1.5L.567 5.652a.999.999 0 000 1.414L5.18 11.68a1.061 1.061 0 001.5-1.5L2.859 6.359z"
      fill={fill}
    />
  </svg>
);

export const ArrowLeftNewIcon = ({ stroke = "black", size = 20, ...props }: TDefaultIconProps) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.8327 9.99996H4.16602M4.16602 9.99996L9.99935 15.8333M4.16602 9.99996L9.99935 4.16663"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ArrowRightIcon = ({ fill = "#000", ...props }: TDefaultIconProps) => (
  <svg width={8} height={6} viewBox="0 0 8 6" fill="none" {...props}>
    <path
      d="M.706 3.587l2.59-2.59a.996.996 0 011.41 0l2.59 2.59c.63.63.18 1.71-.71 1.71h-5.18c-.89 0-1.33-1.08-.7-1.71z"
      fill={fill}
      fillOpacity={0.54}
    />
  </svg>
);
