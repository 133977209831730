import gql from "graphql-tag";

import { boundary, required } from "api/boundary";

import { INITIAL_NUMBER_FRAGMENT_ } from "../number";
import { ONBOARDING_APPLICATION_FRAGMENT, ORGANISATION_DETAILS_FRAGMENT } from "../organisation";

export const CREATE_MANUAL_PADDLE_SUBSCRIPTION = gql`
  mutation createManualPaddleSubscription(
    $addressCountry: String!
    $addressFirstLine: String!
    $addressPostalCode: String!
    $addressRegion: String!
    $customerEmail: String!
    $customerName: String!
    $numberUuid: ID!
    $subscriptionPlan: SubscriptionPlan!
  ) {
    createManualPaddleSubscription(
      addressCountry: $addressCountry
      addressFirstLine: $addressFirstLine
      addressPostalCode: $addressPostalCode
      addressRegion: $addressRegion
      customerEmail: $customerEmail
      customerName: $customerName
      numberUuid: $numberUuid
      subscriptionPlan: $subscriptionPlan
    )
  }
`;

export const ACCEPT_ELUA = {
  query: gql`
    mutation acceptEula($organisationUuid: ID!) {
      acceptEula(organisationUuid: $organisationUuid) {
        uuid
        activeOnboardingApplication {
          uuid
          status
          eulaAccepted
        }
        numbers {
          uuid
          vname
          fromAddr
          profilePicture
          numberType
          backendType
        }
      }
    }
  `,
  parse: boundary({
    uuid: required(),
    activeOnboardingApplication: boundary({
      eulaAccepted: required(),
    }),
    numbers: {
      uuid: required(),
    },
  }),
};

export const REQUEST_PHONE_NUMBER = gql`
  mutation requestPhoneNumber($organisationUuid: ID!, $numberRequest: NumberRequest!) {
    requestPhoneNumber(organisationUuid: $organisationUuid, numberRequest: $numberRequest) {
      uuid
      activeOnboardingApplication {
        uuid
        status
        phoneNumber
        phoneNumberProvider
        poolNumber {
          uuid
          fbGraphId
          phoneNumber
        }
      }
    }
  }
`;

export const ONBOARD_EMBEDDED_SIGNUP_NUMBER = {
  query: gql`
    mutation onboardEmbeddedSignupNumber($onboardingApplicationUuid: ID!, $organisationUuid: ID!) {
      onboardEmbeddedSignupNumber(
        onboardingApplicationUuid: $onboardingApplicationUuid
        organisationUuid: $organisationUuid
      ) {
        ...InitialNumber_
      }
    }

    ${INITIAL_NUMBER_FRAGMENT_.query}
  `,
  parse: boundary({
    onboardEmbeddedSignupNumber: INITIAL_NUMBER_FRAGMENT_.parse,
  }),
};

export const SUBMIT_SIGNUP_ORGANISATION_FORM = {
  query: gql`
    mutation submitSignUpOrganisationForm(
      $communicationOptIn: Boolean!
      $firstName: String!
      $impactArea: ImpactSectorType!
      $lastName: String!
      $organisationCountry: String!
      $organisationName: String!
      $organisationType: OrganisationEntityType!
      $organisationUuid: ID!
      $signupReferral: String
      $website: String!
      $workEmail: String!
    ) {
      submitSignUpOrganisationForm(
        communicationOptIn: $communicationOptIn
        firstName: $firstName
        impactArea: $impactArea
        lastName: $lastName
        organisationCountry: $organisationCountry
        organisationName: $organisationName
        organisationType: $organisationType
        organisationUuid: $organisationUuid
        signupReferral: $signupReferral
        website: $website
        workEmail: $workEmail
      ) {
        ...OrganisationDetails
      }
    }

    ${ORGANISATION_DETAILS_FRAGMENT.query}
  `,
  parse: boundary({
    submitSignUpOrganisationForm: ORGANISATION_DETAILS_FRAGMENT.parse,
  }),
};

export const CREATE_ORG_AND_SUBMIT_SIGNUP_FORM = {
  query: gql`
    mutation createOrgAndSubmitSignUpForm(
      $communicationOptIn: Boolean!
      $firstName: String!
      $impactArea: ImpactSectorType!
      $lastName: String!
      $organisationCountry: String!
      $organisationName: String!
      $organisationType: OrganisationEntityType!
      $signupReferral: String
      $website: String!
      $workEmail: String!
    ) {
      createOrgAndSubmitSignUpForm(
        communicationOptIn: $communicationOptIn
        firstName: $firstName
        impactArea: $impactArea
        lastName: $lastName
        organisationCountry: $organisationCountry
        organisationName: $organisationName
        organisationType: $organisationType
        signupReferral: $signupReferral
        website: $website
        workEmail: $workEmail
      ) {
        ...OrganisationDetails
      }
    }

    ${ORGANISATION_DETAILS_FRAGMENT.query}
  `,
  parse: boundary({
    submitSignUpOrganisationForm: ORGANISATION_DETAILS_FRAGMENT.parse,
  }),
};

export const SUBMIT_USE_CASE_DESCRIPTIONS = gql`
  mutation submitUseCaseDescription($organisationUuid: ID!, $useCaseDescription: String) {
    submitUseCaseDescription(organisationUuid: $organisationUuid, useCaseDescription: $useCaseDescription) {
      uuid
    }
  }
`;

export const NEW_ENTERPRISE_CUSTOMER_EMAIL = gql`
  mutation newEnterpriseCustomerEmail($organisationUuid: ID!) {
    newEnterpriseCustomerEmail(organisationUuid: $organisationUuid)
  }
`;

export const START_ONBOARDING_APPLICATION_FOR_ADDITIONAL_NUMBER = gql`
  mutation startOnboardingApplicationForAdditionalNumber($organisationUuid: ID!) {
    startOnboardingApplicationForAdditionalNumber(organisationUuid: $organisationUuid) {
      ...onboardingApplication
    }
  }

  ${ONBOARDING_APPLICATION_FRAGMENT}
`;

export const CANCEL_ACTIVE_ONBOARDING_APPLICATION = gql`
  mutation cancelActiveOnboardingApplication($organisationUuid: ID!) {
    cancelActiveOnboardingApplication(organisationUuid: $organisationUuid) {
      ...onboardingApplication
    }
  }

  ${ONBOARDING_APPLICATION_FRAGMENT}
`;
