import gql from "graphql-tag";

import { boundary } from "api/boundary";

import { SETTINGS_NUMBER_FRAGMENT } from "./fragments";

export const UPDATE_NUMBER = {
  query: gql`
    mutation updateNumber($numberUuid: ID!, $number: NumberIo!) {
      updateNumber(numberUuid: $numberUuid, number: $number) {
        ...SettingsNumber
      }
    }

    ${SETTINGS_NUMBER_FRAGMENT.query}
  `,
  parse: boundary({
    updateNumber: SETTINGS_NUMBER_FRAGMENT.parse,
  }),
};

export const UPDATE_NOTIFICATIONS_EMAIL = gql`
  mutation updateNotificationsEmail($numberUuid: ID!, $number: NumberIo!) {
    updateNumber(numberUuid: $numberUuid, number: $number) {
      notificationsEmail
    }
  }
`;
