import gql from "graphql-tag";

export const CATEGORY_BILLING_STATS_FIELDS_FRAGMENT = gql`
  fragment CategoryBillingStatsFields on CategoryBillingStats {
    count
    spend
    percentage
    currency
  }
`;

export const BILLING_STATS_FIELDS_FRAGMENT = gql`
  fragment BillingStatsFields on BillingStats {
    total {
      ...CategoryBillingStatsFields
    }
    referralConversion {
      ...CategoryBillingStatsFields
    }
    authentication {
      ...CategoryBillingStatsFields
    }
    marketing {
      ...CategoryBillingStatsFields
    }
    utility {
      ...CategoryBillingStatsFields
    }
    service {
      ...CategoryBillingStatsFields
    }
  }

  ${CATEGORY_BILLING_STATS_FIELDS_FRAGMENT}
`;

export const BILLING_DETAILS_FRAGMENT = gql`
  fragment BillingDetails on BillingOverview {
    creditsAvailable
    highestDailySpendDate
    lastDepositAmount
    lastDepositDate
    pendingSpend
    pendingDeposit
    committedDeposit
    committedSpend
  }
`;

export const BILLING_CHART_DETAILS_FRAGMENT = gql`
  fragment BillingChartDetails on BillingOverview {
    currentMonthStats {
      ...BillingStatsFields
    }
    lastMonthStats {
      ...BillingStatsFields
    }
  }

  ${BILLING_STATS_FIELDS_FRAGMENT}
`;

export const BILLING_COUNTRY_DETAILS_FRAGMENT = gql`
  fragment BillingCountryDetails on MonthlyCountrySpends {
    spendings {
      country
      countryCode
      total {
        ...CategoryBillingStatsFields
      }
      authentication {
        ...CategoryBillingStatsFields
      }
      marketing {
        ...CategoryBillingStatsFields
      }
      utility {
        ...CategoryBillingStatsFields
      }
      service {
        ...CategoryBillingStatsFields
      }
      referralConversion {
        ...CategoryBillingStatsFields
      }
    }
  }

  ${CATEGORY_BILLING_STATS_FIELDS_FRAGMENT}
`;
export const BILLING_NUMBER_DETAILS_FRAGMENT = gql`
  fragment BillingNumberDetails on MonthlyNumberSpends {
    spendings {
      country
      number
      title
      profilePicture
      total {
        ...CategoryBillingStatsFields
      }
      authentication {
        ...CategoryBillingStatsFields
      }
      marketing {
        ...CategoryBillingStatsFields
      }
      utility {
        ...CategoryBillingStatsFields
      }
      service {
        ...CategoryBillingStatsFields
      }
      referralConversion {
        ...CategoryBillingStatsFields
      }
    }
  }

  ${CATEGORY_BILLING_STATS_FIELDS_FRAGMENT}
`;
