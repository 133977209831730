/* tslint:disable */

/* eslint-disable */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as Types from "../../generated/types";
import { MessageDetailsFragment } from "./fragments.generated";
import { MessageDetailsWithChatMediumFragment } from "./fragments.generated";
import { MessageDetailsFragmentDoc } from "./fragments.generated";
import { MessageDetailsWithChatMediumFragmentDoc } from "./fragments.generated";

const defaultOptions = {} as const;
export type GetMessagesQueryVariables = Types.Exact<{
  chatUuid: Types.Scalars["ID"];
  limit?: Types.InputMaybe<Types.Scalars["Int"]>;
  orderBy?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type GetMessagesQuery = {
  __typename: "RootQueryType";
  messages: {
    __typename: "ListMessagesResults";
    canLoadMore: boolean;
    results: Array<{ __typename: "Message" } & MessageDetailsFragment>;
  } | null;
};

export type GetMessageSearchResultsQueryVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  query: Types.SearchQueryIo;
  page?: Types.InputMaybe<Types.Scalars["Int"]>;
  pageSize?: Types.InputMaybe<Types.Scalars["Int"]>;
  sort?: Types.InputMaybe<Types.SortType>;
}>;

export type GetMessageSearchResultsQuery = {
  __typename: "RootQueryType";
  messageSearchResults: {
    __typename: "MessageSearchResults";
    count: number | null;
    results: Array<({ __typename: "Message" } & MessageDetailsWithChatMediumFragment) | null> | null;
  } | null;
};

export type ChatNotesWithMentionsForTaggedAccountQueryVariables = Types.Exact<{
  isCleared?: Types.InputMaybe<Types.Scalars["Boolean"]>;
  limit?: Types.InputMaybe<Types.Scalars["Int"]>;
  numberUuid: Types.Scalars["ID"];
  offset?: Types.InputMaybe<Types.Scalars["Int"]>;
  orderBy?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type ChatNotesWithMentionsForTaggedAccountQuery = {
  __typename: "RootQueryType";
  chatNotesWithMentionsForTaggedAccount: {
    __typename: "ChatNoteSearchResults";
    count: number | null;
    results: Array<({ __typename: "Message" } & MessageDetailsWithChatMediumFragment) | null> | null;
  } | null;
};

export const GetMessagesDocument = gql`
  query getMessages($chatUuid: ID!, $limit: Int, $orderBy: String) {
    messages(chatUuid: $chatUuid, limit: $limit, orderBy: $orderBy) {
      canLoadMore
      results {
        ...MessageDetails
      }
    }
  }
  ${MessageDetailsFragmentDoc}
`;

/**
 * __useGetMessagesQuery__
 *
 * To run a query within a React component, call `useGetMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessagesQuery({
 *   variables: {
 *      chatUuid: // value for 'chatUuid'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetMessagesQuery(baseOptions: Apollo.QueryHookOptions<GetMessagesQuery, GetMessagesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMessagesQuery, GetMessagesQueryVariables>(GetMessagesDocument, options);
}
export function useGetMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMessagesQuery, GetMessagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMessagesQuery, GetMessagesQueryVariables>(GetMessagesDocument, options);
}
export type GetMessagesQueryHookResult = ReturnType<typeof useGetMessagesQuery>;
export type GetMessagesLazyQueryHookResult = ReturnType<typeof useGetMessagesLazyQuery>;
export type GetMessagesQueryResult = Apollo.QueryResult<GetMessagesQuery, GetMessagesQueryVariables>;
export const GetMessageSearchResultsDocument = gql`
  query getMessageSearchResults(
    $numberUuid: ID!
    $query: SearchQueryIo!
    $page: Int
    $pageSize: Int
    $sort: SortType = DESC
  ) {
    messageSearchResults(numberUuid: $numberUuid, query: $query, page: $page, pageSize: $pageSize, sort: $sort) {
      count
      results {
        ...MessageDetailsWithChatMedium
      }
    }
  }
  ${MessageDetailsWithChatMediumFragmentDoc}
`;

/**
 * __useGetMessageSearchResultsQuery__
 *
 * To run a query within a React component, call `useGetMessageSearchResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageSearchResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageSearchResultsQuery({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetMessageSearchResultsQuery(
  baseOptions: Apollo.QueryHookOptions<GetMessageSearchResultsQuery, GetMessageSearchResultsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMessageSearchResultsQuery, GetMessageSearchResultsQueryVariables>(
    GetMessageSearchResultsDocument,
    options
  );
}
export function useGetMessageSearchResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMessageSearchResultsQuery, GetMessageSearchResultsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMessageSearchResultsQuery, GetMessageSearchResultsQueryVariables>(
    GetMessageSearchResultsDocument,
    options
  );
}
export type GetMessageSearchResultsQueryHookResult = ReturnType<typeof useGetMessageSearchResultsQuery>;
export type GetMessageSearchResultsLazyQueryHookResult = ReturnType<typeof useGetMessageSearchResultsLazyQuery>;
export type GetMessageSearchResultsQueryResult = Apollo.QueryResult<
  GetMessageSearchResultsQuery,
  GetMessageSearchResultsQueryVariables
>;
export const ChatNotesWithMentionsForTaggedAccountDocument = gql`
  query chatNotesWithMentionsForTaggedAccount(
    $isCleared: Boolean
    $limit: Int
    $numberUuid: ID!
    $offset: Int
    $orderBy: String
  ) {
    chatNotesWithMentionsForTaggedAccount(
      isCleared: $isCleared
      limit: $limit
      numberUuid: $numberUuid
      offset: $offset
      orderBy: $orderBy
    ) {
      count
      results {
        ...MessageDetailsWithChatMedium
      }
    }
  }
  ${MessageDetailsWithChatMediumFragmentDoc}
`;

/**
 * __useChatNotesWithMentionsForTaggedAccountQuery__
 *
 * To run a query within a React component, call `useChatNotesWithMentionsForTaggedAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatNotesWithMentionsForTaggedAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatNotesWithMentionsForTaggedAccountQuery({
 *   variables: {
 *      isCleared: // value for 'isCleared'
 *      limit: // value for 'limit'
 *      numberUuid: // value for 'numberUuid'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useChatNotesWithMentionsForTaggedAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChatNotesWithMentionsForTaggedAccountQuery,
    ChatNotesWithMentionsForTaggedAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ChatNotesWithMentionsForTaggedAccountQuery,
    ChatNotesWithMentionsForTaggedAccountQueryVariables
  >(ChatNotesWithMentionsForTaggedAccountDocument, options);
}
export function useChatNotesWithMentionsForTaggedAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChatNotesWithMentionsForTaggedAccountQuery,
    ChatNotesWithMentionsForTaggedAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ChatNotesWithMentionsForTaggedAccountQuery,
    ChatNotesWithMentionsForTaggedAccountQueryVariables
  >(ChatNotesWithMentionsForTaggedAccountDocument, options);
}
export type ChatNotesWithMentionsForTaggedAccountQueryHookResult = ReturnType<
  typeof useChatNotesWithMentionsForTaggedAccountQuery
>;
export type ChatNotesWithMentionsForTaggedAccountLazyQueryHookResult = ReturnType<
  typeof useChatNotesWithMentionsForTaggedAccountLazyQuery
>;
export type ChatNotesWithMentionsForTaggedAccountQueryResult = Apollo.QueryResult<
  ChatNotesWithMentionsForTaggedAccountQuery,
  ChatNotesWithMentionsForTaggedAccountQueryVariables
>;
