import _isArray from "lodash/isArray";
import _isFunction from "lodash/isFunction";
import _isPlainObject from "lodash/isPlainObject";

export type Denullify<Def, Data> = Def extends ICallable
  ? Call<Def, Data>
  : Data extends Array<any>
    ? Data extends (infer Datum)[]
      ? NonNullable<Denullify<Def, NonNullable<Datum>>>[]
      : never
    : Data extends object
      ? {
          [Key in keyof Data]: Key extends keyof Def ? Denullify<Def[Key], Data[Key]> : Data[Key];
        }
      : NonNullable<Data>;

export type Call<Fn extends ICallable, Data> =
  Fn extends IRequired<infer Def>
    ? Clean<Def, Data>
    : Fn extends IBoundary<infer Def>
      ? Parse<Def, Data>
      : ReturnType<Fn>;

export type Clean<Def, Data> = NonNullable<Denullify<Def, Data>>;

export type Parse<Def, Data> = null | Denullify<Def, Data>;

export interface ICallable {
  (...args: any[]): any;
}

export type TMaybeBoundaryFn<Def> = IBoundary<Def> | ICallable;

export interface IRequired<Def> extends ICallable {
  __kind: "required";
  __def?: Def;
}

export interface IBoundary<Def> {
  <Data>(data: Data): Parse<Def, Data>;
  __kind: "boundary";
  __def?: Def;
}

class PropagateInstruction {}

type TResultCache = WeakMap<object, TResult<object>>;

type TResult<Data> = Data | PropagateInstruction | null;

const propagateInstruction = new PropagateInstruction();

const isRequired = (definition: unknown): boolean => {
  const kind = (definition as any)?.__kind ?? "required";
  return kind === "required";
};

const computeFunctionResults = <Definition extends (...args: any) => TResult<Data>, Data>(
  definition: Definition,
  data: Data
): TResult<Data> => {
  const result = definition(data);
  return result === null && isRequired(definition) ? propagateInstruction : result;
};

const computeListResults = <Definition, Datum extends {}>(
  definition: Definition,
  data: Datum[],
  cache: TResultCache
): TResult<Datum[]> => {
  const results: Datum[] = [];

  for (const datum of data) {
    const result = computeResults(definition, datum, cache);
    if (result !== propagateInstruction && result !== null) {
      results.push(result as Datum);
    }
  }

  return results;
};

const computeObjectResults = <Definition, Data extends {}>(
  definition: Definition,
  data: Data,
  cache: TResultCache
): TResult<Data> => {
  const results = {};

  for (const k of Object.keys(data)) {
    const datum = data[k];
    const datumDefinition = definition[k];

    if (datumDefinition) {
      const result = computeResults(datumDefinition, datum, cache);

      if (result === propagateInstruction) {
        return isRequired(definition) ? propagateInstruction : null;
      }

      results[k] = result;
    } else {
      results[k] = datum;
    }
  }

  return results;
};

const doComputeResults = <Definition, Data extends {}>(
  definition: Definition,
  data: Data,
  cache: TResultCache
): TResult<Data> => {
  if (data === null && isRequired(definition)) {
    return propagateInstruction;
  }

  if (_isFunction(definition)) {
    return computeFunctionResults(definition, data);
  }

  if (_isPlainObject(data)) {
    return computeObjectResults(definition, data, cache);
  }

  if (_isArray(data)) {
    return computeListResults(definition, data, cache);
  }

  return data;
};

const isCacheable = (data: unknown): data is object => Boolean(data && typeof data === "object");

const computeResults = <Definition, Data extends {}>(
  definition: Definition,
  data: Data,
  cache: TResultCache
): TResult<Data> => {
  if (isCacheable(data) && cache.has(data)) {
    return cache.get(data) as TResult<Data>;
  }

  const result = doComputeResults(definition, data, cache);

  if (isCacheable(data)) {
    cache.set(data, result);
  }

  return result;
};

export const boundary = <Definition>(definition: Definition): IBoundary<Definition> => {
  const cache: TResultCache = new WeakMap();

  const boundaryFn = (data) => {
    const result = computeResults(definition, data, cache);
    return result === propagateInstruction ? null : result;
  };

  Object.assign(boundaryFn, { __kind: "boundary" });
  return boundaryFn as IBoundary<Definition>;
};

export const required = <Def>(fn: TMaybeBoundaryFn<Def> | null = null): IRequired<Def> => {
  const requiredFn = fn ? (v) => fn(v) : (v) => v;

  Object.assign(requiredFn, {
    __kind: "required",
    fn,
  });

  return requiredFn as IRequired<Def>;
};
