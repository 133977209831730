import * as React from "react";

import { TDefaultIconProps } from "./types";

export const Search = ({ fill = "#7A8285", size = 16, ...props }: TDefaultIconProps) => (
  <svg width={size} height={size} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill={fill}
      d="M11.2 12.6a7 7 0 111.41-1.41l3.1 3.1a1 1 0 01-1.42 1.42l-3.1-3.1zm-.64-2.1A4.98 4.98 0 007 2a5 5 0 103.56 8.5z"
    />
  </svg>
);

export const SearchIcon = ({ stroke = "#73787B", size = 20, ...props }: TDefaultIconProps) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.5 17.5L12.5001 12.5M14.1667 8.33333C14.1667 11.555 11.555 14.1667 8.33333 14.1667C5.11167 14.1667 2.5 11.555 2.5 8.33333C2.5 5.11167 5.11167 2.5 8.33333 2.5C11.555 2.5 14.1667 5.11167 14.1667 8.33333Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
