import Icons from "@backlight-dev/turnio.design-icons/Icons";
import { IconsListType } from "@backlight-dev/turnio.design-icons/Icons/types";
import { AliasIconSizeType, colors, defaultTheme } from "ds/theme";
import { ColorsType } from "ds/types/colors";
import { SizeUnitType } from "ds/types/layout";
import React from "react";

type SizeType = `${AliasIconSizeType}` | `${SizeUnitType}`; // this is need for corret type render in Docs

export interface IconProps extends React.ComponentPropsWithoutRef<"svg"> {
  /**
  Name of the icon, all icons name you can find in icons studio
  */
  name: IconsListType;
  /**
  icon size
  */
  size?: SizeType;
  /**
  icon color
  */
  color?: ColorsType;
}

export const getIconSize = (size: SizeType) => {
  const value = defaultTheme.sizes[size] ?? `${size}`;
  return value;
};

const Icon = ({ color, size = "large", name, ...props }: IconProps) => {
  const ImportedIcon = Icons[name];

  return (
    <ImportedIcon
      color={color ? colors[color] || color : undefined}
      size={getIconSize(size)}
      style={{ flexShrink: 0 }}
      {...props}
    />
  );
};

Icon.displayName = "Icon";

export { Icon };
