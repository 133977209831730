import { useApolloClient } from "@apollo/client";
import { Banner } from "ds/Banner";
import { Box } from "ds/Box";
import { Button } from "ds/Button";
import { Text } from "ds/Typography";
import { Icon } from "ds/icons";
import { down } from "ds/theme";
import { Anchor } from "grommet";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useBreakpoint } from "styled-breakpoints/react-styled";

import { unpaidTransactionStatuses } from "containers/Settings/components/Subscription/Details/utils";
import { BillingTransactionState, SubscriptionState } from "graphql/generated/types";
import {
  PaymentNumberSubscriptionDetailsFragment,
  useGetNumberBillingSubscriptionQuery,
  useResumePausedPaddleSubscription,
} from "graphql/subscriptions";
import { useGlobalContext, useNavigation } from "hooks";
import { useModalContext } from "hooks/useModalContext";
import { usePaymentContext } from "hooks/usePaymentContext";
import { notify } from "lib/notification";
import { PaymentBannerStatus } from "utils/context/payment";
import { TurnSupport, getStorageSwitch, saveStorageSwitch } from "utils/index";

import { StyledActionButton, StyledIconContainer } from "./styles";
import { PAYMENT_BANNER_HEIGHT, PaymentBannerOptions, getBannerTitle, getPaymentBannerStatus } from "./utils";

export const isEnterprisePlan = (subscription?: PaymentNumberSubscriptionDetailsFragment | null) =>
  subscription?.billingTransactions?.some((transaction) =>
    transaction.priceName.some((name) => name.toLocaleLowerCase().includes("enterprise"))
  );

interface IPaymentBannerProps {
  sandbox?: boolean;
}

export const PaymentBanner = ({ sandbox = false }: IPaymentBannerProps) => {
  const { updateModal } = useModalContext();
  const { numberUuid, isAdmin } = useGlobalContext();
  const {
    state: paymentState,
    setPaymentState,
    setChargePastDueOnAutomatic,
    status,
    exemptedNumber,
  } = usePaymentContext();
  const { t } = useTranslation();
  const { navigateTo } = useNavigation<{ collectionUuid: string }>();
  const isMobile = useBreakpoint(down("md"));
  const hideBanner = getStorageSwitch(`hidePaymentBanner_${numberUuid}_${status?.status}`);
  const [isPollingSubscription, setIsPollingSubscription] = useState(false);
  const client = useApolloClient();

  const { data, startPolling, stopPolling } = useGetNumberBillingSubscriptionQuery({
    variables: {
      numberUuid,
    },
    skip: !numberUuid,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ number }) => {
      if (number) {
        const subscription = number.subscription;

        /**
         * context(Pringels, 2024-06-30):
         * The "resume subscription" query does not immediately update the subscription DB model.
         * We hit the Paddle API directly which then triggers a webhook asyncronously.
         * Until we have a GQL subscription for subscriptions we need to dynamically
         * poll the query. We start polling when the user clicks "resume", then
         * only stop if the subscription status changed to "active".
         *
         */
        if (isPollingSubscription && subscription?.state === SubscriptionState.ACTIVE) {
          setIsPollingSubscription(false);
          stopPolling();
          notify({
            title: t("settings.subscriptions.resumed.success.title"),
            position: "bottom-right",
            autoClose: 4000,
          });
          /**
           * context(Pringels, 2024-06-30):
           * Due to strange Apollo Client behaviour, this query will not update
           * in other components under specific circumstances. After sinking a lot
           * of time into debugging this problem I eventually resorted to manually refetching
           * the query.
           *
           * Ideally we should avoid using onCompleted callbacks to set the query data as
           * local state. (and rather use the "data" returned by the query)
           *
           * Example: the paymentState state can be derived using a useMemo hook.
           */
          client.refetchQueries({ include: ["getNumberBillingSubscriptionQuery"] });
        }

        const billingTransactions = subscription?.billingTransactions;
        const unpaidBill = billingTransactions?.find(
          (transaction) => unpaidTransactionStatuses.includes(transaction.status!) && transaction.checkout
        );

        const pausedDate = new Date(subscription?.pausedAt);
        const status = getPaymentBannerStatus({
          state: subscription?.state,
          scheduledChangeAction: subscription?.scheduledChangeAction,
          latestUnpaidBillDate: unpaidBill?.billedAt,
          scheduledChangeAt: subscription?.scheduledChangeAt,
          nextPaymentDate: subscription?.nextPaymentDate,
          pausedDate,
        });
        if (status?.status === PaymentBannerStatus.ACTIVE_DATE) {
          setPaymentState({ state: "warning", subscription: subscription!, status });
        } else if (status?.status === PaymentBannerStatus.TRIALING) {
          setPaymentState({ state: "default", subscription: subscription!, status });
        } else if (status?.status === PaymentBannerStatus.INACTIVE) {
          setPaymentState({ state: "activate", status });
        } else if (status?.status) {
          setPaymentState({ state: "error", subscription: subscription!, status });
        } else {
          setPaymentState({ state: undefined, subscription: subscription!, status });
        }
      }
    },
  });

  const { icon, actions, bg } = PaymentBannerOptions[paymentState || "default"];
  const title = getBannerTitle(status, data?.number?.subscription?.nextPaymentDate);

  const onCloseBanner = () => {
    saveStorageSwitch(`hidePaymentBanner_${numberUuid}_${status?.status}`, true);
    setPaymentState({ state: undefined });
  };

  const onSwitchNow = () => {
    setChargePastDueOnAutomatic(false);
    if (
      data?.number?.subscription?.billingTransactions?.some(
        (transaction) =>
          transaction.status === BillingTransactionState.PAST_DUE ||
          transaction.status === BillingTransactionState.BILLED
      )
    ) {
      updateModal("pastDueInvoice", true);
    } else {
      navigateTo("payment")();
    }
  };

  const [resumeSubscription, { loading: resumeSubscriptionLoading }] = useResumePausedPaddleSubscription({
    variables: {
      subscriptionId: data?.number?.subscription?.subscriptionId!,
    },
    refetchQueries: ["getNumberBillingSubscriptionQuery", "getPaymentDetailsQuery"],
    onCompleted: () => startPolling(1000),
    onError: () => {
      stopPolling();
      setIsPollingSubscription(false);
      notify({
        type: "error",
        title: t("settings.subscriptions.resume.error.title"),
        description: (
          <Trans
            i18nKey="settings.subscriptions.resumed.error.description"
            components={{ a: <Anchor href={TurnSupport} target="_blank" /> }}
          />
        ),
        position: "bottom-right",
      });
    },
  });

  const navigateToBilling = navigateTo("settings-billing", { numberUuid });

  return !isAdmin || hideBanner || !paymentState || sandbox || exemptedNumber ? null : (
    <Banner
      height={PAYMENT_BANNER_HEIGHT}
      position="fixed"
      width="100%"
      top="0px"
      zIndex={20}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      p="200"
      gap="100"
      backgroundColor={bg}
      status={paymentState}
    >
      <Box flexDirection="row" alignItems="center" gap="100">
        <StyledIconContainer
          width={32}
          height={32}
          minWidth={32}
          minHeight={32}
          alignItems="center"
          justifyContent="center"
          backgroundColor={icon.color}
          borderRadius="200"
        >
          <Text fontSize="100" lineHeight="50" fontWeight="200" className={icon.animation}>
            {icon.label}
          </Text>
        </StyledIconContainer>
        <Text
          preset="paragraph1-200"
          fontSize={isMobile ? "12px" : undefined}
          lineHeight={isMobile ? "16px" : undefined}
          color={paymentState === "success" ? "neutralWhite" : "neutralBlack"}
        >
          {title}
        </Text>
      </Box>
      <Box flexDirection="row" alignItems="center" gap="100">
        {actions.contact && (
          <StyledActionButton
            size="xsmall"
            variation="secondary"
            text={t("btn.contact")}
            onClick={() => window.open("https://wa.me/16506001008?text=switch-now", "_blank")}
          />
        )}
        {actions.pay && (
          <Button
            size="xsmall"
            variation="primary"
            loading={resumeSubscriptionLoading || isPollingSubscription}
            text={status?.status === PaymentBannerStatus.PAUSED ? t("btn.resume-subscription") : t("btn.pay-now")}
            onClick={
              status?.status === PaymentBannerStatus.PAUSED
                ? () => {
                    setIsPollingSubscription(true);
                    resumeSubscription();
                  }
                : () => navigateToBilling()
            }
          />
        )}
        {actions.switch && (
          <Button size="xsmall" variation="secondary" text={t("btn.switch-now")} onClick={onSwitchNow} />
        )}
        {actions.activate && (
          <StyledActionButton
            size="xsmall"
            variation="primary"
            text={t("btn.subscriptions")}
            onClick={() => navigateToBilling()}
          />
        )}
        {actions.close && <Button size="xsmall" icon={<Icon name="XIcon" />} onClick={onCloseBanner} />}
      </Box>
    </Banner>
  );
};
