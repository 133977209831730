/* tslint:disable */

/* eslint-disable */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as Types from "../../generated/types";
import { AccountDetailsFragment } from "./fragments.generated";
import { AccountDetailsFragmentDoc } from "./fragments.generated";

const defaultOptions = {} as const;
export type UpdateAccountMutationVariables = Types.Exact<{
  accountUpdateInput: Types.AccountUpdateInput;
  accountUuid: Types.Scalars["ID"];
}>;

export type UpdateAccountMutation = {
  __typename: "RootMutationType";
  updateAccount: ({ __typename: "Account" } & AccountDetailsFragment) | null;
};

export const UpdateAccountDocument = gql`
  mutation updateAccount($accountUpdateInput: AccountUpdateInput!, $accountUuid: ID!) {
    updateAccount(accountUpdateInput: $accountUpdateInput, accountUuid: $accountUuid) {
      ...AccountDetails
    }
  }
  ${AccountDetailsFragmentDoc}
`;
export type UpdateAccountMutationFn = Apollo.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      accountUpdateInput: // value for 'accountUpdateInput'
 *      accountUuid: // value for 'accountUuid'
 *   },
 * });
 */
export function useUpdateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, options);
}
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;
