import { Dropdown, DropdownItem } from "ds/Dropdown";
import { Text } from "ds/Typography";
import React from "react";
import { down } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";

import { StyledTab } from "../styles";
import { Tab } from "../types";

export const Tabs = ({ tabs }: { tabs?: Tab[] }) => {
  const isDownLg = useBreakpoint(down("lg"));
  const selectedTab = tabs?.find((tab) => tab.selected);

  return (
    <>
      {isDownLg ? (
        <Dropdown label={selectedTab?.text} style={{ width: "max-content" }}>
          {tabs?.map((tab, index) => <DropdownItem key={index} label={tab.text} onClick={tab.onClick} />)}
        </Dropdown>
      ) : (
        tabs?.map((tab, index) => (
          <StyledTab
            key={index}
            px="200"
            py="250"
            className={tab.selected ? "active" : ""}
            cursor="pointer"
            onClick={tab.onClick}
            data-testid={tab.selected ? "tab-title-selected" : "tab-title"}
          >
            <Text preset="paragraph1-200" fontWeight="normal">
              {tab.text}
            </Text>
          </StyledTab>
        ))
      )}
    </>
  );
};
