/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";

/**
 * A custom effect hook that listens for click events that fall outside the specified element's
 * DOM node tree.
 *
 * This is useful for closing modals, tooltips, popups, notifications, etc.
 *
 * @param {function} fn A callback function which gets called when the click event occurred outside the given DOM node
 * @param options Additional configuration options
 * @param options.ignoreQuery A DOM selector query to ignore click events from.
 */

interface IUseClickOutsideOptions {
  ignoreQuery?: string[]; // ["my-class-1", "should-ignore-outside-click"]
  useVisibility?: boolean;
  visible?: boolean;
  skip?: boolean;
}

export const composedPath = (event: any, query: string) => {
  const path = event.path || (event.composedPath && event.composedPath());

  return path
    ? path.some(
        ({ id, className }) =>
          (id && id === query) ||
          (className &&
            String(className)
              .split(" ")
              .some((cName) => cName === query))
      )
    : event.target.matches(`#${query}` || `.${query}`);
};

export const useClickOutside = (fn = () => {}, options: IUseClickOutsideOptions = {}) => {
  const [node, setRef] = useState<any>();

  useEffect(() => {
    if (options.skip) {
      return;
    }
    function onClickOutside(e) {
      const isTargetChild = node?.contains(e.target);
      const shouldIgnore = () => {
        if (options.ignoreQuery) {
          return options.ignoreQuery.some((query) => composedPath(e, query));
        }
        return;
      };
      if (options.useVisibility) {
        if (!options.visible) {
          return;
        }
      }
      if (!isTargetChild && !shouldIgnore()) {
        fn();
      }
    }

    if (node) {
      window.addEventListener("click", onClickOutside);
    } else {
      window.removeEventListener("click", onClickOutside);
    }
    return () => {
      window.removeEventListener("click", onClickOutside);
    };
  }, [fn, node, options]);

  return [setRef];
};
