import gql from "graphql-tag";

export const PADDLE_CONFIGURATION_FRAGMENT = gql`
  fragment paddleConfiguration on PaddleConfiguration {
    sandboxMode
    vendorId
  }
`;

export const PADDLE_BILLING_PRICES_FRAGMENT = gql`
  fragment paddleBillingPrice on PaddleBillingPrice {
    id
    description
    billingCycle {
      interval
      frequency
    }
    trialPeriod {
      interval
      frequency
    }
    unitPrice {
      amount
      currencyCode
    }
  }
`;

export const PADDLE_BILLING_PRICES_CONFIGURATION_FRAGMENT = gql`
  fragment PricesConfigurationDetails on PricesConfiguration {
    paddleConfiguration {
      ...paddleConfiguration
    }
    paddleBillingPrices {
      ...paddleBillingPrice
    }
  }
`;

export const SUBSCRIPTION_BILLING_TRANSACTION_FRAGMENT = gql`
  fragment SubscriptionBillingTransactionDetails on BillingSubscriptionTransaction {
    amount
    billedAt
    priceName
    receiptUrl
    paddleId
    checkout
    status
  }
`;

export const PAYMENT_DETAILS_FRAGMENT = gql`
  fragment PaymentNumberSubscriptionDetails on NumberSubscription {
    subscriptionId
    state
    nextPaymentDate
    additionalInformation
    collectionMode
    billingTransactions {
      ...SubscriptionBillingTransactionDetails
    }
    scheduledChangeAction
    scheduledChangeAt
  }
  ${SUBSCRIPTION_BILLING_TRANSACTION_FRAGMENT}
`;

export const SUBSCRIPTION_FRAGMENT = gql`
  fragment NumberSubscriptionDetails on NumberSubscription {
    subscriptionId
    state
    nextPaymentDate
    nextPaymentAmount
    recurringTransactionAmount
    scheduledChangeAction
    scheduledChangeAt
    nextPaymentDiscount
    pausedAt
    updateUrl
    cancelUrl
    collectionMode
    customerId
    billingTransactions {
      ...SubscriptionBillingTransactionDetails
    }
    priceName
    additionalInformation
    subscriptionId
  }
  ${SUBSCRIPTION_BILLING_TRANSACTION_FRAGMENT}
`;

export const SUBSCRIPTION_NUMBER_FRAGMENT = gql`
  fragment SubscriptionDetails on Number {
    uuid
    subscription {
      ...NumberSubscriptionDetails
    }
  }
  ${SUBSCRIPTION_FRAGMENT}
`;
