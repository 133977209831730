import defaultCountriesList, { Country } from "countries-phone-masks";
import _sortBy from "lodash/sortBy";

import type { CountryCode } from "libphonenumber-js";

export { CountryCode };

// context(alexandrchebotar, 2022-08-03): fixing masks for some countries
export const countriesList = defaultCountriesList.map((country) =>
  country.iso === "BD"
    ? {
        ...country,
        mask: "###-###-####",
      }
    : country.iso === "MZ"
      ? {
          ...country,
          mask: ["##-###-###", "###-###-###"],
        }
      : country.iso === "CM"
        ? {
            ...country,
            mask: ["####-####", "###-###-###"],
          }
        : country.iso === "ZW"
          ? {
              ...country,
              mask: "#-########",
            }
          : country
);

export const getCountries = (favoriteCountryCodes: CountryCode[]): { favorites: Country[]; countries: Country[] } => {
  const favorites = countriesList.filter((c) => favoriteCountryCodes.includes(c.iso));

  return {
    favorites: _sortBy(favorites, (c) => favoriteCountryCodes.indexOf(c.iso)),
    countries: countriesList.filter((c) => !favoriteCountryCodes.includes(c.iso)),
  };
};

export const getFilterCountriesFn =
  (filterValue: string) =>
  ({ name }: Country) =>
    name.toLowerCase().includes(filterValue.toLowerCase());

export const getCountryByCode = (code?: CountryCode) => countriesList.find((c) => c.iso === code);
