import { Country } from "countries-phone-masks";
import React from "react";

import { Text } from "../../Typography";
import { Spaces } from "../../styles";
import { Flag } from "./Flag";
import { StyledCountryItem } from "./styles";

interface ICountryItemProps {
  country: Country;
  hideCode?: boolean;
  hideFlag?: boolean;
}

export const CountryItem = ({ country, hideCode, hideFlag }: ICountryItemProps) => (
  <StyledCountryItem className="ph-no-capture">
    {!hideFlag && <Flag countryCode={country.iso} margin={{ right: Spaces.S }} />}
    <div>
      <Text size="large" margin={{ right: Spaces.S }}>
        {country.name}
      </Text>
      {!hideCode ? <Text size="large">{country.code}</Text> : null}
    </div>
  </StyledCountryItem>
);
