import gql from "graphql-tag";

export const AUTOMATOR_DETAILS_FRAGMENT = gql`
  fragment AutomatorDetails on Automator {
    name
    uuid
    enabled
    config {
      version
      data {
        operator
        triggers {
          triggerType
          triggerParams
        }
        actions {
          actionType
          actionParams
        }
      }
    }
    insertedAt
    updatedAt
  }
`;
