import React from "react";
import { DefaultTheme, ThemeProvider as StyledThemeProvider } from "styled-components";

import { defaultTheme } from "./theme";

const ThemeProvider = ({ children }: { children: React.ReactNode }) => (
  <StyledThemeProvider theme={defaultTheme as unknown as DefaultTheme}>{children}</StyledThemeProvider>
);

export default ThemeProvider;
