import styled from "styled-components";

import { TSizes } from "lib/impact/styles";

export const StyledDropButton = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledInputContainer = styled.div<{ padSize?: number; hideFlag?: boolean; size?: TSizes }>`
  > div {
    margin: ${({ theme: { spaces } }) => spaces.none};

    input {
      padding-left: ${({ padSize = 1, hideFlag, size, theme: { spaces } }) =>
        // (space left + width of flag + space right + width of icon + space right) + (length of letters * width letter size ~~) + space right
        `calc(${spaces.m + (hideFlag ? spaces.none : spaces.ml) + spaces.xs + spaces.m + spaces.xs}px + ${
          padSize * (size === "small" ? 10 : 15.1575)
        }px + ${spaces.s}px)`};
      width: 100%;
    }
  }
`;

export const StyledCountryContainer = styled.div`
  position: absolute;
  left: ${({ theme: { spaces } }) => spaces.m}px;
  height: 30px;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  cursor: pointer;
`;
