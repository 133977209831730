import React from "react";

export enum LogoTypes {
  "DEFAULT" = "default",
  "CREAM" = "cream",
  "LIME" = "lime",
  "BERRY" = "berry",
  "SUN" = "sun",
  "SKY" = "sky",
  "LAVENDER" = "lavender",
  "ORANGE" = "orange",
}

export enum LogoAs {
  "AVATAR" = "avatar",
  "WORDMARK-AVATAR" = "wordmark-avatar",
  "WORDMARK" = "wordmark",
}

export interface LogoProps extends React.ComponentPropsWithoutRef<"div"> {
  type?: `${LogoTypes}`;
  as?: `${LogoAs}`;
  size?: number;
}
