import * as React from "react";

import { DownTail } from "./DownTail";
import { TDefaultIconProps } from "./types";

// context(justinvdm, 13 August 2021): Alias for backwards compatibility
export const DownArrowIcon = DownTail;

export const DownArrow = ({ fill = "#929a9e", size = 16, ...props }: TDefaultIconProps) => (
  <svg width={size} height={size} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill={fill}
      d="M3.68341017,5.8200922 L7.61588936,10.5390672 C7.7926714,10.7512057 8.10795375,10.7798677 8.3200922,10.6030857 C8.34332373,10.5837261 8.36475103,10.5622988 8.38411064,10.5390672 L12.3165898,5.8200922 C12.4933719,5.60795375 12.4647098,5.2926714 12.2525714,5.11588936 C12.1627139,5.04100811 12.0494474,5 11.9324792,5 L4.06752081,5 C3.79137843,5 3.56752081,5.22385763 3.56752081,5.5 C3.56752081,5.61696825 3.60852892,5.7302347 3.68341017,5.8200922 Z"
    />
  </svg>
);
