import { BoxProps } from "ds/Box/src";

export enum BannerStatus {
  "DEFAULT" = "default",
  "SUCCESS" = "success",
  "WARNING" = "warning",
  "ERROR" = "error",
  "ACTIVATE" = "activate",
}

export interface BannerProps extends BoxProps {
  status?: `${BannerStatus}`;
}
