import langs from "langs";
import _isObject from "lodash/isObject";
import _isString from "lodash/isString";

import { isDefined } from "utils/checks";

const extraAutomationLanguages = [
  { name: "Aasáx", 3: "aas" },
  { name: "Mesopotamian Arabic", 3: "acm" },
  { name: "Adangme", 3: "ada" },
  { name: "Aari", 3: "aiw" },
  { name: "A'ou", 3: "aou" },
  { name: "Bemba (Zambia)", 3: "bem" },
  { name: "Abadi", 3: "kbt" },
  { name: "Kimbundu", 3: "kmb" },
  { name: "Malawi Lomwe", 3: "lon" },
  { name: "Dholuo / Luo (Kenya and Tanzania)", 3: "luo" },
  { name: "Makhuwa-Meetto", 3: "mgh" },
  { name: "Northern Sotho / Pedi/ Sepedi", 3: "nso" },
  { name: "Ronga", 3: "rng" },
  { name: "Kiswahili", 3: "swh" },
  { name: "Tetun Dili", 3: "tdt" },
  { name: "Umbundu", 3: "umb" },
  { name: "Soga", 3: "xog" },
];

type TSortEnglishFirst = {
  (): (a: string, b: string) => -1 | 0 | 1;
  <T extends string | number>(key: T): (a: Record<T, string>, b: Record<T, string>) => -1 | 0 | 1;
};
export const sortEnglishFirst: TSortEnglishFirst = (key?: string | number) => (a: any, b: any) => {
  const isEnglish = (item: any) => {
    const language = key && _isObject(item) ? item[key] : item;

    return _isString(language) && ["en", "eng"].includes(language.toLowerCase());
  };

  return isEnglish(a) ? -1 : isEnglish(b) ? 1 : 0;
};

export const languages = [...langs.all(), ...extraAutomationLanguages]
  .map((lang) => ({
    ...lang,
    3: lang[3],
  }))
  .sort(({ name: a }, { name: b }) => a.localeCompare(b))
  .sort(sortEnglishFirst(3));

export const whatsAppLanguageMap = [
  { iso: "afr", wa: "AF", name: "Afrikaans" },
  { iso: "sqi", wa: "SQ", name: "Albanian" },
  { iso: "ara", wa: "AR", name: "Arabic" },
  { iso: "aze", wa: "AZ", name: "Azerbaijani" },
  { iso: "ben", wa: "BN", name: "Bengali" },
  { iso: "bul", wa: "BG", name: "Bulgarian" },
  { iso: "cat", wa: "CA", name: "Catalan" },
  { iso: "zho", wa: "ZH_CN", name: "Chinese (CHN)" },
  { iso: "yue", wa: "ZH_HK", name: "Chinese (HKG)" },
  { iso: "zho", wa: "ZH_TW", name: "Chinese (TAI)" },
  { iso: "hrv", wa: "HR", name: "Croatian" },
  { iso: "ces", wa: "CS", name: "Czech" },
  { iso: "dan", wa: "DA", name: "Danish" },
  { iso: "nld", wa: "NL", name: "Dutch" },
  { iso: "eng", wa: "EN", name: "English" },
  { iso: "eng", wa: "EN_GB", name: "English (UK)" },
  { iso: "eng", wa: "EN_US", name: "English (US)" },
  { iso: "est", wa: "ET", name: "Estonian" },
  { iso: "fil", wa: "FIL", name: "Filipino" },
  { iso: "fin", wa: "FI", name: "Finnish" },
  { iso: "fra", wa: "FR", name: "French" },
  { iso: "deu", wa: "DE", name: "German" },
  { iso: "ell", wa: "EL", name: "Greek" },
  { iso: "guj", wa: "GU", name: "Gujarati" },
  { iso: "hau", wa: "HA", name: "Hausa" },
  { iso: "heb", wa: "HE", name: "Hebrew" },
  { iso: "hin", wa: "HI", name: "Hindi" },
  { iso: "hun", wa: "HU", name: "Hungarian" },
  { iso: "ind", wa: "ID", name: "Indonesian" },
  { iso: "gle", wa: "GA", name: "Irish" },
  { iso: "ita", wa: "IT", name: "Italian" },
  { iso: "jpn", wa: "JA", name: "Japanese" },
  { iso: "kan", wa: "KN", name: "Kannada" },
  { iso: "kaz", wa: "KK", name: "Kazakh" },
  { iso: "kor", wa: "KO", name: "Korean" },
  { iso: "lao", wa: "LO", name: "Lao" },
  { iso: "lav", wa: "LV", name: "Latvian" },
  { iso: "lit", wa: "LT", name: "Lithuanian" },
  { iso: "mkd", wa: "MK", name: "Macedonian" },
  { iso: "zlm", wa: "MS", name: "Malay" },
  { iso: "mal", wa: "ML", name: "Malayalam" },
  { iso: "mar", wa: "MR", name: "Marathi" },
  { iso: "nor", wa: "NB", name: "Norwegian" },
  { iso: "fas", wa: "FA", name: "Persian" },
  { iso: "pol", wa: "PL", name: "Polish" },
  { iso: "por", wa: "PT_BR", name: "Portuguese (BR)" },
  { iso: "por", wa: "PT_PT", name: "Portuguese (POR)" },
  { iso: "pan", wa: "PA", name: "Punjabi" },
  { iso: "ron", wa: "RO", name: "Romanian" },
  { iso: "rus", wa: "RU", name: "Russian" },
  { iso: "srp", wa: "SR", name: "Serbian" },
  { iso: "slk", wa: "SK", name: "Slovak" },
  { iso: "slv", wa: "SL", name: "Slovenian" },
  { iso: "spa", wa: "ES", name: "Spanish" },
  { iso: "spa", wa: "ES_AR", name: "Spanish (ARG)" },
  { iso: "spa", wa: "ES_ES", name: "Spanish (SPA)" },
  { iso: "spa", wa: "ES_MX", name: "Spanish (MEX)" },
  { iso: "swh", wa: "SW", name: "Swahili" },
  { iso: "swe", wa: "SV", name: "Swedish" },
  { iso: "tam", wa: "TA", name: "Tamil" },
  { iso: "tel", wa: "TE", name: "Telugu" },
  { iso: "tha", wa: "TH", name: "Thai" },
  { iso: "tur", wa: "TR", name: "Turkish" },
  { iso: "ukr", wa: "UK", name: "Ukrainian" },
  { iso: "urd", wa: "UR", name: "Urdu" },
  { iso: "uzb", wa: "UZ", name: "Uzbek" },
  { iso: "vie", wa: "VI", name: "Vietnamese" },
  { iso: "zul", wa: "ZU", name: "Zulu" },
];

export const validTemplateLanguages = [
  { code: "AF", name: "Afrikaans" },
  { code: "SQ", name: "Albanian" },
  { code: "AR", name: "Arabic" },
  { code: "AZ", name: "Azerbaijani" },
  { code: "BN", name: "Bengali" },
  { code: "BG", name: "Bulgarian" },
  { code: "CA", name: "Catalan" },
  { code: "ZH_CN", name: "Chinese (CHN)" },
  { code: "ZH_HK", name: "Chinese (HKG)" },
  { code: "ZH_TW", name: "Chinese (TAI)" },
  { code: "HR", name: "Croatian" },
  { code: "CS", name: "Czech" },
  { code: "DA", name: "Danish" },
  { code: "NL", name: "Dutch" },
  { code: "EN", name: "English" },
  { code: "EN_GB", name: "English (UK)" },
  { code: "EN_US", name: "English (US)" },
  { code: "ET", name: "Estonian" },
  { code: "FIL", name: "Filipino" },
  { code: "FI", name: "Finnish" },
  { code: "FR", name: "French" },
  { code: "DE", name: "German" },
  { code: "EL", name: "Greek" },
  { code: "GU", name: "Gujarati" },
  { code: "HA", name: "Hausa" },
  { code: "HE", name: "Hebrew" },
  { code: "HI", name: "Hindi" },
  { code: "HU", name: "Hungarian" },
  { code: "ID", name: "Indonesian" },
  { code: "GA", name: "Irish" },
  { code: "IT", name: "Italian" },
  { code: "JA", name: "Japanese" },
  { code: "KN", name: "Kannada" },
  { code: "KK", name: "Kazakh" },
  { code: "KO", name: "Korean" },
  { code: "LO", name: "Lao" },
  { code: "LV", name: "Latvian" },
  { code: "LT", name: "Lithuanian" },
  { code: "MK", name: "Macedonian" },
  { code: "MS", name: "Malay" },
  { code: "ML", name: "Malayalam" },
  { code: "MR", name: "Marathi" },
  { code: "NB", name: "Norwegian" },
  { code: "FA", name: "Persian" },
  { code: "PL", name: "Polish" },
  { code: "PT_BR", name: "Portuguese (BR)" },
  { code: "PT_PT", name: "Portuguese (POR)" },
  { code: "PA", name: "Punjabi" },
  { code: "RO", name: "Romanian" },
  { code: "RU", name: "Russian" },
  { code: "SR", name: "Serbian" },
  { code: "SK", name: "Slovak" },
  { code: "SL", name: "Slovenian" },
  { code: "ES", name: "Spanish" },
  { code: "ES_AR", name: "Spanish (ARG)" },
  { code: "ES_ES", name: "Spanish (SPA)" },
  { code: "ES_MX", name: "Spanish (MEX)" },
  { code: "SW", name: "Swahili" },
  { code: "SV", name: "Swedish" },
  { code: "TA", name: "Tamil" },
  { code: "TE", name: "Telugu" },
  { code: "TH", name: "Thai" },
  { code: "TR", name: "Turkish" },
  { code: "UK", name: "Ukrainian" },
  { code: "UR", name: "Urdu" },
  { code: "UZ", name: "Uzbek" },
  { code: "VI", name: "Vietnamese" },
  { code: "ZU", name: "Zulu" },
];

type TGetLanguageOptions = {
  (): Record<"label" | "value", string>[];
  <T extends string>(key: T): Record<T | "value", string>[];
};
export const getLanguageOptions: TGetLanguageOptions = (key = "label"): Record<string, string>[] =>
  languages.map((language) => ({
    [key]: language.name,
    value: language[3],
  }));
export const getContactLanguageOptions: TGetLanguageOptions = (key = "label"): Record<string, string>[] =>
  languages.map((language) => ({
    [key]: language.name,
    value: language[3],
  }));

const fixEnglishLanguageCode = (language: string) => (language === "en" ? "eng" : language);

export const getFilteredLanguages = (data: { language: string }[] = []) => {
  return [
    ...new Set([
      ...data
        .map((item) => fixEnglishLanguageCode(item.language))
        .sort(sortEnglishFirst())
        .map((language) => langs.where(3, language))
        .filter(isDefined),
    ]),
  ].map((item) => ({ ...item, value: item[3] }));
};

export const findLanguageByCode = (languageCode: string) => {
  const value = fixEnglishLanguageCode(languageCode);
  const label = languages.find((lang) => lang[3].toLowerCase() === value.toLowerCase())?.name ?? "Unknown";

  return { label, value };
};

export const findLanguageByLabel = (label: string) => {
  const value = languages.find((lang) => lang.name.toLowerCase() === label.toLowerCase())?.[3] ?? "";

  return { label, value };
};

export const getLanguageLabel = (langCode: string) => findLanguageByCode(langCode).label;
export const getLanguageCode = (langLabel: string) => findLanguageByLabel(langLabel).value;

export const getTemplatesLanguageLabel = (language?: string) =>
  language && validTemplateLanguages.find((l) => l.code === language.toUpperCase())?.name;

export const getTemplatesLanguagesOptions = (data?: { number: { messageTemplatesLanguages: string[] } } | null) =>
  data
    ? [...data.number.messageTemplatesLanguages]
        .sort((a, b) => a.localeCompare(b))
        .sort(sortEnglishFirst())
        .map((language) => validTemplateLanguages.find(({ code }) => language.toUpperCase() === code))
        .filter(isDefined)
        .map(({ code: value, name }) => ({ name, value }))
    : [];

export const getLanguageOptionsFromList = (languages: string[] = []) =>
  [...languages]
    .sort((a, b) => a.localeCompare(b))
    .sort(sortEnglishFirst())
    .map((language) => langs.where(3, language))
    .filter(isDefined)
    .map(({ 3: value, name }) => ({ name, value }));

export const getCardLanguageOptions = (data?: { number: { cardLanguages: string[] } } | null) =>
  getLanguageOptionsFromList(data?.number.cardLanguages);

export const getFormattedWhatsappLanguage = (language: string) => {
  const newLanguage = language
    .split("_")
    .map((item, index) => (index === 0 ? item.toLowerCase() : item.toUpperCase()))
    .join("_");

  return newLanguage ?? language.toLowerCase();
};
