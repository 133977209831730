/* tslint:disable */

/* eslint-disable */
import { gql } from "@apollo/client";

import * as Types from "../../generated/types";

export type LeaseMinFragment = {
  __typename: "Lease";
  uuid: string;
  claimedAt: any;
  claimedUntil: any;
  scope: Types.LeaseScope;
  scopeResourceAsString: string;
};

export const LeaseMinFragmentDoc = gql`
  fragment LeaseMin on Lease {
    uuid
    claimedAt
    claimedUntil
    scope
    scopeResourceAsString
  }
`;
