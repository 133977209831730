import { RoleType } from "graphql/generated/types";
import { TOrganisationDetails } from "graphql/organisation";

import { Action, ActionType } from "./actions";

export interface IGlobalState {
  chatUuid?: string;
  numberUuid: string;
  UNSAFE_numberUuid?: string;
  organisationUuid: string;
  UNSAFE_organisationUuid?: string;
  menuVisible?: boolean;
  isAdmin: boolean;
  role?: RoleType;
  myAccountUuid?: string;
  currentOrganisation?: TOrganisationDetails;
}

export const INITIAL_GLOBAL_STATE: IGlobalState = {
  chatUuid: undefined,
  numberUuid: "",
  UNSAFE_numberUuid: undefined,
  organisationUuid: "",
  UNSAFE_organisationUuid: undefined,
  menuVisible: false,
  isAdmin: false,
  role: undefined,
  myAccountUuid: undefined,
  currentOrganisation: undefined,
};

export const reducer = (state: IGlobalState, action: Action): IGlobalState => {
  switch (action.type) {
    case ActionType.SET_CHAT_UUID:
      return {
        ...state,
        chatUuid: action.payload,
      };
    case ActionType.SET_NUMBER_UUID:
      return {
        ...state,
        numberUuid: action.payload,
      };
    case ActionType.SET_UNSAFE_NUMBER_UUID:
      return {
        ...state,
        UNSAFE_numberUuid: action.payload,
      };
    case ActionType.SET_ORGANISATION_UUID:
      return {
        ...state,
        organisationUuid: action.payload,
      };
    case ActionType.SET_UNSAFE_ORGANISATION_UUID:
      return {
        ...state,
        UNSAFE_organisationUuid: action.payload,
      };
    case ActionType.SET_VISIBLE_MENU:
      return {
        ...state,
        menuVisible: action.payload,
      };
    case ActionType.SET_IS_ADMIN:
      return {
        ...state,
        isAdmin: action.payload,
      };
    case ActionType.SET_ROLE:
      return {
        ...state,
        role: action.payload,
      };
    case ActionType.SET_MY_ACCOUNT_UUID:
      return {
        ...state,
        myAccountUuid: action.payload,
      };
    case ActionType.SET_CURRENT_ORGANISATION:
      return {
        ...state,
        currentOrganisation: action.payload,
      };
    default:
      return state;
  }
};
