import { Image } from "grommet";
import React from "react";
import { useTranslation } from "react-i18next";

import Logo from "assets/turn-logo-black.svg";
import { useNavigation } from "hooks";
import { Button, getSpacing } from "lib/impact";
import { LeftArrowIcon } from "lib/impact/Icons/LeftIcon";
import { TColors, getColor } from "lib/impact/styles/colors";
import { TurnSupportSignUp } from "utils/links";

import { OnboardingFooterContainer, OnboardingHeaderContainer } from "../styles";

interface IHeaderProps {
  showLogo?: boolean;
  showBackButton?: boolean;
  onBack?: () => void;
  background?: TColors;
}

export const Header = ({ showLogo, showBackButton, onBack, background = "white" }: IHeaderProps) => {
  const { t } = useTranslation();
  return (
    <OnboardingHeaderContainer
      direction="row"
      align="center"
      justify={showLogo || showBackButton ? "between" : "end"}
      margin={{ bottom: getSpacing("m") }}
      background={getColor(background)}
    >
      {showBackButton && (
        <Button
          icon={<LeftArrowIcon />}
          size="small"
          state="default"
          label={t("btn.back")}
          onClick={onBack}
          padding={{ vertical: "s", horizontal: "none" }}
        />
      )}
      {showLogo && <Image src={Logo} />}
      <Button
        size="small"
        state="default"
        href={TurnSupportSignUp}
        label={t("global.support")}
        padding={{ vertical: "s", horizontal: "none" }}
      />
    </OnboardingHeaderContainer>
  );
};

export interface IFooterProps {
  label?: string;
  navigate?: string;
  onClick?: () => void;
  href?: string;
  background?: TColors;
}

export const Footer = ({ label, navigate = "/", onClick, href, background = "white" }: IFooterProps) => {
  const { navigateTo } = useNavigation();

  return (
    <OnboardingFooterContainer
      justify="center"
      background={background}
      border={{ side: "top", color: "light-4" }}
      align="center"
    >
      <Button
        margin={{ vertical: "m" }}
        size="small"
        onClick={onClick ?? navigateTo(navigate)}
        href={href}
        state="default"
        label={label}
      />
    </OnboardingFooterContainer>
  );
};
