import { TColors } from "../styles/colors";
import { TButtonStates } from "./types";

export const getTextColor = (state: TButtonStates, disabled?: boolean, color?: TColors): TColors => {
  if (color) {
    return color;
  }
  switch (state) {
    case "light":
      return disabled ? "dark-3" : "black";
    case "dark":
      return "white";
    default:
      return "black";
  }
};
