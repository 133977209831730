import React from "react";

export const ConnectionLostIconOutline = ({ ...props }) => (
  <svg width={130} height={120} viewBox="0 0 130 120" fill="none" {...props}>
    <circle cx={65} cy={60} r={60} fill="#F6F8F8" />
    <g filter="url(#prefix__filter0_d)">
      <path
        d="M109.688 29.5c0-1.208-.98-2.188-2.188-2.188H21.25c-1.208 0-2.188.98-2.188 2.188v55.938h90.626V29.5z"
        fill="#fff"
        stroke="#E7EAEB"
        strokeWidth={0.625}
      />
      <path
        d="M104 34.25c0-.69-.56-1.25-1.25-1.25h-76.5c-.69 0-1.25.56-1.25 1.25V78c0 .69.56 1.25 1.25 1.25h76.5c.69 0 1.25-.56 1.25-1.25V34.25z"
        fill="#F3F4F5"
      />
      <rect
        x={-0.313}
        y={0.313}
        width={103.125}
        height={6.875}
        rx={2.188}
        transform="matrix(-1 0 0 1 116.125 84.5)"
        fill="#fff"
        stroke="#E7EAEB"
        strokeWidth={0.625}
      />
    </g>
    <path
      d="M83.262 50.741a.416.416 0 00-.049-.059C78.26 45.728 71.673 43 64.667 43s-13.592 2.728-18.546 7.681a.41.41 0 00-.001.58l2.712 2.713a.409.409 0 00.579 0c4.082-4.082 9.5-6.33 15.256-6.33s11.174 2.248 15.255 6.33a.41.41 0 00.58 0l2.711-2.712a.41.41 0 00.05-.52z"
      fill="#00C2CC"
    />
    <path
      d="M64.667 50.172c-5.083 0-9.866 1.985-13.47 5.588a.41.41 0 000 .58l2.704 2.703a.41.41 0 00.58 0 14.315 14.315 0 0110.186-4.227c3.844 0 7.461 1.501 10.186 4.227a.41.41 0 00.58 0l2.703-2.704a.41.41 0 000-.579c-3.603-3.603-8.387-5.588-13.469-5.588z"
      fill="#00C2CC"
    />
    <path
      d="M64.666 57.344c-3.17 0-6.152 1.238-8.4 3.485a.409.409 0 000 .58l2.704 2.703a.41.41 0 00.579 0 7.192 7.192 0 015.117-2.123c1.931 0 3.749.754 5.118 2.123a.41.41 0 00.579 0l2.703-2.704a.41.41 0 000-.579 11.803 11.803 0 00-8.4-3.485zM64.667 64.944a4.117 4.117 0 00-2.931 1.214.41.41 0 000 .579l2.641 2.641a.41.41 0 00.58 0l2.64-2.641a.41.41 0 000-.58 4.117 4.117 0 00-2.93-1.213z"
      fill="#00C2CC"
    />
    <circle cx={80.667} cy={49.333} r={7.333} fill="#FFBF1F" />
    <path d="M80.667 46v4" stroke="#fff" strokeWidth={1.333} strokeLinecap="round" />
    <circle cx={80.667} cy={49.333} r={9} stroke="#FFBF1F" strokeWidth={0.667} />
    <circle cx={80.667} cy={52.667} r={0.667} fill="#fff" />
    <defs>
      <filter
        id="prefix__filter0_d"
        x={0.5}
        y={19.5}
        width={128.75}
        height={90}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={5} />
        <feGaussianBlur stdDeviation={6.25} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);
