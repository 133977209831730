import { Clean } from "api/boundary";
import { RoleType } from "graphql/generated/types";

import { ACCOUNT_DETAILS_FRAGMENT, ORGANISATION_ACCOUNT_DETAILS_FRAGMENT } from "./fragments";
import { AccountDetailsFragment, OrganisationAccountDetailsFragment } from "./generated/fragments.generated";

export type TAccount = Clean<typeof ACCOUNT_DETAILS_FRAGMENT.parse, AccountDetailsFragment>;
export type TOrganisationAccount = Clean<
  typeof ORGANISATION_ACCOUNT_DETAILS_FRAGMENT.parse,
  OrganisationAccountDetailsFragment
>;

export const DisplayRoles: Record<RoleType, string> = {
  ADMIN: "Admin",
  BUILDER: "Builder",
  OPERATOR: "Operator",
};
