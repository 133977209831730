import { Box, FormField } from "grommet";
import styled from "styled-components";

import { turnTheme } from "theme";

export const FormFieldStyle = styled(FormField)`
  ${(props) => props.error && `input { border-color: ${turnTheme.global.colors["status-critical"]}`}
`;

export const FormFieldIcon = styled(Box)<{ size?: "small" }>`
  border: 1px solid ${turnTheme.global.colors["light-4"]};
  margin-top: 0;

  &:hover {
    border: 1px solid ${turnTheme.global.colors["light-5"]};
  }
  input {
    border-color: transparent;
    padding: 11px 6px;

    &:focus {
      border-color: transparent;
    }
  }
  ${({ size }) =>
    size === "small" &&
    `
    svg {
      width: 12px;
    }
    input {
      padding: 4px 6px;
    }
  `}
`;

export const FormFieldInvert = styled(Box)`
  border: 1px solid transparent;

  &:hover {
    border: 1px solid ${turnTheme.global.colors["light-5"]};
  }
  input {
    border-color: transparent;

    &:focus {
      border-color: transparent;
    }
  }
`;

export const FormFieldPlain = styled(Box)`
  &:hover {
    background-color: ${turnTheme.global.colors["light-1"]};
  }
  input {
    border-color: transparent;
    &:focus {
      border-color: transparent;
    }
  }
`;
