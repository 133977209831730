import { DefaultTheme } from "styled-components";

import { getColor } from "../../styles";
import { Prompts, TPromptTypes, TStyleStates } from "./types";

export const getFieldStyles = (type: TPromptTypes, theme: DefaultTheme, state?: TStyleStates) => {
  switch (type) {
    case Prompts.ERROR:
      if (state === "prompt") {
        return `background: ${getColor("unknown.6")}`;
      }
      return `border-color: ${theme.colors["status-critical"]};`;
    case Prompts.WARNING:
      if (state === "prompt") {
        return `background: ${theme.colors["status-warning"]};`;
      }
      return `border-color: ${theme.colors["status-warning"]};`;
    case Prompts.SUCCESS:
      if (state === "prompt") {
        return `background: ${theme.colors["status-ok"]}`;
      }
      return `border-color: ${theme.colors["status-ok"]};`;
    default:
      if (state === "focus") {
        return `border-color: ${theme.colors.black};`;
      }
      if (state === "prompt") {
        return `background: ${theme.colors.white}`;
      }
      return `border-color: ${theme.colors["light-5"]};`;
  }
};

interface IGetPrompt {
  error?: string;
  warning?: string;
  success?: string;
}
export const getPrompt = ({ error, warning, success }: IGetPrompt) => {
  if (error) {
    return {
      type: Prompts.ERROR,
      text: error,
    };
  } else if (warning) {
    return {
      type: Prompts.WARNING,
      text: warning,
    };
  } else if (success) {
    return {
      type: Prompts.SUCCESS,
      text: success,
    };
  }
  return {
    type: Prompts.DEFAULT,
  };
};
