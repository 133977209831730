import { Box } from "grommet";
import React from "react";
import { useTranslation } from "react-i18next";

import { TColors, Text, Typography, getColor, getSpacing, heading } from "lib/impact";
import { SimpleSpinner } from "lib/loaders";

import { MAX_FORM_FIELD_WIDTH } from "../styles";

interface ITile {
  background?: TColors;
  children?: React.ReactNode;
  loading?: boolean;
  loaderText?: string;
  animated?: boolean;
  text?: string | null;
  hideSpinner?: boolean;
}
export const Tile = ({
  background = "unknown.17",
  children,
  loading,
  loaderText,
  animated = true,
  hideSpinner,
  text,
}: ITile) => {
  const { t } = useTranslation();

  return (
    <Box
      height="125px"
      justify="center"
      pad={{ horizontal: getSpacing("l") }}
      margin={{ vertical: getSpacing("ml") }}
      background={getColor(background)}
      round="8px"
      width={{ max: MAX_FORM_FIELD_WIDTH }}
      className="ph-no-capture"
    >
      {loading ? (
        hideSpinner ? (
          <Text size="large">{loaderText ?? t("btn.loading")}</Text>
        ) : (
          <SimpleSpinner direction="column" text={loaderText ?? t("btn.loading")} animated={animated} />
        )
      ) : children ? (
        children
      ) : (
        <Typography size="h3" theme={heading} wordBreak="break-word">
          {text}
        </Typography>
      )}
    </Box>
  );
};
