import React from "react";

import { TooltipComponent } from "./TooltipComponent";
import { TooltipProps } from "./types";

export const Tooltip = (props: TooltipProps) => {
  const { disabled, children, content, trigger } = props;

  return !disabled && (children || content) ? (
    <TooltipComponent {...props} />
  ) : React.isValidElement(trigger) ? (
    trigger
  ) : (
    <>{trigger}</>
  );
};
