import React from "react";
import { ToastContainer as ToastifyToastContainer } from "react-toastify";

/**
 * Note:  We have to use custom CSS here to override default react-toastifty styles.
 * This will change if we ever decide to build our own notification components.
 * For now react-toastify does the job quickly and easily.
 */
import "react-toastify/dist/ReactToastify.css";

import "./notifications.css";

export const ToastContainer = () => (
  <ToastifyToastContainer progressClassName="toast-progress" enableMultiContainer containerId="defaultOldToast" />
);
