import { BorderColorDefault } from "ds/Alias";
import { BorderWidth100 } from "ds/Core";
import { Text } from "ds/Typography";
import { Box, BoxExtendedProps, Image } from "grommet";
import { QRCodeSVG } from "qrcode.react";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import logoQr from "assets/logo-qr.png";
import Logo from "assets/turn-logo-black.svg";
import { TInitialNumber } from "graphqlQueries";
import { useLayoutContext } from "hooks/useLayoutContext";
import { Button, Heading, TColors, getColor, getSpacing } from "lib/impact";
import { LeftArrowIcon } from "lib/impact/Icons/LeftIcon";
import { numberWaMeUrl } from "utils";
import { TurnSupportSignUp } from "utils/links";

import OnboardingProgress from "../OnboardingProgress";
import {
  OnboardingContent,
  OnboardingContentContainer,
  OnboardingHeaderContainer,
  OnboardingImageContainer,
  OnboardingLayout,
  QrCodeContainer,
} from "../styles";
import { Footer, IFooterProps } from "./Layout";

interface IOnboardingScreen {
  background?: TColors;
  children?: ReactNode;
  color: TColors;
  content?: ReactNode;
  contentContainer?: React.FC<BoxExtendedProps>;
  contentMaxWidth?: string;
  contentPadding?: string;
  footer?: IFooterProps;
  headingProps?: BoxExtendedProps;
  hideIllustration?: boolean;
  hideSupport?: boolean;
  illustrationHeight?: string;
  image?: string;
  number?: TInitialNumber;
  onBack?: () => void;
  showLogo?: boolean;
  showProgress?: boolean;
  showQrCode?: boolean;
  step?: string;
  title?: string | string[];
  hideHeader?: boolean;
  showStepInHeader?: boolean;
  showStepInTitle?: boolean;
}

export const OnboardingScreen = ({
  background = "white",
  children,
  color,
  content,
  contentContainer,
  contentMaxWidth,
  contentPadding,
  footer,
  headingProps,
  hideIllustration,
  hideSupport,
  illustrationHeight,
  image,
  number,
  onBack,
  showLogo,
  showProgress,
  showQrCode,
  step,
  title,
  hideHeader,
  showStepInHeader,
  showStepInTitle,
}: IOnboardingScreen) => {
  const { offset } = useLayoutContext();
  const { t } = useTranslation();
  const OnboardingContentWrapper = contentContainer ?? OnboardingContent;

  return (
    <OnboardingLayout direction="row" fill>
      <Box width={hideIllustration ? "100%" : "50%"} align="center">
        <OnboardingContentContainer width="100%">
          {!hideHeader && (
            <OnboardingHeaderContainer
              direction="row"
              align="center"
              justify={showLogo || onBack || showStepInHeader ? "between" : "end"}
              margin={{ bottom: getSpacing("m") }}
              background={getColor(background)}
              border={
                showStepInHeader
                  ? {
                      color: BorderColorDefault,
                      size: BorderWidth100,
                      style: "solid",
                      side: "bottom",
                    }
                  : undefined
              }
            >
              {onBack && (
                <Button
                  icon={<LeftArrowIcon />}
                  size="small"
                  state="default"
                  label={t("btn.back")}
                  onClick={onBack}
                  padding={{ vertical: "s", horizontal: "none" }}
                />
              )}
              {showLogo && <Image src={Logo} />}
              {showStepInHeader && showStepInTitle && <Text preset="large200"> {step}</Text>}
              {!hideSupport && (
                <Button
                  size="small"
                  state="default"
                  href={TurnSupportSignUp}
                  label={t("global.support")}
                  padding={{ vertical: "s", horizontal: "none" }}
                />
              )}
            </OnboardingHeaderContainer>
          )}

          <OnboardingContentWrapper full={hideIllustration} maxWidth={contentMaxWidth} padding={contentPadding}>
            {((step && !showStepInHeader) || title || content) && (
              <Box pad={{ bottom: getSpacing("m") }} gap={getSpacing("m")} {...headingProps}>
                {step && !showStepInHeader && showStepInTitle && (
                  <Heading size={5} weight={400} margin="none">
                    {step}
                  </Heading>
                )}
                {title && (
                  <Box margin={{ top: getSpacing("s") }}>
                    {React.Children.map(title, (item, index) => (
                      <Heading key={index} size={2} margin="none">
                        {item}
                      </Heading>
                    ))}
                  </Box>
                )}
                {React.Children.map(content, (item, index) => (
                  <Heading
                    key={index}
                    size={4}
                    weight={400}
                    margin="none"
                    style={{ whiteSpace: "break-spaces" }}
                    className="ph-no-capture"
                  >
                    {item}
                  </Heading>
                ))}
              </Box>
            )}
            {children}
          </OnboardingContentWrapper>
          {footer && <Footer {...footer} />}
        </OnboardingContentContainer>
      </Box>
      {!hideIllustration && (
        <OnboardingImageContainer height={illustrationHeight || `calc(100vh - ${offset})`} background={getColor(color)}>
          {showProgress && number ? (
            <OnboardingProgress number={number} />
          ) : showQrCode && number ? (
            <QrCodeContainer>
              <QRCodeSVG
                level="H"
                value={numberWaMeUrl(number)}
                size={422}
                fgColor={getColor("dark-1")}
                imageSettings={{
                  src: logoQr,
                  excavate: false,
                  height: 150,
                  width: 150,
                }}
              />
            </QrCodeContainer>
          ) : (
            <Box width={{ max: "640px" }} height={{ max: "640px" }} align="center">
              <Image src={image} fit="contain" />
            </Box>
          )}
        </OnboardingImageContainer>
      )}
    </OnboardingLayout>
  );
};
