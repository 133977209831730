import React from "react";
import ReactFlag from "react-flags";
import styled from "styled-components";

import { CountryCode } from "libphonenumber-js";

import { TSpacing, getSpacing } from "../../styles";

const basePath = `${process.env.PUBLIC_URL}/assets/flags`;

interface IFlagContainer {
  width: number;
  height: number;
  round: number;
  margin?: string;
  onClick?: () => void;
}
const FlagContainer = styled.div<IFlagContainer>`
  position: relative;
  flex-shrink: 0;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  ${({ margin }) => margin && `margin: ${margin};`};
  ${({ onClick }) =>
    onClick &&
    `&:hover {
      cursor: pointer;
    }`};

  div,
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    ${({ round }) => round && `border-radius: ${round}px;`};
  }

  div {
    z-index: 2;
  }

  img {
    z-index: 1;
  }
`;
const FlagOverlay = styled.div`
  box-shadow: 0 0 0 1px rgb(245, 245, 245);
`;

interface IFlag {
  countryCode?: CountryCode;
  margin?: TSpacing;
  width?: number;
  height?: number;
  round?: number;
  onClick?: () => void;
  showOverlay?: boolean;
}
export const Flag = ({
  countryCode,
  width = 28,
  height = 21,
  round = 2,
  margin,
  onClick,
  showOverlay = true,
}: IFlag) => (
  <FlagContainer width={width} height={height} round={round} margin={getSpacing(margin)} onClick={onClick}>
    {showOverlay && <FlagOverlay />}
    {countryCode ? (
      <ReactFlag country={countryCode} alt={countryCode} format="svg" width={width} basePath={basePath} />
    ) : (
      <img src={`${basePath}/flags-iso/flat/svg/_unknown.svg`} alt="world flag" width={width} />
    )}
  </FlagContainer>
);
