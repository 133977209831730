import React from "react";

import { Typography } from "../Typography/Typography";
import { ITypographyProps } from "../Typography/types";
import { button } from "../styles";
import Icon from "./Icon";
import { StyledButton } from "./styles";
import { TButtonStates } from "./types";
import { getTextColor } from "./utils";

type TButtonProps = ITypographyProps & JSX.IntrinsicElements["button"];

export interface IButtonProps extends TButtonProps {
  icon?: JSX.Element;
  reverse?: boolean;
  label?: string;
  state?: TButtonStates;
  href?: string;
  target?: "_blank" | "_self";
  width?: string;
  minWidth?: string;
  minHeight?: string;
  round?: string;
}

export const Button = ({
  icon,
  disabled,
  reverse,
  margin,
  padding,
  color,
  weight = 500,
  size = "lmedium",
  label,
  state = "light",
  href,
  target = "_blank",
  onClick,
  round,
  ...props
}: IButtonProps) => {
  const styles = {
    ...button.container[size],
    padding: padding ?? button.container[size].padding,
    state,
    margin,
    disabled,
    round,
  };
  const typographyStyles = { color: getTextColor(state, disabled, color), size, weight, theme: button.text };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (href) {
      window.open(href, target);
    } else if (onClick) {
      onClick(event);
    }
  };

  return (
    <StyledButton onClick={handleClick} {...styles} {...props}>
      <Icon color={typographyStyles.color} icon={icon} reverse={reverse}>
        <Typography as="span" {...typographyStyles}>
          {label}
        </Typography>
      </Icon>
    </StyledButton>
  );
};
