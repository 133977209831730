import React from "react";

import { getColor, text } from "../styles";
import { StyledTypography } from "./styles";
import { ITypographyProps as ITypographyGlobalProps, TPartialHeadingSizes } from "./types";

interface ITypographyProps extends ITypographyGlobalProps {
  as?: "span" | "p" | TPartialHeadingSizes;
  theme?: {
    [key: string]: {
      fontSize: string;
      lineHeight: string;
    };
  };
}

export const Typography = ({
  theme = text,
  as = "span",
  size = "medium",
  weight = 400,
  color = "black",
  margin,
  padding,
  children,
  ...props
}: ITypographyProps) => {
  const styles = { ...theme[size], color: getColor(color), padding, margin, weight };

  return (
    <StyledTypography as={as} {...styles} {...props}>
      {children}
    </StyledTypography>
  );
};
