import { Box } from "grommet";
import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

import { turnTheme } from "theme";

import { SpinnerText } from "./styles";
import { ISpinnerProps } from "./types";

export const ClipSpinner = ({ color, reverse, size, text, textProps, ...props }: ISpinnerProps) => (
  <Box direction="row" align="center" {...props}>
    {reverse && text && (
      <SpinnerText reverse {...textProps}>
        {text}
      </SpinnerText>
    )}
    <ClipLoader color={color || turnTheme.global.colors["light-1"]} size={size || 12} />
    {!reverse && text && <SpinnerText {...textProps}>{text}</SpinnerText>}
  </Box>
);
