import * as React from "react";

export const CloseIconSolid = ({ fill = "#fff", ...props }) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8A8 8 0 110 8a8 8 0 0116 0zm-5.45 3.65c.3.3.8.3 1.1 0 .3-.3.3-.8 0-1.1L9.1 8l2.55-2.55c.3-.3.3-.8 0-1.1-.3-.3-.8-.3-1.1 0L8 6.9 5.45 4.35c-.3-.3-.8-.3-1.1 0-.3.3-.3.8 0 1.1L6.9 8l-2.55 2.55c-.3.3-.3.8 0 1.1.3.3.8.3 1.1 0L8 9.1l2.55 2.55z"
      fill={fill}
    />
  </svg>
);
