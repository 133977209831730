import { cursor, gap, transition } from "ds/theme/theme";
import { BorderType } from "ds/types/border";
import { BackgroundType, ColorType } from "ds/types/colors";
import { LayoutType } from "ds/types/layout";
import { BoxShadowType } from "ds/types/shadow";
import { SpaceType } from "ds/types/space";
import { Override } from "ds/types/utils";
import React from "react";
import styled, { CSSProperties } from "styled-components";
import {
  FlexboxProps,
  GridProps,
  PositionProps,
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
} from "styled-system";

import { ComponentProps } from "./types";

export type StyledBoxProps = Override<
  SpaceType &
    LayoutType &
    FlexboxProps &
    GridProps &
    BackgroundType &
    ColorType &
    BorderType &
    PositionProps &
    BoxShadowType,
  {
    children?: React.ReactNode;
    onResize?: (event: Event) => void;
    onResizeCapture?: (event: Event) => void;
    textOverflow?: CSSProperties["textOverflow"];
    whiteSpace?: CSSProperties["whiteSpace"];
    wordBreak?: CSSProperties["wordBreak"];
    transition?: CSSProperties["transition"];
    cursor?: CSSProperties["cursor"];
  }
>;

const forwardProps = ["display", "size"];

const StyledBox = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !forwardProps.includes(prop) && defaultValidatorFn(prop),
})<StyledBoxProps>(
  space,
  layout,
  flexbox,
  grid,
  background,
  color,
  border,
  position,
  shadow,
  gap,
  transition,
  cursor,
  {
    boxSizing: "border-box",
  },
  ({ bottom, float, left, right, textAlign, textOverflow, top, whiteSpace, wordWrap, wordBreak }) =>
    ({
      bottom,
      float,
      left,
      right,
      textAlign,
      textOverflow,
      top,
      whiteSpace,
      wordBreak,
      wordWrap,
    }) as {
      [key: string]: string;
    }
);

export const Box = React.forwardRef<HTMLDivElement, StyledBoxProps & React.ComponentPropsWithRef<"div">>(
  (props, ref) => <StyledBox ref={ref} display="flex" flexDirection="column" {...props} />
);

export type BoxProps = ComponentProps<typeof Box>;
