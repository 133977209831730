import { unit } from "./breakpoints";
import { spaces } from "./spacing";
import { label } from "./text";

export const field = {
  small: {
    padding: `${spaces.s}${unit}`,
    height: {
      min: `48${unit}`,
    },
    text: {
      fontSize: `14${unit}`,
      lineHeight: `28${unit}`,
    },
    label: label.small,
  },
  medium: {
    padding: `${spaces.m}${unit} ${spaces.ml}${unit}`,
    height: {
      min: `64${unit}`,
    },
    text: {
      fontSize: `20${unit}`,
      lineHeight: `28${unit}`,
    },
    label: label.medium,
  },
  lmedium: {
    padding: `${spaces.m}${unit} ${spaces.ml}${unit}`,
    height: {
      min: `64${unit}`,
    },
    text: {
      fontSize: `20${unit}`,
      lineHeight: `28${unit}`,
    },
    label: label.medium,
  },
  large: {
    padding: `${spaces.m}${unit} ${spaces.ml}${unit}`,
    height: {
      min: `64${unit}`,
    },
    text: {
      fontSize: `24${unit}`,
      lineHeight: `28${unit}`,
    },
    label: label.medium,
  },
};
