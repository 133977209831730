import { ResponsiveContext } from "grommet";
import { useContext } from "react";

export const useResponsiveContext = () => {
  const sizes = ["small", "medium", "lmedium", "large", "xlarge", "xxlarge"];
  const size = useContext(ResponsiveContext);

  const sizeSmallerThan = (targetSize) => {
    const taggetSizeIndex = sizes.indexOf(targetSize);
    const availableSizes = sizes.slice(0, taggetSizeIndex);

    return availableSizes.includes(size);
  };

  const sizeLargerThan = (targetSize) => {
    const taggetSizeIndex = sizes.indexOf(targetSize);
    const availableSizes = sizes.slice(taggetSizeIndex + 1);

    return availableSizes.includes(size);
  };

  return { size, sizeSmallerThan, sizeLargerThan };
};
