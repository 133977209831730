import { Spinner } from "grommet";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button, IButtonProps, getSpacing } from "lib/impact";

interface IAction extends IButtonProps {
  loading?: boolean;
  outline?: boolean;
  plain?: boolean;
}

export const Action = ({ label, onClick, href, disabled, loading, outline, plain, ...props }: IAction) => {
  const { t } = useTranslation();

  return (
    <Button
      onClick={onClick}
      href={href}
      target="_self"
      label={loading ? "" : (label ?? t("btn.continue"))}
      weight={400}
      margin={{ top: "l" }}
      state={plain ? "default" : outline ? "light" : "dark"}
      disabled={disabled || loading}
      icon={
        loading ? (
          <Spinner
            size="18px"
            width={{ max: "18px" }}
            margin={{ left: getSpacing("m") }}
            border={[
              { side: "all", color: "#A6A6A6", size: "3px" },
              { side: "top", color: "white", size: "3px" },
            ]}
          />
        ) : undefined
      }
      width="341px"
      padding={{ vertical: "s", horizontal: "s" }}
      {...props}
    />
  );
};
