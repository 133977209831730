import * as React from "react";

import { theme } from "../styles/theme";
import { TIconProps } from "./types";

export const LeftArrowIcon = ({ fill = theme.colors.black, ...props }: TIconProps) => (
  <svg width={16} height={24} viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.707 6.707L9.414 6 8 4.586l-.707.707-6 6L.586 12l.707.707 6 6 .707.707L9.414 18l-.707-.707L4.414 13H16v-2H4.414l4.293-4.293z"
      fill={fill}
    />
  </svg>
);
