import { Box, BoxExtendedProps } from "grommet";
import React from "react";
import { renderToString } from "react-dom/server";

import { Maybe, Override } from "types";

import { SVGAvatar } from "./components";

type TAvatarProps = Override<
  BoxExtendedProps,
  {
    size?: string;
    name?: Maybe<string>;
    background?: Maybe<string>;
  }
>;

export const Avatar = ({ size, name, background, ...props }: TAvatarProps) => {
  const convertedName = name || "UN";

  return (
    <Box
      a11yTitle="avatar"
      height={size}
      width={size}
      round="full"
      background={
        background ??
        `url("data:image/svg+xml,${encodeURIComponent(renderToString(<SVGAvatar name={convertedName} />))}")`
      }
      flex={false}
      className="ph-no-capture"
      {...props}
    />
  );
};
