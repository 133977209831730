import { Box } from "ds/Box";
import { ColorLavender100 } from "ds/Core";
import styled from "styled-components";

export const StyledAvatarContainer = styled(Box)`
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${ColorLavender100};
  }
`;
