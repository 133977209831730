import { Button } from "ds/Button";
import { Box } from "grommet";
import { BoxProps } from "grommet/es6/components/Box";
import React from "react";
import { useTranslation } from "react-i18next";

import { turnTheme } from "theme";

import { Close } from "../icons/Close";
import { StyledCloseButton, StyledConfirmContainer } from "./Styles";

interface IConfirmProps extends BoxProps {
  onClick?: () => void;
  onClose?: () => void;
  title?: React.ReactNode;
  text?: React.ReactNode;
  btnCancelTitle?: string;
  btnConfirmTitle?: string;
  btnType?: "primary";
  className?: string;
  hideCloseBtn?: boolean;
}

export const Confirm = ({
  btnCancelTitle,
  btnConfirmTitle,
  hideCloseBtn,
  onClick,
  onClose,
  text,
  title,
}: IConfirmProps) => {
  const { t } = useTranslation();

  return (
    <StyledConfirmContainer width="455px" pad={hideCloseBtn ? "32px 32px 40px" : "40px 56px 56px 32px"}>
      {title}
      {text}
      {!hideCloseBtn && (
        <StyledCloseButton
          className="ignore-onclickoutside"
          icon={<Close fill={turnTheme.global.colors["dark-4"]} />}
          onClick={onClose}
        />
      )}
      <Box direction="row" gap="8px" margin={{ top: "35px" }}>
        <Button size="small" destructive className="ignore-onclickoutside" onClick={onClick} text={btnConfirmTitle} />
        <Button
          variation="secondary"
          className="ignore-onclickoutside"
          text={btnCancelTitle || t("btn.cancel")}
          onClick={onClose}
          size="small"
        />
      </Box>
    </StyledConfirmContainer>
  );
};
