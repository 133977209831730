/* tslint:disable */

/* eslint-disable */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as Types from "../../generated/types";
import { MessageDetailsFragment } from "../../messages/generated/fragments.generated";
import { MessageDetailsFragmentDoc } from "../../messages/generated/fragments.generated";
import { StackContainerDetailsFragment } from "./fragments.generated";
import { SimulatorFragment, SimulatorOutputStateFragment } from "./fragments.generated";
import { NotebookDetailsFragment } from "./fragments.generated";
import { StackContainerDetailsFragmentDoc } from "./fragments.generated";
import { SimulatorFragmentDoc, SimulatorOutputStateFragmentDoc } from "./fragments.generated";
import { NotebookDetailsFragmentDoc } from "./fragments.generated";

const defaultOptions = {} as const;
export type CreateNotebookMutationVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  name: Types.Scalars["String"];
  notebook: Types.NotebookIo;
}>;

export type CreateNotebookMutation = {
  __typename: "RootMutationType";
  createNotebook: { __typename: "StackContainer" } & StackContainerDetailsFragment;
};

export type UpdateNotebookMutationVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  stackContainerUuid: Types.Scalars["ID"];
  name: Types.Scalars["String"];
  notebook: Types.NotebookIo;
  publish?: Types.InputMaybe<Types.Scalars["Boolean"]>;
}>;

export type UpdateNotebookMutation = {
  __typename: "RootMutationType";
  updateNotebook: { __typename: "StackContainer" } & StackContainerDetailsFragment;
};

export type ToggleNotebookMutationVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  stackContainerUuid: Types.Scalars["ID"];
  enabled: Types.Scalars["Boolean"];
}>;

export type ToggleNotebookMutation = {
  __typename: "RootMutationType";
  toggleNotebook: { __typename: "StackContainer" } & StackContainerDetailsFragment;
};

export type DeleteNotebookMutationVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  stackContainerUuid: Types.Scalars["ID"];
}>;

export type DeleteNotebookMutation = {
  __typename: "RootMutationType";
  deleteNotebook: { __typename: "StackContainer"; uuid: string; name: string };
};

export type ConvertAllThreadsToJourneysMutationVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
}>;

export type ConvertAllThreadsToJourneysMutation = {
  __typename: "RootMutationType";
  convertAllThreadsToJourneys: string;
};

export type ConvertThreadToJourneyMutationVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  uuid: Types.Scalars["ID"];
}>;

export type ConvertThreadToJourneyMutation = {
  __typename: "RootMutationType";
  convertThreadToJourney: { __typename: "ThreadGraph"; uuid: string | null; title: string | null };
};

export type CreateSimulatorMutationVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  notebook: Types.NotebookIo;
  stackContainerUuid?: Types.InputMaybe<Types.Scalars["ID"]>;
}>;

export type CreateSimulatorMutation = {
  __typename: "RootMutationType";
  createSimulator: { __typename: "Simulator" } & SimulatorFragment;
};

export type DuplicateJourneyMutationVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  stackContainerUuid: Types.Scalars["ID"];
}>;

export type DuplicateJourneyMutation = {
  __typename: "RootMutationType";
  duplicateJourney: { __typename: "StackContainer"; uuid: string; isCanvas: boolean };
};

export type SubmitSimulatorInputMutationVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  uuid: Types.Scalars["ID"];
  vars: Types.Scalars["Json"];
  notebook: Types.NotebookIo;
  userInput: Types.Scalars["String"];
}>;

export type SubmitSimulatorInputMutation = {
  __typename: "RootMutationType";
  submitSimulatorInput: {
    __typename: "SimulatorResponse";
    input: string | null;
    state: string;
    simulator: { __typename: "Simulator" } & SimulatorFragment;
    outputs: Array<{ __typename: "SimulatorOutputState" } & SimulatorOutputStateFragment>;
  };
};

export type CreateJourneyGlobalDataMutationVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  data: Types.JourneyDataIo;
}>;

export type CreateJourneyGlobalDataMutation = {
  __typename: "RootMutationType";
  createJourneyGlobalData: {
    __typename: "JourneyData";
    name: string;
    uuid: string;
    data:
      | { __typename: "JourneyDataDictionary"; id: string; type: Types.JourneyDataPayloadType }
      | { __typename: "JourneyDataTable"; id: string; type: Types.JourneyDataPayloadType };
  };
};

export type UpdateJourneyGlobalDataMutationVariables = Types.Exact<{
  data: Types.JourneyDataIo;
  globalDataUuid: Types.Scalars["ID"];
  numberUuid: Types.Scalars["ID"];
}>;

export type UpdateJourneyGlobalDataMutation = {
  __typename: "RootMutationType";
  updateJourneyGlobalData: {
    __typename: "JourneyData";
    name: string;
    uuid: string;
    data:
      | { __typename: "JourneyDataDictionary"; id: string; type: Types.JourneyDataPayloadType }
      | { __typename: "JourneyDataTable"; id: string; type: Types.JourneyDataPayloadType };
  };
};

export type DeleteJourneyGlobalDataMutationVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  globalDataUuid: Types.Scalars["ID"];
}>;

export type DeleteJourneyGlobalDataMutation = {
  __typename: "RootMutationType";
  deleteJourneyGlobalData: {
    __typename: "JourneyData";
    name: string;
    uuid: string;
    data:
      | { __typename: "JourneyDataDictionary"; id: string; type: Types.JourneyDataPayloadType }
      | { __typename: "JourneyDataTable"; id: string; type: Types.JourneyDataPayloadType };
  };
};

export type CreateJourneyLocalDataMutationVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  stackContainerUuid: Types.Scalars["ID"];
  data: Types.JourneyDataIo;
}>;

export type CreateJourneyLocalDataMutation = {
  __typename: "RootMutationType";
  createJourneyLocalData: {
    __typename: "JourneyData";
    name: string;
    uuid: string;
    data:
      | { __typename: "JourneyDataDictionary"; id: string; type: Types.JourneyDataPayloadType }
      | { __typename: "JourneyDataTable"; id: string; type: Types.JourneyDataPayloadType };
  };
};

export type UpdateJourneyLocalDataMutationVariables = Types.Exact<{
  data: Types.JourneyDataIo;
  localDataUuid: Types.Scalars["ID"];
  numberUuid: Types.Scalars["ID"];
}>;

export type UpdateJourneyLocalDataMutation = {
  __typename: "RootMutationType";
  updateJourneyLocalData: {
    __typename: "JourneyData";
    name: string;
    uuid: string;
    data:
      | { __typename: "JourneyDataDictionary"; id: string; type: Types.JourneyDataPayloadType }
      | { __typename: "JourneyDataTable"; id: string; type: Types.JourneyDataPayloadType };
  };
};

export type DeleteJourneyLocalDataMutationVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  localDataUuid: Types.Scalars["ID"];
}>;

export type DeleteJourneyLocalDataMutation = {
  __typename: "RootMutationType";
  deleteJourneyLocalData: {
    __typename: "JourneyData";
    name: string;
    uuid: string;
    data:
      | { __typename: "JourneyDataDictionary"; id: string; type: Types.JourneyDataPayloadType }
      | { __typename: "JourneyDataTable"; id: string; type: Types.JourneyDataPayloadType };
  };
};

export type StartJourneyMutationVariables = Types.Exact<{
  chatUuid: Types.Scalars["ID"];
  numberUuid: Types.Scalars["ID"];
  stackContainerUuid: Types.Scalars["ID"];
}>;

export type StartJourneyMutation = {
  __typename: "RootMutationType";
  startJourney: { __typename: "Message" } & MessageDetailsFragment;
};

export type NameJourneyVersionMutationVariables = Types.Exact<{
  name: Types.Scalars["String"];
  notebookRevisionUuid: Types.Scalars["ID"];
  numberUuid: Types.Scalars["ID"];
}>;

export type NameJourneyVersionMutation = {
  __typename: "RootMutationType";
  nameNotebookRevision: {
    __typename: "NotebookRevisionPreview";
    uuid: string;
    versionName: string | null;
    accountUuid: string | null;
    insertedAt: any;
  };
};

export type RestoreJourneyVersionMutationVariables = Types.Exact<{
  journeyUuid: Types.Scalars["ID"];
  notebookRevisionUuid: Types.Scalars["ID"];
  numberUuid: Types.Scalars["ID"];
}>;

export type RestoreJourneyVersionMutation = {
  __typename: "RootMutationType";
  restoreNotebookRevision: {
    __typename: "StackContainer";
    uuid: string;
    notebook: { __typename: "NotebookType" } & NotebookDetailsFragment;
    notebookRevisions: {
      __typename: "NotebookRevisionConnection";
      hasNextPage: boolean;
      revisions: Array<{
        __typename: "NotebookRevisionPreview";
        accountUuid: string | null;
        insertedAt: any;
        uuid: string;
        versionName: string | null;
      } | null>;
    };
  };
};

export type PublishLatestNotebookRevisionMutationVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  stackContainerUuid: Types.Scalars["ID"];
}>;

export type PublishLatestNotebookRevisionMutation = {
  __typename: "RootMutationType";
  publishLatestNotebookRevision: {
    __typename: "StackContainer";
    uuid: string;
    productionNotebookRevisionUuid: string | null;
  };
};

export const CreateNotebookDocument = gql`
  mutation createNotebook($numberUuid: ID!, $name: String!, $notebook: NotebookIo!) {
    createNotebook(numberUuid: $numberUuid, name: $name, notebook: $notebook) {
      ...StackContainerDetails
    }
  }
  ${StackContainerDetailsFragmentDoc}
`;
export type CreateNotebookMutationFn = Apollo.MutationFunction<CreateNotebookMutation, CreateNotebookMutationVariables>;

/**
 * __useCreateNotebookMutation__
 *
 * To run a mutation, you first call `useCreateNotebookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotebookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotebookMutation, { data, loading, error }] = useCreateNotebookMutation({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      name: // value for 'name'
 *      notebook: // value for 'notebook'
 *   },
 * });
 */
export function useCreateNotebookMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateNotebookMutation, CreateNotebookMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNotebookMutation, CreateNotebookMutationVariables>(CreateNotebookDocument, options);
}
export type CreateNotebookMutationHookResult = ReturnType<typeof useCreateNotebookMutation>;
export type CreateNotebookMutationResult = Apollo.MutationResult<CreateNotebookMutation>;
export type CreateNotebookMutationOptions = Apollo.BaseMutationOptions<
  CreateNotebookMutation,
  CreateNotebookMutationVariables
>;
export const UpdateNotebookDocument = gql`
  mutation updateNotebook(
    $numberUuid: ID!
    $stackContainerUuid: ID!
    $name: String!
    $notebook: NotebookIo!
    $publish: Boolean
  ) {
    updateNotebook(
      numberUuid: $numberUuid
      stackContainerUuid: $stackContainerUuid
      name: $name
      notebook: $notebook
      publish: $publish
    ) {
      ...StackContainerDetails
    }
  }
  ${StackContainerDetailsFragmentDoc}
`;
export type UpdateNotebookMutationFn = Apollo.MutationFunction<UpdateNotebookMutation, UpdateNotebookMutationVariables>;

/**
 * __useUpdateNotebookMutation__
 *
 * To run a mutation, you first call `useUpdateNotebookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotebookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotebookMutation, { data, loading, error }] = useUpdateNotebookMutation({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      stackContainerUuid: // value for 'stackContainerUuid'
 *      name: // value for 'name'
 *      notebook: // value for 'notebook'
 *      publish: // value for 'publish'
 *   },
 * });
 */
export function useUpdateNotebookMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateNotebookMutation, UpdateNotebookMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateNotebookMutation, UpdateNotebookMutationVariables>(UpdateNotebookDocument, options);
}
export type UpdateNotebookMutationHookResult = ReturnType<typeof useUpdateNotebookMutation>;
export type UpdateNotebookMutationResult = Apollo.MutationResult<UpdateNotebookMutation>;
export type UpdateNotebookMutationOptions = Apollo.BaseMutationOptions<
  UpdateNotebookMutation,
  UpdateNotebookMutationVariables
>;
export const ToggleNotebookDocument = gql`
  mutation toggleNotebook($numberUuid: ID!, $stackContainerUuid: ID!, $enabled: Boolean!) {
    toggleNotebook(numberUuid: $numberUuid, stackContainerUuid: $stackContainerUuid, enabled: $enabled) {
      ...StackContainerDetails
    }
  }
  ${StackContainerDetailsFragmentDoc}
`;
export type ToggleNotebookMutationFn = Apollo.MutationFunction<ToggleNotebookMutation, ToggleNotebookMutationVariables>;

/**
 * __useToggleNotebookMutation__
 *
 * To run a mutation, you first call `useToggleNotebookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleNotebookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleNotebookMutation, { data, loading, error }] = useToggleNotebookMutation({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      stackContainerUuid: // value for 'stackContainerUuid'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useToggleNotebookMutation(
  baseOptions?: Apollo.MutationHookOptions<ToggleNotebookMutation, ToggleNotebookMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ToggleNotebookMutation, ToggleNotebookMutationVariables>(ToggleNotebookDocument, options);
}
export type ToggleNotebookMutationHookResult = ReturnType<typeof useToggleNotebookMutation>;
export type ToggleNotebookMutationResult = Apollo.MutationResult<ToggleNotebookMutation>;
export type ToggleNotebookMutationOptions = Apollo.BaseMutationOptions<
  ToggleNotebookMutation,
  ToggleNotebookMutationVariables
>;
export const DeleteNotebookDocument = gql`
  mutation deleteNotebook($numberUuid: ID!, $stackContainerUuid: ID!) {
    deleteNotebook(numberUuid: $numberUuid, stackContainerUuid: $stackContainerUuid) {
      uuid
      name
    }
  }
`;
export type DeleteNotebookMutationFn = Apollo.MutationFunction<DeleteNotebookMutation, DeleteNotebookMutationVariables>;

/**
 * __useDeleteNotebookMutation__
 *
 * To run a mutation, you first call `useDeleteNotebookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotebookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotebookMutation, { data, loading, error }] = useDeleteNotebookMutation({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      stackContainerUuid: // value for 'stackContainerUuid'
 *   },
 * });
 */
export function useDeleteNotebookMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteNotebookMutation, DeleteNotebookMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteNotebookMutation, DeleteNotebookMutationVariables>(DeleteNotebookDocument, options);
}
export type DeleteNotebookMutationHookResult = ReturnType<typeof useDeleteNotebookMutation>;
export type DeleteNotebookMutationResult = Apollo.MutationResult<DeleteNotebookMutation>;
export type DeleteNotebookMutationOptions = Apollo.BaseMutationOptions<
  DeleteNotebookMutation,
  DeleteNotebookMutationVariables
>;
export const ConvertAllThreadsToJourneysDocument = gql`
  mutation convertAllThreadsToJourneys($numberUuid: ID!) {
    convertAllThreadsToJourneys(numberUuid: $numberUuid)
  }
`;
export type ConvertAllThreadsToJourneysMutationFn = Apollo.MutationFunction<
  ConvertAllThreadsToJourneysMutation,
  ConvertAllThreadsToJourneysMutationVariables
>;

/**
 * __useConvertAllThreadsToJourneysMutation__
 *
 * To run a mutation, you first call `useConvertAllThreadsToJourneysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertAllThreadsToJourneysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertAllThreadsToJourneysMutation, { data, loading, error }] = useConvertAllThreadsToJourneysMutation({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *   },
 * });
 */
export function useConvertAllThreadsToJourneysMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConvertAllThreadsToJourneysMutation,
    ConvertAllThreadsToJourneysMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConvertAllThreadsToJourneysMutation, ConvertAllThreadsToJourneysMutationVariables>(
    ConvertAllThreadsToJourneysDocument,
    options
  );
}
export type ConvertAllThreadsToJourneysMutationHookResult = ReturnType<typeof useConvertAllThreadsToJourneysMutation>;
export type ConvertAllThreadsToJourneysMutationResult = Apollo.MutationResult<ConvertAllThreadsToJourneysMutation>;
export type ConvertAllThreadsToJourneysMutationOptions = Apollo.BaseMutationOptions<
  ConvertAllThreadsToJourneysMutation,
  ConvertAllThreadsToJourneysMutationVariables
>;
export const ConvertThreadToJourneyDocument = gql`
  mutation convertThreadToJourney($numberUuid: ID!, $uuid: ID!) {
    convertThreadToJourney(numberUuid: $numberUuid, uuid: $uuid) {
      uuid
      title
    }
  }
`;
export type ConvertThreadToJourneyMutationFn = Apollo.MutationFunction<
  ConvertThreadToJourneyMutation,
  ConvertThreadToJourneyMutationVariables
>;

/**
 * __useConvertThreadToJourneyMutation__
 *
 * To run a mutation, you first call `useConvertThreadToJourneyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertThreadToJourneyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertThreadToJourneyMutation, { data, loading, error }] = useConvertThreadToJourneyMutation({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useConvertThreadToJourneyMutation(
  baseOptions?: Apollo.MutationHookOptions<ConvertThreadToJourneyMutation, ConvertThreadToJourneyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConvertThreadToJourneyMutation, ConvertThreadToJourneyMutationVariables>(
    ConvertThreadToJourneyDocument,
    options
  );
}
export type ConvertThreadToJourneyMutationHookResult = ReturnType<typeof useConvertThreadToJourneyMutation>;
export type ConvertThreadToJourneyMutationResult = Apollo.MutationResult<ConvertThreadToJourneyMutation>;
export type ConvertThreadToJourneyMutationOptions = Apollo.BaseMutationOptions<
  ConvertThreadToJourneyMutation,
  ConvertThreadToJourneyMutationVariables
>;
export const CreateSimulatorDocument = gql`
  mutation createSimulator($numberUuid: ID!, $notebook: NotebookIo!, $stackContainerUuid: ID) {
    createSimulator(numberUuid: $numberUuid, notebook: $notebook, stackContainerUuid: $stackContainerUuid) {
      ...SimulatorFragment
    }
  }
  ${SimulatorFragmentDoc}
`;
export type CreateSimulatorMutationFn = Apollo.MutationFunction<
  CreateSimulatorMutation,
  CreateSimulatorMutationVariables
>;

/**
 * __useCreateSimulatorMutation__
 *
 * To run a mutation, you first call `useCreateSimulatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSimulatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSimulatorMutation, { data, loading, error }] = useCreateSimulatorMutation({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      notebook: // value for 'notebook'
 *      stackContainerUuid: // value for 'stackContainerUuid'
 *   },
 * });
 */
export function useCreateSimulatorMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSimulatorMutation, CreateSimulatorMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSimulatorMutation, CreateSimulatorMutationVariables>(
    CreateSimulatorDocument,
    options
  );
}
export type CreateSimulatorMutationHookResult = ReturnType<typeof useCreateSimulatorMutation>;
export type CreateSimulatorMutationResult = Apollo.MutationResult<CreateSimulatorMutation>;
export type CreateSimulatorMutationOptions = Apollo.BaseMutationOptions<
  CreateSimulatorMutation,
  CreateSimulatorMutationVariables
>;
export const DuplicateJourneyDocument = gql`
  mutation duplicateJourney($numberUuid: ID!, $stackContainerUuid: ID!) {
    duplicateJourney(numberUuid: $numberUuid, stackContainerUuid: $stackContainerUuid) {
      uuid
      isCanvas
    }
  }
`;
export type DuplicateJourneyMutationFn = Apollo.MutationFunction<
  DuplicateJourneyMutation,
  DuplicateJourneyMutationVariables
>;

/**
 * __useDuplicateJourneyMutation__
 *
 * To run a mutation, you first call `useDuplicateJourneyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateJourneyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateJourneyMutation, { data, loading, error }] = useDuplicateJourneyMutation({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      stackContainerUuid: // value for 'stackContainerUuid'
 *   },
 * });
 */
export function useDuplicateJourneyMutation(
  baseOptions?: Apollo.MutationHookOptions<DuplicateJourneyMutation, DuplicateJourneyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DuplicateJourneyMutation, DuplicateJourneyMutationVariables>(
    DuplicateJourneyDocument,
    options
  );
}
export type DuplicateJourneyMutationHookResult = ReturnType<typeof useDuplicateJourneyMutation>;
export type DuplicateJourneyMutationResult = Apollo.MutationResult<DuplicateJourneyMutation>;
export type DuplicateJourneyMutationOptions = Apollo.BaseMutationOptions<
  DuplicateJourneyMutation,
  DuplicateJourneyMutationVariables
>;
export const SubmitSimulatorInputDocument = gql`
  mutation submitSimulatorInput(
    $numberUuid: ID!
    $uuid: ID!
    $vars: Json!
    $notebook: NotebookIo!
    $userInput: String!
  ) {
    submitSimulatorInput(
      numberUuid: $numberUuid
      uuid: $uuid
      vars: $vars
      notebook: $notebook
      userInput: $userInput
    ) {
      simulator {
        ...SimulatorFragment
      }
      input
      state
      outputs {
        ...SimulatorOutputStateFragment
      }
    }
  }
  ${SimulatorFragmentDoc}
  ${SimulatorOutputStateFragmentDoc}
`;
export type SubmitSimulatorInputMutationFn = Apollo.MutationFunction<
  SubmitSimulatorInputMutation,
  SubmitSimulatorInputMutationVariables
>;

/**
 * __useSubmitSimulatorInputMutation__
 *
 * To run a mutation, you first call `useSubmitSimulatorInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitSimulatorInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitSimulatorInputMutation, { data, loading, error }] = useSubmitSimulatorInputMutation({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      uuid: // value for 'uuid'
 *      vars: // value for 'vars'
 *      notebook: // value for 'notebook'
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useSubmitSimulatorInputMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitSimulatorInputMutation, SubmitSimulatorInputMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitSimulatorInputMutation, SubmitSimulatorInputMutationVariables>(
    SubmitSimulatorInputDocument,
    options
  );
}
export type SubmitSimulatorInputMutationHookResult = ReturnType<typeof useSubmitSimulatorInputMutation>;
export type SubmitSimulatorInputMutationResult = Apollo.MutationResult<SubmitSimulatorInputMutation>;
export type SubmitSimulatorInputMutationOptions = Apollo.BaseMutationOptions<
  SubmitSimulatorInputMutation,
  SubmitSimulatorInputMutationVariables
>;
export const CreateJourneyGlobalDataDocument = gql`
  mutation createJourneyGlobalData($numberUuid: ID!, $data: JourneyDataIo!) {
    createJourneyGlobalData(numberUuid: $numberUuid, data: $data) {
      data {
        id
        type
      }
      name
      uuid
    }
  }
`;
export type CreateJourneyGlobalDataMutationFn = Apollo.MutationFunction<
  CreateJourneyGlobalDataMutation,
  CreateJourneyGlobalDataMutationVariables
>;

/**
 * __useCreateJourneyGlobalDataMutation__
 *
 * To run a mutation, you first call `useCreateJourneyGlobalDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJourneyGlobalDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJourneyGlobalDataMutation, { data, loading, error }] = useCreateJourneyGlobalDataMutation({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateJourneyGlobalDataMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateJourneyGlobalDataMutation, CreateJourneyGlobalDataMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateJourneyGlobalDataMutation, CreateJourneyGlobalDataMutationVariables>(
    CreateJourneyGlobalDataDocument,
    options
  );
}
export type CreateJourneyGlobalDataMutationHookResult = ReturnType<typeof useCreateJourneyGlobalDataMutation>;
export type CreateJourneyGlobalDataMutationResult = Apollo.MutationResult<CreateJourneyGlobalDataMutation>;
export type CreateJourneyGlobalDataMutationOptions = Apollo.BaseMutationOptions<
  CreateJourneyGlobalDataMutation,
  CreateJourneyGlobalDataMutationVariables
>;
export const UpdateJourneyGlobalDataDocument = gql`
  mutation updateJourneyGlobalData($data: JourneyDataIo!, $globalDataUuid: ID!, $numberUuid: ID!) {
    updateJourneyGlobalData(data: $data, globalDataUuid: $globalDataUuid, numberUuid: $numberUuid) {
      data {
        id
        type
      }
      name
      uuid
    }
  }
`;
export type UpdateJourneyGlobalDataMutationFn = Apollo.MutationFunction<
  UpdateJourneyGlobalDataMutation,
  UpdateJourneyGlobalDataMutationVariables
>;

/**
 * __useUpdateJourneyGlobalDataMutation__
 *
 * To run a mutation, you first call `useUpdateJourneyGlobalDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJourneyGlobalDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJourneyGlobalDataMutation, { data, loading, error }] = useUpdateJourneyGlobalDataMutation({
 *   variables: {
 *      data: // value for 'data'
 *      globalDataUuid: // value for 'globalDataUuid'
 *      numberUuid: // value for 'numberUuid'
 *   },
 * });
 */
export function useUpdateJourneyGlobalDataMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateJourneyGlobalDataMutation, UpdateJourneyGlobalDataMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateJourneyGlobalDataMutation, UpdateJourneyGlobalDataMutationVariables>(
    UpdateJourneyGlobalDataDocument,
    options
  );
}
export type UpdateJourneyGlobalDataMutationHookResult = ReturnType<typeof useUpdateJourneyGlobalDataMutation>;
export type UpdateJourneyGlobalDataMutationResult = Apollo.MutationResult<UpdateJourneyGlobalDataMutation>;
export type UpdateJourneyGlobalDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateJourneyGlobalDataMutation,
  UpdateJourneyGlobalDataMutationVariables
>;
export const DeleteJourneyGlobalDataDocument = gql`
  mutation deleteJourneyGlobalData($numberUuid: ID!, $globalDataUuid: ID!) {
    deleteJourneyGlobalData(numberUuid: $numberUuid, globalDataUuid: $globalDataUuid) {
      data {
        id
        type
      }
      name
      uuid
    }
  }
`;
export type DeleteJourneyGlobalDataMutationFn = Apollo.MutationFunction<
  DeleteJourneyGlobalDataMutation,
  DeleteJourneyGlobalDataMutationVariables
>;

/**
 * __useDeleteJourneyGlobalDataMutation__
 *
 * To run a mutation, you first call `useDeleteJourneyGlobalDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJourneyGlobalDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJourneyGlobalDataMutation, { data, loading, error }] = useDeleteJourneyGlobalDataMutation({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      globalDataUuid: // value for 'globalDataUuid'
 *   },
 * });
 */
export function useDeleteJourneyGlobalDataMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteJourneyGlobalDataMutation, DeleteJourneyGlobalDataMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteJourneyGlobalDataMutation, DeleteJourneyGlobalDataMutationVariables>(
    DeleteJourneyGlobalDataDocument,
    options
  );
}
export type DeleteJourneyGlobalDataMutationHookResult = ReturnType<typeof useDeleteJourneyGlobalDataMutation>;
export type DeleteJourneyGlobalDataMutationResult = Apollo.MutationResult<DeleteJourneyGlobalDataMutation>;
export type DeleteJourneyGlobalDataMutationOptions = Apollo.BaseMutationOptions<
  DeleteJourneyGlobalDataMutation,
  DeleteJourneyGlobalDataMutationVariables
>;
export const CreateJourneyLocalDataDocument = gql`
  mutation createJourneyLocalData($numberUuid: ID!, $stackContainerUuid: ID!, $data: JourneyDataIo!) {
    createJourneyLocalData(numberUuid: $numberUuid, stackContainerUuid: $stackContainerUuid, data: $data) {
      data {
        id
        type
      }
      name
      uuid
    }
  }
`;
export type CreateJourneyLocalDataMutationFn = Apollo.MutationFunction<
  CreateJourneyLocalDataMutation,
  CreateJourneyLocalDataMutationVariables
>;

/**
 * __useCreateJourneyLocalDataMutation__
 *
 * To run a mutation, you first call `useCreateJourneyLocalDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJourneyLocalDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJourneyLocalDataMutation, { data, loading, error }] = useCreateJourneyLocalDataMutation({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      stackContainerUuid: // value for 'stackContainerUuid'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateJourneyLocalDataMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateJourneyLocalDataMutation, CreateJourneyLocalDataMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateJourneyLocalDataMutation, CreateJourneyLocalDataMutationVariables>(
    CreateJourneyLocalDataDocument,
    options
  );
}
export type CreateJourneyLocalDataMutationHookResult = ReturnType<typeof useCreateJourneyLocalDataMutation>;
export type CreateJourneyLocalDataMutationResult = Apollo.MutationResult<CreateJourneyLocalDataMutation>;
export type CreateJourneyLocalDataMutationOptions = Apollo.BaseMutationOptions<
  CreateJourneyLocalDataMutation,
  CreateJourneyLocalDataMutationVariables
>;
export const UpdateJourneyLocalDataDocument = gql`
  mutation updateJourneyLocalData($data: JourneyDataIo!, $localDataUuid: ID!, $numberUuid: ID!) {
    updateJourneyLocalData(data: $data, localDataUuid: $localDataUuid, numberUuid: $numberUuid) {
      data {
        id
        type
      }
      name
      uuid
    }
  }
`;
export type UpdateJourneyLocalDataMutationFn = Apollo.MutationFunction<
  UpdateJourneyLocalDataMutation,
  UpdateJourneyLocalDataMutationVariables
>;

/**
 * __useUpdateJourneyLocalDataMutation__
 *
 * To run a mutation, you first call `useUpdateJourneyLocalDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJourneyLocalDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJourneyLocalDataMutation, { data, loading, error }] = useUpdateJourneyLocalDataMutation({
 *   variables: {
 *      data: // value for 'data'
 *      localDataUuid: // value for 'localDataUuid'
 *      numberUuid: // value for 'numberUuid'
 *   },
 * });
 */
export function useUpdateJourneyLocalDataMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateJourneyLocalDataMutation, UpdateJourneyLocalDataMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateJourneyLocalDataMutation, UpdateJourneyLocalDataMutationVariables>(
    UpdateJourneyLocalDataDocument,
    options
  );
}
export type UpdateJourneyLocalDataMutationHookResult = ReturnType<typeof useUpdateJourneyLocalDataMutation>;
export type UpdateJourneyLocalDataMutationResult = Apollo.MutationResult<UpdateJourneyLocalDataMutation>;
export type UpdateJourneyLocalDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateJourneyLocalDataMutation,
  UpdateJourneyLocalDataMutationVariables
>;
export const DeleteJourneyLocalDataDocument = gql`
  mutation deleteJourneyLocalData($numberUuid: ID!, $localDataUuid: ID!) {
    deleteJourneyLocalData(numberUuid: $numberUuid, localDataUuid: $localDataUuid) {
      data {
        id
        type
      }
      name
      uuid
    }
  }
`;
export type DeleteJourneyLocalDataMutationFn = Apollo.MutationFunction<
  DeleteJourneyLocalDataMutation,
  DeleteJourneyLocalDataMutationVariables
>;

/**
 * __useDeleteJourneyLocalDataMutation__
 *
 * To run a mutation, you first call `useDeleteJourneyLocalDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJourneyLocalDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJourneyLocalDataMutation, { data, loading, error }] = useDeleteJourneyLocalDataMutation({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      localDataUuid: // value for 'localDataUuid'
 *   },
 * });
 */
export function useDeleteJourneyLocalDataMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteJourneyLocalDataMutation, DeleteJourneyLocalDataMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteJourneyLocalDataMutation, DeleteJourneyLocalDataMutationVariables>(
    DeleteJourneyLocalDataDocument,
    options
  );
}
export type DeleteJourneyLocalDataMutationHookResult = ReturnType<typeof useDeleteJourneyLocalDataMutation>;
export type DeleteJourneyLocalDataMutationResult = Apollo.MutationResult<DeleteJourneyLocalDataMutation>;
export type DeleteJourneyLocalDataMutationOptions = Apollo.BaseMutationOptions<
  DeleteJourneyLocalDataMutation,
  DeleteJourneyLocalDataMutationVariables
>;
export const StartJourneyDocument = gql`
  mutation startJourney($chatUuid: ID!, $numberUuid: ID!, $stackContainerUuid: ID!) {
    startJourney(chatUuid: $chatUuid, numberUuid: $numberUuid, stackContainerUuid: $stackContainerUuid) {
      ...MessageDetails
    }
  }
  ${MessageDetailsFragmentDoc}
`;
export type StartJourneyMutationFn = Apollo.MutationFunction<StartJourneyMutation, StartJourneyMutationVariables>;

/**
 * __useStartJourneyMutation__
 *
 * To run a mutation, you first call `useStartJourneyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartJourneyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startJourneyMutation, { data, loading, error }] = useStartJourneyMutation({
 *   variables: {
 *      chatUuid: // value for 'chatUuid'
 *      numberUuid: // value for 'numberUuid'
 *      stackContainerUuid: // value for 'stackContainerUuid'
 *   },
 * });
 */
export function useStartJourneyMutation(
  baseOptions?: Apollo.MutationHookOptions<StartJourneyMutation, StartJourneyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StartJourneyMutation, StartJourneyMutationVariables>(StartJourneyDocument, options);
}
export type StartJourneyMutationHookResult = ReturnType<typeof useStartJourneyMutation>;
export type StartJourneyMutationResult = Apollo.MutationResult<StartJourneyMutation>;
export type StartJourneyMutationOptions = Apollo.BaseMutationOptions<
  StartJourneyMutation,
  StartJourneyMutationVariables
>;
export const NameJourneyVersionDocument = gql`
  mutation nameJourneyVersion($name: String!, $notebookRevisionUuid: ID!, $numberUuid: ID!) {
    nameNotebookRevision(name: $name, notebookRevisionUuid: $notebookRevisionUuid, numberUuid: $numberUuid) {
      uuid
      versionName
      accountUuid
      insertedAt
    }
  }
`;
export type NameJourneyVersionMutationFn = Apollo.MutationFunction<
  NameJourneyVersionMutation,
  NameJourneyVersionMutationVariables
>;

/**
 * __useNameJourneyVersionMutation__
 *
 * To run a mutation, you first call `useNameJourneyVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNameJourneyVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nameJourneyVersionMutation, { data, loading, error }] = useNameJourneyVersionMutation({
 *   variables: {
 *      name: // value for 'name'
 *      notebookRevisionUuid: // value for 'notebookRevisionUuid'
 *      numberUuid: // value for 'numberUuid'
 *   },
 * });
 */
export function useNameJourneyVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<NameJourneyVersionMutation, NameJourneyVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<NameJourneyVersionMutation, NameJourneyVersionMutationVariables>(
    NameJourneyVersionDocument,
    options
  );
}
export type NameJourneyVersionMutationHookResult = ReturnType<typeof useNameJourneyVersionMutation>;
export type NameJourneyVersionMutationResult = Apollo.MutationResult<NameJourneyVersionMutation>;
export type NameJourneyVersionMutationOptions = Apollo.BaseMutationOptions<
  NameJourneyVersionMutation,
  NameJourneyVersionMutationVariables
>;
export const RestoreJourneyVersionDocument = gql`
  mutation restoreJourneyVersion($journeyUuid: ID!, $notebookRevisionUuid: ID!, $numberUuid: ID!) {
    restoreNotebookRevision(
      journeyUuid: $journeyUuid
      notebookRevisionUuid: $notebookRevisionUuid
      numberUuid: $numberUuid
    ) {
      uuid
      notebook {
        ...NotebookDetails
      }
      notebookRevisions(limit: 1, offset: 0) {
        hasNextPage
        revisions {
          accountUuid
          insertedAt
          uuid
          versionName
        }
      }
    }
  }
  ${NotebookDetailsFragmentDoc}
`;
export type RestoreJourneyVersionMutationFn = Apollo.MutationFunction<
  RestoreJourneyVersionMutation,
  RestoreJourneyVersionMutationVariables
>;

/**
 * __useRestoreJourneyVersionMutation__
 *
 * To run a mutation, you first call `useRestoreJourneyVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreJourneyVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreJourneyVersionMutation, { data, loading, error }] = useRestoreJourneyVersionMutation({
 *   variables: {
 *      journeyUuid: // value for 'journeyUuid'
 *      notebookRevisionUuid: // value for 'notebookRevisionUuid'
 *      numberUuid: // value for 'numberUuid'
 *   },
 * });
 */
export function useRestoreJourneyVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<RestoreJourneyVersionMutation, RestoreJourneyVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestoreJourneyVersionMutation, RestoreJourneyVersionMutationVariables>(
    RestoreJourneyVersionDocument,
    options
  );
}
export type RestoreJourneyVersionMutationHookResult = ReturnType<typeof useRestoreJourneyVersionMutation>;
export type RestoreJourneyVersionMutationResult = Apollo.MutationResult<RestoreJourneyVersionMutation>;
export type RestoreJourneyVersionMutationOptions = Apollo.BaseMutationOptions<
  RestoreJourneyVersionMutation,
  RestoreJourneyVersionMutationVariables
>;
export const PublishLatestNotebookRevisionDocument = gql`
  mutation publishLatestNotebookRevision($numberUuid: ID!, $stackContainerUuid: ID!) {
    publishLatestNotebookRevision(numberUuid: $numberUuid, stackContainerUuid: $stackContainerUuid) {
      uuid
      productionNotebookRevisionUuid
    }
  }
`;
export type PublishLatestNotebookRevisionMutationFn = Apollo.MutationFunction<
  PublishLatestNotebookRevisionMutation,
  PublishLatestNotebookRevisionMutationVariables
>;

/**
 * __usePublishLatestNotebookRevisionMutation__
 *
 * To run a mutation, you first call `usePublishLatestNotebookRevisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishLatestNotebookRevisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishLatestNotebookRevisionMutation, { data, loading, error }] = usePublishLatestNotebookRevisionMutation({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      stackContainerUuid: // value for 'stackContainerUuid'
 *   },
 * });
 */
export function usePublishLatestNotebookRevisionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishLatestNotebookRevisionMutation,
    PublishLatestNotebookRevisionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PublishLatestNotebookRevisionMutation, PublishLatestNotebookRevisionMutationVariables>(
    PublishLatestNotebookRevisionDocument,
    options
  );
}
export type PublishLatestNotebookRevisionMutationHookResult = ReturnType<
  typeof usePublishLatestNotebookRevisionMutation
>;
export type PublishLatestNotebookRevisionMutationResult = Apollo.MutationResult<PublishLatestNotebookRevisionMutation>;
export type PublishLatestNotebookRevisionMutationOptions = Apollo.BaseMutationOptions<
  PublishLatestNotebookRevisionMutation,
  PublishLatestNotebookRevisionMutationVariables
>;
