import { DefaultTheme } from "styled-components";

import { colors, getColor } from "./colors";
import { spaces } from "./spacing";

declare module "styled-components" {
  export interface DefaultTheme {
    colors: typeof colors;
    getColor: typeof getColor;
    spaces: typeof spaces;
  }
}

export const theme: DefaultTheme = {
  colors,
  getColor,
  spaces,
};
