import { Drop } from "ds/Drop/src";
import isEmpty from "lodash/isEmpty.js";
import React, { useEffect, useState } from "react";

import { DropdownItem } from "./DropdownItem";
import { StyledDropContent, StyledDropFooter, StyledDropHeader } from "./styles";
import { DropdownProps, DropdownSize, DropdownVariation } from "./types";

const Dropdown = ({
  avatar,
  children,
  closeOnClick = true,
  content,
  contentProps,
  description,
  disabled,
  disableOutsideClick,
  drop,
  dropProps,
  footer,
  header,
  headerProps,
  icon,
  isLevelDrop,
  itemProps,
  label,
  loading,
  loadingText,
  onHide,
  onShow,
  position,
  size = DropdownSize.LARGE,
  stopPropagation = false,
  trigger,
  variation = DropdownVariation.PRIMARY,
  visible,
  ...rest
}: DropdownProps) => {
  const [open, setOpen] = useState(visible);
  const handleOpen = () => {
    setOpen(true);
    onShow?.();
  };
  const handleClose = () => {
    setOpen(false);
    onHide?.();
  };

  useEffect(() => {
    setOpen(visible);
  }, [visible]);

  const onClickOnItem =
    (callback?: React.MouseEventHandler<HTMLAnchorElement>, href?: string) =>
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (stopPropagation) {
        e.stopPropagation();
      }
      if (callback || href) {
        callback?.(e);
        if (closeOnClick) {
          handleClose();
        }
      }
    };

  const getDropdownItems = (
    items?: React.ReactElement<DropdownProps> | React.ReactElement<DropdownProps>[] | React.ReactNode
  ) =>
    React.Children.map(items, (item) => {
      if (React.isValidElement(item)) {
        const { size: childSize, children, onClick, href, ...props } = item.props;
        const conditionalProps = !children ? { onClick: onClickOnItem(onClick, href), href, ...props } : {};

        return React.cloneElement(item, {
          size: childSize || itemProps?.size || size,
          children: getDropdownItems(children),
          variation,
          ...conditionalProps,
        });
      } else {
        return item;
      }
    });

  const dropdownContent = children ?? content;

  return (
    <Drop
      disabled={disabled}
      loading={loading}
      loadingText={loadingText}
      visible={open}
      onShow={handleOpen}
      onHide={handleClose}
      disableOutsideClick={disableOutsideClick}
      position={position}
      dropProps={dropProps}
      isLevelDrop={isLevelDrop}
      trigger={
        trigger ? (
          React.cloneElement(trigger, {
            variation,
            secondLevel: true,
            position,
            icon,
            label,
            avatar,
            active: open,
            ...rest,
            ...trigger.props,
          })
        ) : (
          <DropdownItem
            size={size}
            variation={variation}
            secondLevel
            position={position}
            icon={icon}
            label={label}
            description={description}
            avatar={avatar}
            active={open}
            {...rest}
          />
        )
      }
      {...drop}
    >
      {!isEmpty(header) && (
        <StyledDropHeader hasContent={!!dropdownContent} hasFooter={!!footer} {...headerProps}>
          {getDropdownItems(header)}
        </StyledDropHeader>
      )}
      {!isEmpty(dropdownContent) && (
        <StyledDropContent hasHeader={!isEmpty(header)} hasFooter={!isEmpty(footer)} {...contentProps}>
          {getDropdownItems(dropdownContent)}
        </StyledDropContent>
      )}
      {!isEmpty(footer) && <StyledDropFooter>{getDropdownItems(footer)}</StyledDropFooter>}
    </Drop>
  );
};

Dropdown.displayName = "Dropdown";

export { Dropdown };
