import * as React from "react";

import { TDefaultIconProps } from "./types";

export const Logo = ({ fill = "#00B6BF", size = 48, ...props }: TDefaultIconProps) => (
  <svg width={size} height={size} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill={fill} cx="24" cy="24" r="24" />
      <path
        d="M29.997 34.257c-.67.297-1.457.445-2.36.445-1.725 0-2.883-.626-3.474-1.879-.333-.709-.5-1.766-.5-3.17v-9.275h5.253v-5.592h-5.252v-.006h-.037V8s-3.445 1.977-6.622 3.843v2.937H17v14.874c0 2.822.356 4.958 1.07 6.407C19.343 38.687 21.816 40 25.487 40c1.275 0 2.478-.075 3.426-.407a6.796 6.796 0 0 0 1.08-.493c.008-1.908.008-2.673.003-4.843"
        fill="#FFF"
      />
    </g>
  </svg>
);
