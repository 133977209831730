import { Avatar } from "ds/Avatar";
import { Box } from "ds/Box";
import { Button } from "ds/Button";
import { Dropdown } from "ds/Dropdown";
import { DropdownSize } from "ds/Dropdown/src/types";
import { NavItemProps, NavType, Navs } from "ds/Navs";
import { Tooltip } from "ds/Tooltip";
import { Icon } from "ds/icons";
import { ButtonProps } from "ds/main";
import React from "react";
import { down } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";

import { StyledAvatarContainer } from "./styles";
import { MenuProps } from "./types";

export interface NavigationFooterProps {
  collapsed?: boolean;
  help?: ButtonProps;
  menu?: MenuProps;
  qrAction?: ButtonProps;
  feedback?: NavItemProps;
}

// context(patricia-montoya, 2023-10-11): TODO Avoid to rewrite all these props, should be passed only those props that really needs to be RE-define
export const NavigationFooter = ({ collapsed, help, menu, qrAction, feedback }: NavigationFooterProps) => {
  const isMobile = useBreakpoint(down("lg"));
  const { tooltip, visible, onShow, onHide, ...menuProps } = menu ?? {};

  return (
    <>
      {feedback && !collapsed && (
        <Box
          alignItems={collapsed ? "center" : "stretch"}
          py="100"
          px={collapsed ? "150" : "100"}
          flex={0}
          borderBottomColor="borderColorDefault"
          borderBottomWidth={collapsed ? "0" : "1px"}
          borderBottomStyle="solid"
        >
          <Navs
            items={
              collapsed
                ? [
                    {
                      ...feedback,
                      type: NavType.ICON,
                    },
                  ]
                : [feedback]
            }
          />
        </Box>
      )}
      <Box
        flexDirection={collapsed ? "column" : "row"}
        justifyContent={collapsed ? "center" : "space-between"}
        padding="150"
        alignItems="center"
        ml={collapsed ? "0" : "50"}
      >
        {qrAction && <Button size="small" {...qrAction} />}
        <Box flexDirection={collapsed ? "column" : "row"} alignItems="center">
          {help && <Button variation="flat" size="small" icon={<Icon name="HelpCircleIcon" />} {...help} />}
          {feedback && collapsed && (
            <Button
              size="small"
              variation="flat"
              url={feedback?.anchor?.href}
              target={feedback?.anchor?.target}
              icon={feedback?.iconName ? <Icon name={feedback.iconName} /> : undefined}
              tooltip={{ placement: "right", content: feedback.text }}
            />
          )}
          {menu && (
            <Dropdown
              visible={visible}
              onShow={onShow}
              onHide={onHide}
              itemProps={{ size: isMobile ? DropdownSize.SMALL : DropdownSize.LARGE }}
              size={DropdownSize.SMALL}
              trigger={
                collapsed ? (
                  <Tooltip
                    placement="right"
                    showPointer={false}
                    trigger={
                      <StyledAvatarContainer
                        onClick={() => (visible ? onHide?.() : onShow?.())}
                        data-testid={menuProps["data-testid"]}
                      >
                        <Avatar
                          label={menu?.avatar?.label}
                          url={menu?.avatar?.url}
                          size="xsmall"
                          style={{ cursor: "pointer" }}
                        />
                      </StyledAvatarContainer>
                    }
                    {...tooltip}
                  />
                ) : undefined
              }
              px="50"
              {...menuProps}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
