import { Box, Text } from "grommet";
import React from "react";
import BarLoader from "react-spinners/BarLoader";

import { turnTheme } from "theme";

interface ICollectionReplyCardLoaderProps {
  loading?: boolean;
  title?: string;
  children: React.ReactNode;
}

export const CollectionReplyCardLoader = ({ loading, title, children }: ICollectionReplyCardLoaderProps) => {
  return loading ? (
    <Box flex direction="column" alignSelf="stretch" fill="horizontal" justify="center" align="center">
      {title && (
        <Text size="medium" color="brand" margin={{ bottom: "medium" }}>
          {title}
        </Text>
      )}
      <BarLoader width={50} color={turnTheme.global.colors["brand"]} />
    </Box>
  ) : (
    <>{children}</>
  );
};
