import { useEffect } from "react";

export const usePageTitle = (pageTitle: string) => {
  useEffect(() => {
    document.title = pageTitle ? `${pageTitle} - Turn` : "Turn";

    return () => {
      document.title = "Turn";
    };
  }, [pageTitle]);
};
