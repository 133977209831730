import { Box } from "grommet";
import React from "react";
import { ToastContainer, Zoom } from "react-toastify";
import styled from "styled-components";

import { CloseIconSolid } from "../icons/CloseIconSolid";

import "react-toastify/dist/ReactToastify.css";

/**
 * Note:  We have to use custom CSS here to override default react-toastifty styles.
 * This will change if we ever decide to build our own notification components.
 * For now react-toastify does the job quickly and easily.
 */

const StyledToast = styled(Box)`
  .Toastify__toast-container {
    width: 100%;
    top: 0;
    left: 0;
    padding: 0;
  }
  .Toastify__toast {
  }
  .Toastify__toast--error {
    min-height: 48px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #db4b50;
    .Toastify__toast-body {
      font-family: Inter;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #ffffff;
    }
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;

const CloseButton = ({ closeToast }) => <CloseIconSolid onClick={closeToast} />;

export const FullToast = (props) => (
  <StyledToast>
    <ToastContainer
      position="top-left"
      autoClose={false}
      newestOnTop
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      closeButton={<CloseButton />}
      enableMultiContainer
      containerId="fullToast"
      transition={Zoom}
      icon={false}
      {...props}
    />
  </StyledToast>
);
