import * as React from "react";

import { TDefaultIconProps } from "./types";

export const HelpIcon = ({ fill = "#7A8285", size = 16, ...props }: TDefaultIconProps) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.333 8A6.67 6.67 0 018 1.333 6.67 6.67 0 0114.667 8 6.67 6.67 0 018 14.667 6.669 6.669 0 011.333 8zm1.334 0A5.34 5.34 0 008 13.333 5.34 5.34 0 0013.333 8 5.34 5.34 0 008 2.667 5.34 5.34 0 002.667 8zm6 2.667V12H7.333v-1.333h1.334zm-3.214-4.78a2.668 2.668 0 012.954-1.86C9.567 4.2 10.48 5.18 10.64 6.34c.152 1.076-.443 1.595-1.017 2.096-.105.092-.21.183-.31.277-.08.074-.153.147-.22.227l-.01.013a1.838 1.838 0 00-.197.273l-.016.03a1.416 1.416 0 00-.203.743H7.333a2.478 2.478 0 01.14-.855c.002-.01.003-.017.007-.024.003-.01.008-.018.013-.027.005-.008.01-.016.014-.026a2.22 2.22 0 01.166-.334l.01-.016a.145.145 0 00.01-.017c0-.003.002-.005.004-.007.001-.001.003-.003.003-.006.227-.342.532-.576.816-.795.41-.315.778-.599.817-1.125a1.349 1.349 0 00-1.046-1.4 1.335 1.335 0 00-1.534.853c-.093.253-.313.447-.586.447h-.134a.605.605 0 01-.58-.78z"
      fill={fill}
    />
  </svg>
);

export const HelpSolid = ({ fill = "#00B6BF", size = 24, ...props }: TDefaultIconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM11 19V17H13V19H11ZM14.17 12.17L15.07 11.25C16.09 10.23 16.44 8.48 15.26 6.85C14.36 5.6 12.91 4.81 11.39 5.05C9.84 5.29 8.59 6.41 8.16 7.88C8 8.44 8.4 9 8.98 9H9.28C9.67 9 9.98 8.72 10.1 8.35C10.43 7.4 11.46 6.77 12.57 7.08C13.27 7.28 13.83 7.89 13.96 8.61C14.09 9.31 13.87 9.97 13.41 10.41L12.17 11.67C11.75 12.09 11.42 12.61 11.22 13.19C11.08 13.6 11 14.04 11 14.5V15H13C13 14.54 13.05 14.18 13.13 13.86C13.31 13.14 13.67 12.68 14.17 12.17Z"
      fill={fill}
    />
  </svg>
);

export const HelpIconOutline = ({ stroke = "black", size = 20, ...props }: TDefaultIconProps) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.57496 7.49996C7.77088 6.94302 8.15759 6.47338 8.66659 6.17424C9.17559 5.87509 9.77404 5.76574 10.3559 5.86555C10.9378 5.96536 11.4656 6.2679 11.8459 6.71957C12.2261 7.17124 12.4342 7.74289 12.4333 8.33329C12.4333 9.99996 9.93329 10.8333 9.93329 10.8333M9.99996 14.1666H10.0083M18.3333 9.99996C18.3333 14.6023 14.6023 18.3333 9.99996 18.3333C5.39759 18.3333 1.66663 14.6023 1.66663 9.99996C1.66663 5.39759 5.39759 1.66663 9.99996 1.66663C14.6023 1.66663 18.3333 5.39759 18.3333 9.99996Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
