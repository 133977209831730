import * as React from "react";

import { TDefaultIconProps } from "./types";

export const MicrosoftLogo = ({ size = 24, ...props }: TDefaultIconProps) => (
  <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path fill="#F25022" d="M11.429 1.143H1.143v10.286h10.286V1.143Z" />
    <path fill="#00A4EF" d="M11.429 12.571H1.143v10.286h10.286V12.571Z" />
    <path fill="#7FBA00" d="M22.857 1.143H12.571v10.286h10.286V1.143Z" />
    <path fill="#FFB900" d="M22.857 12.571H12.571v10.286h10.286V12.571Z" />
  </svg>
);
