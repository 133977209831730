import { Anchor, Box } from "grommet";
import React from "react";
import { useTranslation } from "react-i18next";

import { QrContent } from "components/shared/qr";
import { useNavigation } from "hooks";
import { ButtonFilledBig } from "lib/buttons";
import { DownloadIcon } from "lib/icons/DownloadIcon";
import { NextArrow } from "lib/icons/NextArrow";
import { CardModal } from "lib/modal/Modal";
import { ChatGroup } from "pages/NumberDetail/pages/Inbox/types";
import { turnTheme } from "theme";
import { numberText } from "utils";
import { downloadSvg } from "utils/download";

export const QRModal = ({ number, onClose = () => {}, ...props }) => {
  const { t } = useTranslation();
  const { navigateTo } = useNavigation();

  const downloadQR = () => {
    const svg = document.querySelector("#qr_canvas_wrapper svg");
    if (svg) {
      downloadSvg(svg, `QR Code ${numberText(number)}.svg`);
    }
  };

  return (
    <CardModal
      onClose={onClose}
      width="675px"
      pad="24px"
      {...props}
      content={<QrContent number={number} />}
      footerContent={
        <Box fill="horizontal">
          <Anchor
            alignSelf="center"
            target="_blank"
            label={t("modals.qr.download")}
            icon={<DownloadIcon fill={turnTheme.global.colors["brand"]} />}
            onClick={downloadQR}
          />
          <ButtonFilledBig
            primary
            label={t("btn.go-to-chat")}
            alignSelf="center"
            margin={{ top: "medium", bottom: "55px" }}
            onClick={() => {
              navigateTo("inbox", { chatGroupId: ChatGroup.ALL_CHATS })();
              onClose();
            }}
            reverse
            icon={<NextArrow />}
          />
        </Box>
      }
    />
  );
};
