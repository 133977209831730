import { BorderColorDefault, TextColorDefault, TextColorSubtle } from "ds/Alias";
import { Box } from "ds/Box";
import { ColorNeutralBlack, ColorNeutralWhite, Space25, Space50, Space100, Space150, Space300 } from "ds/Core";
import { down } from "styled-breakpoints";
import styled from "styled-components";

import { NavigationHeaderProps, NavigationHeaderVariants } from "./types";

export const StyledNavigationContainer = styled.div<NavigationHeaderProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
  gap: ${Space100};
  padding: ${Space150} ${Space150} ${Space150} ${Space100};
  box-sizing: border-box;
  background: ${ColorNeutralWhite};
  border-bottom: 1px solid ${BorderColorDefault};

  ${({ variant }) =>
    (variant === NavigationHeaderVariants.PRIMARY || variant === NavigationHeaderVariants.LOGO) &&
    `padding: ${Space150} ${Space150} ${Space150} ${Space300};`}

  ${down("lg")} {
    padding: ${Space50} ${Space50} ${Space50} ${Space25};
    ${({ variant }) =>
      (variant === NavigationHeaderVariants.PRIMARY || variant === NavigationHeaderVariants.LOGO) &&
      `padding: ${Space50} ${Space50} ${Space50} ${Space100};`}
  }
`;

export const StyledTab = styled(Box)`
  text-decoration: none;
  color: ${TextColorSubtle};

  &:hover {
    box-shadow: 0 -2px 0 0 ${TextColorSubtle} inset;
  }

  &.active {
    color: ${TextColorDefault};
    box-shadow: 0 -2px 0 0 ${ColorNeutralBlack} inset;
  }
`;

export const StyledRight = styled.div`
  display: flex;
  align-items: center;
  gap: ${Space100};
  ${down("lg")} {
    gap: ${Space50};
  }
`;

export const StyledCenterNavigation = styled.div`
  display: flex;
  align-items: center;
  gap: ${Space50};
  ${down("lg")} {
    gap: ${Space25};
  }
`;

export const StyledRightNavigation = styled.div`
  display: flex;
  align-items: center;
  gap: ${Space100};
  ${down("lg")} {
    gap: ${Space50};
  }
`;
