import { useEffect } from "react";

import { useGetNumberLogosLazyQuery } from "graphql/organisation";
import { isDefinedAndNotNull } from "utils/checks";

import { useGlobalContext } from "./useGlobalContext";

// context(alexandrchebotar, 2022-07-20): parse the `?Expires=` or `&Expires=` from the URL
const expiresRegEx = /[?|&]Expires=([0-9]+)/;
const getExpiresTimestamp = (url?: string | null) => (Number(url?.match(expiresRegEx)?.[1]) ?? Infinity) * 1000;
const isExpired = (url?: string | null, interval = 0) => getExpiresTimestamp(url) < Date.now() - interval;

type TNumber =
  | {
      uuid: string;
      profilePicture?: string | null;
    }
  | undefined;

export const useLogosValidation = (...numbers: TNumber[]) => {
  const { organisationUuid, numberUuid } = useGlobalContext();

  const [refetch] = useGetNumberLogosLazyQuery({
    fetchPolicy: "network-only",
    variables: { organisationUuid },
  });

  const getValidLogo = () => {
    const url = numbers.find((n) => n?.uuid === numberUuid)?.profilePicture;
    // context(alexandrchebotar, 2022-07-18): add 15s interval for sending request and handling it on the BE
    const expired = isExpired(url, 15000);

    return expired
      ? refetch().then(({ data }) => data?.organisation?.numbers?.find((n) => n?.uuid === numberUuid)?.profilePicture)
      : Promise.resolve(url);
  };

  const urls = numbers.map((n) => n?.profilePicture).filter(isDefinedAndNotNull);
  const expired = urls.map(isExpired).includes(true);

  useEffect(() => {
    if (expired) {
      refetch();
    }
  }, [expired, refetch]);

  return { getValidLogo };
};
