import { BillingTransactionState, Maybe, ScheduledChange, SubscriptionState } from "graphql/generated/types";
import i18n from "utils/i18n";

export const getTransactionStatusLabel = (status: BillingTransactionState | null) => {
  switch (status) {
    case BillingTransactionState.COMPLETED:
    case BillingTransactionState.PAID:
      return i18n.t("settings.subscriptions.details.invoices.paid");
    default:
      return i18n.t("settings.subscriptions.details.invoices.unpaid");
  }
};

export const unpaidTransactionStatuses = [
  BillingTransactionState.CANCELED,
  BillingTransactionState.DRAFT,
  BillingTransactionState.PAST_DUE,
  BillingTransactionState.READY,
  BillingTransactionState.BILLED,
];

export const discountPercentage = (discountedAmount, total) => {
  if (total === 0 && discountedAmount === 0) return 0;
  return (100 * discountedAmount) / (total + discountedAmount);
};

export const getStatus = (state?: Maybe<SubscriptionState>, scheduleChangedAction?: Maybe<ScheduledChange>) => {
  if (
    scheduleChangedAction === ScheduledChange.CANCEL &&
    state !== SubscriptionState.CANCELED &&
    state !== SubscriptionState.PAUSED
  ) {
    return scheduleChangedAction;
  }
  return state;
};
