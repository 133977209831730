import React from "react";

import { ITypographyProps } from "../Typography/types";
import { TSpacing, anchor, getColor, getOneSizeSmaller, useBreakpointHook } from "../styles";
import { StyledAnchor } from "./styles";
import { TTextDecoration } from "./types";

type TAnchorProps = ITypographyProps & Omit<JSX.IntrinsicElements["a"], "margin" | "ref">;
export interface IAnchorProps extends TAnchorProps {
  decoration?: TTextDecoration;
  margin?: TSpacing;
}

export const Anchor = ({
  color = "black",
  decoration = "none",
  size = "medium",
  weight = 400,
  margin,
  children,
  ...props
}: IAnchorProps) => {
  const { isSmallerThan } = useBreakpointHook();
  const currentSize = isSmallerThan("tablet") ? getOneSizeSmaller(size) : size;
  const styles = { ...anchor[currentSize], color: getColor(color), weight, decoration, margin };

  return (
    <StyledAnchor {...styles} {...props}>
      {children}
    </StyledAnchor>
  );
};
