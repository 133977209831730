import gql from "graphql-tag";

import { OPERATOR_AVAILABILITY_FRAGMENT } from "./fragments";

export const GET_OPERATOR_AVAILABILITY = gql`
  query getOperatorAvailability($accountUuid: ID!, $numberUuid: ID!) {
    getOperatorAvailability(numberUuid: $numberUuid, accountUuid: $accountUuid) {
      ...OperatorAvailabilityFragment
    }
  }

  ${OPERATOR_AVAILABILITY_FRAGMENT}
`;
