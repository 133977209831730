import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  useGetOperatorAvailabilityQuery,
  useSetOperatorAsAvailableMutation,
  useSetOperatorAsUnavailableMutation,
} from "graphql/operators";
import { notify, notifyError } from "lib/notification";

import { useGlobalContext } from "./useGlobalContext";

interface IUseOperatorAvailability {
  onMutationError?: () => void;
  skipQuery?: boolean;
}

export const useOperatorAvailability = ({ onMutationError, skipQuery }: IUseOperatorAvailability = {}) => {
  const { t } = useTranslation();
  const { UNSAFE_numberUuid, myAccountUuid } = useGlobalContext();

  const { data, loading: loadingQuery } = useGetOperatorAvailabilityQuery({
    variables: {
      accountUuid: myAccountUuid!,
      numberUuid: UNSAFE_numberUuid!,
    },
    skip: skipQuery || !myAccountUuid || !UNSAFE_numberUuid,
    fetchPolicy: "cache-and-network",
    onError: notifyError,
  });

  // context(alexandrchebotar, 2023-12-06): we run mutations only from the UI, so we use valid numberUuid and accoutnUuid
  const [setAvailable, { loading: loadingSetAvailable }] = useSetOperatorAsAvailableMutation({
    variables: {
      accountUuid: myAccountUuid!,
      numberUuid: UNSAFE_numberUuid!,
    },
    onCompleted: (data) => {
      const count = Number(data.setOperatorAsAvailable?.count);

      if (count > 0) {
        notify({
          type: "info",
          position: "bottom-right",
          autoClose: 4000,
          title: t("routing.notifications.chats-assigned-to-you.title", { count }),
          description: t("routing.notifications.chats-assigned-to-you.description"),
        });
      }
    },
    onError: (e) => {
      onMutationError?.();
      notifyError(e);
    },
  });

  const [setUnavailable, { loading: loadingSetUnavailable }] = useSetOperatorAsUnavailableMutation({
    onError: (e) => {
      onMutationError?.();
      notifyError(e);
    },
  });

  return {
    isAvailable: !!data?.getOperatorAvailability?.isAvailable,
    loading: loadingQuery || loadingSetAvailable || loadingSetUnavailable,
    setAvailable,
    setUnavailable: useCallback(
      (releaseChats: boolean) =>
        setUnavailable({
          variables: {
            accountUuid: myAccountUuid!,
            numberUuid: UNSAFE_numberUuid!,
            releaseChats,
          },
        }),
      [myAccountUuid, UNSAFE_numberUuid, setUnavailable]
    ),
  };
};
