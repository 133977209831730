import gql from "graphql-tag";

import { LEASE_MIN_FRAGMENT } from "./fragments";

export const REQUEST_LEASE = gql`
  mutation requestLease($numberUuid: ID!, $chatUuid: ID!, $messageUuid: ID!, $eventMessage: String!, $ttl: Int) {
    requestLease(
      numberUuid: $numberUuid
      chatUuid: $chatUuid
      messageUuid: $messageUuid
      eventMessage: $eventMessage
      ttl: $ttl
    ) {
      ...LeaseMin
      childLease {
        ...LeaseMin
      }
    }
  }

  ${LEASE_MIN_FRAGMENT.query}
`;

export const OVERRIDE_LEASE = gql`
  mutation overrideLease($numberUuid: ID!, $chatUuid: ID!, $messageUuid: ID!, $eventMessage: String!) {
    overrideLease(
      numberUuid: $numberUuid
      chatUuid: $chatUuid
      messageUuid: $messageUuid
      eventMessage: $eventMessage
    ) {
      ...LeaseMin
      childLease {
        ...LeaseMin
      }
    }
  }

  ${LEASE_MIN_FRAGMENT.query}
`;

export const RELEASE_LEASE = gql`
  mutation releaseLease($numberUuid: ID!, $chatUuid: ID!) {
    releaseLease(numberUuid: $numberUuid, chatUuid: $chatUuid) {
      ...LeaseMin
    }
  }

  ${LEASE_MIN_FRAGMENT.query}
`;
