import gql from "graphql-tag";

import { NUMBER_TAG_DETAILS_FRAGMENT } from "./fragments";

export const CREATE_NUMBER_TAG = gql`
  mutation createNumberTag($color: String!, $numberUuid: ID!, $value: String!) {
    createNumberTag(color: $color, numberUuid: $numberUuid, value: $value) {
      ...NumberTagDetails
    }
  }

  ${NUMBER_TAG_DETAILS_FRAGMENT.query}
`;

export const UPDATE_NUMBER_TAG = gql`
  mutation updateNumberTag($color: String!, $numberUuid: ID!, $numberTagUuid: ID!, $value: String!) {
    updateNumberTag(color: $color, numberUuid: $numberUuid, numberTagUuid: $numberTagUuid, value: $value) {
      ...NumberTagDetails
    }
  }

  ${NUMBER_TAG_DETAILS_FRAGMENT.query}
`;

export const DELETE_NUMBER_TAG = gql`
  mutation deleteNumberTag($numberUuid: ID!, $numberTagUuid: ID!) {
    deleteNumberTag(numberUuid: $numberUuid, numberTagUuid: $numberTagUuid) {
      ...NumberTagDetails
    }
  }

  ${NUMBER_TAG_DETAILS_FRAGMENT.query}
`;
