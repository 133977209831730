import { Box, TextArea } from "grommet";
import styled from "styled-components";

import { Form, Heading, Paragraph, TColors, devices } from "lib/impact";
import { FOOTER_HEIGHT } from "pages/NumberDetail/pages/Inbox/styles";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

export const MAX_FORM_FIELD_WIDTH = "380px";
export const CONTAINER_MAX_WIDTH = "528px";

export const enum StatusColors {
  SUCCESS = "unknown.12",
  PENDING = "unknown.13",
  ERROR = "unknown.14",
  NONE = "unknown.15",
}

export const StepColors = {
  LOGIN: "unknown.1",
  SIGNUP: "unknown.2",
  TERMS: "unknown.2",
  READINESS: "unknown.2",
  INVITE: "unknown.2",
  CHOOSE_PLAN: "unknown.3",
  NUMBER: "unknown.1",
  EMBEDDED_SIGNUP: "unknown.4",
  META_VERIFICATION: "unknown.5",
  DONE: "unknown.1",
  PROTOTYPE: "unknown.1",
} as const;

export const OnboardingImageContainer = styled(Box)`
  position: fixed;
  right: 0;
  padding: ${({ theme: { spaces } }) => `${spaces.m}px ${spaces.xxl}px`};
  width: 50%;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
  }

  @media ${devices.tablet} {
    height: auto;
    position: inherit;
    padding: ${({ theme: { spaces } }) => spaces.xl}px;
    justify-content: start;
    min-height: auto;
  }
`;

export const OnboardingFormHeading = styled(Heading)`
  @media ${devices.tablet} {
    margin-bottom: ${({ theme: { spaces } }) => spaces.m}px;
  }
`;

export const OnboardingLayout = styled(Box)`
  @media ${devices.tablet} {
    display: block;
    > div {
      width: 100%;
    }
  }
`;

export const OnboardingHeaderContainer = styled(Box)`
  position: sticky;
  top: 0px;
  left: 0px;
  height: 64px;
  padding: 0px ${({ theme: { spaces } }) => spaces.ml}px;
  z-index: 98;

  @media ${devices.tablet} {
    position: fixed;
    width: 100%;
    height: 48px;
    padding: 0px ${({ theme: { spaces } }) => spaces.m}px;
    margin-bottom: ${({ theme: { spaces } }) => spaces.none}px;
    img {
      width: 52px;
    }
  }
`;
export const OnboardingContentContainer = styled(Box)`
  display: block;
  height: 100vh;

  @media ${devices.tablet} {
    display: flex;
    height: 100%;
  }
`;
export const OnboardingFooterContainer = styled(Box)<{
  isScrollVisible?: boolean;
  background: TColors;
}>`
  position: sticky;
  width: 100%;
  height: ${FOOTER_HEIGHT};
  top: 100vh;
  background: ${({ background, theme: { getColor } }) => getColor(background)};

  @media ${devices.tablet} {
    position: initial;
    width: 100%;
  }
`;

export const OnboardingContent = styled(Box)<{
  full?: boolean;
  background?: TColors;
  maxWidth?: string;
  padding?: string;
}>`
  display: block;
  align-self: center;
  margin: 0 auto;
  padding: ${({ padding, theme: { spaces } }) => padding ?? `0 ${spaces.xxl}px ${spaces.l + spaces.s}px`};
  max-width: ${({ full, maxWidth }) => ((maxWidth ?? full) ? "auto" : CONTAINER_MAX_WIDTH)};
  box-sizing: content-box;
  ${({ background, theme: { getColor } }) => background && `background: ${getColor(background)}`};

  @media ${devices.tablet} {
    padding: ${({ padding, theme: { spaces } }) =>
      padding ?? `${spaces.xl}px  ${spaces.ml}px ${spaces.l + spaces.s}px`};
  }
`;

export const OnboardingForm = styled(Form)<{ useBottomSpace?: boolean }>`
  padding-top: ${({ theme: { spaces } }) => spaces.ml}px;
`;

export const OnboardingFormItem = styled(Box)`
  @media ${devices.tablet} {
    display: block;
  }
`;

export const QrCodeContainer = styled(Box)`
  padding: 16px;
  background: white;
  @media ${devices.mobile} {
    padding: 8px;
  }

  svg {
    width: 100% !important;
    height: auto !important;
  }
`;

export const OnboardingStepParagraph = styled(Paragraph)`
  font-size: 22px;
  line-height: 1.25;
  font-weight: 500;
`;

export const FullScreenOverlay = styled.div`
  position: fixed;
  z-index: 101;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.91);
`;

export const OnboardingTextArea = styled(TextArea)`
  font-size: 20px;
  line-height: 1.25;
  border-width: 2px;
  border-radius: 8px;
  :focus {
    border-width: 2px;
  }
  ::-webkit-resizer {
    display: none;
  }
`;
