import gql from "graphql-tag";

export const GET_GENERAL_STATS = gql`
  query getGeneralStats($numberUuid: ID!, $organisationUuid: ID!) {
    generalStats(numberUuid: $numberUuid, organisationUuid: $organisationUuid) {
      totalMessages
      totalOperatorResponse
      totalUsers
    }
  }
`;

export const GET_TITLE_STATS = gql`
  query getTitleStats($days: Int, $startDate: Date, $endDate: Date, $numberUuid: ID!, $organisationUuid: ID!) {
    titleStats(
      days: $days
      startDate: $startDate
      endDate: $endDate
      numberUuid: $numberUuid
      organisationUuid: $organisationUuid
    ) {
      endDate
      newUsers
      receivedMessages
      conversationClosed
      startDate
    }
  }
`;

export const GET_AVG_MESSAGES_STATS = gql`
  query getAverageMessagesStats($days: Int!, $numberUuid: ID!, $organisationUuid: ID!) {
    averageMessagesStats(days: $days, numberUuid: $numberUuid, organisationUuid: $organisationUuid) {
      avg
      leastActiveDay
      leastActiveHour
      mostActiveDay
      mostActiveHour
    }
  }
`;

export const GET_USERS_STATS = gql`
  query getUsersStats($days: Int, $startDate: Date, $endDate: Date, $numberUuid: ID!, $organisationUuid: ID!) {
    usersStats(
      days: $days
      startDate: $startDate
      endDate: $endDate
      numberUuid: $numberUuid
      organisationUuid: $organisationUuid
    ) {
      data {
        newUsers {
          count
          date
          name
        }
        returnedUsers {
          count
          date
          name
        }
      }
      totalNewUsers
      totalReturnedUsers
      totalUsers
    }
  }
`;

export const GET_MESSAGES_STATS = gql`
  query getMessagesStats($days: Int, $startDate: Date, $endDate: Date, $numberUuid: ID!, $organisationUuid: ID!) {
    messagesStats(
      days: $days
      startDate: $startDate
      endDate: $endDate
      numberUuid: $numberUuid
      organisationUuid: $organisationUuid
    ) {
      data {
        inbound {
          count
          date
          name
        }
        outbound {
          count
          date
          name
        }
      }
      totalReceived
      totalSent
    }
  }
`;

export const GET_MOST_ACTIVE_COUNTRIES_STATS = gql`
  query getMostActiveCountriesStats(
    $days: Int
    $startDate: Date
    $endDate: Date
    $numberUuid: ID!
    $organisationUuid: ID!
  ) {
    mostActiveCountriesStats(
      days: $days
      startDate: $startDate
      endDate: $endDate
      numberUuid: $numberUuid
      organisationUuid: $organisationUuid
    ) {
      data {
        count
        name
      }
      totalUsers
    }
  }
`;

export const GET_CONVERSATIONS_STATS = gql`
  query getConversationStats($days: Int, $startDate: Date, $endDate: Date, $numberUuid: ID!, $organisationUuid: ID!) {
    conversationStats(
      days: $days
      startDate: $startDate
      endDate: $endDate
      numberUuid: $numberUuid
      organisationUuid: $organisationUuid
    ) {
      data {
        conversationClosed
        conversationOpen
      }
    }
  }
`;

export const GET_RESPONSE_TYPE_STATS = gql`
  query getResponseTypeStats($days: Int, $startDate: Date, $endDate: Date, $numberUuid: ID!, $organisationUuid: ID!) {
    responseTypeStats(
      days: $days
      startDate: $startDate
      endDate: $endDate
      numberUuid: $numberUuid
      organisationUuid: $organisationUuid
    ) {
      data {
        count
        name
      }
      totalResponses
    }
  }
`;

export const GET_MOST_USED_RESPONSES_STATS = gql`
  query getMostUsedResponsesStats(
    $days: Int
    $startDate: Date
    $endDate: Date
    $numberUuid: ID!
    $organisationUuid: ID!
  ) {
    mostUsedResponsesStats(
      days: $days
      startDate: $startDate
      endDate: $endDate
      numberUuid: $numberUuid
      organisationUuid: $organisationUuid
    ) {
      data {
        count
        name
      }
      totalResponses
    }
  }
`;
