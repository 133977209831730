import { Heading, Text } from "grommet";
import { Google } from "grommet-icons";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { StringParam, useQueryParam } from "use-query-params";

import LoginImage from "assets/onboarding/login.svg";
import { Action, OnboardingScreen } from "containers/Onboarding/components";
import { OnboardingContent } from "containers/Onboarding/styles";
import { StepColors } from "containers/Onboarding/styles";
import { MicrosoftLogo } from "lib/icons/MicrosoftLogo";
import { calcSpacing, devices } from "lib/impact";
import { TurnHostInfo } from "types";

const LoginHeading = styled(Heading)`
  font-weight: 500;
  font-size: 48px;
  line-height: 1.35;
  margin-top: 0;
  margin-bottom: ${calcSpacing(["l", "s"])};

  @media ${devices.tablet} {
    font-size: 36px;
  }
`;
const LoginContent = styled(OnboardingContent)`
  display: flex;
  height: calc(100% - ${calcSpacing(["xl", "m", "xl", "m"])});
  padding-bottom: ${calcSpacing(["xl", "m"])};
  justify-content: center;

  @media ${devices.tablet} {
    display: block;
    height: auto;
  }
`;
export interface ISignIn {
  turnHostInfo: TurnHostInfo;
}
export const SignIn = ({ turnHostInfo }: ISignIn) => {
  const { t } = useTranslation();
  const [referral = ""] = useQueryParam("referral", StringParam);
  const searchParams = new URLSearchParams({
    redirect_to: window.location.href,
    prompt: "login",
    referral,
  });

  const getLoginUrl = (SSOprovider: string) => `${turnHostInfo.turn_host}/auth/${SSOprovider}?${searchParams}`;

  return (
    <OnboardingScreen color={StepColors.LOGIN} image={LoginImage} contentContainer={LoginContent} showLogo hideSupport>
      <LoginHeading>{t("login.heading")}</LoginHeading>
      <Text size="22px">{t("login.text")}</Text>
      <Action
        label={t("login.action-google")}
        href={getLoginUrl("google")}
        icon={<Google color="plain" />}
        outline
        margin={{ top: calcSpacing(["l", "m"]) }}
      />
      <Action
        label={t("login.action-microsoft")}
        href={getLoginUrl("microsoft")}
        icon={<MicrosoftLogo />}
        outline
        margin={{ top: calcSpacing(["m", "s"]) }}
      />
    </OnboardingScreen>
  );
};
