import React from "react";
import styled from "styled-components";

const StyledForm = styled.form<IFormProps>`
  height: ${({ height }) => height || "initial"};
`;

type TFormProps = Omit<JSX.IntrinsicElements["form"], "ref">;

interface IFormProps extends TFormProps {
  height?: string;
}

export const Form = ({ children, height, onSubmit, ...props }: IFormProps) => {
  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (onSubmit) {
      onSubmit(e);
    }
    return false;
  };
  return (
    <StyledForm height={height} onSubmit={handleSubmit} {...props}>
      {children}
    </StyledForm>
  );
};
