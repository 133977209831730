import gql from "graphql-tag";

import { required } from "api/boundary";

export const ACTIONS_CARD_FRAGMENT = {
  query: gql`
    fragment ActionsCardDetails on ActionsCard {
      uuid
      type
      webhookUrl
      webhookHmacSecret
      updatedAt
      insertedAt
    }
  `,
  parse: {
    uuid: required(),
    type: required(),
  },
};

export const CREATE_ACTIONS_CARD = gql`
  mutation createActionsCard($numberUuid: ID!, $action: ActionsCardIo!, $actionType: String!) {
    createActionsCard(numberUuid: $numberUuid, action: $action, actionType: $actionType) {
      ...ActionsCardDetails
    }
  }
  ${ACTIONS_CARD_FRAGMENT.query}
`;

export const UPDATE_ACTIONS_CARD = gql`
  mutation updateActionsCard($numberUuid: ID!, $uuid: ID!, $actionType: String!, $action: ActionsCardIo!) {
    updateActionsCard(action: $action, actionType: $actionType, numberUuid: $numberUuid, uuid: $uuid) {
      ...ActionsCardDetails
    }
  }
  ${ACTIONS_CARD_FRAGMENT.query}
`;

export const DELETE_ACTIONS_CARD = gql`
  mutation deleteActionsCard($numberUuid: ID!, $uuid: ID!) {
    deleteActionsCard(numberUuid: $numberUuid, uuid: $uuid) {
      ...ActionsCardDetails
    }
  }
  ${ACTIONS_CARD_FRAGMENT.query}
`;
