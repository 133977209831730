import { BoxProps } from "ds/Box/src";
import { Config } from "react-popper-tooltip";

import { ValueOf } from "types";

export const TooltipType = {
  BOLD: "bold",
  DARK: "dark",
  NEUTRAL: "neutral",
} as const;

export const TooltipSize = {
  SMALL: "small",
  MEDIUM: "medium",
} as const;

export type TTooltipSize = ValueOf<typeof TooltipSize>;
export type TTooltipType = ValueOf<typeof TooltipType>;

export type TooltipProps = Omit<Config, "trigger"> & {
  children?: React.ReactNode;
  content?: React.ReactNode;
  trigger: React.ReactNode;
  disabled?: boolean;
  triggerProps?: BoxProps;
  type?: TTooltipType;
  size?: TTooltipSize;
  showPointer?: boolean;
  offset?: [number, number];
  usePortal?: boolean;
  maxWidth?: string;
};

export type TooltipConfig = Omit<TooltipProps, "trigger" | "children">;
