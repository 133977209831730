import * as React from "react";

import { theme } from "../styles/theme";
import { TIconProps, getIconSize } from "./types";

export const SearchIcon = ({ fill = theme.colors.black, size = "m", ...props }: TIconProps) => {
  const sizes = getIconSize(size);

  return (
    <svg width={sizes} height={sizes} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 8.5a5.5 5.5 0 1111 0 5.5 5.5 0 01-11 0zM9 1a7.5 7.5 0 104.55 13.463l5.243 5.244a1 1 0 001.414-1.414l-5.244-5.244A7.5 7.5 0 009 1z"
        fill={fill}
      />
    </svg>
  );
};
