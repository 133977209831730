export const coreTokens = {
  color: {
    lime50: "#E3FFD1",
    lime100: "#A6FB6D",
    lime200: "#9EEF68",
    lime300: "#93E061",
    lime400: "#8DD75D",
    lime500: "#82C556",
    lime600: "#7AB950",
    lime700: "#6EA749",
    lime800: "#548038",
    lime900: "#334E22",
    sky50: "#E8FAFF",
    sky100: "#B7EEFF",
    sky200: "#93E6FF",
    sky300: "#64DBFE",
    sky400: "#60D2F4",
    sky500: "#58C0E0",
    sky600: "#52B5D2",
    sky700: "#4BA4BE",
    sky800: "#397D92",
    sky900: "#1D404A",
    berry50: "#FFF4F0",
    berry100: "#FFDFD2",
    berry200: "#FFD0BE",
    berry300: "#FFBDA5",
    berry400: "#FFB196",
    berry500: "#FF9777",
    berry600: "#FF714D",
    berry700: "#CF5A3E",
    berry800: "#994531",
    berry900: "#5F2D22",
    sun50: "#FFF7CF",
    sun100: "#FDE662",
    sun200: "#FBD90E",
    sun300: "#E9CB0D",
    sun400: "#DFC30D",
    sun500: "#CBB30C",
    sun600: "#B29E0B",
    sun700: "#857808",
    sun800: "#695F07",
    sun900: "#423C04",
    lavender50: "#F5EEFF",
    lavender100: "#F0E3FF",
    lavender200: "#E5D2FF",
    lavender300: "#DBC1FF",
    lavender400: "#D4B6FF",
    lavender500: "#C3A7EA",
    lavender600: "#B79DDB",
    lavender700: "#A58EC6",
    lavender800: "#7E6D96",
    lavender900: "#40384C",
    orange50: "#FFF5E9",
    orange100: "#FFE0BD",
    orange200: "#FFD29F",
    orange300: "#FFC077",
    orange400: "#FFB45F",
    orange500: "#FE9C2C",
    orange600: "#EF9329",
    orange700: "#D88525",
    orange800: "#A5661D",
    orange900: "#55340F",
    gray25: "#F9F9FA",
    gray50: "#F6F6F7",
    gray100: "#E7E8E9",
    gray200: "#D9DADB",
    gray300: "#CACCCD",
    gray400: "#C2C4C5",
    gray500: "#B1B4B5",
    gray600: "#9B9EA0",
    gray700: "#73787B",
    gray800: "#5A5F62",
    gray900: "#383C3E",
    neutralBlack: "#000",
    neutralWhite: "#fff",
    neutralCream: "#FFFCEE",
    red50: "#FFF4F2",
    red100: "#FFDED9",
    red200: "#FFCFC8",
    red300: "#FFBCB2",
    red400: "#FFB0A5",
    red500: "#FF978B",
    red600: "#FD7268",
    red700: "#DD363A",
    red800: "#AE2F2F",
    red900: "#6B2320",
    green50: "#EDFAEF",
    green100: "#C7EFCD",
    green200: "#ADE8B6",
    green300: "#8CDE98",
    green400: "#76D885",
    green500: "#49CC5D",
    green600: "#01B71D",
    green700: "#008B15",
    green800: "#006E11",
    green900: "#00460B",
    blue50: "#F0F7FF",
    blue100: "#D3E7FF",
    blue200: "#BFDDFF",
    blue300: "#A6D0FF",
    blue400: "#97C8FF",
    blue500: "#78B7FF",
    blue600: "#4DA0FF",
    blue700: "#0072F3",
    blue800: "#005AC2",
    blue900: "#003A7B",
    transparency00: "#ffffff00",
    brandDefault: "#000",
    brandAccent: "#8654CD",
  },
  space: {
    "0": "0px",
    "25": "2px",
    "50": "4px",
    "100": "8px",
    "150": "12px",
    "200": "16px",
    "250": "20px",
    "300": "24px",
    "400": "32px",
    "500": "40px",
    "600": "48px",
    "700": "56px",
    "800": "64px",
    "900": "72px",
    "1000": "80px",
    "1100": "88px",
    "1200": "96px",
    base: "8px",
  },
  fontSize: {
    "10": "12px",
    "25": "14px",
    "50": "15px",
    "100": "16px",
    "200": "18px",
    "300": "20px",
    "400": "22px",
    "500": "24px",
    "600": "28px",
    "700": "32px",
    "800": "36px",
    "900": "40px",
    "1000": "48px",
    base: "16px",
  },
  size: {
    "0": "0px",
    "8": "8px",
    "12": "12px",
    "16": "16px",
    "20": "20px",
    "24": "24px",
    "32": "32px",
    "40": "40px",
    "48": "48px",
    "56": "56px",
    "64": "64px",
    "72": "72px",
    "80": "80px",
    "88": "88px",
    "96": "96px",
    "104": "104px",
  },
  borderWidth: {
    "0": "0px",
    "100": "1px",
    "200": "2px",
  },
  borderRadius: {
    "0": "0px",
    "100": "3px",
    "200": "8px",
    "300": "16px",
    "400": "32px",
    pill: "9999px",
    circle: "1000px",
  },
  shadow: {
    low: "0px 0px 4px #0000001a, 0px 2px 2px #0000001a",
    medium: "0px 4px 4px #00000026, 0px 2px 2px #0000001a",
    high: "0px 8px 15px 2px #00000014, 0px 0px 4px #00000014",
  },
  lineHeight: {
    "25": "16px",
    "50": "24px",
    "100": "32px",
    "200": "40px",
    "300": "48px",
    "400": "56px",
    "500": "64px",
    "600": "72px",
    tight: "1.1",
    standard: "1.25",
    loose: "1.5",
  },
  fontWeight: {
    "100": 400,
    "200": 500,
  },
  fontFamily: {
    inter: "Inter",
  },
  textDecoration: {
    "0": "none",
    underline: "underline",
  },
};

export type CoreColorType =
  | "lime50"
  | "lime100"
  | "lime200"
  | "lime300"
  | "lime400"
  | "lime500"
  | "lime600"
  | "lime700"
  | "lime800"
  | "lime900"
  | "sky50"
  | "sky100"
  | "sky200"
  | "sky300"
  | "sky400"
  | "sky500"
  | "sky600"
  | "sky700"
  | "sky800"
  | "sky900"
  | "berry50"
  | "berry100"
  | "berry200"
  | "berry300"
  | "berry400"
  | "berry500"
  | "berry600"
  | "berry700"
  | "berry800"
  | "berry900"
  | "sun50"
  | "sun100"
  | "sun200"
  | "sun300"
  | "sun400"
  | "sun500"
  | "sun600"
  | "sun700"
  | "sun800"
  | "sun900"
  | "lavender50"
  | "lavender100"
  | "lavender200"
  | "lavender300"
  | "lavender400"
  | "lavender500"
  | "lavender600"
  | "lavender700"
  | "lavender800"
  | "lavender900"
  | "orange50"
  | "orange100"
  | "orange200"
  | "orange300"
  | "orange400"
  | "orange500"
  | "orange600"
  | "orange700"
  | "orange800"
  | "orange900"
  | "gray25"
  | "gray50"
  | "gray100"
  | "gray200"
  | "gray300"
  | "gray400"
  | "gray500"
  | "gray600"
  | "gray700"
  | "gray800"
  | "gray900"
  | "neutralBlack"
  | "neutralWhite"
  | "neutralCream"
  | "red50"
  | "red100"
  | "red200"
  | "red300"
  | "red400"
  | "red500"
  | "red600"
  | "red700"
  | "red800"
  | "red900"
  | "green50"
  | "green100"
  | "green200"
  | "green300"
  | "green400"
  | "green500"
  | "green600"
  | "green700"
  | "green800"
  | "green900"
  | "blue50"
  | "blue100"
  | "blue200"
  | "blue300"
  | "blue400"
  | "blue500"
  | "blue600"
  | "blue700"
  | "blue800"
  | "blue900"
  | "transparency00"
  | "brandDefault"
  | "brandAccent";

export type CoreSpaceType =
  | "0"
  | "25"
  | "50"
  | "100"
  | "150"
  | "200"
  | "250"
  | "300"
  | "400"
  | "500"
  | "600"
  | "700"
  | "800"
  | "900"
  | "1000"
  | "1100"
  | "1200"
  | "base";

export type CoreFontSizeType =
  | "10"
  | "25"
  | "50"
  | "100"
  | "200"
  | "300"
  | "400"
  | "500"
  | "600"
  | "700"
  | "800"
  | "900"
  | "1000"
  | "base";

export type CoreSizeType =
  | "0"
  | "8"
  | "12"
  | "16"
  | "20"
  | "24"
  | "32"
  | "40"
  | "48"
  | "56"
  | "64"
  | "72"
  | "80"
  | "88"
  | "96"
  | "104";

export type CoreBorderWidthType = "0" | "100" | "200";

export type CoreBorderRadiusType = "0" | "100" | "200" | "300" | "400" | "pill" | "circle";

export type CoreShadowType = "low" | "medium" | "high";

export type CoreLineHeightType =
  | "25"
  | "50"
  | "100"
  | "200"
  | "300"
  | "400"
  | "500"
  | "600"
  | "tight"
  | "standard"
  | "loose";

export type CoreFontWeightType = "100" | "200";

export type CoreFontFamilyType = "inter";

export type CoreTextDecorationType = "0" | "underline";
