import * as React from "react";

export const LockIconOutline = ({ ...props }) => (
  <svg width={120} height={120} viewBox="0 0 120 120" fill="none" {...props}>
    <circle cx={60} cy={60} r={60} fill="#00C2CC" />
    <rect x={32} y={47} width={56} height={50} rx={3} fill="#08415C" />
    <rect x={42} y={25} width={35} height={44} rx={17.5} stroke="#08415C" strokeWidth={4} />
    <path fill="#FFBF1F" d="M36 51h48v42H36z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63 74.197a6 6 0 10-6 0V80a1 1 0 001 1h4a1 1 0 001-1v-5.803z"
      fill="#08415C"
    />
  </svg>
);
