import React from "react";

import { TSizes } from "lib/impact";

import { FieldWrapper } from "../FieldWrapper";
import { TIcon } from "../FieldWrapper/Icon";
import { getPrompt } from "../FieldWrapper/utils";
import { StyledTextInput } from "./styles";

type TTextInputProps = Omit<JSX.IntrinsicElements["input"], "ref" | "size">;
export interface ITextInputProps extends TTextInputProps {
  name: string;
  label?: string;
  error?: string;
  warning?: string;
  success?: string;
  icon?: TIcon;
  size?: TSizes;
}

export const TextInput = React.forwardRef(function TextInput(
  { label, name, id, error, warning, success, icon, size = "medium", ...props }: ITextInputProps,
  ref: React.Ref<HTMLInputElement>
) {
  return (
    <FieldWrapper
      size={size}
      label={label}
      prompt={getPrompt({ error, warning, success })}
      labelProps={{ htmlFor: id || name }}
      icon={icon}
    >
      <StyledTextInput data-test-gl="text-input" type="text" id={id || name} ref={ref} {...props} />
    </FieldWrapper>
  );
});
