import { Country } from "countries-phone-masks";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";

import { CountryCode } from "libphonenumber-js";

import { DropDownCarretIcon } from "../../Icons/BottomIcon";
import { Dropdown } from "../Dropdown";
import { SelectDropContent } from "../SelectDropContent";
import { ITextInputProps, TextInput } from "../TextInput";
import { FAVORITE_COUNTRIES } from "../constants";
import { CountryItem } from "./CountryItem";
import { Flag } from "./Flag";
import { countriesList, getCountries, getFilterCountriesFn } from "./utils";

export interface ICountryInputProps extends Omit<ITextInputProps, "onChange" | "onSelect"> {
  favorite?: CountryCode[];
  onSelect?: (countryCode?: CountryCode, country?: Country) => void;
  onChange?: (value: string) => void;
  value?: CountryCode;
  hideFlag?: boolean;
}

export const CountryInput = ({
  favorite = FAVORITE_COUNTRIES,
  onSelect,
  value,
  onChange,
  hideFlag,
  ...props
}: ICountryInputProps) => {
  const [localValue, setLocalValue] = useState("");
  const [country, setCountry] = useState<Country | undefined>();
  const [visible, setVisible] = useState<boolean>(false);

  const showDrop = () => {
    setVisible(true);
  };
  const hideDrop = () => {
    setVisible(false);
  };
  const toggleDrop = () => {
    setVisible((visible) => !visible);
  };

  const onSelectCountry = (country?: Country) => {
    setCountry(country);
    setLocalValue(country?.name || "");
    if (onSelect) {
      onSelect(country?.iso, country);
    }
    hideDrop();
  };

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setLocalValue(inputValue);
    if (onChange) {
      onChange(inputValue);
    }
    if (!inputValue) {
      setCountry(undefined);

      if (onSelect) {
        onSelect();
      }
    }
  };

  const { favorites, countries } = useMemo(() => getCountries(favorite), [favorite]);
  useEffect(() => {
    if (value) {
      const foundCountry = countriesList.find((item) => item.iso === value);
      setCountry(foundCountry);
      setLocalValue(foundCountry?.name || "");
    }
    // need run just in initialization
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dropdown
      action={
        <TextInput
          onClick={showDrop}
          onChange={onChangeInput}
          icon={{
            left: !hideFlag && <Flag countryCode={country?.iso} onClick={toggleDrop} />,
            right: <DropDownCarretIcon onClick={toggleDrop} />,
          }}
          value={localValue}
          // context(alexandrchebotar, 2022-03-25): hack to remove autocomplete suggestion that overlaps UI as Chrome ignores autocomplete="off"
          autoComplete="none"
          {...props}
        />
      }
      visible={visible}
      onHide={hideDrop}
      drop={{ minHeight: "120px" }}
    >
      <SelectDropContent
        items={countries}
        favorites={favorites}
        hideCode
        filterValue={localValue}
        onFilter={getFilterCountriesFn}
        onSelect={onSelectCountry}
        renderItem={(country: Country) => (
          <CountryItem key={country.name + country.iso} country={country} hideCode hideFlag={hideFlag} />
        )}
      />
    </Dropdown>
  );
};
