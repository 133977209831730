import { Box, Text, TextExtendedProps } from "grommet";
import React from "react";

import { Override } from "types";

import { StyledGrommetSpinner } from "./styles";
import { ISpinnerProps } from "./types";

type TSimpleSpinnerProps = Override<
  ISpinnerProps,
  {
    size?: string;
    strokeSize?: string;
    textProps?: TextExtendedProps;
    animated?: boolean;
  }
>;

export const SimpleSpinner = ({
  color = "black",
  reverse,
  size = "44px",
  strokeSize = "3px",
  text,
  textProps,
  direction = "row",
  animated = true,
  ...props
}: TSimpleSpinnerProps) => (
  <Box align="center" direction={direction} gap="18px" {...props}>
    {reverse && text && (
      <Text size="18px" {...textProps}>
        {text}
      </Text>
    )}
    <StyledGrommetSpinner
      flex={{ shrink: 0 }}
      color={color}
      size={size}
      border={[
        { side: "all", color: "light-4", size: strokeSize },
        { side: "top", color, size: strokeSize },
      ]}
      animated={animated}
    />
    {!reverse && text && (
      <Text size="18px" textAlign={direction === "column" ? "center" : "start"} {...textProps}>
        {text}
      </Text>
    )}
  </Box>
);
