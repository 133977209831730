import * as React from "react";

import { TDefaultIconProps } from "./types";

export const Bullet = ({ size = 24, fill = "#000000", ...props }: TDefaultIconProps) => (
  <svg width={size} height={size} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M6 12l4 4 8-8" stroke={fill} strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" />
  </svg>
);

export const BulletIconOutline = ({ fill = "#64DBFF", size = 24, ...props }: TDefaultIconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z"
      fill={fill}
    />
    <rect x="11" y="7" width="2" height="10" fill={fill} />
    <rect x="11" y="10" width="2" height="7" rx="1" fill="black" />
    <rect x="11" y="7" width="2" height="2" rx="1" fill="black" />
  </svg>
);
