/* tslint:disable */

/* eslint-disable */
import { gql } from "@apollo/client";

import * as Types from "../../generated/types";
import { LeaseMinFragment } from "../../leases/generated/fragments.generated";
import { LeaseMinFragmentDoc } from "../../leases/generated/fragments.generated";

export type ChatMinFragment = {
  __typename: "Chat";
  uuid: string | null;
  title: string | null;
  owner: string | null;
  state: Types.ChatState | null;
  inboundTimestamp: any | null;
};

export type ChatMediumFragment = {
  __typename: "Chat";
  assignedTo: {
    __typename: "Account";
    firstName: string | null;
    lastName: string | null;
    profilePicture: string | null;
    uuid: string | null;
  } | null;
} & ChatMinFragment;

export type MessageForChatListFragment = {
  __typename: "Message";
  uuid: string | null;
  insertedAt: any | null;
  content: string | null;
  messageType: Types.MessageType | null;
  isHandled: boolean | null;
  templateButtonText: string | null;
  lastStatus: Types.StatusType | null;
  hsm: {
    __typename: "Hsm";
    elementName: string | null;
    language: { __typename: "HsmLanguage"; code: string | null } | null;
    localizableParams: Array<{ __typename: "LocalizableParam"; default: string | null } | null> | null;
  } | null;
  template: {
    __typename: "Template";
    name: string | null;
    language: { __typename: "HsmLanguage"; code: string | null } | null;
    components: Array<{
      __typename: "MessageTemplateComponent";
      type: Types.MessageTemplateComponentType | null;
      subType: Types.MessageTemplateComponentSubType | null;
      index: number | null;
      parameters: Array<
        | {
            __typename: "MessageTemplateComponentParameterDocument";
            type: Types.MessageTemplateComponentParameterType | null;
            document: {
              __typename: "MessageTemplateHeaderMediaParametersDocument";
              id: string | null;
              link: string | null;
              filename: string | null;
            } | null;
          }
        | {
            __typename: "MessageTemplateComponentParameterImage";
            type: Types.MessageTemplateComponentParameterType | null;
            image: {
              __typename: "MessageTemplateHeaderMediaParameters";
              id: string | null;
              link: string | null;
            } | null;
          }
        | {
            __typename: "MessageTemplateComponentParameterPayload";
            type: Types.MessageTemplateComponentParameterType | null;
          }
        | {
            __typename: "MessageTemplateComponentParameterText";
            text: string | null;
            type: Types.MessageTemplateComponentParameterType | null;
          }
        | {
            __typename: "MessageTemplateComponentParameterVideo";
            type: Types.MessageTemplateComponentParameterType | null;
            video: {
              __typename: "MessageTemplateHeaderMediaParameters";
              id: string | null;
              link: string | null;
            } | null;
          }
        | null
      > | null;
    } | null> | null;
  } | null;
  interactive: {
    __typename: "Interactive";
    type: Types.InteractiveType | null;
    body: { __typename: "InteractiveBody"; text: string | null } | null;
    buttonReply: { __typename: "ButtonReply"; id: string | null; title: string | null } | null;
    listReply: { __typename: "ListReply"; id: string | null; title: string | null; description: string | null } | null;
  } | null;
};

export type ChatDetailsFragment = {
  __typename: "Chat";
  type: Types.ChatType | null;
  unreadCount: number | null;
  icon: string | null;
  timestamp: any | null;
  outboundTimestamp: any | null;
  updatedAt: any | null;
  lastReaction: {
    __typename: "MessageReaction";
    reaction: string;
    timestamp: any;
    message: ({ __typename: "Message" } & MessageForChatListFragment) | null;
  } | null;
  lastMessage: ({ __typename: "Message" } & MessageForChatListFragment) | null;
  lease:
    | ({ __typename: "Lease"; childLease: ({ __typename: "Lease" } & LeaseMinFragment) | null } & LeaseMinFragment)
    | null;
  number: { __typename: "Number"; uuid: string | null } | null;
} & ChatMediumFragment;

export type ChatSearchResultsFragment = {
  __typename: "ChatSearchResults";
  count: number | null;
  results: Array<({ __typename: "Chat" } & ChatDetailsFragment) | null> | null;
};

export const ChatMinFragmentDoc = gql`
  fragment ChatMin on Chat {
    uuid
    title
    owner
    state
    inboundTimestamp
  }
`;
export const ChatMediumFragmentDoc = gql`
  fragment ChatMedium on Chat {
    ...ChatMin
    assignedTo {
      firstName
      lastName
      profilePicture
      uuid
    }
  }
  ${ChatMinFragmentDoc}
`;
export const MessageForChatListFragmentDoc = gql`
  fragment MessageForChatList on Message {
    uuid
    insertedAt
    content
    messageType
    isHandled
    templateButtonText
    lastStatus
    messageType
    hsm {
      elementName
      language {
        code
      }
      localizableParams {
        default
      }
    }
    template {
      name
      language {
        code
      }
      components {
        type
        subType
        index
        parameters {
          type
          ... on MessageTemplateComponentParameterText {
            text
          }
          ... on MessageTemplateComponentParameterVideo {
            video {
              id
              link
            }
          }
          ... on MessageTemplateComponentParameterImage {
            image {
              id
              link
            }
          }
          ... on MessageTemplateComponentParameterDocument {
            document {
              id
              link
              filename
            }
          }
        }
      }
    }
    interactive {
      body {
        text
      }
      buttonReply {
        id
        title
      }
      listReply {
        id
        title
        description
      }
      type
    }
  }
`;
export const ChatDetailsFragmentDoc = gql`
  fragment ChatDetails on Chat {
    ...ChatMedium
    type
    unreadCount
    icon
    timestamp
    outboundTimestamp
    updatedAt
    lastReaction {
      reaction
      timestamp
      message {
        ...MessageForChatList
      }
    }
    lastMessage {
      ...MessageForChatList
    }
    lease {
      childLease {
        ...LeaseMin
      }
      ...LeaseMin
    }
    number {
      uuid
    }
  }
  ${ChatMediumFragmentDoc}
  ${MessageForChatListFragmentDoc}
  ${LeaseMinFragmentDoc}
`;
export const ChatSearchResultsFragmentDoc = gql`
  fragment ChatSearchResultsFragment on ChatSearchResults {
    count
    results {
      ...ChatDetails
    }
  }
  ${ChatDetailsFragmentDoc}
`;
