/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * All possible types of rules
 */
export enum ActionTypeType {
  CANCEL_TIMER = "CANCEL_TIMER",
  DISPATCH_WEBHOOK = "DISPATCH_WEBHOOK",
  REPLY = "REPLY",
  START_STACK = "START_STACK",
  START_THREAD = "START_THREAD",
  START_TIMER = "START_TIMER",
  UPDATE_CONTACT = "UPDATE_CONTACT",
}

/**
 * All possible types of actions cards
 */
export enum ActionsCardType {
  AUTOMATION = "AUTOMATION",
  WEBHOOK = "WEBHOOK",
}

/**
 * All possible types of automator operators
 */
export enum AutomatorOperatorType {
  AND = "AND",
}

/**
 * The backend type for a number
 */
export enum BackendType {
  CHANNEL = "CHANNEL",
  WHATSAPP = "WHATSAPP",
  WHATSAPP_CAPI = "WHATSAPP_CAPI",
}

/**
 * All possible types of interactive buttons
 */
export enum ButtonType {
  REPLY = "REPLY",
}

/**
 * All possible types of cards
 */
export enum CardType {
  BUTTONS = "BUTTONS",
  FAQ = "FAQ",
  LOCATION = "LOCATION",
  MESSAGE = "MESSAGE",
  MULTI = "MULTI",
  OPT_IN = "OPT_IN",
  STICKER = "STICKER",
  TEXT = "TEXT",
}

/**
 * The verification status of a Facebook Business.
 */
export enum FacebookBusinessVerificationStatus {
  FAILED = "FAILED",
  NOT_VERIFIED = "NOT_VERIFIED",
  PENDING = "PENDING",
  PENDING_NEED_MORE_INFO = "PENDING_NEED_MORE_INFO",
  PENDING_SUBMISSION = "PENDING_SUBMISSION",
  REJECTED = "REJECTED",
  REVOKED = "REVOKED",
  VERIFIED = "VERIFIED",
}

/**
 * All possible types of fields
 */
export enum FieldTypeType {
  BOOLEAN = "BOOLEAN",
  DATETIME = "DATETIME",
  ENUM = "ENUM",
  FLOAT = "FLOAT",
  INTEGER = "INTEGER",
  LOCATION = "LOCATION",
  STRING = "STRING",
}

/**
 * Number account mode (limited trial or live)
 */
export enum GraphAccountMode {
  LIVE = "LIVE",
  SANDBOX = "SANDBOX",
}

/**
 * Number name verification status
 */
export enum GraphNameStatus {
  APPROVED = "APPROVED",
  AVAILABLE_WITHOUT_REVIEW = "AVAILABLE_WITHOUT_REVIEW",
  DECLINED = "DECLINED",
  EXPIRED = "EXPIRED",
  NONE = "NONE",
  PENDING_REVIEW = "PENDING_REVIEW",
  REJECTED = "REJECTED",
}

/**
 * Number quality score as computed by Facebook
 */
export enum GraphQualityScore {
  GREEN = "GREEN",
  RED = "RED",
  UNKNOWN = "UNKNOWN",
  YELLOW = "YELLOW",
}

/**
 * Number connection status
 */
export enum GraphStatus {
  BANNED = "BANNED",
  CONNECTED = "CONNECTED",
  DELETED = "DELETED",
  DISCONNECTED = "DISCONNECTED",
  FLAGGED = "FLAGGED",
  MIGRATED = "MIGRATED",
  PENDING = "PENDING",
  RATE_LIMITED = "RATE_LIMITED",
  RESTRICTED = "RESTRICTED",
  UNKNOWN = "UNKNOWN",
}

/**
 * The possible digest types
 */
export enum HmacDigestType {
  SHA256 = "SHA256",
}

/**
 * All possible types for the quick reply buttons
 */
export enum InteractiveButtonType {
  REPLY = "REPLY",
}

/**
 * All possible types of interactive headers
 */
export enum InteractiveHeaderType {
  DOCUMENT = "DOCUMENT",
  IMAGE = "IMAGE",
  TEXT = "TEXT",
  VIDEO = "VIDEO",
}

export enum LeaseScope {
  ACCOUNT = "ACCOUNT",
  STACK = "STACK",
  THREAD = "THREAD",
  WEBHOOK = "WEBHOOK",
}

/**
 * All possible types of media an attachment can have
 */
export enum MediaType {
  APPLICATION = "APPLICATION",
  AUDIO = "AUDIO",
  CONTACT = "CONTACT",
  DOCUMENT = "DOCUMENT",
  IMAGE = "IMAGE",
  LOCATION = "LOCATION",
  STICKER = "STICKER",
  TEXT = "TEXT",
  VIDEO = "VIDEO",
  VOICE = "VOICE",
}

export enum MessageTemplateButtonType {
  PHONE_NUMBER = "PHONE_NUMBER",
  QUICK_REPLY = "QUICK_REPLY",
  URL = "URL",
}

/**
 * A valid message template category
 */
export enum MessageTemplateCategoryType {
  ACCOUNT_UPDATE = "ACCOUNT_UPDATE",
  ALERT_UPDATE = "ALERT_UPDATE",
  APPOINTMENT_UPDATE = "APPOINTMENT_UPDATE",
  AUTHENTICATION = "AUTHENTICATION",
  AUTO_REPLY = "AUTO_REPLY",
  ISSUE_RESOLUTION = "ISSUE_RESOLUTION",
  MARKETING = "MARKETING",
  OTP = "OTP",
  PAYMENT_UPDATE = "PAYMENT_UPDATE",
  PERSONAL_FINANCE_UPDATE = "PERSONAL_FINANCE_UPDATE",
  RESERVATION_UPDATE = "RESERVATION_UPDATE",
  SHIPPING_UPDATE = "SHIPPING_UPDATE",
  TICKET_UPDATE = "TICKET_UPDATE",
  TRANSACTIONAL = "TRANSACTIONAL",
  TRANSPORTATION_UPDATE = "TRANSPORTATION_UPDATE",
  UTILITY = "UTILITY",
}

/**
 * A valid message template language
 */
export enum MessageTemplateLanguageType {
  AF = "AF",
  AR = "AR",
  AZ = "AZ",
  BG = "BG",
  BN = "BN",
  CA = "CA",
  CS = "CS",
  DA = "DA",
  DE = "DE",
  EL = "EL",
  EN = "EN",
  EN_GB = "EN_GB",
  EN_US = "EN_US",
  ES = "ES",
  ES_AR = "ES_AR",
  ES_ES = "ES_ES",
  ES_MX = "ES_MX",
  ET = "ET",
  FA = "FA",
  FI = "FI",
  FIL = "FIL",
  FR = "FR",
  GA = "GA",
  GU = "GU",
  HA = "HA",
  HE = "HE",
  HI = "HI",
  HR = "HR",
  HU = "HU",
  ID = "ID",
  IT = "IT",
  JA = "JA",
  KK = "KK",
  KN = "KN",
  KO = "KO",
  LO = "LO",
  LT = "LT",
  LV = "LV",
  MK = "MK",
  ML = "ML",
  MR = "MR",
  MS = "MS",
  NB = "NB",
  NL = "NL",
  PA = "PA",
  PL = "PL",
  PT_BR = "PT_BR",
  PT_PT = "PT_PT",
  RO = "RO",
  RU = "RU",
  SK = "SK",
  SL = "SL",
  SQ = "SQ",
  SR = "SR",
  SV = "SV",
  SW = "SW",
  TA = "TA",
  TE = "TE",
  TH = "TH",
  TR = "TR",
  UK = "UK",
  UR = "UR",
  UZ = "UZ",
  VI = "VI",
  ZH_CN = "ZH_CN",
  ZH_HK = "ZH_HK",
  ZH_TW = "ZH_TW",
  ZU = "ZU",
}

export enum MessageTemplateSchemaComponentFormat {
  DOCUMENT = "DOCUMENT",
  IMAGE = "IMAGE",
  TEXT = "TEXT",
  VIDEO = "VIDEO",
}

export enum MessageTemplateSchemaComponentType {
  BODY = "BODY",
  BUTTONS = "BUTTONS",
  FOOTER = "FOOTER",
  HEADER = "HEADER",
}

export enum MessageTemplateStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

/**
 * All possible statuses for a message template
 */
export enum MessageTemplateStatusType {
  APPROVED = "APPROVED",
  DELETED = "DELETED",
  DISABLED = "DISABLED",
  FLAGGED = "FLAGGED",
  IN_APPEAL = "IN_APPEAL",
  PAUSED = "PAUSED",
  PENDING = "PENDING",
  PENDING_DELETION = "PENDING_DELETION",
  REJECTED = "REJECTED",
}

/**
 * The number type for the number
 */
export enum NumberType {
  CHANNEL_HTTP_API = "CHANNEL_HTTP_API",
  DIRECT_NUMBER = "DIRECT_NUMBER",
  VIRTUAL_NUMBER = "VIRTUAL_NUMBER",
  VIRTUAL_NUMBER_PROVIDER = "VIRTUAL_NUMBER_PROVIDER",
}

/**
 * The possible types of a number vertical
 */
export enum NumberVerticalType {
  AUTOMOTIVE = "AUTOMOTIVE",
  BEAUTY_SPA_AND_SALON = "BEAUTY_SPA_AND_SALON",
  CLOTHING_AND_APPAREL = "CLOTHING_AND_APPAREL",
  EDUCATION = "EDUCATION",
  ENTERTAINMENT = "ENTERTAINMENT",
  EVENT_PLANNING_AND_SERVICE = "EVENT_PLANNING_AND_SERVICE",
  FINANCE_AND_BANKING = "FINANCE_AND_BANKING",
  FOOD_AND_GROCERY = "FOOD_AND_GROCERY",
  HOTEL_AND_LODGING = "HOTEL_AND_LODGING",
  MEDICAL_AND_HEALTH = "MEDICAL_AND_HEALTH",
  NON_PROFIT = "NON_PROFIT",
  OTHER = "OTHER",
  PROFESSIONAL_SERVICES = "PROFESSIONAL_SERVICES",
  PUBLIC_SERVICE = "PUBLIC_SERVICE",
  RESTAURANT = "RESTAURANT",
  SHOPPING_AND_RETAIL = "SHOPPING_AND_RETAIL",
  TRAVEL_AND_TRANSPORTATION = "TRAVEL_AND_TRANSPORTATION",
}

/**
 * The provider of the phone number. Records if the phone number is owned
 * by the client or was provided by Turn via Sudonum.
 */
export enum PhoneNumberProvider {
  CLIENT_OWNED = "CLIENT_OWNED",
  SUDONUM = "SUDONUM",
  SUDONUM_CUSTOM = "SUDONUM_CUSTOM",
}

/**
 * The type of signup. Records if the number was signed up by the client
 * via Embedded Signup or if it was signed up manually by us (the BSP).
 */
export enum SignupType {
  BSP_SIGNUP = "BSP_SIGNUP",
  CUSTOM = "CUSTOM",
  EMBEDDED_SIGNUP = "EMBEDDED_SIGNUP",
}

export enum SubscriptionState {
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  DECOMMISSIONED = "DECOMMISSIONED",
  DELETED = "DELETED",
  PAST_DUE = "PAST_DUE",
  PAUSED = "PAUSED",
  PENDING_PAUSE = "PENDING_PAUSE",
  TRIALING = "TRIALING",
}

/**
 * All possible types of rules
 */
export enum TriggerTypeType {
  BUTTON = "BUTTON",
  LIST = "LIST",
  MESSAGE_INBOUND = "MESSAGE_INBOUND",
  MESSAGE_INBOUND_CATCH_ALL = "MESSAGE_INBOUND_CATCH_ALL",
  TIMER = "TIMER",
}

/**
 * The review status of a WABA.
 */
export enum WabaReviewStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export enum WabizClientProvisionState {
  NOT_PROVISIONED = "NOT_PROVISIONED",
  PROVISIONED = "PROVISIONED",
  UNKNOWN = "UNKNOWN",
}

/**
 * The WhatsApp tier the number is on. A number's tier decides
 * how many business-initiated conversations the business can start
 * in a rolling 24 hour window.
 */
export enum WhatsappTier {
  TIER_100K = "TIER_100K",
  TIER_10K = "TIER_10K",
  TIER_1K = "TIER_1K",
  TIER_250 = "TIER_250",
  TIER_50 = "TIER_50",
  TIER_UNLIMITED = "TIER_UNLIMITED",
}

/**
 * Actions Card input object, for creating all types of action cards with
 */
export interface ActionsCardIo {
  webhookUrl?: string | null;
}

/**
 * An action for an automator
 */
export interface AutomatorActionIo {
  actionType?: ActionTypeType | null;
  actionParams?: any | null;
}

export interface AutomatorConfigIo {
  version?: string | null;
  data: AutomatorRuleIo;
}

/**
 * The config for an automator rule
 */
export interface AutomatorRuleIo {
  operator: AutomatorOperatorType;
  triggers: (AutomatorTriggerIo | null)[];
  actions: (AutomatorActionIo | null)[];
}

/**
 * A trigger for an automator
 */
export interface AutomatorTriggerIo {
  triggerType?: TriggerTypeType | null;
  triggerParams?: any | null;
}

/**
 * Button input object
 */
export interface ButtonIo {
  uuid?: string | null;
  type: InteractiveButtonType;
  title: string;
  position: number;
}

export interface ContentIo {
  content?: string | null;
  attachmentId?: string | null;
  title?: string | null;
  language?: string | null;
  numberTags?: (string | null)[] | null;
  noun?: string | null;
  type?: string | null;
  saveToField?: string | null;
  answers?: (string | null)[] | null;
  errorMessage?: string | null;
  metadata?: InteractiveMetadataIo | null;
  buttons?: (ButtonIo | null)[] | null;
}

/**
 * Interactive Metadata Header input object
 */
export interface InteractiveMetadataHeaderIo {
  type: InteractiveHeaderType;
  text?: string | null;
}

/**
 * Interactive Metadata input object
 */
export interface InteractiveMetadataIo {
  header?: InteractiveMetadataHeaderIo | null;
  footer?: string | null;
}

/**
 * Message Template input object, for creating Message Templates with
 */
export interface MessageTemplateIo {
  name: string;
  content?: string | null;
  language: MessageTemplateLanguageType;
  category: MessageTemplateCategoryType;
  components?: (MessageTemplateIoComponent | null)[] | null;
}

/**
 * A message template schema button
 */
export interface MessageTemplateIoButton {
  type: MessageTemplateButtonType;
  text: string;
  phoneNumber?: string | null;
  url?: string | null;
  example?: (string | null)[] | null;
}

/**
 * A message template schema component
 */
export interface MessageTemplateIoComponent {
  type: MessageTemplateSchemaComponentType;
  format?: MessageTemplateSchemaComponentFormat | null;
  text?: string | null;
  buttons?: (MessageTemplateIoButton | null)[] | null;
  example?: MessageTemplateIoExample | null;
}

/**
 * A message template component example
 */
export interface MessageTemplateIoExample {
  bodyText?: ((string | null)[] | null)[] | null;
  headerText?: (string | null)[] | null;
  headerHandle?: (string | null)[] | null;
}

/**
 * A number input object, to modify a number via graphql
 */
export interface NumberIo {
  fallbackChannelUrl?: string | null;
  fallbackErrorThreshold?: number | null;
  bigqueryServiceAccount?: any | null;
  address?: string | null;
  about?: string | null;
  description?: string | null;
  email?: string | null;
  notificationsEmail?: string | null;
  vertical?: NumberVerticalType | null;
  websites?: (string | null)[] | null;
  isRoutingEnabled?: boolean | null;
  operatorCapacityForRouting?: number | null;
}

export interface ThreadCardAssociationIo {
  uuid: string;
  targetCardUuids?: (string | null)[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
