import React from "react";

export const DownloadIcon = ({ fill = "#fff", ...props }) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 9.5H16.59C17.48 9.5 17.92 10.58 17.29 11.21L12.7 15.8C12.31 16.19 11.68 16.19 11.29 15.8L6.7 11.21C6.07 10.58 6.52 9.5 7.41 9.5H9V4.5C9 3.95 9.45 3.5 10 3.5H14C14.55 3.5 15 3.95 15 4.5V9.5ZM6 20.5C5.45 20.5 5 20.05 5 19.5C5 18.95 5.45 18.5 6 18.5H18C18.55 18.5 19 18.95 19 19.5C19 20.05 18.55 20.5 18 20.5H6Z"
      fill={fill}
    />
  </svg>
);
export const DownloadIconBar = ({ fill = "#00979E", ...props }) => (
  <svg width={17} height={16} viewBox="0 0 17 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 9.96V3.167a.5.5 0 011 0V9.96l2.293-2.293a.5.5 0 11.707.707l-2.793 2.793a1 1 0 01-1.414 0L5 8.374a.5.5 0 01.707-.707L8 9.96zm-5.5.707a.5.5 0 011 0v2h10v-2a.5.5 0 011 0v2a1 1 0 01-1 1h-10a1 1 0 01-1-1v-2z"
      fill={fill}
    />
  </svg>
);
