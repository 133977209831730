import gql from "graphql-tag";

export const BIG_QUERY_STATE_CHANGED = gql`
  subscription onStreamStateChanged($numberUuid: ID!) {
    onStreamStateChanged(numberUuid: $numberUuid) {
      hasBigqueryServiceAccount
      streamStates {
        uuid
        table
        lastSync
      }
    }
  }
`;
