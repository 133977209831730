export const aliasTokens = {
  color: {
    backgroundColorLavender: "#D4B6FF",
    backgroundColorBerry: "#FF714D",
    backgroundColorSun: "#FBD90E",
    backgroundColorSky: "#64DBFE",
    backgroundColorLime: "#A6FB6D",
    backgroundColorOrange: "#FE9C2C",
    backgroundColorCream: "#FFFCEE",
    backgroundColorBlack: "#000",
    backgroundColorWhite: "#fff",
    backgroundColorGray: "#F9F9FA",
    backgroundColorTest: "#FBD90E",
    interactiveColorHover: "#40384C",
    interactiveColorHoverAlt: "#F0E3FF",
    interactiveColorActive: "#E5D2FF",
    interactiveColorDefault: "#000",
    interactiveColorDisabled: "#CACCCD",
    interactiveColorAction: "#8654CD",
    interactiveColorActiveAlt: "#8654CD",
    interactiveColorHoverGray: "#D9DADB",
    feedbackColorBackgroundInformative: "#A6D0FF",
    feedbackColorBackgroundCritical: "#FFCFC8",
    feedbackColorBackgroundSuccess: "#ADE8B6",
    feedbackColorBackgroundWarning: "#FFD29F",
    feedbackColorCritical: "#FD7268",
    feedbackColorSuccess: "#49CC5D",
    feedbackColorInformative: "#0072F3",
    feedbackColorWarning: "#FE9C2C",
    textColorDefault: "#000",
    textColorSubtle: "#5A5F62",
    textColorNonessential: "#9B9EA0",
    textColorAction: "#8654CD",
    textColorOnDark: "#fff",
    iconColorStrokeDefault: "#000",
    iconColorStrokeDisabled: "#CACCCD",
    borderColorDefault: "#E7E8E9",
  },
  iconSize: {
    small: "16px",
    medium: "20px",
    large: "24px",
  },
};

export type AliasColorType =
  | "backgroundColorLavender"
  | "backgroundColorBerry"
  | "backgroundColorSun"
  | "backgroundColorSky"
  | "backgroundColorLime"
  | "backgroundColorOrange"
  | "backgroundColorCream"
  | "backgroundColorBlack"
  | "backgroundColorWhite"
  | "backgroundColorGray"
  | "backgroundColorTest"
  | "interactiveColorHover"
  | "interactiveColorHoverAlt"
  | "interactiveColorActive"
  | "interactiveColorDefault"
  | "interactiveColorDisabled"
  | "interactiveColorAction"
  | "interactiveColorActiveAlt"
  | "interactiveColorHoverGray"
  | "feedbackColorBackgroundInformative"
  | "feedbackColorBackgroundCritical"
  | "feedbackColorBackgroundSuccess"
  | "feedbackColorBackgroundWarning"
  | "feedbackColorCritical"
  | "feedbackColorSuccess"
  | "feedbackColorInformative"
  | "feedbackColorWarning"
  | "textColorDefault"
  | "textColorSubtle"
  | "textColorNonessential"
  | "textColorAction"
  | "textColorOnDark"
  | "iconColorStrokeDefault"
  | "iconColorStrokeDisabled"
  | "borderColorDefault";

export type AliasIconSizeType = "small" | "medium" | "large";
