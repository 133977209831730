import { ApolloLink, FetchResult, HttpLink, NextLink, Operation } from "@apollo/client";
import { getOperationDefinition, getOperationName } from "@apollo/client/utilities";
import { FormattedExecutionResult, OperationTypeNode } from "graphql";
import _findLast from "lodash/findLast";
import _isEmpty from "lodash/isEmpty";

import { getToken } from "./API";

interface RequestsLogItem {
  type: "mutation" | "query" | "subscription";
  name: string;
  variables: Record<string, any>;
  operation: Operation;
  result?: FormattedExecutionResult;
}

export class RequestsLog {
  log: RequestsLogItem[];
  constructor() {
    this.log = [];
  }

  push(item: RequestsLogItem) {
    this.log.push(item);
  }
  clear() {
    this.log = [];
  }
  last(options: { type?: string; name?: string }) {
    return _findLast(this.log, ({ name, type }) => {
      return (!options.type || options.type === type) && (!options.name || options.name === name);
    });
  }
}

const logCollapsed = (name: string, message?: any) => {
  console.groupCollapsed(name);
  console.log(message);
  console.groupEnd();
};
const logObject = <T extends {}>(name: string, obj?: T | null) => {
  if (!_isEmpty(obj)) {
    logCollapsed(name, JSON.stringify(obj, null, 2));
  }
};
enum Colors {
  LIGHT = "#909090",
  DARK = "#606060",
  SUCCESS = "#60B000",
  ERROR = "#F02020",
  SUBSCRIPTION = "#00A0D0",
}
interface IWriteOperation {
  operationType: OperationTypeNode;
  operation: Operation;
  result?: FetchResult;
  error?: Error;
}
const writeOperationToConsole = ({ operationType, operation, result, error }: IWriteOperation) => {
  const { operationName, variables } = operation;
  const query = operation.query.loc?.source.body;

  const time =
    operationType === "subscription"
      ? `@ ${new Date().toLocaleTimeString()}`
      : `(in ${Date.now() - operation.getContext().start} ms)`;

  // context (alexandrchebotar, 2021-12-20): there is already merged, but unfortunally not released yet, workaround in
  //   @absithe/socket for 'request: [object Object]` issue. After it released we could replace [error] with error.object.
  const errors = error ? [error] : (result?.errors ?? result?.data?.[operationName]?.errors);

  (errors ? console.group : console.groupCollapsed)(
    `%capollo %c${operationType} %c${operationName} %c${time}`,
    `color: ${Colors.LIGHT}; font-weight: lighter`,
    `color: ${
      operationType === "subscription" && !result ? Colors.SUBSCRIPTION : errors ? Colors.ERROR : Colors.SUCCESS
    }; font-weight: bold;`,
    `color: ${Colors.DARK}; font-weight: bold`,
    `color: ${Colors.LIGHT}; font-weight: lighter`
  );

  if (errors) {
    errors.forEach((err: any) => {
      console.log(`%cError: ${err.message}`, `color: ${Colors.ERROR}; font-weight: lighter`);
    });
  }

  logObject("VARIABLES", variables);
  logCollapsed("QUERY", query);
  logObject("RESULT", result?.data);
  logObject("ERRORS", errors);
  logCollapsed("RAW", {
    operation,
    result,
  });

  console.groupEnd();
};

const writeOperationToRequestsLog = ({ operationType, operation, result }: IWriteOperation) => {
  const logItem = {
    type: operationType,
    name: operation.operationName,
    variables: operation.variables,
    operation,
    result,
  };

  window.__requestsLog?.push(logItem);
};

export class LoggerLink extends ApolloLink {
  constructor() {
    window.__requestsLog = new RequestsLog();

    super((operation: Operation, forward: NextLink) => {
      operation.setContext({ start: Date.now() });

      const operationType = getOperationDefinition(operation.query)?.operation;

      if (operationType === "subscription") {
        writeOperationToConsole({ operationType, operation });
      }

      return operationType
        ? forward(operation).map((result) => {
            writeOperationToConsole({ operationType, operation, result });
            writeOperationToRequestsLog({ operationType, operation, result });

            return result;
          })
        : forward(operation);
    });
  }
}

export const shouldUseAbsintheSocketLink = ({ query }: Operation) => {
  const operationName = getOperationName(query) || "";

  // Queries related to the Build simulator need to use the websocket
  // link because they rely on server-side state, which is kept inside
  // the backend process serving the websocket.
  const isBuildSimulatorQuery = operationName.toLowerCase().includes("simulator");
  const isSubscription = getOperationDefinition(query)?.operation === "subscription";

  return isSubscription || isBuildSimulatorQuery || operationName === "submitTextMessage";
};

interface ICreateHttpLink {
  uri: string;
  onAuthenticationError?: () => void;
}
export const createHttpLink = ({ uri, onAuthenticationError }: ICreateHttpLink) =>
  new HttpLink({
    uri,
    credentials: "include",
    fetch: (url, options) =>
      getToken().then((csrf_token) =>
        fetch(url, {
          ...options,
          headers: {
            ...options?.headers,
            "x-csrf-token": csrf_token,
          },
        }).then((response) => {
          if (response.status === 401 || response.status === 403) {
            onAuthenticationError?.();
          }

          return response;
        })
      ),
  });
