import gql from "graphql-tag";

import { AUTOMATOR_DETAILS_FRAGMENT } from "./fragments";

export const GET_AUTOMATORS = gql`
  query getNumberAutomators(
    $numberUuid: ID!
    $orderBy: String
    $limit: Int
    $offset: Int
    $search: String
    $actionType: ActionTypeType
  ) {
    number(numberUuid: $numberUuid) {
      uuid
      automatorsCount(search: $search)
      automators(orderBy: $orderBy, limit: $limit, offset: $offset, search: $search, actionType: $actionType) {
        ...AutomatorDetails
      }
    }
  }

  ${AUTOMATOR_DETAILS_FRAGMENT}
`;

export const GET_TIMER_ACTIONS = gql`
  query getNumberTimerAutomators($numberUuid: ID!, $actionType: ActionTypeType = START_TIMER, $limit: Int = null) {
    number(numberUuid: $numberUuid) {
      uuid
      automators(limit: $limit, actionType: $actionType) {
        uuid
        config {
          data {
            actions {
              actionParams
              actionType
            }
          }
        }
      }
    }
  }
`;
