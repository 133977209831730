import gql from "graphql-tag";

import { required } from "api/boundary";

import { ACCOUNT_DETAILS_FRAGMENT, ORGANISATION_ACCOUNT_DETAILS_FRAGMENT } from "../accounts";
import { NUMBER_MIN_FRAGMENT_ } from "../number";

export const LIST_ORGANISATION_TOKENS_FRAGMENT = gql`
  fragment ListOrganisationTokensDetails on Organisation {
    uuid
    organisationTokens {
      uuid
      type
      scope
      name
      summary
      createdAt
      expiresAt
    }
  }
`;

export const ONBOARDING_APPLICATION_FRAGMENT = gql`
  fragment onboardingApplication on OnboardingApplication {
    customNumberRequest {
      country
      state
    }
    eulaAccepted
    phoneNumber
    phoneNumberProvider
    status
    uuid
    poolNumber {
      uuid
      fbGraphId
      phoneNumber
    }
  }
`;

export const ORGANISATION_DETAILS_FRAGMENT = {
  query: gql`
    fragment OrganisationDetails on Organisation {
      insertedAt
      name
      uuid
      type
      signupType
      signupReferral
      privacyEnabled
      waba {
        fbGraphId
        uuid
        cachedFreeMonthlyConversationsCount
        conversationsWaived
        conversationsWaiverCustomAmount
      }
      numbers {
        ...NumberMinFragment_
      }
      organisationAccounts {
        ...OrganisationAccountDetails
      }
      myAccount {
        ...AccountDetails
      }
      activeOnboardingApplication {
        ...onboardingApplication
      }
    }

    ${ONBOARDING_APPLICATION_FRAGMENT}
    ${NUMBER_MIN_FRAGMENT_.query}
    ${ACCOUNT_DETAILS_FRAGMENT.query}
    ${ORGANISATION_ACCOUNT_DETAILS_FRAGMENT.query}
  `,
  parse: {
    uuid: required(),
    name: required(),
    numbers: NUMBER_MIN_FRAGMENT_.parse,
    myAccount: ACCOUNT_DETAILS_FRAGMENT.parse,
    organisationAccounts: ORGANISATION_ACCOUNT_DETAILS_FRAGMENT.parse,
  },
};
