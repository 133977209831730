import { ContactFieldDetails } from "graphqlQueries/generated/ContactFieldDetails";
import { convertToOptions, dontSaveOption } from "utils/options";

export const selectCardContactFields = (contactFields: ContactFieldDetails[]) => [
  dontSaveOption,
  ...contactFields.filter(
    (field) =>
      field.fieldType === "STRING" && field.fieldName !== "whatsapp_id" && field.fieldName !== "whatsapp_profile_name"
  ),
];

export const selectCardContactFieldOptions = (contactFields: ContactFieldDetails[]) =>
  convertToOptions(selectCardContactFields(contactFields), "fieldDisplay", "fieldName");

export const getContactFieldFromScheme = (
  number: { contactSchema: { contactFields: ContactFieldDetails[] } },
  contactFieldName?: string
) => {
  return number.contactSchema.contactFields.find((cf) => cf.fieldName === contactFieldName);
};
