import { Box } from "ds/Box";
import { BoxProps } from "ds/Box/src";
import React, { useEffect, useState } from "react";

import { DEFAULT_APP_CONFIG } from "_constants";

const { profitwell_public_token } = window.APP_CONFIG || DEFAULT_APP_CONFIG;

interface PaddleCheckoutFrameProps extends Omit<BoxProps, "onError"> {
  sellerId: number;
  _ptxn: string;
  onError?: (type: "FIELD" | "PADDLE") => void;
  onLoading?: (value: boolean) => void;
  onCompleted?: () => void;
  frameTarget?: string;
  children?: React.ReactNode;
}

export const PaddleCheckoutFrame = ({
  sellerId,
  _ptxn,
  frameTarget = "checkout-frame",
  onError,
  onLoading,
  onCompleted,
  children,
  ...props
}: PaddleCheckoutFrameProps) => {
  const [paddle, setPaddle] = useState<any>();

  useEffect(() => {
    if (!_ptxn || !sellerId) {
      onError?.("FIELD");
    }
  }, [_ptxn, onError, sellerId]);

  useEffect(() => {
    const paddleDocumentScript = document.getElementById("paddle") as HTMLScriptElement | undefined;

    if (window["Paddle"] && paddleDocumentScript?.src === "https://cdn.paddle.com/paddle/v2/paddle.js") {
      setPaddle(window["Paddle"]);
    } else {
      window["Paddle"] = undefined;
      paddleDocumentScript?.remove();

      const script = document.createElement("script");
      script.src = "https://cdn.paddle.com/paddle/v2/paddle.js";
      script.async = true;
      script.id = "paddle";
      script.onload = () => setPaddle(window["Paddle"]);

      document.head.appendChild(script);
    }
  }, [onLoading]);

  useEffect(() => {
    if (paddle && _ptxn && sellerId) {
      if (
        process.env.NODE_ENV !== "production" ||
        ["qa.whatsapp.turn.io", "pr.turn.io"].includes(window.location.host)
      ) {
        paddle.Environment.set("sandbox");
      }
      paddle.Setup({
        pwAuth: profitwell_public_token,
        seller: sellerId,
        checkout: {
          settings: {
            displayMode: "inline",
            allowLogout: false,
            theme: "light",
            locale: "en",
            frameInitialHeight: "500",
            frameTarget,
            frameStyle: "width: 100%; min-width: 375px; background-color: transparent; border: none;",
          },
        },
        eventCallback: (data) => {
          if (data.name === "checkout.loaded" || data.type === "checkout.error") {
            if (data.type === "checkout.error") {
              onError?.("PADDLE");
            }
            setTimeout(() => onLoading?.(false), 1000);
          }
          if (data.name === "checkout.completed") {
            onCompleted?.();
          }

          if (data.name === "checkout.payment.failed") {
            onLoading?.(false);
            onError?.("PADDLE");
          }
        },
      });
      paddle.Checkout.open({
        transactionId: _ptxn,
        frameTarget,
      });

      // context(Puvvl, 10-12-2023) Clean loading state in case if eventCallback doesn't return any callback
      setTimeout(() => {
        onLoading?.(false);
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_ptxn, frameTarget, paddle, sellerId]);

  return (
    <Box {...props} className={frameTarget}>
      {children}
    </Box>
  );
};
